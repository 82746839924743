import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchLoginData } from "../store/login/loginActions";

function Login() {
    const [active, setActive] = useState(false)
    const [showError, setShowError] = useState(false)
    const [labelShift, setLabelShift] = useState("text-gray-300 left-4 top-2.5 text-sm")
    const [labelShiftTwo, setLabelShiftTwo] = useState("text-gray-300 left-4 top-2.5 text-sm")
    const [loginInfos, setLoginInfos] = useState([])
    const toggleActive = () => setActive(active => !active)
    const dispatch = useDispatch()

    function shiftLabel(e) {
        if (e.target.value.length > 0) {
            setLabelShift('text-dark-primary -top-2 left-3 px-2 text-xs')
        }
        else {
            setLabelShift("text-gray-300 left-4 top-2.5 text-sm")
        }
    }
    function shiftLabelTwo(e) {
        if (e.target.value.length > 0) {
            setLabelShiftTwo('text-dark-primary -top-2 left-3 px-2 text-xs')
        }
        else {
            setLabelShiftTwo("text-gray-300 left-4 top-2.5 text-sm")
        }
    }

    function login(e) {
        dispatch(fetchLoginData(loginInfos))
    }
 
    useEffect(() => {
        if (JSON.parse(localStorage.getItem("userType") === 'admin')) {
            window.location.replace("/admin/regions")
        }
        if (JSON.parse(localStorage.getItem("userType") === 'student') || JSON.parse(localStorage.getItem("userType") === 'teacher') || JSON.parse(localStorage.getItem("userType") === 'parent')) {
            window.location.replace("/user/dashboard")
        }
    }, [])

    return (
        <div className="h-screen bg-primary bg-login-image">
            {/*<img className="bg-cover absolute bg-center h-full w-full z-0" src={bg} alt=""/>*/}
            <div className="flex items-center justify-center z-100 h-full">
                <div className="rounded-lg bg-white w-3/4 sm:w-2/4 xl:w-1/4">
                    <div className="flex flex-col py-8 px-14">
                        <h1 className="text-dark-primary text-center">Login</h1>
                        <div className="flex mt-16 w-full relative">
                            <input onChange={(e) => {
                                setLoginInfos({ ...loginInfos, username: e.target.value })
                                shiftLabel(e)
                            }} className="w-full px-3 p-2 outline-none border border-gray-200 focus:ring-1 focus:ring-green-500 focus:border-transparent" type="text" />
                            <span className={`absolute bg-white pointer-events-none transition transition-all ${labelShift}`}>Username</span>
                        </div>
                        <div className="flex mt-6 w-full relative">
                            <input onChange={(e) => {
                                setLoginInfos({ ...loginInfos, password: e.target.value })
                                shiftLabelTwo(e)
                            }} className="w-full px-3 p-2 outline-none border border-gray-200 focus:ring-1 focus:ring-green-500 focus:border-transparent" type="password" />
                            <span className={`absolute bg-white pointer-events-none transition transition-all ${labelShiftTwo}`}>Password</span>
                        </div>
                        <div className={`text-red-700 mt-8 text-left ${showError ? '' : 'hidden'}`}>Sorry your informations doesn't match!</div>
                        <div className="flex justify-between mt-12 w-full">
                            <div className="flex items-center">
                                <input id="rememberMe" className="hidden px-3 py-1 outline-none border border-gray-200 focus:border-primary" type="checkbox" />
                                <label onClick={toggleActive} htmlFor="rememberMe" className={`border cursor-pointer border-2 border-gray-300 rounded-full h-4 w-4 ${active ? "bg-gray-400" : "bg-white"}`}>

                                </label>
                                <label onClick={toggleActive} htmlFor="rememberMe" className="text-gray-400 text-xs ml-2 cursor-pointer">Remember Me</label>
                            </div>
                            <button onClick={login} className="bg-dark-primary text-white py-1 px-3 rounded-md">Login</button>
                        </div>
                        <div className="flex items-center mt-12 w-full">
                            <div className="h-2 border-b border-dashed border-gray-300 mb-1 flex-1 mr-2"></div>
                            <div className="text-dark-primary">Login With</div>
                            <div className="h-2 border-b border-dashed border-gray-300 mb-1 flex-1 ml-2"></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center p-8 bg-sign-up">
                        {/* <button className="text-white">Sign Up</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;