import { ApiUrl } from "../../components/utils/Urls";
import { selfClassActions } from "./selfClassSlice";
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

export const fetchSelfClassData = id => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/companySchoolroom', {
                method: 'POST',
                body: 'companyId=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch class data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const selfClassData = await fetchData()
            dispatch(selfClassActions.getSelfClasses(selfClassData.list))
        } catch (error) { }
    }
}

export const updateSelfClassData = (id, name, bookLevels) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'system/companySchoolroomEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name + '&level=' + bookLevels,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch class data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(selfClassActions.getIsSelfClass(2))
            callToaster("class_updated")
        } catch (error) { }
    }
}

export const deleteSelfClassData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'system/companySchoolroomDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch education level data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(selfClassActions.getIsSelfClass(2))
            callToaster("class_deleted")
        } catch (error) { }
    }
}

export const addSelfClassData = (companyId,classes) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/companySchoolroomAdd', {
                method: 'POST', body: 'companyId=' + companyId + '&schoolroomList=' + classes,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch education level data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(selfClassActions.getIsSelfClass(2))
            dispatch(selfClassActions.getSelfClass(classes))
            // dispatch(fetchSelfClassData(companyId))
            callToaster("class_added")
        } catch (error) { }
    }
}

export const assignTeacherData = (companyId,classroomId,teacherId) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/companySchoolroomSelectTeacher', {
                method: 'POST', body: 'companyId=' + companyId + '&schoolroomId=' + classroomId + '&teacherId=' + teacherId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch education level data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(fetchSelfClassData(companyId))
            callToaster("teacher_assigned_to_class")
        } catch (error) { }
    }
}