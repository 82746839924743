import { createSlice } from "@reduxjs/toolkit";

const soundSlice = createSlice({
    name: 'sounds',
    initialState: {
        sounds: [],
        sound: ''
    },
    reducers: {
        getSounds(state, action) {
            state.sounds = action.payload
        },
        getSound(state, action) {
            state.sound = action.payload
        }
    }
})

export const soundActions = soundSlice.actions

export default soundSlice