import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import CirclePlus from "../../assets/icons/CirclePlus";
import CrossCircle from "../../assets/icons/CrossCircle";
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {
    addParentChildData,
    addParentData, deleteParentChildData,
    deleteParentData,
    fetchParentData,
    updateParentData
} from "../../store/parent/parentActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import CustomUser from "../../assets/icons/CustomUser";
import CircleArrowDownward from "../../assets/icons/CircleArrowDownward";
import {useLocation} from "react-router-dom";
import SubHeader from "../../components/header/SubHeader";
import TextInput from "../../components/textInput/TextInput";
import {TrendingUpIcon} from "@heroicons/react/solid";
import Checkbox from "@material-ui/core/Checkbox";
import DialogHeader from "../../components/dialogs/DialogHeader";
import {studentActions} from "../../store/student/studentSlice";

function Parents({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const parents = useSelector(state => state.parents.parents)
    const students = useSelector(state => state.students.students)
    // const children = useSelector(state=>state.parents.children)
    const [children,setChildren] = useState([{id:1641,name:"sa"},{id:1532,name:"as"}])
    const isParent = useSelector(state=>state.parents.isParent)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [parentId, setParentId] = useState(false)
    const [openAssign,setOpenAssign] = useState(false)
    const [searchParam] = useState(["firstName","middleName","lastName"]);
    const [query, setQuery] = useState("");

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [parent, setParent] = useState({})

    const location = useLocation()
    const  { pathname } = location
    const url = pathname.split("/")
    const corporationId = url[11]

    const cols = [
        {
            name: "firstName",
            label: language.name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <div className="flex items-center">
                            <CustomUser className="h-6 w-6" />
                            <div className="flex flex-col ml-1">
                                <div className="font-semibold">{value + " " + parents[tableMeta.rowIndex].middleName + " " + parents[tableMeta.rowIndex].lastName}</div>
                                <div className="text-xs">{parents[tableMeta.rowIndex].id}</div>
                            </div>
                        </div>
                    );
                }
            }
        },
        // {
        //     name: "parent_authorized",
        //     label: language.department,
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (value, tableMeta, rowIndex) => {
        //             return (
        //                 <div className="flex items-center space-x-4 ml-1">
        //                     <div className="text-bold">{value}</div>
        //                     <span className="rounded-md bg-soft-blue text-white text-sm py-1 px-2">+5</span>
        //                 </div>
        //             );
        //         }
        //     }
        // },
        {
            name: "mail",
            label: language.contact,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <div className="flex flex-col ml-1">
                            <div className="font-semibold">{value}</div>
                            <div className="text-xs">{parents[tableMeta.rowIndex].mail}</div>
                        </div>
                    );
                }
            }
        },
        {
            // name: language.actions,
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <TrendingUpIcon onClick={()=> {
                                // setSelfClass(selfClasses[tableMeta.rowIndex])
                                setParentId(parents[tableMeta.rowIndex].id)
                                setOpenAssign(true)
                                setOpenAdd(true)
                            }} className="bg-gray-200 opacity-70 hover:opacity-100 transition-all cursor-pointer h-6 w-6 text-blue-700 rounded-md p-1" />
                            <EditButton
                                onClick={() => {
                                    setParentId(parents[tableMeta.rowIndex].id)
                                    setParent(parents[tableMeta.rowIndex])
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    // dispatch(parentActions.getParent(parents[tableMeta.rowIndex]))
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setParentId(parents[tableMeta.rowIndex].id)
                                    // dispatch(parentActions.getParent(parents[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    function search(students) {
        return students.filter((item) => {
            return searchParam.some((newItem) => {
                console.log(newItem)
                if(item[newItem] !== null){
                    return (
                        students.length > 0 &&
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(query.toLowerCase()) > -1
                    );
                }
            });
        });
    }

    const saveParent = () => {
        dispatch(addParentData(parent,corporationId))
        setOpenAdd(false)
    }

    const deleteParent = () => {
        dispatch(deleteParentData(parentId))
        setOpenDelete(false)
    }

    const editParent = () => {
        dispatch(updateParentData(parent,corporationId))
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchParentData(corporationId))
    }, [dispatch,isParent])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <SubHeader/>
                <button onClick={()=>dispatch(deleteParentChildData())}>training</button>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.parents}
                        data={parents}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteParent}
                title={language.delete_parent}
                warning={language.parent_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={()=> {
                    setOpenAdd(false)
                    setTimeout(()=>{
                        setOpenAssign(false)
                    },500)
                }}
                cancelAddRef={cancelAddRef}
                saveItem={saveParent}
                editItem={editParent}
            >
                {
                    openAssign ?
                        <div>
                            <DialogHeader
                                closeModal={() => {setOpenAdd(false)}}
                                title={language.assign_student}
                            />
                                    {/*<div className="flex items-center space-x mt-3 mr-3">*/}
                                    {/*        <Checkbox onChange={(e) => {*/}
                                    {/*            if (e.target.checked) {*/}
                                    {/*                setChildren([*/}
                                    {/*                    ...children,*/}
                                    {/*                    {id: item.id,name:item.firstName},*/}
                                    {/*                ]);*/}
                                    {/*            } else {*/}
                                    {/*                setChildren(*/}
                                    {/*                    children.filter((y) => y.id !== item.id),*/}
                                    {/*                );*/}
                                    {/*            }*/}
                                    {/*        }}*/}
                                    {/*          id={item.id}*/}
                                    {/*          checked={children.map((x)=> {*/}
                                    {/*              return x.id*/}
                                    {/*          }).indexOf(item.id) > -1}*/}
                                    {/*          color="primary"*/}
                                    {/*          value={item.id}*/}
                                    {/*          className="h-6" />*/}
                                    {/*        <label className="cursor-pointer" htmlFor={item.id} >{item.firstName + " " + item.middleName + " " + item.lastName}</label>*/}
                                    {/*    </div>*/}
                                <div className="flex flex-col mt-3">
                                    <div className="flex space-x-1 mb-1">
                                        <span>{language.students}</span>
                                        <span className="text-red-400">*</span>
                                        <GrayQuestionMark />
                                    </div>
                                    <div className="flex relative items-center">
                                        <select onChange={(e)=> {
                                            // setCurrentClass({...currentClass, })
                                            dispatch(addParentChildData(parentId,e.target.value))
                                        }
                                        } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                            <option value="">{language.choose}</option>
                                            {
                                                students.map((item,index)=>{
                                                    return(
                                                        <option key={item.id} value={item.id}>{item.firstName + " " + item.middleName + " " + item.lastName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <CircleArrowDownward />
                                    </div>
                                </div>
                        </div>
                        :
                        <div>
                            <DialogHeader
                                closeModal={() => {setOpenAdd(false)}}
                                title={openEdit ? language.edit_parent : language.add_parent}
                            />
                            <div className="flex gap-2">
                                <TextInput
                                    label={language.name}
                                    value={openEdit ? parent.firstName : null}
                                    onChange={(e) => setParent({ ...parent, firstName: e.target.value })}
                                    placeholder={language.name}
                                />
                                <TextInput
                                    label={language.middle_name}
                                    value={openEdit ? parent.middleName : null}
                                    onChange={(e) => setParent({ ...parent, middleName: e.target.value })}
                                    placeholder={language.middle_name}
                                />
                            </div>
                            <div className="flex gap-2">
                                <TextInput
                                    label={language.last_name}
                                    value={openEdit ? parent.lastName : null}
                                    onChange={(e) => setParent({ ...parent, lastName: e.target.value })}
                                    placeholder={language.last_name}
                                />
                                <div className="flex flex-col mt-3">
                                    <div className="flex space-x-1 mb-1">
                                        <span>{language.gender}</span>
                                        <span className="text-red-400">*</span>
                                        <GrayQuestionMark />
                                    </div>
                                    <div className="flex relative items-center">
                                        <select value={openEdit ? parent.gender : null} onChange={(e)=> {
                                            setParent({...parent, gender: e.target.value})
                                        }
                                        } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                            <option value="">{language.choose}</option>
                                            <option value="0">Female</option>
                                            <option value="1">Male</option>
                                        </select>
                                        <CircleArrowDownward />
                                    </div>
                                </div>
                            </div>
                            <TextInput
                                label={language.phone}
                                value={openEdit ? parent.phone : null}
                                onChange={(e) => setParent({ ...parent, phone: e.target.value })}
                                placeholder={language.phone}
                            />
                        </div>
                }
            </AddDialog>
        </div>
    );
}

export default Parents;