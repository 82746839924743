import { ApiUrl } from "../../components/utils/Urls";
import { publishingHouseActions } from "./publishingHouseSlice";
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

export const fetchPublishingHouseData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/publisher', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch publishing house data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const publishingHouseData = await fetchData()
            dispatch(publishingHouseActions.getPublishingHouses(publishingHouseData))
        } catch (error) { }
    }
}

export const updatePublishingHouseData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/publisherEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch publishing house data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(publishingHouseActions.getPublishingHouse(''))
            callToaster("publishing_house_updated")
        } catch (error) { }
    }
}

export const deletePublishingHouseData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'library/publisherDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch publishing house data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(publishingHouseActions.getPublishingHouse(''))
            callToaster("publishing_house_deleted")
        } catch (error) { }
    }
}

export const addPublishingHouseData = publishingHouse => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'library/publisherAdd', {
                method: 'POST', body: 'name=' + publishingHouse,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch publishing house data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(publishingHouseActions.getPublishingHouse(''))
            callToaster("publishing_house_added")
        } catch (error) { }
    }
}