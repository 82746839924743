import React, { useState, useRef, Fragment, useEffect } from 'react';
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { tableTheme } from "../../components/customTable/TableTheme";
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import AddDialog from '../../components/dialogs/AddDialog';
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useLocation} from "react-router-dom";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import DeleteButton from "../../components/customTable/DeleteButton";
import NavigateButton from "../../components/customTable/NavigateButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {
    addCorporationData,
    deleteCorporationData,
    fetchCorporationData, updateCorporationData
} from "../../store/corporation/corporationActions";
import {fetchEducationTypeData} from "../../store/educationType/educationTypeActions";
import {educationTypeActions} from "../../store/educationType/educationTypeSlice";
import TextInput from "../../components/textInput/TextInput";
import DialogHeader from "../../components/dialogs/DialogHeader";

function Corporations(
    { getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const corporations = useSelector(state => state.corporations.corporations)
    const isCorporation = useSelector(state=>state.corporations.isCorporation)
    const educationTypes = useSelector(state=>state.educationTypes.educationTypes)
    const educationType = useSelector(state=>state.educationTypes.educationType)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [corporationId, setCorporationId] = useState(false)
    const [educationId,setEducationId] = useState('')

    const [school, setSchool] = useState("Ana Okul")
    const schools = ["Kindergarten", "Primary School", "Secondary School", "High School"]

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [corporation, setCorporation] = useState({})

    const location = useLocation()
    const  { pathname } = location
    const url = pathname.split("/")
    const regionId = url[3]
    const cityId = url[5]
    const ministryId = url[7]
    const directorateId = url[9]

    const cols = [
        {
            name: "name",
            label: language.corporation_name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <NavLink to={`/admin/regions/${regionId}/cities/${cityId}/ministries/${ministryId}/directorates/${directorateId}/corporations/${corporations[tableMeta.rowIndex].id}/statistics`}>{value}</NavLink>
                    );
                }
            }
        },
        {
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    setCorporationId(corporations[tableMeta.rowIndex].id)
                                    dispatch(educationTypeActions.getEducationType(corporations[tableMeta.rowIndex].educationId))
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setCorporation(corporations[tableMeta.rowIndex])
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setCorporationId(corporations[tableMeta.rowIndex].id)
                                    setOpenDelete(true)
                                }
                                }
                            />
                            <NavigateButton navigate={`/admin/regions/${regionId}/cities/${cityId}/ministries/${ministryId}/directorates/${directorateId}/corporations/${corporations[tableMeta.rowIndex].id}/statistics`} />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    const handleEducation = educationType =>{
            dispatch(educationTypeActions.getEducationType(educationType.id))
    }

    const saveCorporation = () => {
        dispatch(addCorporationData(directorateId,educationType,corporation.name))
        setOpenAdd(false)
    }

    const deleteCorporation = () => {
        dispatch(deleteCorporationData(corporationId))
        setOpenDelete(false)
    }

    const editCorporation = () => {
        dispatch(updateCorporationData(corporationId, corporation.name, educationType))
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchCorporationData(directorateId))
        dispatch(fetchEducationTypeData())
    }, [dispatch,isCorporation])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.corporations}
                        data={corporations}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteCorporation}
                title={language.delete_corporation}
                warning={language.corporation_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={setOpenAdd}
                cancelAddRef={cancelAddRef}
                saveItem={saveCorporation}
                editItem={editCorporation}
            >
                <DialogHeader
                    closeModal={() => setOpenAdd(false)}
                    title={openEdit ? language.edit_corporation : language.add_corporation}
                />
                <TextInput
                    label={language.corporation_name}
                    value={openEdit ? corporation.name : null}
                    onChange={(e) => setCorporation({ ...corporation, name: e.target.value })}
                    placeholder={language.corporation_name}
                />
                <div className="flex flex-col mt-3">
                    <div className="flex space-x-1 mb-1">
                        <span>{language.education_types}</span>
                        <span className="text-red-400">*</span>
                        <GrayQuestionMark />
                    </div>
                    <div className="flex flex-wrap relative items-center gap-2">
                        {
                            educationTypes.map(function (item) {
                                return (
                                    <label onClick={()=>handleEducation(item)} className={`rounded-lg p-5 h-16 border border-dashed text-sm flex justify-center border-gray-200 ${item.id === educationType ? "border-primary" : ""}`}>{item.name}</label>
                                )
                            })
                        }
                    </div>
                </div>
            </AddDialog>
        </div>
    );
}

export default Corporations;