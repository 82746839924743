import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import {educationTypeActions} from "../../store/educationType/educationTypeSlice";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {addEducationTypeData, deleteEducationTypeData, fetchEducationTypeData, updateEducationTypeData} from "../../store/educationType/educationTypeActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import DialogHeader from "../../components/dialogs/DialogHeader";
import TextInput from "../../components/textInput/TextInput";

function EducationTypes({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const educationTypes = useSelector(state => state.educationTypes.educationTypes)
    const isEducationType = useSelector(state=>state.educationTypes.isEducationType)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [educationTypeId, setEducationTypeId] = useState(false)

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [educationType, setEducationType] = useState({})

    const cols = [
        {
            name: "name",
            label: language.education_type_name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>{value}</div>
                    );
                }
            }
        },
        {
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    setEducationTypeId(educationTypes[tableMeta.rowIndex].id)
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setEducationType(educationTypes[tableMeta.rowIndex])
                                    dispatch(educationTypeActions.getEducationType(educationTypes[tableMeta.rowIndex]))
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setEducationTypeId(educationTypes[tableMeta.rowIndex].id)
                                    dispatch(educationTypeActions.getEducationType(educationTypes[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    const saveEducationType = () => {
        dispatch(educationTypeActions.getEducationType(''))
        dispatch(addEducationTypeData(educationType.name))
        setOpenAdd(false)
    }

    const deleteEducationType = () => {
        dispatch(deleteEducationTypeData(educationTypeId))
        setOpenDelete(false)
    }

    const editEducationType = () => {
        dispatch(updateEducationTypeData(educationTypeId, educationType.name))
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchEducationTypeData())
    }, [dispatch,isEducationType])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.educationTypes}
                        data={educationTypes}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteEducationType}
                title={language.delete_education_type}
                warning={language.education_type_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={setOpenAdd}
                cancelAddRef={cancelAddRef}
                saveItem={saveEducationType}
                editItem={editEducationType}
            >
                <DialogHeader
                    closeModal={() => setOpenAdd(false)}
                    title={openEdit ? language.edit_education_type : language.add_education_type}
                />
                <TextInput
                    label={language.education_type_name}
                    value={openEdit ? educationType.name : null}
                    onChange={(e) => setEducationType({ ...educationType, name: e.target.value })}
                    placeholder={language.education_type_name}
                />
            </AddDialog>
        </div>
    );
}

export default EducationTypes;