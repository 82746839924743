const Language =
{
    Deutsch: {
        id: "Deutsch",
        //dashboard
        "homepage": "Starseite",
        "my_books": "meine Bücher",
        "library": "Bücherei",
        "reading": "lesen",
        "statistics": "Statistiken",
        "my_students": "meine Schüler",
        "faq": "Help",
        "dashboard_statistics_one": "liest Bücher",
        "dashboard_statistics_two": "hören",
        "dashboard_statistics_three": "Zeit",
        "dashboard_statistics_four": "Quiz",
        "minutes": "Protokoll",
        "hours": "Std",
        "reads_for_months": "liest monatelang",
        "listens_for_months": "hört monatelang zu",
        "january": "Januar",
        "february": "Februar",
        "march": "Marsch",
        "april": "April",
        "may": "kann",
        "june": "Juni",
        "july": "Juli",
        "august": "August",
        "september": "September",
        "october": "Oktober",
        "november": "November",
        "december": "Dezember",
        //library
        "my_library": "meine Bibliothek",
        "students": "Studenten",
        "all_students": "alle Schüler",
        "send_message": "Nachricht senden",
        "this_year": "dieses Jahr",
        "this_month": "Diesen Monat",
        "this_week": "In dieser Woche",
        "listen": "Hör mal zu",
        "read": "Lesen",
        "quiz": "Quiz",
        "page": "Seite",
        "author": "Autorin",
        "languages": "Sprachen",
        "back_to_library": "zurück zur Bibliothek",
        "finish": "beenden",
        //statistics
        "level": "eben",
        "level_up": "aufleveln",
        "completed": "vollendet",
        "current_reads": "aktuelle liest",
        "reads_this_year": "liest dieses Jahr",
        "reads_this_month": "liest diesen Monat",
        "reads_this_week": "liest diese Woche",
        "reads_this_day": "liest heute",
        "completed_activities": "abgeschlossene Aktivitäten",
        "total_reads": "insgesamt gelesen",
        "total_listens": "total hört",
        "total_quizzes": "gesamt quiz",
        "classes": "Klassen",
        "search": "Suche",
        "filter": "Filter",
        "add": "hinzufügen",
        "name_surname": "Vorname Nachname",
        "progress_status": "Fortschrittsstatus",
        "actions": "Aktionen",
        "showing": "Zeigen",
        "previous": "Vorherige",
        "next": "Nächste",
        "name": "Name",
        "surname": "Familien-oder Nachname",
        "class": "Klasse",
        //profile
        "my_profile": "mein Profil",
        "user_id": "Benutzeridentifikation",
        "e_mail": "Email",
        "first_name": "Vorname",
        "middle_name": "zweiter Vorname",
        "last_name": "Nachname",
        "phone": "Telefon",
        "birthday": "Geburtstag",
        "time_zone": "Zeitzone",
        "edit": "bearbeiten",
        "save": "speichern",
        "cancel": "Abbrechen",
        "choose": "wählen",
        "my_information": "meine Information",
        "avatar_builder": "Avatar-Ersteller",
        "background_builder": "Hintergrund-Builder",
        "password_change": "Passwortänderung",
        "password": "Passwort",
        "re_password": "wieder passwort",
        "type_message": "Nachricht eingeben",
        //avatar&&image
        "show": "Show",
        "hide": "verbergen",
        "avatar_text": "Avatar-Text",
        "hide_bar": "Leiste ausblenden",
        "select_avatar": "Avatar auswählen",
        "help": "Hilfe",
        "write_to_teacher": "schreibe an den Lehrer",

        //sonradan eklenen
        "book_information": "Book Information",
        "faq_not_found": "There is not any question answers yet",
        "admin_pages": "Admin Pages",
        "profile_updated": "Profile updated successfully",
        "password_updated": "Password updated successfully",
        "avatar_updated": "Avatar updated successfully",
        "background_updated": "Background updated successfully"
    },
    English: {
        id: "English",
        //dashboard
        "homepage": "Homepage",
        "my_books": "My Books",
        "library": "Library",
        "reading": "Reading",
        "statistics": "Statistics",
        "my_students": "My Students",
        "faq": "Help",
        "dashboard_statistics_one": "Reads BookLevels",
        "dashboard_statistics_two": "Listen",
        "dashboard_statistics_three": "Time",
        "dashboard_statistics_four": "Quizzes",
        "minutes": "Minutes",
        "hours": "Hours",
        "reads_for_months": "Reads for months",
        "listens_for_months": "Listens for months",
        "january": "Jan",
        "february": "Feb",
        "march": "Mar",
        "april": "Apr",
        "may": "May",
        "june": "Jun",
        "july": "Jul",
        "august": "Aug",
        "september": "Sep",
        "october": "Oct",
        "november": "Nov",
        "december": "Dec",
        //library
        "my_library": "My Library",
        "students": "Students",
        "all_students": "All Students",
        "send_message": "Send Message",
        "this_year": "This Year",
        "this_month": "This Month",
        "this_week": "This Week",
        "listen": "Listen",
        "read": "Read",
        "quiz": "Quiz",
        "page": "Page",
        "author": "Author",
        "languages": "Languages",
        "back_to_library": "Back To Library",
        "finish": "Finish",
        //statistics
        "level": "LEVEL",
        "level_up": "Level Up",
        "completed": "completed",
        "current_reads": "Current Reads",
        "reads_this_year": "Reads This Year",
        "reads_this_month": "Reads This Month",
        "reads_this_week": "Reads This Week",
        "reads_this_day": "Reads This Day",
        "completed_activities": "Completed Activities",
        "total_reads": "Total Reads",
        "total_listens": "Total Listens",
        "total_quizzes": "Total Quizzes",
        "classes": "Classes",
        "search": "Search",
        "filter": "Filter",
        "add": "Add",
        "name_surname": "Name Surname",
        "progress_status": "Progress Status",
        "actions": "Actions",
        "showing": "Showing",
        "previous": "Previous",
        "next": "Next",
        "class": "Class",
        //profile
        "my_profile": "My Profile",
        "user_id": "User ID",
        "e_mail": "E-mail",
        "first_name": "First Name",
        "middle_name": "Middle Name",
        "last_name": "Last Name",
        "phone": "Phone",
        "birthday": "Birthday",
        "time_zone": "Time Zone",
        "edit": "Edit",
        "save": "Save",
        "cancel": "Cancel",
        "choose": "Choose",
        "my_information": "My Information",
        "avatar_builder": "Avatar Builder",
        "background_builder": "Background",
        "password_change": "Password Change",
        "password": "Type New Password",
        "re_password": "Type New Password Again",
        "type_message": "Type your message here",
        //avatar&&image
        "show": "Show",
        "hide": "Hide",
        "avatar_text": "Hello I'm your new avatar!",
        "hide_bar": "Hide Bar",
        "select_avatar": "Select Avatar",
        "help": "Get Some Help",
        "write_to_teacher": "Write to your teacher",

        //sonradan eklenen
        "book_information": "Book Information",
        "faq_not_found": "There is not any question answers yet",
        "admin_pages": "Admin Pages",
        "profile_updated": "Profile updated successfully",
        "password_updated": "Password updated successfully",
        "avatar_updated": "Avatar updated successfully",
        "background_updated": "Background updated successfully",

        //daha sonradan
        "continue" : "Continue",
        "complete_quiz" : "Complete Quiz",
        "you_have" : "You have",
        "unanswered_questions" : "unanswered question. Are you sure you want to finish your quiz?",
        "quiz_completed" : "You have completed your quiz. Thank you for your contribution. Now you will redirect to library page!",
        "start_conversation_text" : "Start a conversation by clicking users available in the sidebar",
        "language_updated": "Language updated successfully",
    },
    Türkçe: {
        id: "Türkçe",
        //dashboard
        "homepage": "Anasayfa",
        "my_books": "Kitaplarım",
        "library": "Kütüphane",
        "reading": "Okuma",
        "statistics": "İstatistik",
        "my_students": "Öğrencilerim",
        "faq": "SSS",
        "dashboard_statistics_one": "Okunan Kitaplar",
        "dashboard_statistics_two": "Dinlenen",
        "dashboard_statistics_three": "Zaman",
        "dashboard_statistics_four": "Sınavlar",
        "minutes": "Dakika",
        "hours": "Saat",
        "reads_for_months": "Aylara göre okunanlar",
        "listens_for_months": "Aylara göre dinlenenler",
        "january": "Oca",
        "february": "Şub",
        "march": "Mar",
        "april": "Nis",
        "may": "May",
        "june": "Haz",
        "july": "Tem",
        "august": "Ağu",
        "september": "Eyl",
        "october": "Eki",
        "november": "Kas",
        "december": "Ara",
        //library
        "my_library": "Kütüphanem",
        "students": "Öğrenciler",
        "all_students": "Tümü",
        "send_message": "Mesaj Gönder",
        "this_year": "Bu Yıl",
        "this_month": "Bu Ay",
        "this_week": "Bu Hafta",
        "listen": "Dinleme",
        "read": "Okuma",
        "quiz": "Sınav",
        "author": "Yazar",
        "languages": "Diller",
        "back_to_library": "Kütüphaneye Dön",
        "finish": "Bitiş",
        //statistics
        "level": "Seviye",
        "level_up": "Seviye Atla",
        "completed": "Tamamlandı",
        "current_reads": "Toplam Okunan",
        "reads_this_year": "Bu Yıl Okunan",
        "reads_this_month": "Bu Ay Okunan",
        "reads_this_week": "Bu Hafta Okunan",
        "reads_this_day": "Bugün Okunan",
        "completed_activities": "Tamamlanan Aktiviteler",
        "total_reads": "Toplam Okunan",
        "total_listens": "Toplam Dinlenen",
        "total_quizzes": "Toplam Sınavlar",
        "classes": "Sınıflar",
        "search": "Ara",
        "filter": "Filtre",
        "add": "Ekle",
        "name_surname": "İsim Soyisim",
        "progress_status": "İlerleme Durumu",
        "actions": "İşlemler",
        "showing": "Gösteriliyor",
        "previous": "Önceki",
        "next": "Sonraki",
        "class": "Sınıf",
        //profile
        "my_profile": "Profilim",
        "user_id": "Kullanıcı ID",
        "e_mail": "E-posta",
        "first_name": "Ad",
        "middle_name": "İkinci Ad",
        "last_name": "Soyad",
        "phone": "Telefon",
        "birthday": "Doğum Günü",
        "time_zone": "Saat Dilimi",
        "edit": "Düzenle",
        "save": "Kaydet",
        "cancel": "Vazgeç",
        "choose": "Seç",
        "my_information": "Bilgilerim",
        "avatar_builder": "Avatar Oluşturucu",
        "background_builder": "Arkaplan",
        "password_change": "Şifre Değiştir",
        "password": "Yeni Şifrenizi Girin",
        "re_password": "Yeni Şifrenizi Tekrar Girin",
        "type_message": "Mesajınızı buraya yazın",
        //avatar&&image
        "show": "Göster",
        "hide": "Gizle",
        "avatar_text": "Merhaba Ben senin yeni avatarınım!",
        "hide_bar": "Sakla",
        "select_avatar": "Avatar Seç",
        "help": "Yardım Alın",
        "write_to_teacher": "Öğretmeninize yazın",

        //sonradan eklenen
        "book_information": "Book Information",
        "faq_not_found": "There is not any question answers yet",
        "admin_pages": "Admin Pages",
        "profile_updated": "Profile updated successfully",
        "password_updated": "Password updated successfully",
        "avatar_updated": "Avatar updated successfully",
        "background_updated": "Background updated successfully"
    },
    عربي: {
        id: "عربي",
        //dashboard
        "homepage": "الصفحة الرئيسية",
        "my_books": "كتبي",
        "library": "مكتبة",
        "reading": "قراءة",
        "statistics": "الإحصاء",
        "my_students": "طلابي",
        "faq": "التعليمات",
        "dashboard_statistics_one": "إحصائيات لوحة القيادة واحد",
        "dashboard_statistics_two": "إحصائيات لوحة القيادة الثانية",
        "dashboard_statistics_three": "ثلاثة إحصاءات لوحة القيادة",
        "dashboard_statistics_four": "أربعة إحصاءات لوحة القيادة",
        "minutes": "الدقائق",
        "hours": "ساعات",
        "reads_for_months": "يقرأ لشهور",
        "listens_for_months": "يستمع لشهور",
        "january": "كانون الثاني",
        "february": "شهر فبراير",
        "march": "مارس",
        "april": "أبريل",
        "may": "قد",
        "june": "يونيو",
        "july": "تموز",
        "august": "أغسطس",
        "september": "شهر تسعة",
        "october": "اكتوبر",
        "november": "شهر نوفمبر",
        "december": "ديسمبر",
        //library
        "my_library": "مكتبتي",
        "students": "الطلاب",
        "all_students": "كل الطلاب",
        "send_message": "إرسال رسالة",
        "this_year": "هذه السنة",
        "this_month": "هذا الشهر",
        "this_week": "هذا الاسبوع",
        "listen": "يستمع",
        "read": "اقرأ",
        "quiz": "اختبار",
        "page": "صفحة",
        "author": "مؤلف",
        "languages": "اللغات",
        "back_to_library": "العودة الى المكتبة",
        "finish": "ينهي",
        //statistics
        "level": "مستوى",
        "level_up": "يصل المستوى",
        "completed": "منجز",
        "current_reads": "القراءات الحالية",
        "reads_this_year": "يقرأ هذا العام",
        "reads_this_month": "يقرأ هذا الشهر",
        "reads_this_week": "يقرأ هذا الأسبوع",
        "reads_this_day": "يقرأ هذا اليوم",
        "completed_activities": "الأنشطة المنجزة",
        "total_reads": "إجمالي القراءات",
        "total_listens": "مجموع يستمع",
        "total_quizzes": "مجموع الاختبارات",
        "classes": "الطبقات",
        "search": "بحث",
        "filter": "منقي",
        "add": "يضيف",
        "name_surname": "اسم اللقب",
        "progress_status": "حالة التقدم",
        "actions": "أجراءات",
        "showing": "تظهر",
        "previous": "سابق",
        "next": "التالي",
        "class": "فصل",
        //profile
        "my_profile": "ملفي",
        "user_id": "معرف المستخدم",
        "e_mail": "البريد الإلكتروني",
        "first_name": "الاسم الأول",
        "middle_name": "الاسم الوسطى",
        "last_name": "الكنية",
        "phone": "هاتف",
        "birthday": "عيد الميلاد",
        "time_zone": "وحدة زمنية",
        "edit": "تعديل",
        "save": "حفظ",
        "my_information": "معلوماتي",
        "avatar_builder": "منشئ الصور الرمزية",
        "background_builder": "منشئ الخلفية",
        "password_change": "تغيير كلمة السر",
        "password": "كلمه السر",
        "re_password": "إعادة كلمة المرور",
        "type_message": "اكتب رسالة",
        //avatar&&image
        "show": "تبين",
        "hide": "يخفي",
        "avatar_text": "نص الصورة الرمزية!",
        "hide_bar": "شريط الاختباء",
        "select_avatar": "حدد الصورة الرمزية",
        "help": "مساعدة",
        "write_to_teacher": "اكتب للمعلم",

        //sonradan eklenen
        "book_information": "Book Information",
        "faq_not_found": "There is not any question answers yet",
        "admin_pages": "Admin Pages",
        "profile_updated": "Profile updated successfully",
        "password_updated": "Password updated successfully",
        "avatar_updated": "Avatar updated successfully",
        "background_updated": "Background updated successfully"
    },
    بادینی: {
        id: "بادینی",
        //dashboard
        "homepage": "الصفحة الرئيسية",
        "my_books": "My BookLevels",
        "library": "مكتبة",
        "reading": "قراءة",
        "statistics": "",
        "my_students": "",
        "faq": "التعليمات",
        "dashboard_statistics_one": "يقرأ الكتب",
        "dashboard_statistics_two": "استمع",
        "dashboard_statistics_three": "زمن",
        "dashboard_statistics_four": "اختبار",
        "minutes": "دقيقة",
        "hours": "ساعات",
        "reads_for_months": "يقرأ لشهور",
        "listens_for_months": "يستمع لشهور",
        "january": "يناير",
        "february": "فبراير",
        "march": "آذار",
        "april": "أبريل",
        "may": "قد",
        "june": "يونيو",
        "july": "تموز",
        "august": "جليل",
        "september": "سبتمبر",
        "october": "اكتوبر",
        "november": "نوفمبر",
        "december": "ديسمبر",
        //library
        "my_library": "عربي",
        "students": "",
        "all_students": "",
        "send_message": "",
        "this_year": "This Year",
        "this_month": "This Month",
        "this_week": "This Week",
        "listen": "Listen",
        "read": "Read",
        "quiz": "Quiz",
        "page": "صفحة",
        "author": "مؤلف",
        "languages": "اللغات",
        "back_to_library": "Kütüphaneye Dön",
        "finish": "Finish",
        //statistics
        "level": "مستوى",
        "level_up": "يصل المستوى",
        "completed": "منجز",
        "current_reads": "القراءات الحالية",
        "reads_this_year": "يقرأ هذا العام",
        "reads_this_month": "يقرأ هذا الأسبوع",
        "reads_this_week": "يقرأ هذا الأسبوع",
        "reads_this_day": "يقرأ هذا اليوم",
        "completed_activities": "الأنشطة المنجزة",
        "total_reads": "إجمالي القراءات",
        "total_listens": "مجموع يستمع",
        "total_quizzes": "مجموع الاختبارات",
        "classes": "Classes",
        "search": "Search",
        "filter": "Filter",
        "add": "Add",
        "name_surname": "Name Surname",
        "progress_status": "Progress Status",
        "actions": "Actions",
        "showing": "Showing",
        "previous": "Previous",
        "next": "Next",
        "class": "Class",
        //profile
        "my_profile": "ملفي",
        "user_id": "معرف المستخدم",
        "e_mail": "البريد الإلكتروني",
        "first_name": "الاسم الأول",
        "middle_name": "الاسم الأوسط",
        "last_name": "الكنية",
        "phone": "هاتف",
        "birthday": "عيد الميلاد",
        "time_zone": "وحدة زمنية",
        "edit": "تعديل",
        "save": "حفظ",
        "my_information": "معلوماتي",
        "avatar_builder": "منشئ الصورة الرمزية",
        "background_builder": "معرفتي",
        "password_change": "تغيير كلمة السر",
        "password": "Type New Password",
        "re_password": "Type New Password Again",
        "type_message": "Type your message here",
        //avatar&&image
        "show": "Göster",
        "hide": "Gizle",
        "avatar_text": "Merhaba Ben senin yeni avatarınım!",
        "hide_bar": "Sakla",
        "select_avatar": "Avatar Seç",
        "help": "Yardım Alın",
        "write_to_teacher": "Öğretmeninize yazın",

        //sonradan eklenen
        "book_information": "Book Information",
        "faq_not_found": "There is not any question answers yet",
        "admin_pages": "Admin Pages",
        "profile_updated": "Profile updated successfully",
        "password_updated": "Password updated successfully",
        "avatar_updated": "Avatar updated successfully",
        "background_updated": "Background updated successfully"
    },
    سۆرانی: {
        id: "سۆرانی",
        //dashboard
        "homepage": "پەڕەی سەرەکی",
        "my_books": "کتێبەکانم",
        "library": "کتێبخانە",
        "reading": "خوێندنەوە",
        "statistics": "ئامار",
        "my_students": "قوتابیەکانم",
        "faq": "پرسیاره باوەکان",
        "dashboard_statistics_one": "داشبۆرد تاتیستیک یەک",
        "dashboard_statistics_two": "ئاماری داشبۆرد دوو",
        "dashboard_statistics_three": "ئاماری داشبۆرد سێ",
        "dashboard_statistics_four": "ئاماری داشبۆرد چوار",
        "minutes": "خولەک",
        "hours": "کاتژمێر",
        "reads_for_months": "دەخوێنرێتەوە بۆ چەند مانگێک",
        "listens_for_months": "بۆ ماوەی چەند مانگێک گوێ دەگریت",
        "january": "کانوونی دووەم",
        "february": "شوبات",
        "march": "ئازار",
        "april": "نیسان",
        "may": "مەی",
        "june": "حوزەیران",
        "july": "تەممووز",
        "august": "ئاب",
        "september": "ئەیلول",
        "october": "تشرینی یەکەم",
        "november": "تشرینی دووەم",
        "december": "کانوونی یەکەم",
        //library
        "my_library": "کتێبخانەکەم",
        "students": "قوتابی",
        "all_students": "هەموو خوێندکارەکان",
        "send_message": "نامە بنێرە",
        "this_year": "ئەمساڵ",
        "this_month": "ئەم مانگە",
        "this_week": "ئەم هەفتەیە",
        "listen": "گوێ گرتن",
        "read": "خوێندنەوە",
        "quiz": "کویز",
        "page": "پەڕە",
        "author": "نووسەر",
        "languages": "زمانەکان",
        "back_to_library": "گەڕانەوە بۆ کتێبخانە",
        "finish": "تەواوکردن",
        //statistics
        "level": "ئاست",
        "level_up": "ئاست بەرز",
        "completed": "تەواوبوو",
        "current_reads": "خوێندنەوەکانی ئێستا",
        "reads_this_year": "ئەمساڵ دەخوێنرێتەوە",
        "reads_this_month": "ئەم مانگە دەخوێنێتەوە",
        "reads_this_week": "ئەم هەفتەیە دەخوێنێتەوە",
        "reads_this_day": "ئەم ڕۆژە دەخوێنێتەوە",
        "completed_activities": "چالاکی تەواوکراو",
        "total_reads": "تەواوی خوێندنەوەکان",
        "total_listens": "هەموو گوێگرەکان",
        "total_quizzes": "کۆی گێژاوەکان",
        "classes": "پۆلەکان",
        "search": "گەڕان",
        "filter": "فلتەر",
        "add": "زیادکردن",
        "name_surname": "ناوی پاشناو",
        "progress_status": "دۆخی پێشکەوتن",
        "actions": "کارەکان",
        "showing": "پیشاندان",
        "previous": "پێشوو",
        "next": "دواتر",
        "class": "پۆل",
        //profile
        "my_profile": "پرۆفایلی من",
        "user_id": "ناسنامەی بەکارهێنەر",
        "e_mail": "ئیمەیڵ",
        "first_name": "الاسم الأولناوی یەکەم",
        "middle_name": "ناوی ناوەڕاست",
        "last_name": "ناوی کۆتایی",
        "phone": "تەلەفۆن",
        "birthday": "لە دایک بوون",
        "time_zone": "ناوچەی کات",
        "edit": "بژارکردن",
        "save": "هەڵگرتن",
        "my_information": "زانیاری من",
        "avatar_builder": "دروستکەری ئاڤاتار",
        "background_builder": "دروستکەری باکگراوند",
        "password_change": "گۆڕینی نهێنوشە",
        "password": "نهێنوشە",
        "re_password": "دووبارە نهێنوشە",
        "type_message": "نامە بنووسە",
        //avatar&&image
        "show": "پیشاندان",
        "hide": "شاردنەوە",
        "avatar_text": "دەقی avatar",
        "hide_bar": "شاردنەوەی شریت",
        "select_avatar": "avatar دیاریبکە",
        "help": "یارمەتی",
        "write_to_teacher": "بۆ مامۆستا بنووسە",

        //sonradan eklenen
        "book_information": "Book Information",
        "faq_not_found": "There is not any question answers yet",
        "admin_pages": "Admin Pages",
        "profile_updated": "Profile updated successfully",
        "password_updated": "Password updated successfully",
        "avatar_updated": "Avatar updated successfully",
        "background_updated": "Background updated successfully"
    },
}

export default Language
