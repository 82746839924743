import React from 'react';

function PageNeedle(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="12.998" height="16.699" viewBox="0 0 15.998 21.999">
                <g id="Union_1" data-name="Union 1" transform="translate(126.998 13395)" fill="#fff">
                    <path d="M -119.0008926391602 -13374.0009765625 C -122.8593063354492 -13374.0009765625 -125.9983673095703 -13377.1396484375 -125.9983673095703 -13380.998046875 C -125.9983673095703 -13383.5751953125 -124.589241027832 -13385.9384765625 -122.3208694458008 -13387.1630859375 L -122.0601272583008 -13387.3037109375 L -121.9182052612305 -13387.564453125 L -119.0008697509766 -13392.912109375 L -116.0844802856445 -13387.56640625 L -115.9424591064453 -13387.3056640625 L -115.6814498901367 -13387.1650390625 C -113.4106674194336 -13385.939453125 -112.0000305175781 -13383.5771484375 -112.0000305175781 -13380.998046875 C -112.0000305175781 -13377.1396484375 -115.1406021118164 -13374.0009765625 -119.0008926391602 -13374.0009765625 Z" stroke="none"/>
                    <path d="M -119.0008926391602 -13375.0009765625 C -115.6920013427734 -13375.0009765625 -113.0000305175781 -13377.69140625 -113.0000305175781 -13380.998046875 C -113.0000305175781 -13383.208984375 -114.2094421386719 -13385.234375 -116.1562881469727 -13386.28515625 L -116.6782913208008 -13386.56640625 L -119.000862121582 -13390.82421875 L -121.3241653442383 -13386.564453125 L -121.8456573486328 -13386.283203125 C -123.7903213500977 -13385.2333984375 -124.9983673095703 -13383.2080078125 -124.9983673095703 -13380.998046875 C -124.9983673095703 -13377.69140625 -122.3079071044922 -13375.0009765625 -119.0008926391602 -13375.0009765625 M -119.0008926391602 -13373.0009765625 C -123.4181594848633 -13373.0009765625 -126.9983673095703 -13376.5810546875 -126.9983673095703 -13380.998046875 C -126.9983673095703 -13384.044921875 -125.2985687255859 -13386.69140625 -122.7960815429688 -13388.04296875 L -119.0008926391602 -13395 L -115.2066192626953 -13388.044921875 C -112.7015762329102 -13386.693359375 -111.0000305175781 -13384.0458984375 -111.0000305175781 -13380.998046875 C -111.0000305175781 -13376.5810546875 -114.5802459716797 -13373.0009765625 -119.0008926391602 -13373.0009765625 Z" stroke="none" fill="#0e9e57"/>
                </g>
            </svg>
        </div>
    );
}

export default PageNeedle;