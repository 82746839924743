import React from 'react';

function Time ({fill}) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="23.999" height="23.999" viewBox="0 0 23.999 23.999">
                <g id="Icon_ionic-md-time" data-name="Icon ionic-md-time" transform="translate(-3.375 -3.375)" opacity="0.3">
                    <path id="Path_1141" data-name="Path 1141" d="M15.363,3.375a12,12,0,1,0,12.011,12A12,12,0,0,0,15.363,3.375Zm.012,21.6a9.6,9.6,0,1,1,9.6-9.6A9.6,9.6,0,0,1,15.375,24.974Z" fill="rgba(0,0,0,0.9)"/>
                    <path id="Path_1142" data-name="Path 1142" d="M18.337,10.688h-1.8v7.2l6.3,3.779.9-1.477-5.4-3.2Z" transform="translate(-2.363 -1.313)" fill={fill}/>
                </g>
            </svg>
        </div>
    );
}

export default Time;