import React from 'react';

function SpecialLock(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="17.446" height="22.68" viewBox="0 0 17.446 22.68">
                <path id="Icon_ionic-ios-lock" data-name="Icon ionic-ios-lock" d="M22.015,11.225H20.707V8.679a5.234,5.234,0,1,0-10.467-.071v2.617H8.931A2.187,2.187,0,0,0,6.75,13.406V23.874a2.187,2.187,0,0,0,2.181,2.181H22.015A2.187,2.187,0,0,0,24.2,23.874V13.406A2.187,2.187,0,0,0,22.015,11.225Zm-5.779,6.8V21.78a.782.782,0,0,1-.725.785.764.764,0,0,1-.8-.763V18.029a1.745,1.745,0,1,1,1.527,0Zm2.944-6.8H11.766V8.609a3.707,3.707,0,0,1,7.414,0Z" transform="translate(-6.75 -3.374)" fill="#0999ab"/>
            </svg>
        </div>
    );
}

export default SpecialLock;