import { ApiUrl } from "../../components/utils/Urls";
import { bookLevelActions } from "./bookLevelSlice";
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

export const fetchBookLevelData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/level', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch book level data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const bookLevelData = await fetchData()
            dispatch(bookLevelActions.getBookLevels(bookLevelData))
        } catch (error) { }
    }
}

export const updateBookLevelData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/levelEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch book level data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(bookLevelActions.getBookLevel(''))
            callToaster("book_level_updated")
        } catch (error) { }
    }
}

export const deleteBookLevelData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'library/levelDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch book level data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(bookLevelActions.getBookLevel(''))
            callToaster("book_level_deleted")
        } catch (error) { }
    }
}

export const addBookLevelData = bookLevel => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'library/levelAdd', {
                method: 'POST', body: 'name=' + bookLevel,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch book level data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(bookLevelActions.getBookLevel(''))
            callToaster("book_level_added")
        } catch (error) { }
    }
}