import React from 'react';

function ArrowRight({fill}) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.807" height="18.807" viewBox="0 0 13.373 13.373">
                <path id="Icon_ionic-ios-arrow-dropdown-circle" data-name="Icon ionic-ios-arrow-dropdown-circle" d="M3.375,10.062a6.687,6.687,0,1,0,6.687-6.687A6.686,6.686,0,0,0,3.375,10.062Zm9.294-1.4a.623.623,0,0,1,.878,0,.613.613,0,0,1,.18.437.624.624,0,0,1-.183.44l-3.031,3.022a.62.62,0,0,1-.855-.019L6.58,9.48A.621.621,0,0,1,7.458,8.6l2.607,2.633Z" transform="translate(-3.375 16.748) rotate(-90)" fill={fill}/>
            </svg>
        </div>
    );
}

export default ArrowRight;