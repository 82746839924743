import React, {useEffect, useState} from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {useDropzone} from "react-dropzone";
import {TrashIcon} from "@heroicons/react/solid";
import AvatarOne from '../../assets/imgs/avatar-one.png'
import AvatarTwo from '../../assets/imgs/avatar-two.png'
import AvatarThree from '../../assets/imgs/avatar-three.png'
import AvatarFour from '../../assets/imgs/avatar-four.png'
import AvatarFive from '../../assets/imgs/avatar-five.png'
import AvatarSix from '../../assets/imgs/avatar-six.png'
import AvatarSeven from '../../assets/imgs/avatar-seven.png'
import AvatarNine from '../../assets/imgs/avatar-nine.png'
import AvatarTen from '../../assets/imgs/avatar-ten.png'
import AvatarEleven from '../../assets/imgs/avatar-eleven.png'
import AvatarTwelve from '../../assets/imgs/avatar-twelve.png'
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {fetchBookPagesData} from "../../store/readBook/readBookActions";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width:'100%',
    //marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    // border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    marginTop:20,
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: '100%',
    height: '100%'
};

const pictures = [
    {
        "id" : 1,
        "path": "avatar-five.png",
        "preview": AvatarOne
    },
    {
        "id" : 2,
        "path": "avatar-one.png",
        "preview": AvatarTwo
    },
    {
        "id" : 3,
        "path": "avatar-nine.png",
        "preview": AvatarThree
    },
    {
        "id" : 4,
        "path": "avatar-one.png",
        "preview": AvatarFour
    },
    {
        "id" : 5,
        "path": "avatar-seven.png",
        "preview": AvatarFive
    },
    {
        "id" : 6,
        "path": "avatar-six.png",
        "preview": AvatarSix
    },
    {
        "id" : 7,
        "path": "avatar-seven.png",
        "preview": AvatarSeven
    },
    {
        "id" : 8,
        "path": "avatar-seven.png",
        "preview": AvatarNine,
    },
    {
        "id" : 9,
        "path": "avatar-six.png",
        "preview": AvatarTen
    },
    {
        "id" : 10,
        "path": "avatar-seven.png",
        "preview": AvatarEleven
    },
    {
        "id" : 11,
        "path": "avatar-six.png",
        "preview": AvatarTwelve
    }
]



// const initialItems = () =>
//     Array.from({ length: 48 }, (v, k) => k).map(k => ({
//         id: `item-${k + 1}-${new Date().getTime()}`,
//         src: `https://picsum.photos/id/1${k}/200/150`
//     }));

const itemsPerRow = 6;

const makeRows = items => {
    let i,
        j,
        row,
        rows = [];

    for (i = 0, j = items.length; i < j; i += itemsPerRow) {
        row = items.slice(i, i + itemsPerRow);
        rows.push(row);
    }

    return rows;
};

const mergeRows = rows => {
    let merged = [].concat.apply([], rows);
    return merged;
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    flexBasis: "16.6666667%",
    // image: isDragging ? "gray" : "transparent",
    background: "transparent",
    flexShrink: 0,

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#0E9E57" : "transparent",
    display: "flex",
    overflow: "hidden"
});

function AddPages() {
    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const currentPages = useSelector(state=>state.readBook.pages)
    const [allPictures,setAllPictures] = useState(pictures)
    const [bookCover,setBookCover] = useState('')

    const location = useLocation()
    const  { pathname } = location
    const url = pathname.split("/")
    const bookId = url[4]

    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            // setFiles(acceptedFiles.map((file,k) => Object.assign(file, {
            //     preview: URL.createObjectURL(file),
            //     id:`item-${k + 1}-${new Date().getTime()}`,
            // })));
            setRows(makeRows(mergeRows(acceptedFiles.map((file,k) => Object.assign(file, {
                file:file.name,
                preview: URL.createObjectURL(file),
                id:`item-${k + 1}-${new Date().getTime()}`,
            })))))
            console.log(rows)
        }
    });

    const initialItems = () =>
        Array.from({ length: files.length }, (v, k) => k).map(k => ({
            id: `item-${k + 1}-${new Date().getTime()}`,
            path: `${files[k].path}`,
            preview: `${files[k].preview}`,
            name: `${files[k].file}`
        }));

    const [items, setItems] = useState(initialItems);
    const [rows, setRows] = useState(makeRows(items));

    makeRows(initialItems);

    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        const newRows = [...rows];

        if (sInd === dInd) {
            const items = reorder(rows[sInd], source.index, destination.index);
            newRows[sInd] = items;
            setRows(newRows);
        } else {
            const result = move(rows[sInd], rows[dInd], source, destination);
            newRows[sInd] = result[sInd];
            newRows[dInd] = result[dInd];

            setRows(newRows.filter(group => group.length));
        }

        let squashed = mergeRows(newRows);
        setItems(squashed);
        setRows(makeRows(squashed));

        console.log(rows)
    }

    useEffect(() => () => {
        rows.forEach(file => URL.revokeObjectURL(file.preview));
    }, [rows]);

    useEffect(()=>{
        dispatch(fetchBookPagesData(bookId))
    },[dispatch])

    const array = [
            {
                "path": "avatar-five.png",
                "preview": "blob:http://localhost:3000/8d519330-4b17-4a06-8bbd-4a8c94b56b20",
                "id": "item-1-1645195943898"
            },
            {
                "path": "avatar-four.png",
                "preview": "blob:http://localhost:3000/c95c7e23-34da-40d1-aec1-3ff0ba1ff8ef",
                "id": "item-2-1645195943898"
            },
            {
                "path": "avatar-nine.png",
                "preview": "blob:http://localhost:3000/3916b1e9-c8f6-4cd7-8a67-bd18eb57b3d8",
                "id": "item-3-1645195943899"
            },
            {
                "path": "avatar-one.png",
                "preview": "blob:http://localhost:3000/e3e2e8c6-1263-4143-9b7b-749d6e1e88dc",
                "id": "item-4-1645195943899"
            },
            {
                "path": "avatar-seven.png",
                "preview": "blob:http://localhost:3000/a97b5cbe-41a5-4316-a0a1-8877d8ffda46",
                "id": "item-5-1645195943899"
            },
            {
                "path": "avatar-six.png",
                "preview": "blob:http://localhost:3000/16dfedaf-d7fe-4dbc-80b0-db91c07c41ce",
                "id": "item-6-1645195943899"
            },
            {
                "path": "avatar-ten.png",
                "preview": "blob:http://localhost:3000/6d9f7297-80ae-4b56-862a-cd8d2bfec073",
                "id": "item-7-1645195943899"
            }
    ]

    // let string = []
    //
    // array.map((outer)=>{
    //     outer.map((inner)=>{
    //         // console.log(inner.path)
    //         string.push(inner.path)
    //         console.log(string.toString())
    //     })
    // })

    return (
        <div className="container bg-white px-3 mx-auto max-w-screen-lg mt-5">
            <div className="flex flex-col items-center justify-center bg-white px-8 pb-5 pt-10">
                <div className="w-full text-center border border-gray-100 p-5 cursor-pointer" {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>Drag and drop some files here, or click to select files</p>
                </div>
                {
                    rows.length > 0 &&
                    <div style={thumbsContainer}>
                        <div style={{ display: "flex", flexDirection: "column",width:'100%' }}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                {
                                    rows.map((el, ind) => (
                                        <Droppable key={ind} droppableId={`${ind}`} direction="horizontal">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                    {...provided.droppableProps}
                                                >
                                                    {el.map((item, index) => (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={item.id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            justifyContent: "space-around",
                                                                            padding: 4
                                                                        }}
                                                                    >
                                                                        {/*<img*/}
                                                                        {/*    src={item.src}*/}
                                                                        {/*    alt={item.content}*/}
                                                                        {/*    style={{*/}
                                                                        {/*        width: "100%",*/}
                                                                        {/*        height: "auto"*/}
                                                                        {/*    }}*/}
                                                                        {/*/>*/}
                                                                        <div className={`${bookCover === item.id ? 'border border-dark-primary p-5' : ''}`} onClick={()=>setBookCover(item.id)} style={thumb} key={item.id}>
                                                                            <div className="relative flex items-center justify-center" style={thumbInner}>
                                                                                <img
                                                                                    src={item.preview}
                                                                                    style={img}
                                                                                    alt={"images"}/>
                                                                                {
                                                                                    item.id === bookCover &&
                                                                                    <div className="flex rounded-md p-1 absolute top-1 bg-primary">
                                                                                        <span className="text-xs text-white">{language.book_cover}</span>
                                                                                    </div>
                                                                                }
                                                                                <div className="flex items-center justify-center p-3">
                                                                                    <TrashIcon onClick={()=> {
                                                                                        // const newestRows = el.filter((e) => e.id !== item.id)
                                                                                        // console.log(newestRows)
                                                                                        setRows(makeRows(mergeRows(rows).filter((e) => e.id !== item.id)))
                                                                                    }} className="absolute right-0 bottom-0 bg-gray-200 opacity-70 hover:opacity-100 transition-all cursor-pointer h-6 text-red-700 rounded-md p-1" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    ))}
                            </DragDropContext>
                        </div>
                    </div>
                }
            </div>
            {
                files.length > 0 &&
                <div className="flex justify-end">
                    <button type="button"
                            className="my-5 inline-flex w-1/3 justify-center rounded-md shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none sm:mt-0 sm:text-sm">
                        Save
                    </button>
                </div>
            }
        </div>
    );
}

export default AddPages;