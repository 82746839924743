import { createSlice } from "@reduxjs/toolkit";

const parentSlice = createSlice({
    name: 'parents',
    initialState: {
        parents: [],
        parent:{},
        isParent: '',
        children:[],
    },
    reducers: {
        getParents(state, action) {
            state.parents = action.payload
        },
        getIsParent(state, action) {
            state.isParent = !state.isParent
        },
        getChildren(state,action){
            state.children = action.payload
        }
    }
})

export const parentActions = parentSlice.actions

export default parentSlice