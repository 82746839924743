import React from 'react';

function Notification () {
        return (
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.336" height="22" viewBox="0 0 19.336 22">
                    <g id="notification" transform="translate(-30.999)">
                        <path id="Path_21" data-name="Path 21" d="M47.327,11.295V9.238A6.672,6.672,0,0,0,42.6,2.865V1.934a1.934,1.934,0,1,0-3.867,0v.931a6.672,6.672,0,0,0-4.727,6.374v2.057a10.12,10.12,0,0,1-2.829,7.036.645.645,0,0,0,.465,1.091h5.866a3.223,3.223,0,0,0,6.316,0H49.69a.645.645,0,0,0,.465-1.091A10.12,10.12,0,0,1,47.327,11.295Zm-7.3-9.361a.645.645,0,1,1,1.289,0v.676c-.212-.02-.427-.031-.645-.031s-.432.011-.645.031Zm.645,18.777a1.937,1.937,0,0,1-1.823-1.289H42.49A1.937,1.937,0,0,1,40.667,20.711Zm-7.634-2.578A11.4,11.4,0,0,0,35.3,11.295V9.238a5.371,5.371,0,0,1,10.742,0v2.057A11.4,11.4,0,0,0,48.3,18.133Z" transform="translate(0)" fill="currentColor"/>
                        <path id="Path_22" data-name="Path 22" d="M392.135,58.532a.645.645,0,0,0,1.289,0,9.6,9.6,0,0,0-2.832-6.836.645.645,0,0,0-.911.911A8.324,8.324,0,0,1,392.135,58.532Z" transform="translate(-343.09 -49.294)" fill="currentColor"/>
                        <path id="Path_23" data-name="Path 23" d="M31.645,59.177a.645.645,0,0,0,.645-.645,8.324,8.324,0,0,1,2.454-5.925.645.645,0,0,0-.911-.911A9.6,9.6,0,0,0,31,58.533.645.645,0,0,0,31.645,59.177Z" transform="translate(-0.001 -49.294)" fill="currentColor"/>
                    </g>
                </svg>
            </div>
        );
}

export default Notification;