import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import {soundActions} from "../../store/listening/soundSlice";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {addSoundData, deleteSoundData, fetchSoundData, updateSoundData} from "../../store/listening/soundActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import {fetchLanguageData} from "../../store/language/languageActions";
import {fetchBookData} from "../../store/book/bookActions";
import CircleArrowDownward from "../../assets/icons/CircleArrowDownward";
import DialogHeader from "../../components/dialogs/DialogHeader";

function Listening({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const bookList = useSelector(state => state.books.books)
    const languages = useSelector(state => state.bookLanguages.languages)
    const sounds = useSelector(state => state.sounds.sounds)
    const isSound = useSelector(state=>state.sounds.sound)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [soundId, setSoundId] = useState(false)
    const [currentLanguage,setCurrentLanguage] = useState({})
    const [currentBook,setCurrentBook] = useState({})

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [sound, setSound] = useState({name:language.click_to_upload})

    const cols = [
        {
            name: "name",
            label: language.book_name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>{value}</div>
                    );
                }
            }
        },
        {
            name: "name",
            label: language.language,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>{value}</div>
                    );
                }
            }
        },
        {
            // name: language.actions,
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    setSoundId(sounds[tableMeta.rowIndex].id)
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setSound(sounds[tableMeta.rowIndex])
                                    dispatch(soundActions.getSound(sounds[tableMeta.rowIndex]))
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setSoundId(sounds[tableMeta.rowIndex].id)
                                    dispatch(soundActions.getSound(sounds[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    const saveSound = () => {
        dispatch(soundActions.getSound(''))
        dispatch(addSoundData(sound.name))
        dispatch(fetchSoundData())
        setOpenAdd(false)
    }

    const deleteSound = () => {
        dispatch(deleteSoundData(soundId))
        dispatch(fetchSoundData())
        setOpenDelete(false)
    }

    const editSound = () => {
        dispatch(updateSoundData(soundId, sound.name))
        dispatch(fetchSoundData())
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchSoundData())
        dispatch(fetchLanguageData())
        dispatch(fetchBookData())
    }, [dispatch,isSound])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.sounds}
                        data={sounds}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteSound}
                title={language.delete_region}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={setOpenAdd}
                cancelAddRef={cancelAddRef}
                saveItem={saveSound}
                editItem={editSound}
            >
                <DialogHeader
                    closeModal={() => setOpenAdd(false)}
                    title={openEdit ? language.edit_sound : language.add_sound}
                />
                <div className="flex flex-col mt-3">
                    <div className="flex space-x-1 mb-1">
                        <span>{language.books}</span>
                        <span className="text-red-400">*</span>
                        <GrayQuestionMark />
                    </div>
                    <div className="flex relative items-center">
                        <select value={openEdit ? currentBook.id : null} onChange={(e)=> {
                            setCurrentBook({...currentBook, id: e.target.value})
                        }
                        } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                            <option value="">{language.choose}</option>
                            {
                                bookList.map((item)=>{
                                    return(
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                        <CircleArrowDownward />
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <div className="flex space-x-1 mb-1">
                        <span>{language.education_levels}</span>
                        <span className="text-red-400">*</span>
                        <GrayQuestionMark />
                    </div>
                    <div className="flex relative items-center">
                        <select value={openEdit ? currentLanguage.id : null} onChange={(e)=> {
                            setCurrentLanguage({...currentLanguage, id: e.target.value})
                        }
                        } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                            <option value="">{language.choose}</option>
                            {
                                languages.map((item)=>{
                                    return(
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                        <CircleArrowDownward />
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <div className="flex space-x-1 mb-1">
                        <span>{language.sound_name}</span>
                        <span className="text-red-400">*</span>
                        <GrayQuestionMark />
                    </div>

                    <input id="soundFile"
                           value={openEdit ? sound.name : null}
                           onChange={(e) => {
                               setSound({...sound, name: URL.createObjectURL(e.target.files[0])})
                           }}
                           className="block w-full text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="file" />
                    {/*<label className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" htmlFor="soundFile">*/}
                    {/*    { sound.name }*/}
                    {/*</label>*/}
                </div>
            </AddDialog>
        </div>
    );
}

export default Listening;