import { createSlice } from "@reduxjs/toolkit";

const parentStudentSlice = createSlice({
    name: 'parentStudents',
    initialState: {
        statistic: [],
        students: [],
        student: '',
        class: '',
        year: '',
        month: '',
        week: '',
        levels: [],
        levelStatistic: {},
        levelYear: '',
        levelMonth: '',
        levelWeek: '',
        firstStudentId:'',
    },
    reducers: {
        getStudents(state, action) {
            state.students = [""]
            state.students = action.payload
            state.firstStudentId = action.payload[0].id
        },
        resetStudents(state,action){
            state.students = action.payload
        },
        getStudent(state, action) {
            state.firstStudentId = action.payload
        },
        getStatistic(state, action) {
            state.year = action.payload.year[0]
            state.month = action.payload.month[0]
            state.week = action.payload.week[0]
        },
        getLevelStatistic(state, action) {
            state.levelStatistic = action.payload.statistic[0]
        },
    }
})

export const parentStudentActions = parentStudentSlice.actions

export default parentStudentSlice