import { ApiUrl } from "../../components/utils/Urls";
import { directorateActions } from "./directorateSlice";
import {callToaster} from "../../components/utils/ToastText";

export const fetchDirectorateData = ministryId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/directorate', {
                method: 'POST',
                body: 'ministryId=' + ministryId ,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch directorate data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const directorateData = await fetchData()
            dispatch(directorateActions.getDirectorates(directorateData))
        } catch (error) { }
    }
}

export const updateDirectorateData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'system/directorateEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch directorate data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(directorateActions.getIsDirectorate(2))
            callToaster("directorate_updated")
        } catch (error) { }
    }
}

export const deleteDirectorateData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'system/directorateDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch directorate data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(directorateActions.getIsDirectorate(2))
            callToaster("directorate_deleted")
        } catch (error) { }
    }
}

export const addDirectorateData = (ministryId,directorate) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/directorateAdd', {
                method: 'POST', body: 'ministryId=' + ministryId + '&name=' + directorate,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch directorate data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(directorateActions.getIsDirectorate(2))
            callToaster("directorate_added")
        } catch (error) { }
    }
}