import { ApiUrl } from "../../components/utils/Urls";
import { subjectActions } from "./subjectSlice";
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

export const fetchSubjectData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/subject', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch subject data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const subjectData = await fetchData()
            dispatch(subjectActions.getSubjects(subjectData))
        } catch (error) { }
    }
}

export const updateSubjectData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/subjectEdit', {
                method: 'POST', body: 'id=' + id + '&' + 'name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch subject data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(subjectActions.getSubject(''))
            callToaster("subject_updated")
        } catch (error) { }
    }
}

export const deleteSubjectData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'library/subjectDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch subject data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(subjectActions.getSubject(''))
            callToaster("subject_deleted")
        } catch (error) { }
    }
}

export const addSubjectData = subject => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'library/subjectAdd', {
                method: 'POST', body: 'name=' + subject,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch subject data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(subjectActions.getSubject(''))
            callToaster("subject_added")
        } catch (error) { }
    }
}