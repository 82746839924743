import { ApiUrl } from "../../components/utils/Urls";
import { languageActions } from "./languageSlice";
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

export const fetchLanguageData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/language', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch language data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const languageData = await fetchData()
            dispatch(languageActions.getLanguages(languageData))
        } catch (error) { }
    }
}

export const updateLanguageData = language => {
    console.log(JSON.stringify(language))
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/languageEdit', {
                method: 'POST', body: 'code=' + language.code + '&name=' + language.name ,
                // method: 'POST', body: 'name=--EN_US&code=EN_US',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not update language data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(languageActions.getLanguage(''))
            callToaster("language_updated")
        } catch (error) { }
    }
}

export const deleteLanguageData = code => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'library/languageDelete', {
                method: 'POST', body: 'code=' + code,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch language data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(languageActions.getLanguage(''))
            callToaster("language_deleted")
        } catch (error) { }
    }
}

export const addLanguageData = language => {
    console.log(language)
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'library/languageAdd', {
                method: 'POST', body: 'name=' + language.name + '&code=' + language.code,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch language data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(languageActions.getLanguage(''))
            callToaster("language_added")
        } catch (error) { }
    }
}