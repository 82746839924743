import React from 'react';

function PaintBrush ({fill}) {
        return (
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22.051" height="22.051" viewBox="0 0 22.051 22.051">
                    <g id="theme" transform="translate(0 0)">
                        <path id="Path_58" data-name="Path 58" d="M6.616,20.915a3.85,3.85,0,0,0,1.136-2.74c0-.775,1.259-2.334,2.546-2.867.372-.154.88-.274,1.184.03l.353.353,0,0a2.59,2.59,0,0,0,3.655,0l6.369-6.369a.646.646,0,0,0,0-.914L13.639.19a.646.646,0,0,0-.914,0L6.357,6.559a2.59,2.59,0,0,0,0,3.655l.356.356a.66.66,0,0,1,.189.5c0,1.267-2.011,3.231-3.026,3.231A3.881,3.881,0,0,0,0,18.176H0a3.875,3.875,0,0,0,6.616,2.74ZM13.183,1.56l7.309,7.309L17.751,11.61,10.442,4.3ZM2.05,20a2.583,2.583,0,0,1,1.826-4.41A4.383,4.383,0,0,0,6.7,14.062a4.861,4.861,0,0,0,1.495-2.993,1.95,1.95,0,0,0-.568-1.412L7.27,9.3a1.3,1.3,0,0,1,0-1.827L9.528,5.215l7.309,7.309-2.258,2.258a1.3,1.3,0,0,1-1.827,0l-.357-.357a2.255,2.255,0,0,0-2.592-.31c-1.546.64-3.343,2.554-3.343,4.06A2.583,2.583,0,0,1,2.05,20Z" fill={fill}/>
                        <circle id="Ellipse_4" data-name="Ellipse 4" cx="0.646" cy="0.646" r="0.646" transform="translate(3.23 17.53)" fill={fill}/>
                    </g>
                </svg>
            </div>
        );
}

export default PaintBrush;