import { createSlice } from "@reduxjs/toolkit";
import Language from '../../components/language/Language'
import AdminLanguage from '../../components/language/AdminLanguage'
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

const loginSlice = createSlice({
    name: 'userInfos',
    initialState: {
        userInfos: JSON.parse(localStorage.getItem("userInfos")),
        identity:JSON.parse(localStorage.getItem("userType")),
        language:JSON.parse(localStorage.getItem("userInfos")) ? JSON.parse(localStorage.getItem("userType")) === 'admin' ? AdminLanguage[JSON.parse(localStorage.getItem("userInfos")).language] : Language[JSON.parse(localStorage.getItem("userInfos")).language] : {},
        isAuthorized:true,
    },
    reducers: {
        getUserInfos(state, action) {
            state.userInfos = action.payload.user
            state.identity = action.payload.type
        },
        changeLanguage(state,action){
            state.language = action.payload
            localStorage.setItem("userInfos",JSON.stringify({...state.userInfos,language:action.payload.id}))
            callToaster("language_updated")
        },
        getIsAuthorized(state,action){
            state.isAuthorized = action.payload
        }
    }
})

export const loginActions = loginSlice.actions

export default loginSlice