export const tableTheme = {
    overrides: {
        MUIDataTableBodyRow: {
            root: {
                padding: '10px 0',
                background: '#fff'
            }
        },
        MUIDataTable: {
            responsiveBase: {
                minHeight: '60vh'
            }
        },
        MuiButtonBase:{
            root:{
                padding:'6px 16px',
                display:'inherit',
                height:'inherit!important',
            }
        },
        MuiTableCell: {
            root: {
                borderTop: '8px solid rgba(243, 244, 246)',
                // borderTop: '8px solid transparent',
                borderBottom: 'none'
            },
            footer: {
                padding: '0'
            },
            head: {
                padding: '5px 10px'
            }
        },
        MuiPaper: {
            root: {
                boxShadow: 'none!important',
                backgroundColor: 'transparent'
            }
        },
        MuiTableRow: {
            hover: {
                backgroundColor: '#fff!important'
            }
        },
        MUIDataTableHeadCell: {
            fixedHeader: {
                backgroundColor: 'transparent',
                zIndex: '0',
                borderRight: '1px solid #919E9F',
                borderTop: '1px solid #919e9f',
                borderBottom: '1px solid #919e9f',
            }
        },
        MUIDataTableSelectCell: {
            headerCell: {
                backgroundColor: 'transparent',
                borderTop: '1px solid #919e9f',
                borderLeft: '1px solid #919e9f',
                borderBottom: '1px solid #919e9f'
            }
        },
        MUIDataTableToolbar: {
            actions: {
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center'
            },
            icon: {
                color: '#000!important',
                padding: '8px',
                fontSize: '16px!important',
                '&:hover': {
                    // color:'#000!important',
                    backgroundColor: 'transparent'
                }
            },
            iconActive: {
                color: '#1b3638'
            }
        },
        MuiIconButton: {
            root: {
                padding: '8px',
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiPopover: {
            paper: {
                backgroundColor: '#fff',
                boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important'
            }
        },
        MuiToolbar: {
            gutters: {
                padding: '0!important'
            }
        }
    }
}
