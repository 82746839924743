import React from 'react';
import { useSelector } from 'react-redux';
import CirclePlus from "../../assets/icons/CirclePlus";

const AddButton = (props) => {
    const language = useSelector(state => state.userInfos.language)
    return (
        <button onClick={props.onClick} className="rounded-md ml-1.5 py-2 px-4 flex items-center bg-primary text-white h-10">
            <CirclePlus fillColor={"#fff"} />
            <span className="ml-2">{language.add}</span>
        </button>
    );
}

export default AddButton;