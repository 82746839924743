import React from 'react';
import {bookActions} from "../../store/book/bookSlice";
import {PlusIcon} from "@heroicons/react/solid";
import {useDispatch} from "react-redux";

function AddFeature(props) {
    const dispatch = useDispatch()
    return (
        <div>
            <div onClick={()=> {
                // setItems(categories)
                // dispatch(bookActions.changeItem(categories))
                props.setDialogBody({body:props.item,setChange:props.setChange,change:props.change})
                props.setOpenAdd(true)
                dispatch(bookActions.changeTitle(props.title))
            }} className="flex items-center w-full bg-gray-100 hover:bg-primary text-dark-primary hover:text-white cursor-pointer rounded-md px-4 py-2 mt-3">
                <PlusIcon className="w-6 h-6" fill={"currentColor"}/>
                <span className="ml-2">{props.title}</span>
            </div>
            {
                props.change &&
                <div className="flex flex-wrap space-x-1">
                    {/*{*/}
                    {/*    props.change.map((item)=>*/}
                    {/*        item.status ?*/}
                    {/*            <div className="px-3 py-1 my-1 h-6 text-white bg-primary text-xs">*/}
                    {/*                {item.item.name}*/}
                    {/*            </div> : ''*/}
                    {/*    )*/}
                    {/*}*/}
                    {
                        props.change.map((item)=>
                            <div key={item.id} className="px-3 py-1 my-1 h-6 text-white bg-primary text-xs">
                                {item.name}
                            </div>
                        )
                    }
                </div>
            }
        </div>
    );
}

export default AddFeature;