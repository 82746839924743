import { createSlice } from "@reduxjs/toolkit";

const bookSlice = createSlice({
    name: 'books',
    initialState: {
        books: [],
        isBook: false,
        title: '',
    },
    reducers: {
        getBooks(state, action) {
            state.books = action.payload.list
        },
        getIsBook(state, action) {
            state.isBook = !state.isBook
        },
        changeTitle(state, action) {
            state.title = action.payload
        },
        changeItem(state, action) {
            state.items = action.payload
        }
    }
})

export const bookActions = bookSlice.actions

export default bookSlice