import { ApiUrl } from "../../components/utils/Urls";
import { categoryActions } from "./categorySlice";
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

export const fetchCategoryData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/category', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const categoryData = await fetchData()
            dispatch(categoryActions.getCategories(categoryData))
        } catch (error) { }
    }
}

export const updateCategoryData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/categoryEdit', {
                method: 'POST', body: 'id=' + id + '&' + 'name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch category data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(categoryActions.getCategory(''))
            callToaster("category_updated")
        } catch (error) { }
    }
}

export const deleteCategoryData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'library/categoryDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch category data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(categoryActions.getCategory(''))
            callToaster("category_deleted")
        } catch (error) { }
    }
}

export const addCategoryData = category => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'library/categoryAdd', {
                method: 'POST', body: 'name=' + category,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch category data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(categoryActions.getCategory(''))
            callToaster("category_added")
        } catch (error) { }
    }
}