import React, { Fragment, useRef, useState } from 'react';
import CirclePlus from "../../assets/icons/CirclePlus";
import { Dialog, Transition } from "@headlessui/react";
import CrossCircle from "../../assets/icons/CrossCircle";
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {extendNonSelectable} from "../../components/customTable/ExtendNonSelectable";
import {tableTheme} from "../../components/customTable/TableTheme";
import {useSelector} from "react-redux";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import CustomUser from "../../assets/icons/CustomUser";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import DeleteButton from "../../components/customTable/DeleteButton";
import AddButton from "../../components/customTable/AddButton";
import TableFooter from "../../components/customTable/TableFooter";
import MUIDataTable from "mui-datatables";
import CircleArrowDownward from "../../assets/icons/CircleArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";

function Notifications( { getMuiTheme = () => createTheme(extendNonSelectable, tableTheme) }) {
    const language = useSelector(state => state.userInfos.language)
    const TableOptions = useSelector(state=>state.tableOptions.options)
    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const cols = [
        {
            name: "notification_name",
            label: language.notification_name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    console.log(tableMeta)
                    return (
                        <div className="flex items-center">
                            <CustomUser className="h-6 w-6" />
                            <div className="flex flex-col ml-1">
                                {/*<div className="font-semibold">{value + " " + employees[tableMeta.rowIndex].employee_surname}</div>*/}
                                <div className="text-xs">202134</div>
                            </div>
                        </div>
                    );
                }
            }
        },
        {
            name: "notification_id",
            label: language.notification_name,
            options: {
                filter: false,
                sort: false,
                display:false,
                viewColumns:false,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    console.log(tableMeta)
                    return (
                        <div></div>
                    );
                }
            }
        },
        {
            // name: language.actions,
            name: "",
            options: {
                filter: false,
                sort: false,
                download: false,
                print: false,
                viewColumns:false,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    // setEmployeeDelete(employees[tableMeta.rowIndex].id)
                                    // setOpenEdit(true)
                                    // setEmployeeIndex(tableMeta.rowIndex)
                                    // setEmployee(employees[tableMeta.rowIndex])
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    // setEmployeeDelete(employees[tableMeta.rowIndex].id)
                                    setOpenDelete(true)
                                }}
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const options = {...TableOptions,...StaticTableOptions,
        selectableRows: false,
        customToolbar: () => {
            return (
                <div className="flex space-x-3">
                    <button onClick={handleOpenDialog} className="rounded-md py-2 px-4 flex items-center bg-soft-blue text-white h-10">
                        <CirclePlus fillColor={"#fff"} />
                        <span className="ml-2">Add Category</span>
                    </button>
                    <button onClick={() => handleOpenDialog("notification")} className="rounded-md py-2 px-4 flex items-center bg-primary text-white h-10">
                        <CirclePlus fillColor={"#fff"} />
                        <span className="ml-2">Add Notification</span>
                    </button>
                </div>
            )
        },
    };

    const [openAdd, setOpenAdd] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [dialogType, setDialogType] = useState(false)
    const [data,setData] = useState([])

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)
    const cancelEditRef = useRef(null)

    const notifications = [{name:language.sms,status:false},{name:language.e_posta,status:false},{name:language.system_notification,status:false},{name:language.mobile_notification,status:false}]

    const handleOpenDialog = (dType) =>  {
        setOpenAdd(true)
        if(dType === 'notification'){
            setDialogType(true)
        }
        else{
            setDialogType(false)
        }
    }
    const handleCloseDialog = () => {
       setOpenAdd(false)
       setTimeout(()=>{
           setDialogType(false)
       },500)
    }
    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.notifications}
                        data={data}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <Transition.Root show={openAdd} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelAddRef} onClose={handleCloseDialog}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex flex-col">
                                        <div className="flex justify-between items-center pb-2 border-b border-gray-200">
                                            <div className="flex space-x-2 items-center">
                                                <CirclePlus fillColor={"#0E9E57"} />
                                                <h3>{dialogType ? language.add_notification : language.add_category}</h3>
                                            </div>
                                            <CrossCircle closeModal={handleCloseDialog} />
                                        </div>
                                        {
                                            dialogType ?
                                            <div className="sm:flex flex-col">
                                                <div className="flex gap-2">
                                                    <div className="flex flex-1 flex-col mt-3">
                                                        <div className="flex space-x-1 mb-1">
                                                            <span>{language.notification_name}</span>
                                                            <span className="text-red-400">*</span>
                                                            <GrayQuestionMark />
                                                        </div>
                                                        <input onChange={()=>{}
                                                            // (e) => setEmployee({ ...employee, employee_name: e.target.value })
                                                        } placeholder={language.notification_name} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                                                    </div>
                                                    <div className="flex flex-1 flex-col mt-3">
                                                        <div className="flex space-x-1 mb-1">
                                                            <span>{language.title}</span>
                                                            <span className="text-red-400">*</span>
                                                            <GrayQuestionMark />
                                                        </div>
                                                        <input onChange={()=>{}
                                                            // (e) => setEmployee({ ...employee, employee_surname: e.target.value })
                                                        } placeholder={language.title} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-3">
                                                    <div className="flex space-x-1 mb-1">
                                                        <span>{language.message}</span>
                                                        <span className="text-red-400">*</span>
                                                        <GrayQuestionMark />
                                                    </div>
                                                    <input onChange={()=>{}
                                                        // (e) => setEmployee({ ...employee, employee_phone: e.target.value })
                                                    } placeholder={language.message} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                                                </div>
                                                <div className="flex flex-col mt-3">
                                                    <div className="flex space-x-1 mb-1">
                                                        <span>{language.category}</span>
                                                        <span className="text-red-400">*</span>
                                                        <GrayQuestionMark />
                                                    </div>
                                                    <div className="flex relative items-center">
                                                        <select onChange={()=>{}
                                                            // (e) => setEmployee({ ...employee, employee_main_department: e.target.value })
                                                        } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                                            <option value="">{language.choose}</option>
                                                            <option value="">Category-1</option>
                                                            <option value="">Category-2</option>
                                                        </select>
                                                        <CircleArrowDownward />
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-3">
                                                    <div className="flex space-x-1 mb-1">
                                                        <span>{language.department}</span>
                                                        <span className="text-red-400">*</span>
                                                        <GrayQuestionMark />
                                                    </div>
                                                    <div className="flex relative items-center">
                                                        <select onChange={()=>{}
                                                            // (e) => setEmployee({ ...employee, employee_main_department: e.target.value })
                                                        } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                                            <option value="">{language.choose}</option>
                                                            <option value="">Department-1</option>
                                                            <option value="">Department-2</option>
                                                        </select>
                                                        <CircleArrowDownward />
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-3">
                                                    <div className="flex space-x-1 mb-1">
                                                        <span>{language.publishing_status}</span>
                                                        <span className="text-red-400">*</span>
                                                        <GrayQuestionMark />
                                                    </div>
                                                    <div className="flex flex-wrap space-y-2 relative items-center">
                                                        <div className="flex items-center space-x mt-3 mr-3">
                                                            <Checkbox onChange={(e) => {
                                                                // if (e.target.checked) {
                                                                //     setSelectedBookLevels([
                                                                //         ...selectedBookLevels,
                                                                //         {
                                                                //             id: item.id,
                                                                //             book_level: item.book_level,
                                                                //             status: true,
                                                                //         },
                                                                //     ]);
                                                                // } else {
                                                                //     // remove from list
                                                                //     setSelectedBookLevels(
                                                                //         selectedBookLevels.filter((bookLevel) => bookLevel.id !== item.id),
                                                                //     );
                                                                // }
                                                            }} id={language.active} color="primary" value={language.active} className="h-6" />

                                                            <label className="cursor-pointer" htmlFor={language.active} >{language.active}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col mt-3">
                                                    <div className="flex space-x-1 mb-1">
                                                        <span>{language.allowed_notifications}</span>
                                                        <span className="text-red-400">*</span>
                                                        <GrayQuestionMark />
                                                    </div>
                                                    <div className="flex flex-wrap space-y-2 relative items-center">
                                                        {
                                                            notifications.map((item) => {
                                                                return (
                                                                    <div className="flex items-center space-x mt-3 mr-3">
                                                                        <Checkbox onChange={(e) => {
                                                                            // if (e.target.checked) {
                                                                            //     setSelectedBookLevels([
                                                                            //         ...selectedBookLevels,
                                                                            //         {
                                                                            //             id: item.id,
                                                                            //             book_level: item.book_level,
                                                                            //             status: true,
                                                                            //         },
                                                                            //     ]);
                                                                            // } else {
                                                                            //     // remove from list
                                                                            //     setSelectedBookLevels(
                                                                            //         selectedBookLevels.filter((bookLevel) => bookLevel.id !== item.id),
                                                                            //     );
                                                                            // }
                                                                        }} id={item.name} color="primary" value={item.name} className="h-6" />
                                                                        <label className="cursor-pointer" htmlFor={item.name} >{item.name}</label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div> :
                                                <div className="flex gap-2">
                                                    <div className="flex flex-1 flex-col mt-3">
                                                        <div className="flex space-x-1 mb-1">
                                                            <span>{language.category_name}</span>
                                                            <span className="text-red-400">*</span>
                                                            <GrayQuestionMark />
                                                        </div>
                                                        <input onChange={()=>{}
                                                            // (e) => setEmployee({ ...employee, employee_name: e.target.value })
                                                        } placeholder={language.category_name} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="mt-5 mb-2 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={()=>{setDialogType(false)}}
                                        ref={cancelAddRef}
                                    >
                                        {language.continue}
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-primary text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={handleCloseDialog}
                                    >
                                        {language.cancel}
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <Transition.Root show={openEdit} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelEditRef} onClose={setOpenEdit}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex flex-col">
                                        <div className="flex justify-between items-center pb-2 border-b border-gray-200">
                                            <div className="flex space-x-2 items-center">
                                                <CirclePlus fillColor={"#0E9E57"} />
                                                <h3>{dialogType ? language.add_notification : language.add_category}</h3>
                                            </div>
                                            <CrossCircle closeModal={handleCloseDialog} />
                                        </div>
                                        {
                                            dialogType ?
                                                <div className="sm:flex flex-col">
                                                    <div className="flex gap-2">
                                                        <div className="flex flex-1 flex-col mt-3">
                                                            <div className="flex space-x-1 mb-1">
                                                                <span>{language.notification_name}</span>
                                                                <span className="text-red-400">*</span>
                                                                <GrayQuestionMark />
                                                            </div>
                                                            <input onChange={()=>{}
                                                                // (e) => setEmployee({ ...employee, employee_name: e.target.value })
                                                            } placeholder={language.notification_name} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                                                        </div>
                                                        <div className="flex flex-1 flex-col mt-3">
                                                            <div className="flex space-x-1 mb-1">
                                                                <span>{language.title}</span>
                                                                <span className="text-red-400">*</span>
                                                                <GrayQuestionMark />
                                                            </div>
                                                            <input onChange={()=>{}
                                                                // (e) => setEmployee({ ...employee, employee_surname: e.target.value })
                                                            } placeholder={language.title} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col mt-3">
                                                        <div className="flex space-x-1 mb-1">
                                                            <span>{language.message}</span>
                                                            <span className="text-red-400">*</span>
                                                            <GrayQuestionMark />
                                                        </div>
                                                        <input onChange={()=>{}
                                                            // (e) => setEmployee({ ...employee, employee_phone: e.target.value })
                                                        } placeholder={language.message} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                                                    </div>
                                                    <div className="flex flex-col mt-3">
                                                        <div className="flex space-x-1 mb-1">
                                                            <span>{language.category}</span>
                                                            <span className="text-red-400">*</span>
                                                            <GrayQuestionMark />
                                                        </div>
                                                        <div className="flex relative items-center">
                                                            <select onChange={()=>{}
                                                                // (e) => setEmployee({ ...employee, employee_main_department: e.target.value })
                                                            } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                                                <option value="">{language.choose}</option>
                                                                <option value="">Category-1</option>
                                                                <option value="">Category-2</option>
                                                            </select>
                                                            <CircleArrowDownward />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col mt-3">
                                                        <div className="flex space-x-1 mb-1">
                                                            <span>{language.department}</span>
                                                            <span className="text-red-400">*</span>
                                                            <GrayQuestionMark />
                                                        </div>
                                                        <div className="flex relative items-center">
                                                            <select onChange={()=>{}
                                                                // (e) => setEmployee({ ...employee, employee_main_department: e.target.value })
                                                            } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                                                <option value="">{language.choose}</option>
                                                                <option value="">Department-1</option>
                                                                <option value="">Department-2</option>
                                                            </select>
                                                            <CircleArrowDownward />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col mt-3">
                                                        <div className="flex space-x-1 mb-1">
                                                            <span>{language.publishing_status}</span>
                                                            <span className="text-red-400">*</span>
                                                            <GrayQuestionMark />
                                                        </div>
                                                        <div className="flex flex-wrap space-y-2 relative items-center">
                                                            <div className="flex items-center space-x mt-3 mr-3">
                                                                <Checkbox onChange={(e) => {
                                                                    // if (e.target.checked) {
                                                                    //     setSelectedBookLevels([
                                                                    //         ...selectedBookLevels,
                                                                    //         {
                                                                    //             id: item.id,
                                                                    //             book_level: item.book_level,
                                                                    //             status: true,
                                                                    //         },
                                                                    //     ]);
                                                                    // } else {
                                                                    //     // remove from list
                                                                    //     setSelectedBookLevels(
                                                                    //         selectedBookLevels.filter((bookLevel) => bookLevel.id !== item.id),
                                                                    //     );
                                                                    // }
                                                                }} id={language.active} color="primary" value={language.active} className="h-6" />

                                                                <label className="cursor-pointer" htmlFor={language.active} >{language.active}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col mt-3">
                                                        <div className="flex space-x-1 mb-1">
                                                            <span>{language.allowed_notifications}</span>
                                                            <span className="text-red-400">*</span>
                                                            <GrayQuestionMark />
                                                        </div>
                                                        <div className="flex flex-wrap space-y-2 relative items-center">
                                                            {
                                                                notifications.map((item) => {
                                                                    return (
                                                                        <div className="flex items-center space-x mt-3 mr-3">
                                                                            <Checkbox onChange={(e) => {
                                                                                // if (e.target.checked) {
                                                                                //     setSelectedBookLevels([
                                                                                //         ...selectedBookLevels,
                                                                                //         {
                                                                                //             id: item.id,
                                                                                //             book_level: item.book_level,
                                                                                //             status: true,
                                                                                //         },
                                                                                //     ]);
                                                                                // } else {
                                                                                //     // remove from list
                                                                                //     setSelectedBookLevels(
                                                                                //         selectedBookLevels.filter((bookLevel) => bookLevel.id !== item.id),
                                                                                //     );
                                                                                // }
                                                                            }} id={item.name} color="primary" value={item.name} className="h-6" />

                                                                            <label className="cursor-pointer" htmlFor={item.name} >{item.name}</label>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className="flex gap-2">
                                                    <div className="flex flex-1 flex-col mt-3">
                                                        <div className="flex space-x-1 mb-1">
                                                            <span>{language.category_name}</span>
                                                            <span className="text-red-400">*</span>
                                                            <GrayQuestionMark />
                                                        </div>
                                                        <input onChange={()=>{}
                                                            // (e) => setEmployee({ ...employee, employee_name: e.target.value })
                                                        } placeholder={language.category_name} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="mt-5 mb-2 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={()=>{setDialogType(false)}}
                                        ref={cancelAddRef}
                                    >
                                        {language.continue}
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-primary text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={handleCloseDialog}
                                    >
                                        {language.cancel}
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <Transition.Root show={openDelete} as={Fragment}>
                <Dialog as="span" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelDeleteRef} onClose={setOpenDelete}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex flex-col">
                                        <div className="flex justify-between items-center pb-2 border-b border-gray-200">
                                            <div className="flex space-x-2 items-center">
                                                <CirclePlus fillColor={"#0E9E57"} />
                                                <h3>{language.delete_employee}</h3>
                                            </div>
                                            <CrossCircle closeModal={() => setOpenDelete(false)} />
                                        </div>
                                        <div className="flex flex-col mt-5">
                                            <div className="flex justify-center bg-red-200 p-5 rounded-lg space-x-1 mb-1">
                                                <span className="text-red-500">{language.are_you_sure}</span>
                                                <GrayQuestionMark />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 mb-2 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={()=>{}}
                                        ref={cancelDeleteRef}
                                    >
                                        {language.continue}
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-primary text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpenDelete(false)}
                                    >
                                        {language.cancel}
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}

export default Notifications;