import React from 'react';
import Location from "../../assets/icons/Location";
import Book from "../../assets/icons/Book";
import {NavLink} from "react-router-dom";
import Library from "../../assets/icons/Library";
import {useSelector} from "react-redux";

function AdminNavigation () {
        const language = useSelector(state => state.userInfos.language)
        return (
            <div className="bg-white pt-3">
                <div className="container px-3 mx-auto max-w-screen-lg">
                    <div className="flex">
                        <div className="flex space-x-4">
                            {/*<NavLink to="/admin/corporations" activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">*/}
                            {/*    <Location/>*/}
                            {/*    <div className="ml-2 text-lg">Corporations</div>*/}
                            {/*</NavLink>*/}
                            <NavLink to="/admin/regions" activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                                <Location/>
                                <div className="ml-2 text-lg">{language.regions}</div>
                            </NavLink>
                            <NavLink to="/admin/books" activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                                <Book fill={'currentColor'} />
                                <div className="ml-2 text-lg">{language.books}</div>
                            </NavLink>
                            <NavLink to="/admin/library" activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                                <Library fill={'currentColor'}/>
                                <div className="ml-2 text-lg">{language.library}</div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default AdminNavigation;