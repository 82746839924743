import { ApiUrl } from "../../components/utils/Urls";
import { soundActions } from "./soundSlice";
import {toast} from "react-toastify";

export const fetchSoundData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/sound', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch sound data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const soundData = await fetchData()
            dispatch(soundActions.getSounds(soundData))
        } catch (error) { }
    }
}

export const updateSoundData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/soundEdit', {
                method: 'POST', body: 'id=' + id + '&' + 'name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not update sound data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(soundActions.getSound(''))
            toast.success("Sound updated successfully")
        } catch (error) { }
    }
}

export const deleteSoundData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'library/soundDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not delete sound data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(soundActions.getSound(''))
            toast.success("Sound deleted successfully")
        } catch (error) { }
    }
}

export const addSoundData = (bookId,language,file) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'book/bookAddListen', {
                method: 'POST', body: 'bookId=' + bookId + '&language=' + language + '&file=' + file,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not add sound data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            toast.success("Sound added successfully")
            dispatch(soundActions.getSound(''))
        } catch (error) { }
    }
}