import React, { useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";
import { Chat } from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { tableTheme } from '../../components/customTable/TableTheme';
import { extendNonSelectable } from '../../components/customTable/ExtendNonSelectable';
import { Components } from '../../components/customTable/Components';
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import {fetchTeacherClassData, fetchTeacherStudentData} from "../../store/teacherStudent/teacherStudentActions";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import SwiperCore, {
    Navigation
} from 'swiper';
import {FileUrl} from "../../components/utils/Urls";
import {teacherStudentActions} from "../../store/teacherStudent/teacherStudentSlice";
SwiperCore.use([Navigation]);

function MyStudents(
    props,
    { getMuiTheme = () => createTheme(tableTheme, extendNonSelectable) }) {

    const dispatch = useDispatch()

    const prevRef = useRef(null)
    const nextRef = useRef(null)
    const classes = useSelector(state=>state.teacherStudents.classes)
    const currentClass = useSelector(state=>state.teacherStudents.class)

    const students = useSelector(state=>state.teacherStudents.students)
    const language = useSelector(state => state.userInfos.language)
    const userInfos = useSelector(state=>state.userInfos.userInfos)
    const TableOptions = useSelector(state=>state.tableOptions.options)

    const cols = [
        {
            name: "firstName",
            label: language.name_surname,
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <NavLink to={`/user/statistics/${currentClass.id}/${students[tableMeta.rowIndex].id}`} className="flex items-center">
                            <img className="w-6 h-6" src={FileUrl + students[tableMeta.rowIndex].avatar} alt="" />
                            <div className="flex flex-col mx-2">
                                <div className="font-semibold">{value + " " + (students[tableMeta.rowIndex].lastName !== null ? students[tableMeta.rowIndex].lastName : '')}</div>
                                <div className="text-xs">{students[tableMeta.rowIndex].id}</div>
                            </div>
                        </NavLink>
                    );
                }
            }
        },
        {
            name: "lastName",
            label: language.surname,
            options: {
                filter: true,
                sort: true,
                display: false,
                viewColumns:false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div className="hidden">{value}</div>
                    );
                }
            }
        },
        {
            name: "student_level",
            label: language.level,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div className="flex items-center space-x-4 ml-1">
                            <div className="text-bold">1/12</div>
                        </div>
                    );
                }
            }
        },
        {
            name: "progress",
            label: language.progress_status,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div className="flex items-centerml-1">
                            <div className="font-semibold">%{value}</div>
                            <div className="bg-gray-200 mx-2 h-4 w-32 rounded-md">
                                <div style={{width:`${value}%`}} className="bg-primary h-full rounded-md"></div>
                            </div>
                        </div>
                    );
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                download: false,
                print: false,
                viewColumns:false,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <div className="flex items-center space-x-1">
                            <div className="flex gap-2 items-center">
                                <NavLink to="/user/chat"><Chat className="text-gray-100 h-2 w-2 hover:text-primary" /></NavLink>
                            </div>
                        </div>
                    );
                }
            }
        }];

    const options = {...TableOptions,...StaticTableOptions,
        selectableRows: false,
    };

    const getClassStudents = item => {
            dispatch(teacherStudentActions.getClass(item))
            dispatch(fetchTeacherStudentData(userInfos.id,item.id))
    }

    useEffect(() => {
        dispatch(fetchTeacherClassData(userInfos.id))
    }, [dispatch])

    return (
        <div className="container mt-3 px-3 mx-auto max-w-screen-lg">
            <div className="flex flex-col bg-white rounded-md space-y-4 p-5 mb-5">
                <div className="text-dark-primary text-md font-bold">{language.classes}</div>
                <div className="mt-3">
                    <Swiper
                        loop={false} loopFillGroupWithBlank={true}
                        slidesPerView={20.5}
                        navigation={{
                            prevEl: prevRef.current,
                            nextEl: nextRef.current
                        }}
                        onSlideChange={(swiper) => {
                            console.log(swiper)
                        }}
                        onSwiper={(swiper) => console.log(swiper)}
                        className="mySwiper"
                    >
                        {
                            classes.map((item) => {
                                return (
                                    <SwiperSlide>
                                        <div onClick={()=>getClassStudents(item)} className={`${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "" : "ml-4"} flex flex-col h-8 w-8 cursor-pointer items-center border p-1 rounded-md ${item.id === currentClass.id ? "border-dark-primary" : "border-gray-300"}`}>
                                            <h3 className="text-sm text-center">{item.name}</h3>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>
            <div className="mt-3 px-3 mx-auto data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.my_students}
                        data={students}
                        columns={cols}
                        options={options}
                        components={Components}
                    />
                </MuiThemeProvider>
            </div>
        </div>
    );
}

export default MyStudents