import React, {useState} from 'react';

const HelpItem = ({title,content,index}) => {
    const [isActive,setIsActive] = useState(false)
    return (
        <div className="flex space-x-4 mb-3">
            <div
                className={`${isActive ? 'text-white bg-soft-blue':'text-gray-400 bg-gray-200'} flex cursor-pointer z-10 items-center justify-center text-sm font-bold p-4 ml-3 h-4 w-4 rounded-md`}>
                {index+1>=10 ? '':'0'}{index+1}
            </div>
            <div className="flex flex-col">
                <div onClick={()=>{
                    setIsActive(!isActive)
                }} className="cursor-pointer text-xs font-bold z-0 text-dark-primary">
                    {title}
                </div>
                <div className={`accordion text-xs text-dark-primary mt-2 transition duration-500 ease-in-out ${isActive ? 'h-auto':'h-0 overflow-hidden'}`}>
                    {content}
                </div>
            </div>
        </div>
    );
}

export default HelpItem;