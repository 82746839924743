import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import PaintBrush from "../../assets/icons/PaintBrush";
import Avatar from "../../assets/icons/Avatar";
import ArrowLeftLg from "../../assets/icons/ArrowLeftLg";

import ArrowRightLg from "../../assets/icons/ArrowRightLg";
import Tick from "../../assets/icons/Tick";
import {useDispatch, useSelector} from 'react-redux'

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import SwiperCore, {
    Navigation
} from 'swiper';
import {
    fetchBackgroundsData, updateStudentBackgroundData
} from "../../store/image/imageActions";
import {FileUrl} from "../../components/utils/Urls";

// install Swiper modules
SwiperCore.use([Navigation]);

function BackgroundBuilder(props) {
    const dispatch = useDispatch()
    const [isHidden, setIsHidden] = useState("")

    const backgroundImages = useSelector(state=>state.image.backgroundImages)
    const backgroundImage = useSelector(state=>state.image.backgroundImage)
    const userInfos = useSelector(state => state.userInfos.userInfos)

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    function saveBackground(item) {
        dispatch(updateStudentBackgroundData(item.file,userInfos.id))
    }

    useEffect(()=>{
        dispatch(fetchBackgroundsData())
    },[dispatch])

    return (
        <div className="container flex flex-col flex-1 px-3 mx-auto max-w-screen-lg items-center justify-between">
            <div></div>
            <div className="flex flex-col w-full">
                <div className="flex">
                    <div onClick={() => setIsHidden("")} className="flex items-center py-2 px-5 text-white bg-dark-primary cursor-pointer">
                        <PaintBrush fill={"#fff"} />
                        <span className="mx-2">Background</span>
                    </div>
                    <NavLink to="/user/settings/avatar-builder" className="flex items-center py-2 px-5 text-white bg-primary">
                        <Avatar fill={"#fff"} />
                        <span className="flex mx-2">Avatar Build</span>
                    </NavLink>
                </div>
                <div className={`flex flex-col bg-white ${isHidden}`}>
                    <div className="flex justify-between mx-14 mt-3">
                        <div className="text-dark-primary text-sm">Select Background</div>
                        <div className="cursor-pointer text-gray-300 text-sm" onClick={() => setIsHidden("hidden")}>Hide Bar</div>
                    </div>
                    <div>
                        <div className="flex items-center p-2 gap-2">
                            <div className="cursor-pointer z-100" ref={prevRef} ><ArrowLeftLg fill={"#919e9f"} /></div>
                            <div className="flex-1">
                                <Swiper
                                    loop={true} loopFillGroupWithBlank={true}
                                    slidesPerView={10}
                                    navigation={{
                                        prevEl: prevRef.current,
                                        nextEl: nextRef.current
                                    }}
                                    className="mySwiper"
                                >
                                    {
                                        backgroundImages.map((item)=> {
                                            return (
                                                <SwiperSlide>
                                                    <div onClick={() => {
                                                        saveBackground(item)
                                                    }} className="h-full max-h-16 mx-2 relative flex items-center justify-center border border-gray-300">
                                                        <img className="w-full h-full p-1" src={FileUrl + item.file} alt="" />
                                                        <div className={`h-full w-full absolute flex items-center justify-center bg-black opacity-70 ${item.file === backgroundImage ? "" : "hidden"}`}>
                                                            <Tick fill={"#fff"} width={20} height={20} />
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                            <div className="cursor-pointer" ref={nextRef}><ArrowRightLg fill={"#919e9f"} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BackgroundBuilder;
