import { ApiUrl } from "../../components/utils/Urls";
import {departmentActions} from "./departmentSlice";

export const fetchDepartmentData = id => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/department', {
                method: 'POST',
                body: 'type=6',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch dashboard data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const departmentData = await fetchData()
            dispatch(departmentActions.getDepartments(departmentData))
        } catch (error) { }
    }
}