import { createSlice } from "@reduxjs/toolkit";

const imageSlice = createSlice({
    name: 'image',
    initialState: {
        avatarImage: JSON.parse(localStorage.getItem("userInfos")) ? JSON.parse(localStorage.getItem("userInfos")).avatar : '',
        // avatarImage: '',
        avatarImages: [],
        backgroundImage: JSON.parse(localStorage.getItem("userInfos")) ? JSON.parse(localStorage.getItem("userInfos")).background : '',
        // backgroundImage: '',
        backgroundImages: [],
        isImage:false,
    },
    reducers: {
        updateBackgroundImage(state, action) {
            state.backgroundImage = action.payload
            const userInfos = JSON.parse(localStorage.getItem("userInfos"))
            localStorage.setItem("userInfos",JSON.stringify({...userInfos,background:action.payload}))
        },
        updateAvatarImage(state, action) {
            const userInfos = JSON.parse(localStorage.getItem("userInfos"))
            localStorage.setItem("userInfos",JSON.stringify({...userInfos,avatar:action.payload}))
            state.avatarImage = action.payload
        },
        getAvatarImages(state, action) {
            state.avatarImages = action.payload
        },
        getBackgroundImages(state, action) {
            state.backgroundImages = action.payload
        },
        getAvatarImage(state, action) {
            state.avatarImage = action.payload.student[0].avatar
        },
        getBackgroundImage(state, action) {
            state.backgroundImage = action.payload.student[0].background
        },
        getIsImage(state,action){
            state.isImage = !state.isImage
        }
    }
})

export const imageActions = imageSlice.actions

export default imageSlice