import { ApiUrl } from "../../components/utils/Urls";
import { corporationActions } from "./corporationSlice";
import {callToaster} from "../../components/utils/ToastText";

export const fetchCorporationData = directorateId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/company', {
                method: 'POST',
                body:'directorateId='+directorateId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch corporation data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const corporationData = await fetchData()
            dispatch(corporationActions.getCorporations(corporationData))
        } catch (error) { }
    }
}

export const updateCorporationData = (id, name, educationId) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'system/companyEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name + '&educationId=' + educationId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch corporation data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(corporationActions.getIsCorporation(2))
            callToaster("corporation_updated")
        } catch (error) { }
    }
}

export const deleteCorporationData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'system/companyDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch corporation data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(corporationActions.getIsCorporation(2))
            callToaster("corporation_deleted")
        } catch (error) { }
    }
}

export const addCorporationData = (directorateId,educationId,corporationName) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/companyAdd', {
                method: 'POST', body: 'directorateId=' + directorateId + '&educationId=' + educationId +  '&name=' + corporationName,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch corporation data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(corporationActions.getIsCorporation(2))
            callToaster("corporation_added")
        } catch (error) { }
    }
}