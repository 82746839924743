import React from 'react';

function Dash(props) {
    return (
        <div>
            <svg id="menu" xmlns="http://www.w3.org/2000/svg" width="22.1" height="22.1" viewBox="0 0 22.1 22.1">
                <g id="Group_100" data-name="Group 100">
                    <g id="Group_99" data-name="Group 99">
                        <path fill="currentColor" id="Path_16" data-name="Path 16" d="M7.631,0H2.556A2.559,2.559,0,0,0,0,2.556V7.631a2.559,2.559,0,0,0,2.556,2.556H7.631a2.559,2.559,0,0,0,2.556-2.556V2.556A2.559,2.559,0,0,0,7.631,0ZM8.46,7.631a.83.83,0,0,1-.829.829H2.556a.83.83,0,0,1-.829-.829V2.556a.83.83,0,0,1,.829-.829H7.631a.83.83,0,0,1,.829.829Z"/>
                    </g>
                </g>
                <g id="Group_102" data-name="Group 102" transform="translate(11.913)">
                    <g id="Group_101" data-name="Group 101">
                        <path fill="currentColor" id="Path_17" data-name="Path 17" d="M283.6,0H278.59A2.593,2.593,0,0,0,276,2.59V7.6a2.593,2.593,0,0,0,2.59,2.59H283.6a2.593,2.593,0,0,0,2.59-2.59V2.59A2.593,2.593,0,0,0,283.6,0Zm.863,7.6a.864.864,0,0,1-.863.863H278.59a.864.864,0,0,1-.863-.863V2.59a.864.864,0,0,1,.863-.863H283.6a.864.864,0,0,1,.863.863Z" transform="translate(-276)"/>
                    </g>
                </g>
                <g id="Group_104" data-name="Group 104" transform="translate(0 11.913)">
                    <g id="Group_103" data-name="Group 103">
                        <path fill="currentColor" id="Path_18" data-name="Path 18" d="M7.631,276H2.556A2.559,2.559,0,0,0,0,278.556v5.075a2.559,2.559,0,0,0,2.556,2.556H7.631a2.559,2.559,0,0,0,2.556-2.556v-5.075A2.559,2.559,0,0,0,7.631,276Zm.829,7.631a.83.83,0,0,1-.829.829H2.556a.83.83,0,0,1-.829-.829v-5.075a.83.83,0,0,1,.829-.829H7.631a.83.83,0,0,1,.829.829Z" transform="translate(0 -276)"/>
                    </g>
                </g>
                <g id="Group_106" data-name="Group 106" transform="translate(11.913 11.913)">
                    <g id="Group_105" data-name="Group 105">
                        <path fill="currentColor" id="Path_19" data-name="Path 19" d="M283.6,276H278.59a2.593,2.593,0,0,0-2.59,2.59V283.6a2.593,2.593,0,0,0,2.59,2.59H283.6a2.593,2.593,0,0,0,2.59-2.59V278.59A2.593,2.593,0,0,0,283.6,276Zm.863,7.6a.864.864,0,0,1-.863.863H278.59a.864.864,0,0,1-.863-.863V278.59a.864.864,0,0,1,.863-.863H283.6a.864.864,0,0,1,.863.863Z" transform="translate(-276 -276)"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default Dash;