import React, {useEffect, useRef, useState} from 'react';
import ArrowLeft from "../../assets/icons/ArrowLeft";
import { NavLink } from "react-router-dom";
import BookOne from "../../assets/imgs/book-one.png"
import BookTwo from "../../assets/imgs/book-two.png"
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import CheckDialog from "../../components/dialogs/CheckDialog";


function Quiz(props) {
    const language = useSelector(state => state.userInfos.language)
    const questionNumbers = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"]
    const questions = [
        {
            question:'Are you going to answer first question rightly?',
            answers:{id:0,options:[
                {id:"A",option:'Yes i am going to answer first question right'},
                {id:"B",option:'I will take my %25 percent chance'},
                {id:"C",option:'I don\'t have any idea about the answer'},
                {id:"D",option:'That option is the closest one'}
            ]},
        },
        {
            question:'Are you going to answer second question rightly?',
            answers:{id:1,options:[
                {id:"A",option:'Yes i am going to answer second question right'},
                {id:"B",option:'I will take my %25 percent chance'},
                {id:"C",option:'I don\'t have any idea about the answer'},
                {id:"D",option:'That option is the closest one'}
            ]},
        },
        {
            question:'Are you going to answer third question rightly?',
            answers:{id:2,options:[
                {id:"A",option:'Yes i am going to answer third question right'},
                {id:"B",option:'I will take my %25 percent chance'},
                {id:"C",option:'I don\'t have any idea about the answer'},
                {id:"D",option:'That option is the closest one'}
            ]},
        },
        {
            question:'Are you going to answer fourth question rightly?',
            answers:{id:3,options:[
                {id:"A",option:'Yes i am going to answer fourth question right'},
                {id:"B",option:'I will take my %25 percent chance'},
                {id:"C",option:'I don\'t have any idea about the answer'},
                {id:"D",option:'That option is the closest one'}
            ]},
        },
        {
            question:'Are you going to answer fifth question rightly?',
            answers:{id:4,options:[
                {id:"A",option:'Yes i am going to answer fifth question right'},
                {id:"B",option:'I will take my %25 percent chance'},
                {id:"C",option:'I don\'t have any idea about the answer'},
                {id:"D",option:'That option is the closest one'}
            ]},
        },
        {
            question:'Are you going to answer sixth question rightly?',
            answers:{id:5,options:[
                {id:"A",option:'Yes i am going to answer sixth question right'},
                {id:"B",option:'I will take my %25 percent chance'},
                {id:"C",option:'I don\'t have any idea about the answer'},
                {id:"D",option:'That option is the closest one'}
            ]},
        },
        {
            question:'Are you going to answer seventh question rightly?',
            answers:{id:6,options:[
                {id:"A",option:'Yes i am going to answer seventh question right'},
                {id:"B",option:'I will take my %25 percent chance'},
                {id:"C",option:'I don\'t have any idea about the answer'},
                {id:"D",option:'That option is the closest one'}
            ]},
        },
        {
            question:'Are you going to answer eighth question rightly?',
            answers:{id:7,options:[
                {id:"A",option:'Yes i am going to answer eighth question right'},
                {id:"B",option:'I will take my %25 percent chance'},
                {id:"C",option:'I don\'t have any idea about the answer'},
                {id:"D",option:'That option is the closest one'}
            ]},
        },
        {
            question:'Are you going to answer ninth question rightly?',
            answers:{id:8,options:[
                {id:"A",option:'Yes i am going to answer ninth question right'},
                {id:"B",option:'I will take my %25 percent chance'},
                {id:"C",option:'I don\'t have any idea about the answer'},
                {id:"D",option:'That option is the closest one'}
            ]},
        },
        {
            question:'Are you going to answer tenth question rightly?',
            answers:{id:9,options:[
                {id:"A",option:'Yes i am going to answer tenth question right'},
                {id:"B",option:'I will take my %25 percent chance'},
                {id:"C",option:'I don\'t have any idea about the answer'},
                {id:"D",option:'That option is the closest one'}
            ]},
        }
    ]

    const cancelRef = useRef(null)
    const [questionNumber, setQuestionNumber] = useState({id:0,pageNumber:"01",question:questions[0]})
    const [selectedAnswers,setSelectedAnswers] = useState([
        {answer:''},
        {answer:''},
        {answer:''},
        {answer:''},
        {answer:''},
        {answer:''},
        {answer:''},
        {answer:''},
        {answer:''},
        {answer:''}])
    const [unansweredQuestions, setUnansweredQuestions] = useState(selectedAnswers.length)
    const [open,setOpen] = useState(false)
    const [completed,setCompleted] = useState(false)

    const answerQuestion = (item) =>{
        let newArray = [...selectedAnswers]
        newArray[(questionNumber.question.answers.id)] = {answer: item.id}
        setSelectedAnswers(newArray)

        let answeredQuestions = 0
        newArray.map((selected)=>{
            if(selected.answer !== ''){
                answeredQuestions++
                console.log("ok")
            }
        })
        setUnansweredQuestions((newArray.length - answeredQuestions))
    }

    const finishQuiz = () =>{
        setCompleted(true)
    }

    return (
        <div className="min-h-screen sm:h-screen flex flex-col bg-gray-100 bg-user-image overflow-hidden">
            <div className="container flex flex-col px-3 mx-auto max-w-screen-lg z-10">
                <div className="flex justify-between mt-5">
                    <NavLink to="/user/books" className="flex items-center space-x-2">
                        <ArrowLeft opacity={"0.46"} fill={"#fff"} />
                        <div className="text-white text-sm px-2">{language.back_to_library}</div>
                    </NavLink>
                    <h3 className="text-sm text-white mt-3">Wilds Animal House</h3>
                    <div className="w-16"></div>
                </div>
                <div className="flex gap-4 mt-5">
                    <div className="flex flex-col gap-2 mt-5">
                        {
                            questionNumbers.map((page,index) => {
                                return (
                                    <div onClick={(e) => setQuestionNumber({id:index,pageNumber:page,question:questions[index]})} className={`flex cursor-pointer items-center justify-center text-xs font-bold text-white p-1 h-10 w-10 rounded-md ${page === questionNumber.pageNumber ? "bg-primary" : "bg-gray-400"}`}>
                                        {page}
                                    </div>
                                )
                            })
                        }
                        <div onClick={()=>setOpen(true)} className={`flex cursor-pointer items-center justify-center text-xs font-bold text-white p-1 h-10 w-10 rounded-md bg-secondary`}>
                            {language.finish}
                        </div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="flex items-center bg-gray-300 text-dark-secondary rounded-md p-5 mt-5">
                            <h1>{questionNumber.question.question}</h1>
                        </div>
                        <div className="flex flex-col mt-5">
                            {
                                questionNumber.question.answers.options.map((item,index)=>{
                                    return(
                                        <div key={item.id} onClick={()=> answerQuestion(item)} className={`flex items-center ${selectedAnswers[(questionNumber.question.answers.id)].answer === item.id ? 'bg-gray-400' : ''} group quiz cursor-pointer hover:bg-gray-400 bg-gray-200 p-5`}>
                                            <h1 className={`w-10 h-10 p-6 ${selectedAnswers[(questionNumber.question.answers.id)].answer === item.id ? 'bg-primary text-white' : ''} group-hover:text-white group-hover:bg-primary border-2 border-primary mr-5 flex items-center justify-center rounded-full bg-white`}>
                                                {item.id}
                                            </h1>
                                            <h1 className={`mx-2 ${selectedAnswers[(questionNumber.question.answers.id)].answer === item.id ? 'italic text-white' : ''}`}>{item.option}</h1>
                                        </div>
                                    )
                                })
                            }

                            {/*<div className="flex items-center group quiz cursor-pointer hover:bg-gray-400 bg-gray-200 p-5">*/}
                            {/*    <h1 className="w-10 h-10 p-6 group-hover:text-white border-2 border-primary mr-5 flex items-center justify-center rounded-full bg-white">*/}
                            {/*        B*/}
                            {/*    </h1>*/}
                            {/*    <h1 className="mx-2">I will take my %25 percent chance</h1>*/}
                            {/*</div>*/}
                            {/*<div className="flex items-center group quiz cursor-pointer hover:bg-gray-400 bg-gray-200 p-5">*/}
                            {/*    <h1 className="w-10 h-10 p-6 group-hover:text-white border-2 border-primary mr-5 flex items-center justify-center rounded-full bg-white">*/}
                            {/*        C*/}
                            {/*    </h1>*/}
                            {/*    <h1 className="mx-2">I don't have any idea about the answer</h1>*/}
                            {/*</div>*/}
                            {/*<div className="flex items-center group quiz cursor-pointer hover:bg-gray-400 bg-gray-200 p-5">*/}
                            {/*    <h1 className="w-10 h-10 p-6 group-hover:text-white border-2 border-primary mr-5 flex items-center justify-center rounded-full bg-white">*/}
                            {/*        D*/}
                            {/*    </h1>*/}
                            {/*    <h1 className="mx-2">That option is the closest one</h1>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="hidden md:flex flex-col w-2/12 bg-white rounded-md space-y-4 p-5 mt-5">
                        <div className="text-dark-primary text-md font-bold text-center">Other Quizzes</div>
                        <div className="flex flex-col items-center border border-gray-300 p-1 rounded-md">
                            <img className="px-1 mt-2" src={BookOne} alt="" />
                            <h3 className="text-sm mt-3 text-center">Wilds Animal House</h3>
                            <span className="text-gray-400 text-xs text-center">Xavier Deneux</span>
                            <div className="flex items-center">
                                <QuestionMarkCircleIcon className="h-4 w-4 text-dark-primary" />
                                <span className="text-xs mx-1">Quiz 1</span>
                            </div>
                        </div>
                        <div className="flex flex-col items-center border border-gray-300 p-1 rounded-md">
                            <img className="px-1 mt-2" src={BookTwo} alt="" />
                            <h3 className="text-sm mt-3 text-center">Journey To The Stars</h3>
                            <span className="text-gray-400 text-xs text-center">Matt Zhang</span>
                            <div className="flex items-center">
                                <QuestionMarkCircleIcon className="h-4 w-4 text-dark-primary" />
                                <span className="text-xs mx-1">Quiz 2</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`h-full w-full z-0 absolute flex items-center justify-center bg-black opacity-70`}>
            </div>
            <CheckDialog
                open={open}
                completed={completed}
                setCompleted={setCompleted}
                setOpen={setOpen}
                continue={finishQuiz}
                cancelRef={cancelRef}
                title={language.complete_quiz}
                description={completed ? language.quiz_completed : `${language.you_have} ${unansweredQuestions} ${language.unanswered_questions}`}
            />
        </div>
    );
}

export default Quiz;