import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import {selfClassActions} from "../../store/selfClass/selfClassSlice";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {
    addSelfClassData,
    assignTeacherData,
    deleteSelfClassData,
    fetchSelfClassData,
    updateSelfClassData
} from "../../store/selfClass/selfClassActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import Checkbox from "@material-ui/core/Checkbox";
import DialogHeader from "../../components/dialogs/DialogHeader";
import {useLocation} from "react-router-dom";
import SubHeader from "../../components/header/SubHeader";
import {fetchClassData} from "../../store/class/classActions";
import {TrendingUpIcon} from "@heroicons/react/solid";
import CircleArrowDownward from "../../assets/icons/CircleArrowDownward";
import {fetchEmployeeData} from "../../store/employee/employeeActions";

function SelfClasses({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const selfClasses = useSelector(state => state.selfClasses.selfClasses)
    const classrooms = useSelector(state=>state.classes.classes)
    const teachers = useSelector(state => state.employees.employees)
    const isSelfClass = useSelector(state=>state.selfClasses.isSelfClass)
    const getSelfClass = useSelector(state=>state.selfClasses.selfClass)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [openAssign,setOpenAssign] = useState(false)
    const [selfClassId, setSelfClassId] = useState(false)
    const [editClassrooms,setEditClassrooms] = useState([])
    const [currentTeacher,setCurrentTeacher] = useState({})
    const [errorMessage,setErrorMessage] = useState(false)

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [selfClass, setSelfClass] = useState({})
    const [selectedClasses,setSelectedClasses] = useState([])
    const [editSelectedClasses,setEditSelectedClasses] = useState([])

    const location = useLocation()
    const  { pathname } = location
    const url = pathname.split("/")
    const corporationId = url[11]

    const cols = [
        {
            name: "gradeName",
            label: language.grade_name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>{value}</div>
                    );
                }
            }
        },
        {
            name: "name",
            label: language.name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>{value}</div>
                    );
                }
            }
        },
        {
            // name: language.actions,
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <TrendingUpIcon onClick={()=> {
                                setSelfClass(selfClasses[tableMeta.rowIndex])
                                setOpenAssign(true)
                                setOpenAdd(true)
                            }} className="bg-gray-200 opacity-70 hover:opacity-100 transition-all cursor-pointer h-6 w-6 text-blue-700 rounded-md p-1" />
                            {/*<EditButton*/}
                            {/*    onClick={() => {*/}
                            {/*        const finalArray = []*/}
                            {/*        classrooms.map((item)=>{*/}
                            {/*            if(selfClasses[tableMeta.rowIndex].level.split(',').indexOf(item.id.toString()) !== -1){*/}
                            {/*                finalArray.push({*/}
                            {/*                    status:true,*/}
                            {/*                    id:item.id,*/}
                            {/*                    name:item.name*/}
                            {/*                })*/}
                            {/*            }*/}
                            {/*            else{*/}
                            {/*                finalArray.push({*/}
                            {/*                    status:false,*/}
                            {/*                    id:item.id,*/}
                            {/*                    name:item.name*/}
                            {/*                })*/}
                            {/*                console.log(item.name)*/}
                            {/*            }*/}
                            {/*            setEditSelectedClasses(finalArray)*/}
                            {/*        })*/}
                            {/*        setSelfClassId(selfClasses[tableMeta.rowIndex].id)*/}
                            {/*        setOpenEdit(true)*/}
                            {/*        setOpenAdd(true)*/}
                            {/*        setSelfClass(selfClasses[tableMeta.rowIndex])*/}

                            {/*        dispatch(selfClassActions.getSelfClass(selfClasses[tableMeta.rowIndex]))*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <DeleteButton
                                onClick={() => {
                                    setSelfClassId(selfClasses[tableMeta.rowIndex].id)
                                    dispatch(selfClassActions.getSelfClass(selfClasses[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    const assignTeacher = () => {
        dispatch(assignTeacherData(corporationId,selfClass.id,currentTeacher.id))
        console.log(corporationId,selfClass.id,currentTeacher.id)
        setOpenAdd(false)
    }

    const saveSelfClass = () => {
        if(selectedClasses.length > 0){
            dispatch(selfClassActions.getSelfClass(''))
            let sendClassrooms = ''
            selectedClasses.map((item,index)=>{
                sendClassrooms += (index>0 ? ',' : '') + item
            })
            dispatch(addSelfClassData(corporationId,sendClassrooms))
            setOpenAdd(false)
            setErrorMessage(false)
        }
        else{
            setErrorMessage(true)
        }
    }

    const deleteSelfClass = () => {
        dispatch(deleteSelfClassData(selfClassId))
        dispatch(fetchSelfClassData())
        setOpenDelete(false)
    }

    const editSelfClass = () => {
        let sendClassrooms = ''
        editSelectedClasses.map((item,index)=>{
            if(item.status){
                sendClassrooms += (index>0 ? ',' : '') + item.id
            }
            return sendClassrooms
        })
        if(sendClassrooms.length > 0){
            dispatch(updateSelfClassData(selfClassId, selfClass.name,sendClassrooms))
            dispatch(fetchSelfClassData())
            setOpenEdit(false)
            setOpenAdd(false)
            setErrorMessage(false)
        }
        else{
            setErrorMessage(true)
        }
    }

    useEffect(() => {
        dispatch(fetchSelfClassData(corporationId))
        dispatch(fetchClassData())
        dispatch(fetchEmployeeData(corporationId))
    }, [dispatch,isSelfClass,getSelfClass])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <SubHeader/>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.selfClasses}
                        data={selfClasses}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteSelfClass}
                title={language.delete_class}
                warning={language.class_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenAssign={setOpenAssign}
                setOpenEdit={()=>{
                    setOpenEdit(false)
                    setEditSelectedClasses([])
                }}
                reset
                setOpenAdd={()=> {
                    setOpenAdd(false)
                    setTimeout(()=>{
                        setOpenAssign(false)
                    },500)
                    setSelectedClasses([])
                }}
                cancelAddRef={cancelAddRef}
                saveItem={openAssign ? assignTeacher : saveSelfClass}
                editItem={editSelfClass}
            >
                {
                    openAssign ?
                        <div>
                            <DialogHeader
                                closeModal={() => {
                                    setTimeout(()=>{
                                        setOpenAssign(false)
                                    },500)
                                    setOpenAdd(false)
                                }}
                                title={language.assign_teacher}
                            />
                            <div className="flex flex-col mt-3">
                                <div className="flex space-x-1 mb-1">
                                    <span>{language.teachers}</span>
                                    <span className="text-red-400">*</span>
                                    <GrayQuestionMark />
                                </div>
                                <div className="flex relative items-center">
                                    <select value={openEdit ? currentTeacher.id : null} onChange={(e)=> {
                                        setCurrentTeacher({...currentTeacher, id: e.target.value})
                                        console.log(currentTeacher)
                                    }
                                    } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                        <option value="">{language.choose}</option>
                                        {
                                            teachers.map((item)=>{
                                                return(
                                                    <option value={item.id}>{item.firstName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <CircleArrowDownward />
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <DialogHeader
                                closeModal={() => setOpenAdd(false)}
                                title={openEdit ? language.edit_education_level : language.add_education_level}
                            />
                            <div className="flex flex-col mt-3">
                                <div className="flex space-x-1 mb-1">
                                    <span>{language.classes}</span>
                                    <span className="text-red-400">*</span>
                                    <GrayQuestionMark />
                                </div>
                                <div className="flex flex-wrap space-y-2 relative items-center">
                                    {
                                        openEdit ?
                                            editSelectedClasses.map((item,index) => {
                                                return (
                                                    <div className="flex items-center space-x mt-3 mr-3">
                                                        <Checkbox onChange={(e) => {
                                                            const changeStation = editSelectedClasses
                                                            if (e.target.checked) {
                                                                changeStation[index] = {id:item.id,name:item.name,status:true}
                                                                setEditSelectedClasses(changeStation);
                                                                setEditClassrooms(['reset'])
                                                            } else {
                                                                changeStation[index] = {id:item.id,name:item.name,status:false}
                                                                setEditSelectedClasses(changeStation);
                                                                setEditClassrooms(['reset'])
                                                            }
                                                            setEditSelectedClasses(editSelectedClasses)
                                                        }}
                                                                  checked={item.status}
                                                                  id={item.name} color="primary" value={item.name} className="h-6" />
                                                        <label className="cursor-pointer" htmlFor={item.name} >{item.name}</label>
                                                    </div>
                                                )
                                            }) :
                                            classrooms.map((item)=>{
                                                return(
                                                    <div className="flex items-center space-x mt-3 mr-3">
                                                        <Checkbox onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setSelectedClasses([
                                                                    ...selectedClasses,
                                                                    item.id,
                                                                ]);
                                                            } else {
                                                                setSelectedClasses(
                                                                    selectedClasses.filter((classroom) => classroom !== item.id),
                                                                );
                                                            }
                                                        }} id={item.name} color="primary" value={item.name} className="h-6" />
                                                        <label className="cursor-pointer" htmlFor={item.name} >{item.name}</label>
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            </div>
                            {
                                errorMessage &&
                                <div className="text-md text-red-600 bg-red-300 text-center p-2 rounded-md">
                                    {language.book_level_warning}
                                </div>
                            }
                        </div>
                }
            </AddDialog>
        </div>
    );
}

export default SelfClasses;