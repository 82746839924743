import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import TextInput from "../../components/textInput/TextInput";
import DialogHeader from "../../components/dialogs/DialogHeader";
import {addImageData, deleteImageData, fetchBackgroundsData, updateImageData} from "../../store/image/imageActions";
import {FileUrl} from "../../components/utils/Urls";
import {CloudUpload} from "@material-ui/icons";
import S3 from 'react-aws-s3';
import {amazonConfig} from "../../components/utils/AmazonConfig";

function Backgrounds({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const backgrounds = useSelector(state => state.image.backgroundImages)
    const isImage = useSelector(state=>state.image.isImage)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [isBtnDisabled,setIsBtnDisabled] = useState(true)
    const [coverImage,setCoverImage] = useState({file:'',name:'',show:''})
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [backgroundId, setBackgroundId] = useState(false)

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [background, setBackground] = useState({})

    const ReactS3Client = new S3(amazonConfig);

    const cols = [
        {
            name: "file",
            label: language.image,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div className="flex items-center">
                            <img className="h-6 object-contain" src={FileUrl + value} alt="" />
                        </div>
                    );
                }
            }
        },
        {
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    setBackgroundId(backgrounds[tableMeta.rowIndex].id)
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setIsBtnDisabled(true)
                                    setCoverImage({file:'start',name:'changed',show:''})
                                    setBackground(backgrounds[tableMeta.rowIndex])
                                    // dispatch(backgroundActions.getBackground(backgrounds[tableMeta.rowIndex]))
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setBackgroundId(backgrounds[tableMeta.rowIndex].id)
                                    // dispatch(backgroundActions.getBackground(backgrounds[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                    setCoverImage({file:'',name:'reset',show:''})
                }} />
            )
        }
    }

    const saveBackground = () => {
        setOpenAdd(false)
        ReactS3Client
            .uploadFile(coverImage.file,coverImage.name)
            .then(data => {
                    dispatch(addImageData(coverImage,"background"))
                }
            )
            .catch(err => console.error(err))
    }

    const deleteBackground = () => {
        dispatch(deleteImageData(backgroundId,"background"))
        setOpenDelete(false)
    }

    const editBackground = () => {
        ReactS3Client
            .uploadFile(coverImage.file,coverImage.name)
            .then(data => dispatch(updateImageData(backgroundId,coverImage,"background")))
            .catch(err => console.error(err))
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchBackgroundsData())
    }, [dispatch,isImage])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.backgrounds}
                        data={backgrounds}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteBackground}
                title={language.delete_background}
                warning={language.background_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={setOpenAdd}
                cancelAddRef={cancelAddRef}
                saveItem={saveBackground}
                editItem={editBackground}
            >
                <DialogHeader
                    closeModal={() => setOpenAdd(false)}
                    title={openEdit ? language.edit_background : language.add_background}
                />
                <div className="flex flex-col w-full relative">
                    <label htmlFor="cover_image" className="border w-full border-gray-300 h-52 flex items-center justify-center cursor-pointer">
                        {
                            coverImage.file.length !== 0 &&
                            <img className="p-2 h-24 object-contain" src={coverImage.name === 'changed' ? (FileUrl + background.file) : coverImage.show} alt=""/>
                        }
                        {
                            coverImage.file.length === 0 && !openEdit &&
                            <div className="flex flex-col items-center space-y-2 p-2">
                                <CloudUpload className="h-18 w-18 text-gray-300" />
                                <div className="text-gray-300 text-center">{language.upload_cover_image}</div>
                            </div>
                        }
                        <input onChange={(e)=> {
                            setIsBtnDisabled(false)
                            setCoverImage({file: e.target.files[0],name:e.target.files[0].name,show:URL.createObjectURL(e.target.files[0])})
                            console.log(e.target.files[0])
                        }} id="cover_image" type="file" className="hidden"/>
                    </label>
                    {
                        openEdit &&
                        <div className="absolute text-gray-300 text-center bottom-6 w-full">{language.change_image}</div>
                    }
                </div>
            </AddDialog>
        </div>
    );
}

export default Backgrounds;