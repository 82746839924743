import React from 'react';

function ArrowDownward () {
    return (
        <div className="h-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="7.496" height="11.24" viewBox="0 0 7.496 11.24">
                <path id="Icon_ionic-ios-arrow-round-down" data-name="Icon ionic-ios-arrow-round-down" d="M18.6,15.043a.51.51,0,0,0-.718,0L15.5,17.409V8.379a.508.508,0,0,0-1.015,0v9.03l-2.378-2.374a.514.514,0,0,0-.718,0,.505.505,0,0,0,0,.714l3.24,3.217h0a.57.57,0,0,0,.16.105.484.484,0,0,0,.2.039.509.509,0,0,0,.355-.144l3.24-3.217A.5.5,0,0,0,18.6,15.043Z" transform="translate(-11.247 -7.875)" fill="#1b3638"/>
            </svg>
        </div>
    );
}

export default ArrowDownward;