import React from 'react';

function Microphone(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="12.768" height="19.693" viewBox="0 0 12.768 19.693">
                <g id="microphone" transform="translate(-0.501 -0.001)">
                    <path id="Path_40" data-name="Path 40" d="M13.269,260.4a.587.587,0,0,0-1.175,0,5.21,5.21,0,1,1-10.419,0,.587.587,0,0,0-1.175,0,6.392,6.392,0,0,0,5.8,6.357v1.581H4.118a.587.587,0,0,0,0,1.175H9.652a.587.587,0,0,0,0-1.175H7.472V266.76a6.392,6.392,0,0,0,5.8-6.357Zm0,0" transform="translate(0 -249.822)" fill="#fff"/>
                    <path id="Path_41" data-name="Path 41" d="M72.475,14.342a3.765,3.765,0,0,0,3.76-3.76V3.761a3.76,3.76,0,0,0-7.521,0v6.82A3.765,3.765,0,0,0,72.475,14.342ZM69.889,3.761a2.586,2.586,0,0,1,5.172,0v6.82a2.586,2.586,0,1,1-5.172,0Zm0,0" transform="translate(-65.59 0)" fill="#fff"/>
                </g>
            </svg>
        </div>
    );
}

export default Microphone;