import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        statistics: [],
        statistic: {
            book: 0,
            listen: 0,
            listenTime: 0,
            page: 0,
        },
        studentId: '',
        students: [],
        book: 0,
        // listen: 0,
        // listenTime: 0,
        // page: 0,
        studentListenGraphic:[],
        studentReadGraphic:[],
    },
    reducers: {
        getStatistics(state, action) {
            state.statistics = action.payload
        },
        resetStatistic(state, action) {
            state.statistic = action.payload
        },
        getStatistic(state, action) {
            state.statistic = {
                book: 0,
                listen: 0,
                listenTime: 0,
                page: 0,
            }
            action.payload.map(item => {
                state.statistic = {
                    book: 0,
                    listen: 0,
                    listenTime: 0,
                    page: 0,
                }
                state.statistic.book = state.statistic.book + item.year[0].book
                state.statistic.listen = state.statistic.listen + item.year[0].listen
                state.statistic.listenTime = state.statistic.listenTime + item.year[0].listenTime
                state.statistic.page = state.statistic.page + item.year[0].page
            })
        },
        getStudents(state, action) {
            state.students = action.payload
        },
        getStudent(state, action) {
            state.studentId = action.payload
            // state.statistic = {
            //     book: 0,
            //     listen: 0,
            //     listenTime: 0,
            //     page: 0,
            // }
            // action.payload.statistic.map(item => {
            //     state.statistic.book = state.statistic.book + item.year[0].book
            //     state.statistic.listen = state.statistic.listen + item.year[0].listen
            //     state.statistic.listenTime = state.statistic.listenTime + item.year[0].listenTime
            //     state.statistic.page = state.statistic.page + item.year[0].page
            // })
        },
        getStudentGraphic(state,action){
            state.studentReadGraphic = []
            state.studentListenGraphic = []
            action.payload.map((item)=>{
                state.studentReadGraphic.push(item.read)
                state.studentListenGraphic.push(item.listen)
            })

        }
    }
})

export const dashboardActions = dashboardSlice.actions

export default dashboardSlice