import { createSlice } from "@reduxjs/toolkit";

const citySlice = createSlice({
    name: 'cities',
    initialState: {
        cities: [],
        // city: '',
        isCity:false,
    },
    reducers: {
        getCities(state, action) {
            state.cities = action.payload
        },
        // getCity(state, action) {
        //     state.city = action.payload
        // },
        getIsCity(state,action){
            state.isCity = !state.isCity
        }
    }
})

export const cityActions = citySlice.actions

export default citySlice