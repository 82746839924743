import React from 'react';

function Avatar ({fill}) {
        return (
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.336" height="21.999" viewBox="0 0 19.336 21.999">
                    <g id="pinocchio" transform="translate(-30.985 0)">
                        <g id="Group_255" data-name="Group 255" transform="translate(34.852 14.909)">
                            <g id="Group_254" data-name="Group 254" transform="translate(0)">
                                <path id="Path_59" data-name="Path 59" d="M124.865,350.215a2.581,2.581,0,0,1-2.578-2.578.645.645,0,0,0-1.289,0,3.872,3.872,0,0,0,3.867,3.867.645.645,0,1,0,0-1.289Z" transform="translate(-120.998 -346.992)" fill={fill}/>
                            </g>
                        </g>
                        <g id="Group_257" data-name="Group 257" transform="translate(30.985 0)">
                            <g id="Group_256" data-name="Group 256" transform="translate(0 0)">
                                <path id="Path_60" data-name="Path 60" d="M48.387,14.265H45.165V10.312h.645a.645.645,0,0,0,.576-.933L45.117,6.842,43.843,3.018a.645.645,0,0,0-.611-.441H35.37c-.681-1.362-.7-1.63-1.084-2.012A1.934,1.934,0,1,0,31.551,3.3a6.048,6.048,0,0,0,1.648.91l-.877,2.632L31.053,9.379a.645.645,0,0,0,.576.933h.645v5.242a6.446,6.446,0,0,0,12.354,2.578h3.759a1.934,1.934,0,1,0,0-3.867ZM35.375,5.249a.645.645,0,0,0,.859-.859l-.25-.523h6.782l.859,2.578H33.813l.558-1.675Zm-2.913-2.86a.645.645,0,1,1,.911-.911c.138.138.087.125.912,1.823C32.588,2.476,32.6,2.527,32.463,2.389Zm.854,5.345h10.8l.645,1.289H32.672Zm5.4,12.976a5.162,5.162,0,0,1-5.156-5.156V10.312H43.876v3.953H41.942a1.931,1.931,0,1,0,0,3.863h1.243A5.172,5.172,0,0,1,38.719,20.71Zm9.668-3.867H41.942a.645.645,0,0,1,0-1.289h6.445a.645.645,0,0,1,0,1.289Z" transform="translate(-30.985 0)" fill={fill}/>
                            </g>
                        </g>
                        <g id="Group_259" data-name="Group 259" transform="translate(37.429 11.601)">
                            <g id="Group_258" data-name="Group 258" transform="translate(0)">
                                <circle id="Ellipse_5" data-name="Ellipse 5" cx="0.645" cy="0.645" r="0.645" fill={fill}/>
                            </g>
                        </g>
                        <g id="Group_261" data-name="Group 261" transform="translate(41.296 11.601)">
                            <g id="Group_260" data-name="Group 260" transform="translate(0)">
                                <circle id="Ellipse_6" data-name="Ellipse 6" cx="0.645" cy="0.645" r="0.645" fill={fill}/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
}

export default Avatar;