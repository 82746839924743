import { createSlice } from "@reduxjs/toolkit";

const classSlice = createSlice({
    name: 'classes',
    initialState: {
        classes: [],
        class: false
    },
    reducers: {
        getClasses(state, action) {
            state.classes = action.payload
        },
        getClass(state, action) {
            state.class = !state.class
        }
    }
})

export const classActions = classSlice.actions

export default classSlice