import React from 'react';
import { useSelector } from 'react-redux';
import Filter from "../../assets/icons/Filter";

const FilterButton = () => {
    const language = useSelector(state => state.userInfos.language)
    return (
        <button className="rounded-md py-2 px-4 flex items-center bg-dark-primary text-white h-10">
            <Filter />
            <span className="ml-2">{language.filter}</span>
        </button>
    );
}

export default FilterButton;