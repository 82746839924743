import React from 'react';

function ActionsWrapper (props) {
    return (
        <div className="flex items-center space-x-1">
            <div className="flex gap-2 items-center">
                {props.children}
            </div>
        </div>
    );
}

export default ActionsWrapper;