import { createSlice } from "@reduxjs/toolkit";

const ministrySlice = createSlice({
    name: 'ministries',
    initialState: {
        ministries: [],
        isMinistry: ''
    },
    reducers: {
        getMinistries(state, action) {
            state.ministries = action.payload
        },
        getIsMinistry(state, action) {
            state.isMinistry = !state.isMinistry
        }
    }
})

export const ministryActions = ministrySlice.actions

export default ministrySlice