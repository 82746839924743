import { ApiUrl } from "../../components/utils/Urls";
import { employeeActions } from "./employeeSlice";
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

export const fetchEmployeeData = (id) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/companyPersonal', {
                method: 'POST',
                body:'companyId=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch employee data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const employeeData = await fetchData()
            const employees = employeeData.list
            dispatch(employeeActions.getEmployees(employees))
        } catch (error) { }
    }
}

export const updateEmployeeData = employee => {
    console.log(employee)
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'system/companyPersonalEdit', {
                method: 'POST', body: 'id=' + employee.id + '&firstName=' + employee.firstName + '&middleName=' + employee.middleName + '&lastName=' + employee.lastName + '&mail=' + employee.mail + '&phone=' + employee.phone + '&departmentId=' + employee.departmentId + '&departmentList=' + employee.departmentList,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch employee data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(employeeActions.getIsEmployee(2))
            callToaster("employee_updated")
        } catch (error) { }
    }
}

export const deleteEmployeeData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'system/companyPersonalDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch employee data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(employeeActions.getIsEmployee(2))
            callToaster("employee_deleted")
        } catch (error) { }
    }
}

export const addEmployeeData = (employee,corporationId,selectedDepartments) => {
    console.log(employee)
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/companyPersonalAdd', {
                method: 'POST', body: 'firstName=' + employee.firstName + '&middleName=' + employee.middleName + '&lastName=' + employee.lastName + '&mail=' + employee.mail + '&phone=' + employee.phone + '&departmentId=' + employee.departmentId + '&departmentList=' + selectedDepartments + '&companyId=' + corporationId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch employee data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(employeeActions.getIsEmployee(2))
            callToaster("employee_added")
        } catch (error) { }
    }
}