import { ApiUrl } from "../../components/utils/Urls";
import { faqActions } from "./faqSlice";
import {toast} from "react-toastify";

export const fetchFaqData = (type) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/faq', {
                method: 'POST',
                body: 'type=' + type,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch faq data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const faqData = await fetchData()
            dispatch(faqActions.getFaqs(faqData))
        } catch (error) { }
    }
}

export const updateFaqData = (id, faq) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'system/faqEdit', {
                method: 'POST', body: 'id=' + id + '&question=' + faq.question + '&answer=' + faq.answer,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not update faq data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(faqActions.getFaq(''))
            dispatch(fetchFaqData(faq.type))
            toast.success("Faq updated successfully")
        } catch (error) { }
    }
}

export const deleteFaqData = faq => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'system/faqDelete', {
                method: 'POST', body: 'id=' + faq.id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not delete faq data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(faqActions.getFaq(''))
            dispatch(fetchFaqData(faq.type))
            toast.success("Faq deleted successfully")
        } catch (error) { }
    }
}

export const addFaqData = faq => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/faqAdd', {
                method: 'POST', body: 'question=' + faq.question + '&answer=' + faq.answer + '&type=' + faq.type,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch faq data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            toast.success("Faq added successfully")
            dispatch(faqActions.getFaq(''))
            dispatch(fetchFaqData(faq.type))
        } catch (error) { }
    }
}