import React from 'react';

function Manage () {
        return (
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="28.997" height="22" viewBox="0 0 28.997 22">
                    <g id="diagram" transform="translate(0 -50)">
                        <g id="Group_107" data-name="Group 107" transform="translate(0 50)">
                            <path id="Path_20" data-name="Path 20" d="M27.822,63.939h-2.3V60.972a.529.529,0,0,0-.56-.56H15.059V58.005h2.3a1.148,1.148,0,0,0,1.176-1.176V51.176A1.148,1.148,0,0,0,17.354,50h-5.71a1.148,1.148,0,0,0-1.176,1.176v5.71a1.148,1.148,0,0,0,1.176,1.176h2.3v2.351H4.031a.529.529,0,0,0-.56.56v2.967h-2.3A1.148,1.148,0,0,0,0,65.115v5.71A1.148,1.148,0,0,0,1.176,72h5.71a1.148,1.148,0,0,0,1.176-1.176v-5.71a1.148,1.148,0,0,0-1.176-1.176H4.59V61.532h9.349v2.407h-2.3a1.148,1.148,0,0,0-1.176,1.176v5.71A1.148,1.148,0,0,0,11.644,72h5.71a1.148,1.148,0,0,0,1.176-1.176v-5.71a1.148,1.148,0,0,0-1.176-1.176h-2.3V61.532h9.349v2.407h-2.3a1.148,1.148,0,0,0-1.176,1.176v5.71A1.148,1.148,0,0,0,22.112,72h5.71A1.148,1.148,0,0,0,29,70.824v-5.71A1.182,1.182,0,0,0,27.822,63.939ZM6.941,65.115c-.056-.056,0-.056,0,0v5.71c0,.056,0,.056-.056.056H1.176c-.056,0-.056,0-.056-.056v-5.71c0-.056,0-.056.056-.056Zm10.468,0c-.056-.056,0-.056,0,0v5.71c0,.056,0,.056-.056.056h-5.71c-.056,0-.056,0-.056-.056v-5.71c0-.056,0-.056.056-.056ZM11.588,56.83c.056.056,0,.056,0,0V51.176c0-.056,0-.056.056-.056h5.71c.056,0,.056,0,.056.056v5.71c0,.056,0,.056-.056.056Zm16.29,13.939c0,.056-.056.056,0,0l-5.766.056c-.056,0-.056,0-.056-.056V65.115c0-.056,0-.056.056-.056h5.71c.056,0,.056,0,.056.056Z" transform="translate(0 -50)" fill="currentColor"/>
                        </g>
                    </g>
                </svg>
            </div>
        );
}

export default Manage;