import React, { useState } from 'react';
import { QuestionMarkCircleIcon, XIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import HelpItem from "../helpItem/HelpItem";

function HelpWindow(props) {
    // const language = useSelector(state => state.languageReducer)
    const language = useSelector(state => state.userInfos.language)
    const [openHelp, setOpenHelp] = useState(false)
    const helpData =
    {
        Deutsch: [
            {
                title: 'Aber ich muss Ihnen erklären, wie all diese irrige Idee, Freude anzuprangern und Schmerz zu preisen, geboren wurde, und ich werde Ihnen einen vollständigen Überblick über das System geben, und.',
                content: 'Aber ich muss Ihnen erklären, wie all diese irrige Idee, Freude anzuprangern und Schmerz zu preisen, geboren wurde, und ich werde Ihnen einen vollständigen Bericht über das System geben und ' +
                    'die tatsächlichen Lehren des großen Entdeckers der Wahrheit, des Baumeisters der Menschheit, erläutern Glück. Niemand lehnt Vergnügen ab, mag es nicht oder vermeidet es, weil es Vergnügen ist, ' +
                    'sondern weil diejenigen, die es nicht tun.'
            },
            {
                title: 'Aber ich muss Ihnen erklären, wie all diese irrige Idee, Freude anzuprangern und Schmerz zu preisen, geboren wurde, und ich werde Ihnen einen vollständigen Überblick über das System geben, und.',
                content: 'Aber ich muss Ihnen erklären, wie all diese irrige Idee, Freude anzuprangern und Schmerz zu preisen, geboren wurde, und ich werde Ihnen einen vollständigen Bericht über das System geben und ' +
                    'die tatsächlichen Lehren des großen Entdeckers der Wahrheit, des Baumeisters der Menschheit, erläutern Glück. Niemand lehnt Vergnügen ab, mag es nicht oder vermeidet es, weil es Vergnügen ist, ' +
                    'sondern weil diejenigen, die es nicht tun.'
            },
            {
                title: 'Aber ich muss Ihnen erklären, wie all diese irrige Idee, Freude anzuprangern und Schmerz zu preisen, geboren wurde, und ich werde Ihnen einen vollständigen Überblick über das System geben, und.',
                content: 'Aber ich muss Ihnen erklären, wie all diese irrige Idee, Freude anzuprangern und Schmerz zu preisen, geboren wurde, und ich werde Ihnen einen vollständigen Bericht über das System geben und ' +
                    'die tatsächlichen Lehren des großen Entdeckers der Wahrheit, des Baumeisters der Menschheit, erläutern Glück. Niemand lehnt Vergnügen ab, mag es nicht oder vermeidet es, weil es Vergnügen ist, ' +
                    'sondern weil diejenigen, die es nicht tun.'
            },
            {
                title: 'Aber ich muss Ihnen erklären, wie all diese irrige Idee, Freude anzuprangern und Schmerz zu preisen, geboren wurde, und ich werde Ihnen einen vollständigen Überblick über das System geben, und.',
                content: 'Aber ich muss Ihnen erklären, wie all diese irrige Idee, Freude anzuprangern und Schmerz zu preisen, geboren wurde, und ich werde Ihnen einen vollständigen Bericht über das System geben und ' +
                    'die tatsächlichen Lehren des großen Entdeckers der Wahrheit, des Baumeisters der Menschheit, erläutern Glück. Niemand lehnt Vergnügen ab, mag es nicht oder vermeidet es, weil es Vergnügen ist, ' +
                    'sondern weil diejenigen, die es nicht tun.'
            },
            {
                title: 'Aber ich muss Ihnen erklären, wie all diese irrige Idee, Freude anzuprangern und Schmerz zu preisen, geboren wurde, und ich werde Ihnen einen vollständigen Überblick über das System geben, und.',
                content: 'Aber ich muss Ihnen erklären, wie all diese irrige Idee, Freude anzuprangern und Schmerz zu preisen, geboren wurde, und ich werde Ihnen einen vollständigen Bericht über das System geben und ' +
                    'die tatsächlichen Lehren des großen Entdeckers der Wahrheit, des Baumeisters der Menschheit, erläutern Glück. Niemand lehnt Vergnügen ab, mag es nicht oder vermeidet es, weil es Vergnügen ist, ' +
                    'sondern weil diejenigen, die es nicht tun.'
            },
        ],
        English: [
            {
                title: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and.',
                content: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and ' +
                    'expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because ' +
                    'it is pleasure, but because those who do not.'
            },
            {
                title: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and.',
                content: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and ' +
                    'expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because ' +
                    'it is pleasure, but because those who do not.'
            },
            {
                title: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and.',
                content: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and ' +
                    'expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because ' +
                    'it is pleasure, but because those who do not.'
            },
            {
                title: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and.',
                content: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and ' +
                    'expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because ' +
                    'it is pleasure, but because those who do not.'
            },
            {
                title: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and.',
                content: 'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and ' +
                    'expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because ' +
                    'it is pleasure, but because those who do not.'
            }
        ],
        Türkçe: [
            {
                title: 'Ama zevki kınamak ve acıyı övmek gibi tüm bu yanlış fikrin nasıl doğduğunu size açıklamalıyım ve size sistemin tam bir açıklamasını vereceğim ve.',
                content: 'Ama size zevki kınamak ve acıyı övmek gibi tüm bu yanlış fikrin nasıl doğduğunu açıklamalıyım ve size sistemin tam bir açıklamasını vereceğim ve gerçeğin büyük kaşifi, insanın usta-inşaatçısının gerçek öğretilerini açıklayacağım. mutluluk. Hiç kimse zevkin kendisini reddetmez, sevmez veya bundan kaçınmaz, çünkü bu zevktir, ama hoşlanmayanlar için.'
            },
            {
                title: 'Ama zevki kınamak ve acıyı övmek gibi tüm bu yanlış fikrin nasıl doğduğunu size açıklamalıyım ve size sistemin tam bir açıklamasını vereceğim ve.',
                content: 'Ama size zevki kınamak ve acıyı övmek gibi tüm bu yanlış fikrin nasıl doğduğunu açıklamalıyım ve size sistemin tam bir açıklamasını vereceğim ve gerçeğin büyük kaşifi, insanın usta-inşaatçısının gerçek öğretilerini açıklayacağım. mutluluk. Hiç kimse zevkin kendisini reddetmez, sevmez veya bundan kaçınmaz, çünkü bu zevktir, ama hoşlanmayanlar için.'
            },
            {
                title: 'Ama zevki kınamak ve acıyı övmek gibi tüm bu yanlış fikrin nasıl doğduğunu size açıklamalıyım ve size sistemin tam bir açıklamasını vereceğim ve.',
                content: 'Ama size zevki kınamak ve acıyı övmek gibi tüm bu yanlış fikrin nasıl doğduğunu açıklamalıyım ve size sistemin tam bir açıklamasını vereceğim ve gerçeğin büyük kaşifi, insanın usta-inşaatçısının gerçek öğretilerini açıklayacağım. mutluluk. Hiç kimse zevkin kendisini reddetmez, sevmez veya bundan kaçınmaz, çünkü bu zevktir, ama hoşlanmayanlar için.'
            },
            {
                title: 'Ama zevki kınamak ve acıyı övmek gibi tüm bu yanlış fikrin nasıl doğduğunu size açıklamalıyım ve size sistemin tam bir açıklamasını vereceğim ve.',
                content: 'Ama size zevki kınamak ve acıyı övmek gibi tüm bu yanlış fikrin nasıl doğduğunu açıklamalıyım ve size sistemin tam bir açıklamasını vereceğim ve gerçeğin büyük kaşifi, insanın usta-inşaatçısının gerçek öğretilerini açıklayacağım. mutluluk. Hiç kimse zevkin kendisini reddetmez, sevmez veya bundan kaçınmaz, çünkü bu zevktir, ama hoşlanmayanlar için.'
            },
            {
                title: 'Ama zevki kınamak ve acıyı övmek gibi tüm bu yanlış fikrin nasıl doğduğunu size açıklamalıyım ve size sistemin tam bir açıklamasını vereceğim ve.',
                content: 'Ama size zevki kınamak ve acıyı övmek gibi tüm bu yanlış fikrin nasıl doğduğunu açıklamalıyım ve size sistemin tam bir açıklamasını vereceğim ve gerçeğin büyük kaşifi, insanın usta-inşaatçısının gerçek öğretilerini açıklayacağım. mutluluk. Hiç kimse zevkin kendisini reddetmez, sevmez veya bundan kaçınmaz, çünkü bu zevktir, ama hoşlanmayanlar için.'
            },
        ],
        عربي: [
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
        ],
        بادینی: [
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
        ],
        سۆرانی: [
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
            {
                title: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة اللذة ومدح الألم وسأقدم لك وصفًا كاملاً للنظام ، و.',
                content: 'لكن يجب أن أشرح لك كيف ولدت كل هذه الفكرة الخاطئة المتمثلة في إدانة المتعة والثناء على الألم ، وسأقدم لك وصفًا' +
                    ' كاملاً للنظام ، وأشرح التعاليم الفعلية للمستكشف العظيم للحقيقة ، الباني البارع للإنسان سعادة. لا أحد يرفض أو يكره أو يتجنب اللذة نفسها ، لأنها متعة ، ولكن لمن لا يحبها.'
            },
        ]
    }

    return (
        <div className={`fixed bottom-14 right-16 cursor-pointer border border-primary ${openHelp ? "w-64" : "w-12"}`}>
            <div onClick={() => setOpenHelp(!openHelp)} className="flex space-x-4 p-3 bg-primary text-white">
                {
                    openHelp ? <XIcon className="h-6 w-6 text-white" /> : <QuestionMarkCircleIcon className="h-6 w-6 text-white" />
                }
                {
                    openHelp &&
                    <div className="px-2">{language.help}</div>
                }
            </div>
            <div className={`flex flex-col bg-white ${openHelp ? 'h-auto overflow-auto max-h-72' : 'h-0 overflow-hidden'}`}>
                <div className="p-2">
                    {
                        helpData[language.id].map(({ title, content }, index) => {
                            return (
                                <HelpItem title={title} content={content} index={index} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default HelpWindow;