import React from 'react';

function Location(props) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="17.428" height="22.1" viewBox="0 0 17.428 22.1">
                <g id="location-pointer" transform="translate(-37.259)">
                    <g id="Group_4" data-name="Group 4" transform="translate(37.259)">
                        <path id="Path_5" data-name="Path 5" d="M45.973,0a8.715,8.715,0,0,0-3.519,16.688L45.291,21.7a.784.784,0,0,0,1.364,0l2.837-5.014A8.715,8.715,0,0,0,45.973,0Zm2.675,15.343a.784.784,0,0,0-.389.341l-2.286,4.041-2.286-4.041a.784.784,0,0,0-.389-.341,7.147,7.147,0,1,1,5.349,0Z" transform="translate(-37.259)" fill="currentColor"/>
                        <path id="Path_6" data-name="Path 6" d="M127.05,85.759a3.291,3.291,0,1,0,3.291,3.291A3.295,3.295,0,0,0,127.05,85.759Zm0,5.015a1.724,1.724,0,1,1,1.724-1.724A1.726,1.726,0,0,1,127.05,90.774Z" transform="translate(-118.336 -80.383)" fill="currentColor"/>
                    </g>
                </g>
            </svg>

        </>
    );
}

export default Location;