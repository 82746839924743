import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
    name: 'languages',
    initialState: {
        languages: [],
        language: false,
    },
    reducers: {
        getLanguages(state, action) {
            state.languages = action.payload
        },
        getLanguage(state, action) {
            state.language = !state.language
        }
    }
})

export const languageActions = languageSlice.actions

export default languageSlice