import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";

function BookDialogBody(props) {
    return (
        <div>
            {
                props.items.map((item,index)=>{
                    return(
                        <div key={item.id} className="flex items-center space-x mt-3 mr-3">
                            <Checkbox onChange={(e)=> props.handleChanges(e,item)}
                                      id={item.name} color="#000" />
                            <label className="cursor-pointer" htmlFor={item.name} >{item.name}</label>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default BookDialogBody;