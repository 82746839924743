import React from "react";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import PaginationLeft from "../../assets/icons/PaginationLeft";
import PaginationRight from "../../assets/icons/PaginationRight";

const defaultFooterStyles = {};

function CustomFooter ({rowsPerPage,onChangePage,page,count,changePage,onChangeRowsPerPage,totalPages,total}) {

        return (
            <TableFooter>
                <TableRow>
                    <TableCell>
                        <div className="flex justify-between items-center bg-dark-primary border border-dark-primary px-2">
                            <div className="flex items-center justify-between p-1">
                                <div className="flex items-center">
                                    <label className="text-white">{count} of {rowsPerPage}</label>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-1 space-x-1">
                                <div onClick={e=> page !== 0 ? onChangePage(e, page-1) : ''}  className="flex items-center cursor-pointer">
                                    <PaginationLeft/>
                                    <div className="text-sm mx-1 text-bold text-white">Previous </div>
                                </div>
                                <div className="text-sm text-bold text-white border-opacity-30 p-2 border border-white rounded-md">{(page <= Math.ceil(count/rowsPerPage)) ? page+1 : 1}</div>
                                <div className="text-sm text-bold opacity-30 text-white">/ {Math.ceil(count/rowsPerPage)}</div>
                                <div onClick={e=> page+1 !== Math.ceil((count/rowsPerPage)) ? onChangePage(e, page+1) : ''} className="flex items-center cursor-pointer">
                                    <div className="text-sm mx-1 text-bold text-white">Next </div>
                                    <PaginationRight/>
                                </div>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
            </TableFooter>
        );
}

export default withStyles(defaultFooterStyles, { name: "CustomFooter" })(
    CustomFooter
);
