import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { tableTheme } from '../../components/customTable/TableTheme'
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import AddButton from "../../components/customTable/AddButton";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import DeleteButton from "../../components/customTable/DeleteButton";
import EditButton from "../../components/customTable/EditButton";
import NavigateButton from "../../components/customTable/NavigateButton";
import { StaticTableOptions } from "../../components/customTable/StaticTableOptions";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import DialogHeader from "../../components/dialogs/DialogHeader";
import { addRegionData, deleteRegionData, fetchRegionData, updateRegionData } from '../../store/region/regionActions';
import { useDispatch } from 'react-redux';
import { regionActions } from '../../store/region/regionSlice';
import TextInput from "../../components/textInput/TextInput";

function Regions({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()

    const language = useSelector(state => state.userInfos.language)
    const regions = useSelector(state => state.regions.regions)
    const isRegion = useSelector(state=>state.regions.region)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [regionId, setRegionId] = useState(false)

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [region, setRegion] = useState({})

    const cols = [
        {
            name: "name",
            label: language.region_name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <NavLink to={`/admin/regions/${regions[tableMeta.rowIndex].id}/cities`}>{value}</NavLink>
                    );
                }
            }
        },
        {
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    setRegionId(regions[tableMeta.rowIndex].id)
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setRegion(regions[tableMeta.rowIndex])
                                    dispatch(regionActions.getRegion(regions[tableMeta.rowIndex]))
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setRegionId(regions[tableMeta.rowIndex].id)
                                    dispatch(regionActions.getRegion(regions[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }
                                }
                            />
                            <NavigateButton navigate={"/admin/regions/cities"} />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    const saveRegion = () => {
        dispatch(addRegionData(region.name))
        setOpenAdd(false)
    }

    const deleteRegion = () => {
        dispatch(deleteRegionData(regionId))
        setOpenDelete(false)
    }

    const editRegion = () => {
        dispatch(updateRegionData(regionId, region.name))
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchRegionData())
    }, [dispatch])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.regions}
                        data={regions}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteRegion}
                title={language.delete_region}
                warning={language.region_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={setOpenAdd}
                cancelAddRef={cancelAddRef}
                saveItem={saveRegion}
                editItem={editRegion}
            >
                <DialogHeader
                    closeModal={() => setOpenAdd(false)}
                    title={openEdit ? language.edit_region : language.add_region}
                />
                <TextInput
                    label={language.region_name}
                    value={openEdit ? region.name : null}
                    onChange={(e) => setRegion({ ...region, name: e.target.value })}
                    placeholder={language.region_name}
                />
            </AddDialog>
        </div>
    );
}

export default Regions;