import { createSlice } from "@reduxjs/toolkit";

const directorateSlice = createSlice({
    name: 'directorates',
    initialState: {
        directorates: [],
        directorate: '',
        isDirectorate:false,
    },
    reducers: {
        getDirectorates(state, action) {
            state.directorates = action.payload
        },
        getIsDirectorate(state, action) {
            state.isDirectorate = !state.isDirectorate
        }
    }
})

export const directorateActions = directorateSlice.actions

export default directorateSlice