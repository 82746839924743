import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import {subjectActions} from "../../store/subject/subjectSlice";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {addSubjectData, deleteSubjectData, fetchSubjectData, updateSubjectData} from "../../store/subject/subjectActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import DialogHeader from "../../components/dialogs/DialogHeader";
import TextInput from "../../components/textInput/TextInput";

function Subjects({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const subjects = useSelector(state => state.subjects.subjects)
    const isSubject = useSelector(state=>state.subjects.subject)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [subjectId, setSubjectId] = useState(false)

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [subject, setSubject] = useState({})

    const cols = [
        {
            name: "name",
            label: language.subject_name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>{value}</div>
                    );
                }
            }
        },
        {
            // name: language.actions,
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    setSubjectId(subjects[tableMeta.rowIndex].id)
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setSubject(subjects[tableMeta.rowIndex])
                                    dispatch(subjectActions.getSubject(subjects[tableMeta.rowIndex]))
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setSubjectId(subjects[tableMeta.rowIndex].id)
                                    dispatch(subjectActions.getSubject(subjects[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    const saveSubject = () => {
        dispatch(subjectActions.getSubject(''))
        dispatch(addSubjectData(subject.name))
        setOpenAdd(false)
    }

    const deleteSubject = () => {
        dispatch(deleteSubjectData(subjectId))
        setOpenDelete(false)
    }

    const editSubject = () => {
        dispatch(updateSubjectData(subjectId, subject.name))
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchSubjectData())
    }, [dispatch,isSubject])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.subjects}
                        data={subjects}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteSubject}
                title={language.delete_subject}
                warning={language.subject_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={setOpenAdd}
                cancelAddRef={cancelAddRef}
                saveItem={saveSubject}
                editItem={editSubject}
            >
                <DialogHeader
                    closeModal={() => setOpenAdd(false)}
                    title={openEdit ? language.edit_subject : language.add_subject}
                />
                <TextInput
                    label={language.subject_name}
                    value={openEdit ? subject.name : null}
                    onChange={(e) => setSubject({ ...subject, name: e.target.value })}
                    placeholder={language.subject_name}
                />
            </AddDialog>
        </div>
    );
}

export default Subjects;