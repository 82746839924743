import React, { useEffect, useState } from 'react';
import Header from "../components/header/Header";
import AdminNavigation from "../components/navigation/AdminNavigation";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Regions from "../pages/admin/Regions";
import Corporations from "../pages/admin/Corporations";
import BookLevels from "../pages/admin/BookLevels";
import PublishingHouses from "../pages/admin/PublishingHouses";
import Library from "../pages/admin/Library";
import Statistics from "../pages/admin/Statistics";
import Students from "../pages/admin/Students";
import Readings from "../pages/admin/Readings";
import Employees from "../pages/admin/Employees";
import Cities from "../pages/admin/Cities";
import Ministries from "../pages/admin/Ministries";
import Directorates from "../pages/admin/Directorates";
import Authors from "../pages/admin/Authors";
import EducationLevels from "../pages/admin/EducationLevels";
import Categories from "../pages/admin/Categories";
import EducationTypes from "../pages/admin/EducationTypes";
import DragAndDrop from "../pages/admin/DragAndDrop";
import { ToastContainer } from "react-toastify";
import Books from "../pages/admin/Books";
import AddBook from "../pages/admin/AddBook";
import AddPages from "../pages/admin/AddPages";
import Breadcrumb from "../components/breadCrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import Classes from "../pages/admin/Classes";
import Avatars from "../pages/admin/Avatars";
import Backgrounds from "../pages/admin/Backgrounds";
import Subjects from "../pages/admin/Subjects";
import Languages from "../pages/admin/Languages";
import Faq from "../pages/admin/Faq";
import Listening from "../pages/admin/Listening";
import SelfClasses from "../pages/admin/SelfClasses";
import Parents from "../pages/admin/Parents";
import {fetchTableOptionsData} from "../store/tableOptionsActions";
import {loginActions} from "../store/login/loginSlice";
function Admin() {
    const dispatch = useDispatch()
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false)
    const language = useSelector(state => state.userInfos.language)
    const userInfos = useSelector(state => state.userInfos.userInfos)
    const isAuthorized = useSelector(state => state.userInfos.isAuthorized)

    const splitList = userInfos.departmentList.split(",")

    let isBreadcrumb
    if (!location.pathname.startsWith("/admin/regions/corporations") || location.pathname === "/admin/regions/corporations") {
        isBreadcrumb = true
    }
    else {
        isBreadcrumb = false
    }

    useEffect(() => {
        console.log(splitList)
        if((splitList.indexOf("1013") > -1 && splitList.length > 1)){
            dispatch(loginActions.getIsAuthorized(false))
        }
        dispatch(fetchTableOptionsData())
            setTimeout(() => {
                setIsLoading(true)
            }, 200)
    }, [isLoading])

    useEffect(() => {
        if (language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe") {
            document.body.style.direction = "ltr";
        }
        else if (language.id === 'عربي' || language.id === 'بادینی' || language.id === 'سۆرانی') {
            document.body.style.direction = "rtl";
        }
        else {
            document.body.style.direction = "ltr";
        }
    }, [language, dispatch])

    if (!isLoading) {
        return (
            <div>Loading...</div>
        )
    }

    else {
        return (
            <div className="min-h-screen bg-gray-100 pb-5">
                <Header />

                {isAuthorized ?
                    <div>
                        <AdminNavigation />
                        <Breadcrumb />
                        <Switch>
                            <Route exact path="/admin/regions" component={Regions} />
                            <Route exact path="/admin/regions/:id/cities" component={Cities} />
                            <Route exact path="/admin/regions/:id/cities/:id/ministries" component={Ministries} />
                            <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates" component={Directorates} />
                            <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations" component={Corporations} />

                            <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/statistics" component={Statistics} />
                            <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/employees" component={Employees} />
                            <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/classes" component={SelfClasses} />
                            <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/students" component={Students} />
                            <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/readings" component={Readings} />
                            <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/parents" component={Parents} />

                            <Route exact path="/admin/books" component={Books} />

                            <Route exact path="/admin/books/add-pages/:id" component={AddPages} />
                            <Route exact path="/admin/books/add-book" component={AddBook} />
                            <Route exact path="/admin/books/edit-book/:id" component={AddBook} />
                            <Route exact path="/admin/drag-drop" component={DragAndDrop} />
                            <Route exact path="/admin/library" component={Library} />
                            <Route exact path="/admin/library/publishing-houses" component={PublishingHouses} />
                            <Route exact path="/admin/library/authors" component={Authors} />
                            <Route exact path="/admin/library/subjects" component={Subjects} />
                            <Route exact path="/admin/library/education-levels" component={EducationLevels} />
                            <Route exact path="/admin/library/education-types" component={EducationTypes} />
                            <Route exact path="/admin/library/categories" component={Categories} />
                            <Route exact path="/admin/library/book-levels" component={BookLevels} />
                            <Route exact path="/admin/library/languages" component={Languages} />
                            <Route exact path="/admin/library/faq" component={Faq} />
                            <Route exact path="/admin/library/listening" component={Listening} />
                            <Route exact path="/admin/library/classes" component={Classes} />
                            <Route exact path="/admin/library/avatars" component={Avatars} />
                            <Route exact path="/admin/library/backgrounds" component={Backgrounds} />
                        </Switch>
                    </div> :
                    <Switch>
                        <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/statistics" component={Statistics} />
                        <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/employees" component={Employees} />
                        <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/classes" component={SelfClasses} />
                        <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/students" component={Students} />
                        <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/readings" component={Readings} />
                        <Route exact path="/admin/regions/:id/cities/:id/ministries/:id/directorates/:id/corporations/:id/parents" component={Parents} />
                        <Redirect from="*" to={`/admin/regions/${userInfos.regionId}/cities/${userInfos.cityId}/ministries/${userInfos.ministryId}/directorates/${userInfos.directorateId}/corporations/${userInfos.companyId}/statistics`}/>
                    </Switch>
                }
                <ToastContainer />
            </div>
        )
    }
}

export default Admin;