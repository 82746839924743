import { ApiUrl } from "../../components/utils/Urls";
import { toast } from "react-toastify";
import { imageActions } from "./imageSlice";
import {callToaster} from "../../components/utils/ToastText";

export const fetchAvatarsData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/avatar', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const avatarData = await fetchData()
            dispatch(imageActions.getAvatarImages(avatarData))
        } catch (error) { }
    }
}

export const fetchBackgroundsData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/background', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const backgroundData = await fetchData()
            dispatch(imageActions.getBackgroundImages(backgroundData))
        } catch (error) { }
    }
}

export const fetchAvatarData = id => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'student/view', {
                method: 'POST',
                body: 'id=1250',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const avatarData = await fetchData()
            dispatch(imageActions.getAvatarImage(avatarData))
        } catch (error) { }
    }
}

export const updateUserAvatarData = (avatar, id) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'user/avatar', {
                method: 'POST',
                body: 'avatar=' + avatar + '&id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            callToaster("avatar_updated")
            return data;
        }

        try {
            await fetchData()
            dispatch(imageActions.updateAvatarImage(avatar))
        } catch (error) { }
    }
}

export const updateStudentBackgroundData = (background, id) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'user/background', {
                method: 'POST',
                // body:'password=' + password +'&id=' + id,
                body: 'background=' + background + '&id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            callToaster("background_updated")
            return data;
        }

        try {
            await fetchData()
            dispatch(imageActions.updateBackgroundImage(background))
        } catch (error) { }
    }
}


//admin avatar && background actions

export const updateImageData = (id,image, type) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + `library/${type}Edit`, {
                method: 'POST',
                body: 'name=' + image.name + '&id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            if(type === "avatar"){
                callToaster("avatar_updated")
            }
            else{
                callToaster("background_updated")
            }
            return data;
        }

        try {
            await fetchData()
            dispatch(imageActions.getIsImage(2))
        } catch (error) { }
    }
}

export const deleteImageData = (id,type) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + `library/${type}Delete`, {
                method: 'POST',
                // body:'password=' + password +'&id=' + id,
                body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            if(type === "avatar"){
                callToaster("avatar_deleted")
            }
            else{
                callToaster("background_deleted")
            }
            return data;
        }

        try {
            await fetchData()
            dispatch(imageActions.getIsImage(2))
        } catch (error) { }
    }
}

export const addImageData = (image, type) => {
    console.log(image.name)
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + `library/${type}Add`, {
                method: 'POST',
                // body:'password=' + password +'&id=' + id,
                body: 'name=' + image.name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            if(type === "avatar"){
                callToaster("avatar_updated")
            }
            else{
                callToaster("background_updated")
            }
            return data;
        }

        try {
            await fetchData()
            dispatch(imageActions.getIsImage(2))
        } catch (error) { }
    }
}

export const addPagesData = (bookId,page,language,file,type) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'book/bookAddPage', {
                method: 'POST',
                // body:'password=' + password +'&id=' + id,
                body: 'bookId=' + bookId + '&page=' + page + '&language=' + language + '&file=' + file,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            callToaster("avatar_added")
            return data;
        }

        try {
            await fetchData()
            dispatch(imageActions.getIsImage(2))
        } catch (error) { }
    }
}