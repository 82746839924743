import React, { useEffect, useState } from 'react';
import Header from "../components/header/Header";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'
import { languageActions } from '../store/languageSlice';
import { identityActions } from '../store/identitySlice';

import MenuSettings from "../pages/admin/MenuSettings";
import AdminSettingsNavigation from "../components/navigation/AdminSettingsNavigation";
import DepartmentSettings from "../pages/admin/DepartmentSettings";
import Notifications from "../pages/admin/Notifications";
import AdminLanguage from "../components/language/AdminLanguage";
import {ToastContainer} from "react-toastify";
import {fetchTableOptionsData} from "../store/tableOptionsActions";
function Admin(props) {
    const language = useSelector(state => state.userInfos.language)
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        axios.get('https://mylesson-62cc8-default-rtdb.firebaseio.com/userSettings/-MmUJuChLkvh54r44lbm.json')
            .then(response => {
                dispatch(languageActions.changeLanguage(AdminLanguage[response.data.language]))
                dispatch(identityActions.changeIdentity(response.data.identity))
                dispatch(fetchTableOptionsData())
                console.log(response.data.identity + "gelen")
                setTimeout(() => {
                    setIsLoading(true)
                }, 200)
            })
            .catch(error => {
                console.log(error)
            });
    }, [isLoading])

    useEffect(() => {
        if (language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe") {
            document.body.style.direction = "ltr";
        }
        else if(language.id === 'عربي' || language.id === 'بادینی' || language.id === 'سۆرانی') {
            document.body.style.direction = "rtl";
        }
        else{
            document.body.style.direction = "ltr";
        }
    }, [language, dispatch])


    if (!isLoading) {
        return (
            <div>Loading...</div>
        )
    }
    return (
        <div className="h-screen bg-gray-100">
            <Header />
            <AdminSettingsNavigation />
            <Switch>
                <Route exact path="/admin-settings/manage-menus" component={MenuSettings} />
                <Route exact path="/admin-settings/manage-departments" component={DepartmentSettings} />
                <Route exact path="/admin-settings/notifications" component={Notifications} />

                {/*<Redirect from="/ayarlar" to="/ayarlar/menu-yonetimi"/>*/}
            </Switch>
            <ToastContainer />
        </div>
    )
}

export default Admin;