import TableFooter from "./TableFooter";

export const StaticTableOptions = {
    toolbar: false,
    download: false,
    print: false,
    filter: false,
    rowsPerPage: 10,
    customFooter: (
        count,
        page,
        rowsPerPage,
        changeRowsPerPage,
        changePage,
        onChangePage,
        onChangeRowsPerPage,
        totalPage
    ) => {
        return <TableFooter totalPage={totalPage} onChangePage={(e, page) => changePage(page)} onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)} page={page} rowsPerPage={rowsPerPage} changePage={changePage} count={count} />;
    },
};
