import React from 'react';

function HeadPhones  (props) {
    return (
        <div>
            <svg id="headphones" xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 15 15">
                <g id="Group_210" data-name="Group 210">
                    <path id="Path_39" data-name="Path 39" d="M7.5,0A7.508,7.508,0,0,0,0,7.5v5.893A1.607,1.607,0,0,0,1.607,15H3.75a.536.536,0,0,0,.536-.536V9.107a.536.536,0,0,0-.536-.536H1.607a1.59,1.59,0,0,0-.536.1V7.5a6.429,6.429,0,0,1,12.857,0V8.67a1.589,1.589,0,0,0-.536-.1H11.25a.536.536,0,0,0-.536.536v5.357A.536.536,0,0,0,11.25,15h2.143A1.607,1.607,0,0,0,15,13.393V7.5A7.508,7.508,0,0,0,7.5,0ZM1.607,9.643H3.214v4.286H1.607a.536.536,0,0,1-.536-.536V10.179A.536.536,0,0,1,1.607,9.643Zm12.321,3.75a.536.536,0,0,1-.536.536H11.786V9.643h1.607a.536.536,0,0,1,.536.536Z" fill={props.fill}/>
                </g>
            </svg>
        </div>
    );
}

export default HeadPhones;