import { createSlice } from "@reduxjs/toolkit";

const selfClassSlice = createSlice({
    name: 'selfClasses',
    initialState: {
        selfClasses: [],
        selfClass: '',
        isSelfClass:false,
    },
    reducers: {
        getSelfClasses(state, action) {
            state.selfClasses = action.payload
        },
        getSelfClass(state, action) {
            state.selfClass = action.payload
        },
        getIsSelfClass(state,action){
            state.isSelfClass = !state.isSelfClass
        }
    }
})

export const selfClassActions = selfClassSlice.actions

export default selfClassSlice