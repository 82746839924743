import { configureStore } from '@reduxjs/toolkit'
import identitySlice from "./identitySlice";
import imageSlice from "./image/imageSlice";
import languageSlice from "./languageSlice";
import bookLanguagesSlice from "./language/languageSlice";
import bookSlice from "./book/bookSlice";
import tableOptionsSlice from "./tableOptionsSlice";
import regionSlice from "./region/regionSlice";
import citySlice from "./city/citySlice";
import ministrySlice from "./ministry/ministrySlice";
import directorateSlice from "./directorate/directorateSlice";
import corporationSlice from "./corporation/corporationSlice";
import assignEmployeeSlice from "./assignEmployee/assignEmployeeSlice";
import classSlice from "./class/classSlice";
import bookLevelSlice from "./bookLevel/bookLevelSlice";
import authorSlice from "./author/authorSlice";
import publishingHouseSlice from "./publishingHouse/publishingHouseSlice";
import categorySlice from "./category/categorySlice";
import subjectSlice from "./subject/subjectSlice";
import educationLevelSlice from "./educationLevel/educationLevelSlice";
import educationTypeSlice from "./educationType/educationTypeSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import teacherStudentSlice from "./teacherStudent/teacherStudentSlice";
import profileSlice from "./profile/profileSlice";
import readBookSlice from "./readBook/readBookSlice";
import parentStudentSlice from "./parentStudent/parentStudentSlice";
import faqSlice from "./faq/faqSlice";
import soundSlice from "./listening/soundSlice";
import employeeSlice from "./employee/employeeSlice";
import selfClassSlice from "./selfClass/selfClassSlice";
import studentSlice from "./student/studentSlice";
import parentSlice from "./parent/parentSlice";
import loginSlice from "./login/loginSlice";
import departmentSlice from "./department/departmentSlice";

const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
    reducer: {
        identity: identitySlice.reducer,
        image: imageSlice.reducer,
        language: languageSlice.reducer,
        books: bookSlice.reducer,
        tableOptions: tableOptionsSlice.reducer,
        regions: regionSlice.reducer,
        cities:citySlice.reducer,
        ministries: ministrySlice.reducer,
        directorates: directorateSlice.reducer,
        corporations: corporationSlice.reducer,
        employees: employeeSlice.reducer,
        departments: departmentSlice.reducer,
        students: studentSlice.reducer,
        parents: parentSlice.reducer,
        selfClasses: selfClassSlice.reducer,
        assignEmployees : assignEmployeeSlice.reducer,
        classes: classSlice.reducer,
        bookLevels: bookLevelSlice.reducer,
        authors: authorSlice.reducer,
        sounds: soundSlice.reducer,
        faqs: faqSlice.reducer,
        bookLanguages: bookLanguagesSlice.reducer,
        publishingHouses: publishingHouseSlice.reducer,
        categories: categorySlice.reducer,
        subjects : subjectSlice.reducer,
        educationLevels: educationLevelSlice.reducer,
        educationTypes: educationTypeSlice.reducer,
        dashboard: dashboardSlice.reducer,
        teacherStudents: teacherStudentSlice.reducer,
        profile:profileSlice.reducer,
        readBook: readBookSlice.reducer,
        parentStudents: parentStudentSlice.reducer,
        userInfos: loginSlice.reducer,
    }
})

export default store