import React, {Component, Fragment, useRef, useState} from 'react';
import Search from "../../assets/icons/Search";
import Filter from "../../assets/icons/Filter";
import CirclePlus from "../../assets/icons/CirclePlus";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDownward from "../../assets/icons/ArrowDownward";
import ChangeOrder from "../../assets/icons/ChangeOrder";
import Book from "../../assets/icons/Book";
import Actions from "../../assets/icons/Actions";
import Location from "../../assets/icons/Location";
import Student from "../../assets/icons/Student";
import Person from "../../assets/icons/Person";
import PaginationLeft from "../../assets/icons/PaginationLeft";
import PaginationRight from "../../assets/icons/PaginationRight";
import {Dialog, Transition} from "@headlessui/react";
import CrossCircle from "../../assets/icons/CrossCircle";
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import {Checkbox} from "@mui/material";

function DepartmentSettings () {
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg">
                <div className="flex justify-between mt-5">
                    <h1>Manage Departments</h1>
                    <div className="flex space-x-3">
                        <button className="rounded-md py-2 px-4 flex items-center bg-white h-10">
                            <Search/>
                            <span className="ml-2">Search</span>
                        </button>
                        <button onClick={()=>setOpen(true)} className="rounded-md py-2 px-4 flex items-center bg-primary text-white h-10">
                            <CirclePlus fillColor={"#fff"}/>
                            <span className="ml-2">Add</span>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col space-y-2 mt-3">
                    <div className="flex items-center">
                        <div className="w-2/12 flex items-center justify-between border border-gray-400 p-2">
                            <Checkbox className="h-6"/>
                            <div className="flex items-center">
                                <label htmlFor="checkbox">Icon</label>
                            </div>
                            <div className="flex items-center space-x-1">
                                <MenuIcon className="p-0.5"/>
                                <ArrowDownward className="h-6"/>
                            </div>
                        </div>
                        <div className="w-2/12 flex items-center justify-between border border-l-0 border-gray-400 p-2">
                            <div className="flex items-center">
                                <div>Menu Name</div>
                            </div>
                            <div className="flex items-center space-x-1">
                                <MenuIcon className="p-0.5"/>
                                <ArrowDownward className="h-6"/>
                            </div>
                        </div>
                        <div className="w-2/12 flex items-center justify-between border border-l-0 border-gray-400 p-2">
                            <div className="flex items-center">
                                <div>Type</div>
                            </div>
                            <div className="flex items-center space-x-1">
                                <MenuIcon className="p-0.5"/>
                                <ArrowDownward className="h-6"/>
                            </div>
                        </div>
                        <div className="w-2/12 flex items-center justify-between border border-l-0 border-gray-400 p-2">
                            <div className="flex items-center">
                                <div>Access</div>
                            </div>
                            <div className="flex items-center space-x-1">
                                <MenuIcon className="p-0.5"/>
                                <ArrowDownward className="h-6"/>
                            </div>
                        </div>
                        <div className="w-2/12 flex items-center justify-between border border-l-0 border-gray-400 p-2">
                            <div className="flex items-center">
                                <div>Extra</div>
                            </div>
                            <div className="flex items-center space-x-1">
                                <MenuIcon className="p-0.5"/>
                                <ArrowDownward className="h-6"/>
                            </div>
                        </div>
                        <div className="w-2/12 flex items-center justify-between border border-l-0 border-gray-400 p-2">
                            <div className="flex items-center">
                                <div>Dashboard</div>
                            </div>
                            <div className="flex items-center space-x-1">
                                <MenuIcon className="p-0.5"/>
                                <ArrowDownward/>
                            </div>
                        </div>
                        <div className="w-1/12 flex items-center justify-between border border-l-0 border-gray-400 py-5">

                        </div>
                    </div>
                    <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                        <div className="w-2/12 flex items-center justify-between px-1 py-2">
                            <div className="flex items-center">
                                <Checkbox className="h-6"/>
                                <label htmlFor="checkbox">202134</label>
                            </div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Admin</div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">System</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-primary text-white px-2 py-1 rounded-md">21 Page</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">Unauthorized</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">0 Tab</div>
                        </div>
                        <div className="w-1/12 flex justify-end p-1">
                            <div className="p-2 mr-2"><Actions/></div>
                        </div>
                    </div>
                    <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                        <div className="w-2/12 flex items-center justify-between px-1 py-2">
                            <div className="flex items-center">
                                <Checkbox className="h-6"/>
                                <label htmlFor="checkbox">202134</label>
                            </div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Admin</div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">System</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-primary text-white px-2 py-1 rounded-md">21 Page</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">Unauthorized</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">0 Tab</div>
                        </div>
                        <div className="w-1/12 flex justify-end p-1">
                            <div className="p-2 mr-2"><Actions/></div>
                        </div>
                    </div>
                    <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                        <div className="w-2/12 flex items-center justify-between px-1 py-2">
                            <div className="flex items-center">
                                <Checkbox className="h-6"/>
                                <label htmlFor="checkbox">202134</label>
                            </div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Admin</div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Sistem</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-primary text-white px-2 py-1 rounded-md">21 Sayfa</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">Unauthorized</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">0 Tab</div>
                        </div>
                        <div className="w-1/12 flex justify-end p-1">
                            <div className="p-2 mr-2"><Actions/></div>
                        </div>
                    </div>
                    <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                        <div className="w-2/12 flex items-center justify-between px-1 py-2">
                            <div className="flex items-center">
                                <Checkbox className="h-6"/>
                                <label htmlFor="checkbox">202134</label>
                            </div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Admin</div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Sistem</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-primary text-white px-2 py-1 rounded-md">21 Sayfa</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">Unauthorized</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">0 Tab</div>
                        </div>
                        <div className="w-1/12 flex justify-end p-1">
                            <div className="p-2 mr-2"><Actions/></div>
                        </div>
                    </div>
                    <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                        <div className="w-2/12 flex items-center justify-between px-1 py-2">
                            <div className="flex items-center">
                                <Checkbox className="h-6"/>
                                <label htmlFor="checkbox">202134</label>
                            </div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Admin</div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Sistem</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-primary text-white px-2 py-1 rounded-md">21 Sayfa</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">Unauthorized</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">0 Tab</div>
                        </div>
                        <div className="w-1/12 flex justify-end p-1">
                            <div className="p-2 mr-2"><Actions/></div>
                        </div>
                    </div>
                    <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                        <div className="w-2/12 flex items-center justify-between px-1 py-2">
                            <div className="flex items-center">
                                <Checkbox className="h-6"/>
                                <label htmlFor="checkbox">202134</label>
                            </div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Admin</div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Sistem</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-primary text-white px-2 py-1 rounded-md">21 Sayfa</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">Unauthorized</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">0 Tab</div>
                        </div>
                        <div className="w-1/12 flex justify-end p-1">
                            <div className="p-2 mr-2"><Actions/></div>
                        </div>
                    </div>
                    <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                        <div className="w-2/12 flex items-center justify-between px-1 py-2">
                            <div className="flex items-center">
                                <Checkbox className="h-6"/>
                                <label htmlFor="checkbox">202134</label>
                            </div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Admin</div>
                        </div>
                        <div className="w-2/12 flex p-1">
                            <div className="text-bold">Sistem</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-primary text-white px-2 py-1 rounded-md">21 Sayfa</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">Unauthorized</div>
                        </div>
                        <div className="w-2/12 flex items-center p-1">
                            <div className="bg-custom-gray text-white px-2 py-1 rounded-md">0 Tab</div>
                        </div>
                        <div className="w-1/12 flex justify-end p-1">
                            <div className="p-2 mr-2"><Actions/></div>
                        </div>
                    </div>

                    <div className="flex justify-between items-center bg-dark-primary border border-dark-primary px-2">
                        <div className="flex items-center justify-between p-1">
                            <div className="flex items-center">
                                <label className="text-white">5000 veriden 100 verisini görüyorsunuz</label>
                            </div>
                        </div>
                        <div className="flex items-center justify-between p-1 space-x-1">
                            <PaginationLeft/>
                            <div className="text-sm text-bold text-white">Önceki</div>
                            <div className="text-sm text-bold text-white border-opacity-30 p-2 border border-white rounded-md">10</div>
                            <div className="text-sm text-bold opacity-30 text-white">/ 70</div>
                            <PaginationRight/>
                        </div>
                    </div>
                </div>
            </div>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                              &#8203;
                            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex flex-col">
                                        <div className="flex justify-between items-center pb-2 border-b border-gray-200">
                                            <div className="flex space-x-2 items-center">
                                                <CirclePlus fillColor={"#0E9E57"}/>
                                                <h3>Departman Ekle</h3>
                                            </div>
                                            <CrossCircle closeModal={()=>setOpen(false)} />
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <div className="flex space-x-1 mb-1">
                                                <span>Departman Adı</span>
                                                <span className="text-red-400">*</span>
                                                <GrayQuestionMark/>
                                            </div>
                                            <input placeholder="Departman Adı" className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text"/>
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <div className="flex space-x-1 mb-1">
                                                <span>Açıklama</span>
                                                <span className="text-red-400">*</span>
                                                <GrayQuestionMark/>
                                            </div>
                                            <textarea placeholder="Açıklama" className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2 resize-none h-20">
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 mb-2 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Devam Et
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-primary text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setOpen(false)}
                                    >
                                        Vazgeç
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}

export default DepartmentSettings;