import { ApiUrl } from "../../components/utils/Urls";
import { studentActions } from "./studentSlice";
import {callToaster} from "../../components/utils/ToastText";

export const fetchStudentData = (id) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/companyStudent', {
                method: 'POST',
                body:'companyId=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const studentData = await fetchData()
            const students = studentData.list
            dispatch(studentActions.getStudents(students))
        } catch (error) { }
    }
}

export const fetchStudentClassData = (id) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'student/view', {
                method: 'POST',
                body:'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const studentData = await fetchData()
            const classroomId = studentData.student[0].schoolroomId
            const gradeId = studentData.student[0].gradeId
            dispatch(studentActions.getStudentClass({id:classroomId,gradeId:gradeId}))
        } catch (error) { }
    }
}

export const updateStudentData = (student,currentClass) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'system/companyStudentEdit', {
                method: 'POST', body: 'id=' + student.id + '&firstName=' + student.firstName + '&middleName=' + student.middleName + '&lastName=' + student.lastName + '&companyNumber=' + student.mail + '&gender=' + student.gender + '&gradeId=' + currentClass.gradeId + '&schoolroomId=' + currentClass.id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(studentActions.getIsStudent(2))
            callToaster("student_updated")
        } catch (error) { }
    }
}

export const deleteStudentData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'system/companyStudentDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(studentActions.getIsStudent(2))
            callToaster("student_deleted")
        } catch (error) { }
    }
}

export const addStudentData = (student,currentClass,corporationId) => {
    console.log(student,currentClass,corporationId)
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/companyStudentAdd', {
                method: 'POST', body: 'firstName=' + student.firstName + '&middleName=' + student.middleName + '&lastName=' + student.lastName + '&companyNumber=' + student.mail + '&gender=' + student.gender + '&companyId=' + corporationId + '&gradeId=' + currentClass.gradeId + '&schoolroomId=' + currentClass.id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(studentActions.getIsStudent(2))
            callToaster("student_added")
        } catch (error) { }
    }
}