import React from 'react';
import SubHeader from "../../components/header/SubHeader";

function Statistics (){

        return (
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg">
                <SubHeader/>
            </div>
        );
}

export default Statistics;