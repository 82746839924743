import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
    name: 'categories',
    initialState: {
        categories: [],
        category: false
    },
    reducers: {
        getCategories(state, action) {
            state.categories = action.payload
        },
        getCategory(state, action) {
            state.category = !state.category
        }
    }
})

export const categoryActions = categorySlice.actions

export default categorySlice