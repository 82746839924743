import React from 'react';
import ArrowRight from "../../assets/icons/ArrowRight";
import {NavLink, useLocation} from "react-router-dom";

function Breadcrumb () {
    const location = useLocation();
    const { pathname } = location;
    const breadcrumbItem = pathname.split("/").slice(1)
    console.log(breadcrumbItem)
    let currentUrl = ""
    let currentYurl = ""
    return (
        <div className="container px-3 mx-auto max-w-screen-lg">
            <div className="flex bg-white rounded-md mt-5 p-5 space-x-2">
                {
                    breadcrumbItem.map((item,index)=>{
                        currentUrl = currentUrl + (isNaN(Number(item)) ? item : "") + " "
                        currentYurl = currentYurl + (index > 0 ? "/" : "") +  item + ""
                        return(
                            <NavLink to={(index%2 === 1) && "/" + currentYurl} className="flex items-center space-x-1">
                                <div className={`capitalize ${breadcrumbItem.length-1 === index ? "text-primary" : ""}`}>{isNaN(Number(item)) && (index %2 === 1) ? item : ""}</div>
                                {index % 2 === 1 && <ArrowRight fill={breadcrumbItem.length-1 === index ? "#0a9852" : "#919e9f"}/>}
                            </NavLink>
                        )
                    })
                }
                {/*<div>Süleymaniye</div>*/}
                {/*<div className="flex items-center space-x-1">*/}
                {/*    <ArrowRight fill={"#0a9852"}/>*/}
                {/*    <div className="text-primary">Süleymaniye</div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Breadcrumb;