import { createSlice } from "@reduxjs/toolkit";

const assignEmployeeSlice = createSlice({
    name: 'assignEmployees',
    initialState: {
        employeeTasks: [],
        employeeTask: ''
    },
    reducers: {
        getEmployeeTasks(state, action) {
            state.employeeTasks = action.payload
        },
        getEmployeeTask(state, action) {
            state.assignEmployee = action.payload
        }
    }
})

export const assignEmployeeActions = assignEmployeeSlice.actions

export default assignEmployeeSlice