import React, { useEffect } from 'react';
import Book from "../assets/icons/Book";
import HeadPhones from "../assets/icons/HeadPhones";
import Time from "../assets/icons/Time";
import Question from "../assets/icons/Question";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import Dinosaur from "../assets/imgs/dinosor.png";
import avatarBig from "../assets/imgs/avatar-big.png";
import {
    fetchDashboardData,
    fetchDashboardStudentsData,
    fetchStudentGraphicData
} from "../store/dashboard/dashboardActions";
import { FileUrl } from "../components/utils/Urls";
import { dashboardActions } from "../store/dashboard/dashboardSlice";
import { fetchParentStudentData } from "../store/parentStudent/parentStudentActions";

function Dashboard(props) {
    const dispatch = useDispatch()
    const {statistic,students,studentId,studentListenGraphic,studentReadGraphic} = useSelector(state => state.dashboard)
    // const {parentStudents,studentIdFromParent,firstStudentId} = useSelector(state => state.parentStudents)
    const parentStudents = useSelector(state => state.parentStudents.students)
    const {language,identity,userInfos} = useSelector(state => state.userInfos)

    const data = {
        labels: [language.january, language.february, language.march, language.april, language.may, language.june, language.july,language.august,language.september,language.october,language.november,language.december],
        datasets: [
            {
                label: `${language.reads_for_months}`,
                // data: [12, 19, 3, 5, 2, 3, 7],
                data:studentReadGraphic,
                backgroundColor: [
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataTwo = {
        labels: [language.january, language.february, language.march, language.april, language.may, language.june, language.july,language.august,language.september,language.october,language.november,language.december],
        datasets: [
            {
                label: `${language.listens_for_months}`,
                // data: [12, 19, 3, 5, 2, 3, 7],
                data:studentListenGraphic,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                    gridLines: {
                        display: false,
                        borderColor: 'white',
                        borderWidth: 0,
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                        borderColor: 'white',
                        borderWidth: 0,
                    },

                }
            ]
        },
    };

    const getDatas = (item) =>{
        dispatch(dashboardActions.resetStatistic({
            book: 0, listen: 0, listenTime: 0, page: 0
        }))
        dispatch(dashboardActions.getStudent(item))
        dispatch(fetchDashboardData(item))
        dispatch(fetchStudentGraphicData(item))
    }

    useEffect(() => {
        if (identity === 'parent') {
            dispatch(fetchParentStudentData(userInfos.id))
        }
        else if (identity === 'student') {
            dispatch(fetchDashboardData(userInfos.id))
            dispatch(fetchStudentGraphicData(userInfos.id))
        }
        else {
            dispatch(fetchDashboardStudentsData(userInfos.id))
        }
    }, [dispatch])

    return (
        <div className="container px-3 mx-auto max-w-screen-lg mt-5">
            <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4 grid-cols">
                <div className="h-28 bg-dark-primary flex items-center justify-center p-5">
                    <div className="opacity-30"><Book fill={"#fff"} /></div>
                    <div className="flex flex-col mx-4 items-center text-white">
                        <div>{language.dashboard_statistics_one}</div>
                        <div className="font-bold text-xl">{statistic && statistic.book}</div>
                    </div>
                </div>
                <div className="h-28 bg-soft-blue flex items-center justify-center p-5">
                    <div className="opacity-70" ><HeadPhones width={24} height={24} fill={"#fff"} /></div>
                    <div className="flex flex-col mx-4 items-center text-white">
                        <div>{language.dashboard_statistics_two}</div>
                        <div className="font-bold text-xl">{statistic && statistic.listen} Hours</div>
                    </div>
                </div>
                <div className="h-28 bg-yellow-400 flex items-center justify-center p-5">
                    <div className="opacity-50" ><Time fill={"#000"} /></div>
                    <div className="flex flex-col mx-4 items-center text-white">
                        <div>{language.dashboard_statistics_three}</div>
                        <div className="font-bold text-xl">{statistic && statistic.listenTime} {language.minutes}</div>
                    </div>
                </div>
                <div className="h-28 bg-primary flex items-center justify-center p-5">
                    <div className="opacity-30" ><Question width={24} height={24} fill={"#000"} /></div>
                    <div className="flex flex-col mx-4 items-center text-white">
                        <div>{language.dashboard_statistics_four}</div>
                        <div className="font-bold text-xl">55</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col-reverse md:flex-row gap-4 mt-5 mb-5">
                <div className={`flex ${identity === 'student' ? 'flex-col w-full' : 'lg:flex-row flex-col w-full md:w-9/12'}`}>
                    <div className={identity === 'student' ? "flex flex-col sm:flex-row gap-4 mb-5" : "w-full lg:w-1/2"}>
                        <div className={`bg-white p-5 flex items-center h-96 ${identity === 'student' ? 'w-full sm:w-8/12' : 'w-full'}`}>
                            <Bar type="bar" data={data} options={options} />
                        </div>
                        {
                            identity === 'student' &&
                            <div className="bg-white h-96 w-full sm:w-4/12 flex justify-center items-end">
                                <img className="mb-5 w-7/12 h-full object-contain" src={Dinosaur} alt="" />
                            </div>
                        }
                    </div>
                    <div className={identity === 'student' ? "flex flex-col sm:flex-row gap-4" : "w-full lg:w-1/2"}>
                        <div className={`bg-white p-5 flex items-center h-96 ${identity === 'student' ? 'w-full sm:w-8/12' : ''}`}>
                            <Bar type="bar" data={dataTwo} options={options} />
                        </div>
                        {
                            identity === 'student' &&
                            <div className="bg-white h-96 w-full sm:w-4/12 flex justify-center items-end">
                                <img className="mb-5 w-7/12 h-full object-contain" src={avatarBig} alt="" />
                            </div>
                        }
                    </div>
                </div>
                {
                    identity !== 'student' &&
                    <div className="flex flex-col w-full md:w-3/12 bg-white rounded-md space-y-4 max-h-96 overflow-y-auto p-5">

                        <div className="text-dark-primary text-md font-bold text-center">{language.childs}</div>
                        {
                            identity === 'teacher' ?
                                students.map((item) => {
                                    return (
                                        <div key={item.id} onClick={()=>getDatas(item.id)} className={`flex flex-col cursor-pointer items-center border p-1 rounded-md ${item.id === studentId ? "border-dark-primary" : "border-gray-300"}`}>
                                            {
                                                item.avatar &&
                                                <img className="px-1 mt-2 h-8 w-8" src={FileUrl + item.avatar} alt="" />
                                            }
                                            <h3 className={`text-xs text-center ${item.avatar ? 'mt-3' : ''} `}>{item.firstName}</h3>
                                        </div>
                                    )
                                }) :
                                parentStudents.map((item) => {
                                    return (
                                        <div key={item.id} onClick={()=>getDatas(item.id)} className={`flex flex-col cursor-pointer items-center border p-1 rounded-md ${item.id === studentId ? "border-dark-primary" : "border-gray-300"}`}>
                                            {
                                                item.avatar &&
                                                <img className="px-1 mt-2 h-8 w-8" src={FileUrl + item.avatar} alt="" />
                                            }
                                            <h3 className={`text-xs text-center ${item.avatar ? 'mt-3' : ''} `}>{item.firstName}</h3>
                                        </div>
                                    )
                                })
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default Dashboard;