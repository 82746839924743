import { createSlice } from "@reduxjs/toolkit";

const teacherStudentSlice = createSlice({
    name: 'teacherStudents',
    initialState: {
        statistic: [],
        students: [],
        student: '',
        classes: [],
        class: '',
        year: '',
        month: '',
        week: '',
        levels: [],
        levelStatistic: {},
        bookList: [],
        levelYear: '',
        levelMonth: '',
        levelWeek: '',
        completed: '',
        total:'',
        totalReaded:'',
        totalListened:'',
    },
    reducers: {
        getStudents(state, action) {
            state.students = action.payload
        },
        getStudent(state, action) {
            state.student = action.payload
        },
        // getStatistic(state, action) {
        //     state.year = 0
        //     state.month = 0
        //     state.week = 0
        //     state.year = action.payload.year[0]
        //     state.month = action.payload.month[0]
        //     state.week = action.payload.week[0]
        // },
        getClasses(state, action) {
            state.classes = action.payload
        },
        getClass(state, action) {
            state.class = action.payload
        },
        getLevels(state, action) {
            state.levels = action.payload.levels
            state.year = action.payload.statistic[0].year[0]
            state.month = action.payload.statistic[0].month[0]
            state.week = action.payload.statistic[0].week[0]
        },
        getLevelStatistic(state, action) {
            state.total = action.payload.book
            state.completed = action.payload.complete
            state.bookList = action.payload.bookList
            state.levelStatistic = action.payload.statistic[0]
            action.payload.bookList.map((item)=>{
                if(item.student.read === 1){
                    state.totalReaded = state.totalReaded + 1
                }
                if(item.student.listen === 1){
                    state.totalListened = state.totalListened + 1
                }
            })
        },
        // getLevelBooks(state, action) {
        //     state.levelYear = action.payload.istatistic[0].year[0]
        //     state.levelMonth = action.payload.istatistic[0].month[0]
        //     state.levelWeek = action.payload.istatistic[0].week[0]
        //     console.log("geldik")
        // }
    }
})

export const teacherStudentActions = teacherStudentSlice.actions

export default teacherStudentSlice