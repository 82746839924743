import React, { Fragment, useEffect, useRef, useState } from 'react';
import ArrowLeft from "../assets/icons/ArrowLeft";
import { NavLink, useLocation } from "react-router-dom";
import Microphone from "../assets/icons/Microphone";
import HeadPhones from "../assets/icons/HeadPhones";
import PenBox from "../assets/icons/PenBox";
import Edit from "../assets/icons/Edit";
import bookOne from "../assets/imgs/book-one.png"
import bookInside from "../assets/imgs/book-inside.png"
import pageTwo from "../assets/imgs/page-two.jpeg"
import pageThree from "../assets/imgs/page-three.jpeg"
import ArrowRight from "../assets/icons/ArrowRight";

import HTMLFlipBook from "react-pageflip";
import { Pause, PlayArrow, Refresh, Star, Stop } from "@material-ui/icons";
import PaintBrush from "../assets/icons/PaintBrush";
import Stamp from "../assets/icons/Stamp";
import { PencilIcon } from "@heroicons/react/solid";
import ArrowRightSolid from "../assets/icons/ArrowRightSolid";
import Check from "../assets/icons/Check";
import Question from "../assets/icons/Question";
import Smiley from "../assets/icons/Smiley";
import { Dialog, Transition } from "@headlessui/react";
import CirclePlus from "../assets/icons/CirclePlus";
import CrossCircle from "../assets/icons/CrossCircle";
import GrayQuestionMark from "../assets/icons/GrayQuestionMark";
import { useSelector, useDispatch } from "react-redux";
import useSound from 'use-sound';
import SoundPlayer from '../components/soundPlayer/SoundPlayer';
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import "audio-react-recorder/dist/index.css";
import { bookReadedData, fetchBookIdData, fetchBookPagesData, updatePageData } from '../store/readBook/readBookActions';
import { FileUrl } from '../components/utils/Urls';
import ReactAudioPlayer from "react-audio-player";
import { ReactPainter } from "react-painter";


const Page = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>{props.children}</div>
    );
});

function ReadBook(props) {
    const dispatch = useDispatch()
    const book = useRef();

    const {identity,userInfos} = useSelector(state => state.userInfos)
    const {isChanged,responseId,pages,book:currentBook} = useSelector(state => state.readBook)

    const [recordIcon, setRecordIcon] = useState(false)
    const [playIcon, setPlayIcon] = useState(false)
    const [isRecordHidden, setIsRecordHidden] = useState(true)
    const [isListenHidden, setIsListenHidden] = useState(true)
    const [isPaintHidden, setIsPaintHidden] = useState(true)
    const [isPenHidden, setIsPenHidden] = useState(true)
    const [isBrushHidden, setIsBrushHidden] = useState(true)
    const [isStampHidden, setIsStampHidden] = useState(true)
    const [myNotes, setMyNotes] = useState([])
    const [myNote, setMyNote] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(pages.length)
    const [currentColor, setCurrentColor] = useState("#000000")

    const [showNoteModal, setShowNoteModal] = useState(false)

    const cancelNoteRef = useRef(null)
    const colorRef = useRef(null)
    const triggerRef = useRef(null)

    const force_value_input = useRef(null);

    const location = useLocation()
    const { pathname } = location
    const url = pathname.split("/")
    const bookId = url[2]


    function onFlip(e) {
        console.log('Current page: ' + e.data);
        //setPage(book.current.pageFlip().getPageCount())
        console.log(book.current.pageFlip().getPageCount())
        setCurrentPage(e.data)
        //const getTotalPage = book.current.pageFlip().getPageCount()

        //setTotalPage(totalPage=>getTotalPage)
        if (e.data === pages.length || (e.data + 1) === pages.length) {
            dispatch(bookReadedData(responseId, identity))
        }
        dispatch(updatePageData(responseId, e.data, identity))
    }

    // useEffect(()=>{
    //     setTotalPage(book.current.pageFlip().getPageCount())
    // })

    function saveNote() {
        setMyNotes([...myNotes, myNote])
        setShowNoteModal(false)
    }

    // const language = useSelector(state => state.languageReducer)
    const language = useSelector(state => state.userInfos.language)

    useEffect(() => {
        setTimeout(() => {
            if (isChanged) {
                setTotalPage(book.current.pageFlip().getPageCount())
            }
        }, 1000)
    }, [dispatch])

    const changeColor = (color) => {
        setIsPaintHidden(!isPaintHidden)
        {
            pages.map((e, index) => {
                const input = document.querySelector("#input" + index);
                console.log(currentColor)
                Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
                    .set.call(input, color);
                input.dispatchEvent(new Event('change', { bubbles: true }));
            })

        }
    }

    useEffect(() => {
        if (language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe") {
            document.body.style.direction = "ltr";
        }
        else {
            document.body.style.direction = "rtl";
        }
    }, [language, dispatch])

    useEffect(() => {
        dispatch(fetchBookPagesData(bookId))
        dispatch(fetchBookIdData(userInfos.id, bookId, identity, currentBook.language))
    }, [dispatch])

    return (
        <div className="min-h-screen h-full relative flex flex-col bg-gray-100 bg-user-image">
            <div className="flex flex-col min-h-screen flex-1 justify-between z-50">
                <div className="text-white">
                </div>
                <div className="flex items-center relative justify-center sm:mx-16 gap-12 select-none">
                    <div onClick={() => book.current.pageFlip().flipPrev()} className="flex book-arrows absolute -bottom-10 sm:bottom-10 left-20 sm:static items-center cursor-pointer">
                        <ArrowLeft fill={"#fff"} />
                        <div className="text-white text-sm mx-2">{language.previous}</div>
                    </div>
                    <HTMLFlipBook
                        width={400}
                        height={500}
                        ref={book}
                        onFlip={onFlip}
                        // changeState="use_fold"
                        showCover
                        useMouseEvents={false}
                        startZIndex={50}
                    >
                        {
                            pages.map((item, index) => {
                                return (
                                    <Page number={index + 1}>
                                        <ReactPainter
                                            width={400}
                                            height={500}
                                            // image={}
                                            onSave={blob => console.log(blob)}
                                            render={({ triggerSave, canvas, setColor }) => (
                                                <div className="awesomeContainer h-full w-full">
                                                    <input id={"input" + index} ref={colorRef} className="input hidden" type="color" onChange={e => {
                                                        setColor(e.target.value)
                                                    }} />
                                                    <img className="w-full h-full demoPage" src={FileUrl + item.file} alt="" />
                                                    {canvas}
                                                </div>
                                            )}
                                        />

                                    </Page>
                                )
                            })
                        }
                    </HTMLFlipBook>
                    <div onClick={() => book.current.pageFlip().flipNext()} className="flex book-arrows absolute -bottom-10 sm:bottom-10 right-20 sm:static items-center cursor-pointer">
                        <div className="text-white text-sm mx-2">{language.next}</div>
                        <ArrowRight fill={"#fff"} />
                    </div>
                </div>
                <div className="bg-dark-secondary p-5">
                    <div className="flex flex-col sm:flex-row justify-between container max-w-screen-lg mx-auto px-3">
                        <div className="flex justify-between flex-1 sm:mr-4 md:mr-20">
                            <NavLink to="/user/books" className="items-center hidden sm:flex">
                                <ArrowLeft opacity={"0.46"} fill={"#fff"} />
                                <div className="text-white text-sm mx-2 hidden md:block">{language.back_to_library}</div>
                            </NavLink>
                            <div className="flex justify-between w-full sm:w-8/12">
                                <div className="flex items-center">
                                    <img className="h-8 rounded-md" src={bookOne} alt="" />
                                    <div className="flex flex-col mx-2">
                                        <div onClick={() => console.log(totalPage)} className="font-bold text-sm text-white">Journey To The Stars</div>
                                        <div className="font-light text-xs text-white opacity-50">Matt Zhang</div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between p-1 space-x-1">
                                    <div className="text-sm text-bold text-white border-opacity-30 p-2 border border-white rounded-md">
                                        {totalPage === currentPage + 1 && totalPage % 2 === 0 ? totalPage - 1 : currentPage + '-' + (currentPage + 1)}
                                    </div>
                                    <div className="text-sm text-bold opacity-30 text-white">
                                        {/*/ {totalPage > 0 && (Number(totalPage)-1)}*/}
                                        / {pages.length > 1 && pages.length - 1}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center space-x-2 mt-3 sm:mt-0">
                            <div className={`flex relative ${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "" : "ml-2"}`}>
                                <div onClick={() => {
                                    setIsRecordHidden(!isRecordHidden)
                                    setIsPaintHidden(true)
                                    setIsListenHidden(true)
                                }} className="bg-secondary p-2 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer">
                                    <Microphone />
                                </div>
                                <SoundPlayer record isRecordHidden={isRecordHidden} />
                            </div>
                            <div className={`flex relative ${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "" : "ml-2"}`}>
                                <div onClick={() => {
                                    setIsListenHidden(!isListenHidden)
                                    setIsRecordHidden(true)
                                    setIsPaintHidden(true)
                                }} className="bg-primary p-2 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer">
                                    <HeadPhones width={15} height={18} fill={"#fff"} />
                                </div>
                                <SoundPlayer isRecordHidden={isListenHidden} />
                            </div>
                            <div className="flex relative">
                                <div onClick={() => {
                                    setIsPaintHidden(!isPaintHidden)
                                    setIsListenHidden(true)
                                    setIsRecordHidden(true)
                                    setIsStampHidden(true)
                                    setIsBrushHidden(true)
                                    setIsPenHidden(true)
                                }} className="bg-custom-blue p-2 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer">
                                    <PenBox />
                                </div>
                                <div className={`flex p-2 bg-white rounded-md flex-col items-center absolute left-0 bottom-8 mb-2 ${isPaintHidden ? 'hidden' : ''}`}>
                                    <div className="relative">
                                        <div onClick={() => {
                                            setIsPenHidden(!isPenHidden)
                                            setIsStampHidden(true)
                                            setIsBrushHidden(true)
                                        }} className="bg-red-600 p-2 w-8 h-8 text-white flex items-center justify-center rounded-md cursor-pointer">
                                            <PencilIcon />
                                        </div>
                                        <div className={`flex p-2 bg-white rounded-md flex-col items-center space-y-2 absolute left-12 bottom-0 mb-2 ${isPenHidden ? 'hidden' : ''}`}>
                                            <div onClick={() => changeColor("#dc2626")} className="bg-red-600 p-2 w-8 h-8 rounded-md cursor-pointer">
                                            </div>
                                            <div onClick={() => changeColor("#0999AB")} className="bg-soft-blue p-2 w-8 h-8 rounded-md cursor-pointer">
                                            </div>
                                            <div onClick={() => changeColor("#0e9e57")} className="bg-primary p-2 w-8 h-8 rounded-md cursor-pointer">
                                            </div>
                                            <div onClick={() => changeColor("#EB792D")} className="bg-secondary p-2 w-8 h-8 rounded-md cursor-pointer">
                                            </div>
                                            <div onClick={() => changeColor("#000000")} className="bg-black p-2 w-8 h-8 rounded-md cursor-pointer">
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="relative">*/}
                                    {/*    <div onClick={() => {*/}
                                    {/*        setIsBrushHidden(!isBrushHidden)*/}
                                    {/*        setIsPenHidden(true)*/}
                                    {/*        setIsStampHidden(true)*/}
                                    {/*    }} className="bg-soft-blue p-2 w-8 h-8 text-white flex items-center justify-center rounded-md cursor-pointer">*/}
                                    {/*        <PaintBrush fill={"#fff"} />*/}
                                    {/*    </div>*/}
                                    {/*    <div className={`flex p-2 bg-white rounded-md flex-col items-center space-y-2 absolute left-12 bottom-0 mb-2 ${isBrushHidden ? 'hidden' : ''}`}>*/}
                                    {/*        <div className="bg-red-600 p-2 w-8 h-8 rounded-md cursor-pointer">*/}
                                    {/*        </div>*/}
                                    {/*        <div className="bg-soft-blue p-2 w-8 h-8 rounded-md cursor-pointer">*/}
                                    {/*        </div>*/}
                                    {/*        <div className="bg-primary p-2 w-8 h-8 rounded-md cursor-pointer">*/}
                                    {/*        </div>*/}
                                    {/*        <div className="bg-secondary p-2 w-8 h-8 rounded-md cursor-pointer">*/}
                                    {/*        </div>*/}
                                    {/*        <div className="bg-black p-2 w-8 h-8 rounded-md cursor-pointer">*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="relative">*/}
                                    {/*    <div onClick={() => {*/}
                                    {/*        setIsStampHidden(!isStampHidden)*/}
                                    {/*        setIsBrushHidden(true)*/}
                                    {/*        setIsPenHidden(true)*/}
                                    {/*    }} className="bg-primary p-2 w-8 h-8 text-white flex items-center justify-center rounded-md cursor-pointer">*/}
                                    {/*        <Stamp />*/}
                                    {/*    </div>*/}
                                    {/*    <div className={`flex p-2 bg-white rounded-md flex-col items-center space-y-2 absolute left-12 bottom-0 mb-2 ${isStampHidden ? 'hidden' : ''}`}>*/}
                                    {/*        <div className="p-2 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer">*/}
                                    {/*            <Star className="text-yellow-500" />*/}
                                    {/*        </div>*/}
                                    {/*        <div className="p-2 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer">*/}
                                    {/*            <ArrowRightSolid />*/}
                                    {/*        </div>*/}
                                    {/*        <div className="p-2 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer">*/}
                                    {/*            <Check />*/}
                                    {/*        </div>*/}
                                    {/*        <div className="p-2 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer">*/}
                                    {/*            <Question width={18} height={18} fill={"#dc2626"} />*/}
                                    {/*        </div>*/}
                                    {/*        <div className="p-2 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer">*/}
                                    {/*            <Smiley />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="bg-soft-blue p-2 w-8 h-8 text-white flex items-center justify-center rounded-md cursor-pointer">*/}
                                    {/*    <Refresh />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div onClick={() => setShowNoteModal(true)} className="bg-custom-purple p-2 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer">
                                {/*<Letters/>*/}
                                <Edit />
                            </div>
                            {/*<div className="bg-soft-blue p-2 w-8 h-8 flex items-center justify-center rounded-md cursor-pointer">*/}
                            {/*    <Edit/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`h-full w-full z-0 absolute flex items-center justify-center bg-black opacity-70`}>
            </div>
            <div className="absolute top-10 left-10">
                {
                    myNotes.map((item) => {
                        return (
                            <div className="text-white">{item.mynote}</div>
                        )
                    })
                }
            </div>
            <Transition.Root show={showNoteModal} as={Fragment}>
                <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelNoteRef} onClose={setShowNoteModal}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex flex-col">
                                        <div className="flex justify-between items-center pb-2 border-b border-gray-200">
                                            <div className="flex space-x-2 items-center">
                                                <CirclePlus fillColor={"#0E9E57"} />
                                                <h3>Add Note</h3>
                                            </div>
                                            <CrossCircle closeModal={() => setShowNoteModal(false)} />
                                        </div>
                                        <div className="flex flex-col mt-3">
                                            <div className="flex space-x-1 mb-1">
                                                <span>Add note into this page</span>
                                                <span className="text-red-400">*</span>
                                                <GrayQuestionMark />
                                            </div>
                                            <textarea
                                                value={myNotes.mynote}
                                                onChange={(e) => setMyNote({ ...myNote, mynote: e.target.value })}
                                                placeholder="Notes" className="resize-none h-36 text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text">

                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 mb-2 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={saveNote}
                                        ref={cancelNoteRef}
                                    >
                                        Continue
                                    </button>
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-primary text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => setShowNoteModal(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}

export default ReadBook;