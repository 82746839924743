import React from 'react';

function OpenBook (props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 18.419 15">
                <g id="book_3_" data-name="book (3)" transform="translate(0 -47.336)">
                    <path id="Path_34" data-name="Path 34" d="M17.9,50.021c-.035,0-.746-.025-1.79,0V47.878a.542.542,0,0,0-.587-.54A10.315,10.315,0,0,0,9.21,50.11,10.315,10.315,0,0,0,2.9,47.338a.542.542,0,0,0-.587.54v2.2c-.593-.022-1.155-.038-1.755-.056A.542.542,0,0,0,0,50.563v9.866a.542.542,0,0,0,.505.541C2.66,61.114,6.27,61.13,9,62.293a.543.543,0,0,0,.425,0c2.727-1.163,6.336-1.179,8.492-1.324a.542.542,0,0,0,.505-.541V50.563A.542.542,0,0,0,17.9,50.021Zm-2.874-1.534v9.285a11.494,11.494,0,0,0-5.271,2.676V51.137A8.745,8.745,0,0,1,15.023,48.487Zm-11.626,0a8.745,8.745,0,0,1,5.271,2.649v9.312A11.494,11.494,0,0,0,3.4,57.772ZM1.083,59.919v-8.8c.388.011.8.024,1.23.041v7.053a.542.542,0,0,0,.442.532A10.838,10.838,0,0,1,7.1,60.568a36.7,36.7,0,0,0-6.016-.649Zm16.252,0a36.7,36.7,0,0,0-6.016.649,10.838,10.838,0,0,1,4.345-1.821.542.542,0,0,0,.442-.532V51.1c.5-.011.923-.011,1.23-.009v8.824Z" fill={props.fill}/>
                    <path id="Path_35" data-name="Path 35" d="M133.13,166.051a5.046,5.046,0,0,0-1.915-.782.542.542,0,1,0-.181,1.068,3.95,3.95,0,0,1,1.476.6.542.542,0,0,0,.619-.889Z" transform="translate(-125.867 -113.666)" fill={props.fill}/>
                    <path id="Path_36" data-name="Path 36" d="M133.076,250.8a5.789,5.789,0,0,0-1.861-.771.542.542,0,1,0-.181,1.068,4.837,4.837,0,0,1,1.476.627.542.542,0,1,0,.565-.925Z" transform="translate(-125.867 -195.368)" fill={props.fill}/>
                    <path id="Path_37" data-name="Path 37" d="M303.318,166.939a3.95,3.95,0,0,1,1.476-.6.542.542,0,1,0-.181-1.068,5.045,5.045,0,0,0-1.915.782.542.542,0,1,0,.619.889Z" transform="translate(-291.543 -113.666)" fill={props.fill}/>
                    <path id="Path_38" data-name="Path 38" d="M305.331,250.033a5.788,5.788,0,0,0-1.861.771.542.542,0,0,0,.565.925,4.838,4.838,0,0,1,1.476-.627.542.542,0,1,0-.181-1.068Z" transform="translate(-292.26 -195.368)" fill={props.fill}/>
                </g>
            </svg>
        </div>
    );
}

export default OpenBook;