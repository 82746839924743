import { ApiUrl } from "../../components/utils/Urls";
import { dashboardActions } from "./dashboardSlice";

export const fetchDashboardStudentsData = teacherId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'teacher/students', {
                method: 'POST',
                body: 'teacherId=' + teacherId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch dashboard data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const dashboardData = await fetchData()
            const students = dashboardData.list
            const studentId = dashboardData.list[0].id
            dispatch(dashboardActions.getStudents(students))
            dispatch(dashboardActions.getStudent(studentId))
            dispatch(fetchStudentGraphicData(studentId))
            dispatch(fetchDashboardData(studentId))
        } catch (error) { }
    }
}

export const fetchDashboardData = studentId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'teacher/student', {
                method: 'POST',
                // body: 'studentId=' + studentId,
                body: 'studentId=' + studentId.toString(),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch dashboard data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const dashboardData = await fetchData()
            dispatch(dashboardActions.getStatistic(dashboardData.statistic))
            dispatch(dashboardActions.getStudent(dashboardData.student.id))
            // dispatch(teacherStudentActions.getStatistic(statistic[0]))
        } catch (error) { }
    }
}

export const fetchStudentGraphicData = studentId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'student/read', {
                method: 'POST',
                // body: 'studentId=' + studentId,
                body: 'id=' + studentId.toString(),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch dashboard data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const dashboardGraphicData = await fetchData()
            dispatch(dashboardActions.getStudentGraphic(dashboardGraphicData.report))
        } catch (error) { }
    }
}