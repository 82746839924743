import React from 'react';

function Library(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="21.159" height="22.1" viewBox="0 0 21.159 22.1">
                <g id="book" transform="translate(-10.896 0)">
                    <path id="Path_26" data-name="Path 26" d="M31.959,18.719,26.493,1.524A2.031,2.031,0,0,0,23.945.205L21.97.833a2.027,2.027,0,0,0-.39.171,2.029,2.029,0,0,0-1.75-1H17.759a2.021,2.021,0,0,0-1.381.545A2.021,2.021,0,0,0,15,0H12.925A2.031,2.031,0,0,0,10.9,2.029V20.071A2.031,2.031,0,0,0,12.925,22.1H15a2.021,2.021,0,0,0,1.381-.545,2.021,2.021,0,0,0,1.381.545h2.072a2.031,2.031,0,0,0,2.029-2.029V7.18l4.258,13.4a2.028,2.028,0,0,0,2.548,1.319l1.975-.628a2.029,2.029,0,0,0,1.318-2.548Zm-3.326-6.19L25.259,13.6,23.141,6.938l3.373-1.072Zm.392,1.234.445,1.4L26.1,16.234l-.445-1.4Zm-12-7.591h3.539v6.992H17.025ZM15.73,13.165H12.191V6.172H15.73ZM12.191,14.46H15.73v1.468H12.191Zm4.834,0h3.539v1.468H17.025Zm.734-13.165h2.072a.735.735,0,0,1,.734.734V4.877H17.025V2.029A.735.735,0,0,1,17.759,1.295Zm-4.834,0H15a.735.735,0,0,1,.734.734V4.877H12.191V2.029A.735.735,0,0,1,12.925,1.295ZM15,20.8H12.925a.735.735,0,0,1-.734-.734V17.222H15.73v2.849A.735.735,0,0,1,15,20.8Zm4.834,0H17.759a.735.735,0,0,1-.734-.734V17.222h3.539v2.849A.735.735,0,0,1,19.831,20.8ZM21.86,2.662a.734.734,0,0,1,.5-.6l1.975-.628a.735.735,0,0,1,.922.477l.863,2.715L22.749,5.7l-.863-2.715c-.013-.04-.027-.326-.027-.326Zm8.818,17.008a.73.73,0,0,1-.429.362l-1.975.628a.735.735,0,0,1-.922-.477l-.863-2.715L29.862,16.4l.863,2.715A.728.728,0,0,1,30.677,19.671Z" transform="translate(0 0)" fill="currentColor"/>
                </g>
            </svg>
        </div>
    );
}

export default Library;