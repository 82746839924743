import React from 'react';

function CrossCircle ({closeModal}) {
    return (
        <div className="cursor-pointer" onClick={closeModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="35.522" height="35.522" viewBox="0 0 35.522 35.522">
                <g id="add-tool" transform="translate(17.761 -0.001) rotate(45)">
                    <g id="Group_6" data-name="Group 6" transform="translate(0 0.001)">
                        <path id="Path_7" data-name="Path 7" d="M12.559,0A12.559,12.559,0,1,0,25.118,12.56,12.573,12.573,0,0,0,12.559,0Zm0,23.059a10.5,10.5,0,1,1,10.5-10.5A10.512,10.512,0,0,1,12.559,23.06Z" transform="translate(0 -0.001)" fill="#9da8b2"/>
                        <path id="Path_8" data-name="Path 8" d="M79.642,72.558H74.618V67.534a1.029,1.029,0,0,0-2.059,0v5.023H67.536a1.029,1.029,0,0,0,0,2.059h5.023V79.64a1.029,1.029,0,1,0,2.059,0V74.616h5.023a1.029,1.029,0,0,0,0-2.059Z" transform="translate(-61.03 -61.028)" fill="#9da8b2"/>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default CrossCircle;