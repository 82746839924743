import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        userInfos:JSON.parse(localStorage.getItem("userInfos")),
        password: '',
        language:'',
        isChanged:false,
    },
    reducers: {
        getUserInfos(state,action){
            state.userInfos = action.payload[0]
            state.language = action.payload.language
        },
        updatePassword(state, action) {
            state.password = {
                ...state.password,
                ...action.payload
            }
        },
        getIsChanged(state){
            state.isChanged = !state.isChanged
        },
        updateUserInfos(state,action){
            state.userInfos = {
                ...state.userInfos,
                ...action.payload
            }
        }
    }
})

export const profileActions = profileSlice.actions

export default profileSlice