import { createSlice } from "@reduxjs/toolkit";

const educationLevelSlice = createSlice({
    name: 'educationLevels',
    initialState: {
        educationLevels: [],
        educationLevel: false,
    },
    reducers: {
        getEducationLevels(state, action) {
            state.educationLevels = action.payload
        },
        getEducationLevel(state, action) {
            state.educationLevel = !state.educationLevel
        }
    }
})

export const educationLevelActions = educationLevelSlice.actions

export default educationLevelSlice