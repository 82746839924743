import React from 'react';

function Statistics(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.939" height="22" viewBox="0 0 19.939 22">
                <g id="graph" transform="translate(-23.988)">
                    <path id="Path_9" data-name="Path 9" d="M179.254,205.75h-1.719a1.549,1.549,0,0,1-1.547-1.547V193.547A1.549,1.549,0,0,1,177.535,192h1.719a1.549,1.549,0,0,1,1.547,1.547V204.2A1.549,1.549,0,0,1,179.254,205.75Zm-1.719-12.375a.172.172,0,0,0-.172.172V204.2a.172.172,0,0,0,.172.172h1.719a.172.172,0,0,0,.172-.172V193.547a.172.172,0,0,0-.172-.172Z" transform="translate(-145.469 -183.75)" fill="currentColor"/>
                    <path id="Path_10" data-name="Path 10" d="M27.254,282.313H25.535a1.549,1.549,0,0,1-1.547-1.547v-7.219A1.549,1.549,0,0,1,25.535,272h1.719a1.549,1.549,0,0,1,1.547,1.547v7.219A1.549,1.549,0,0,1,27.254,282.313Zm-1.719-8.937a.172.172,0,0,0-.172.172v7.219a.172.172,0,0,0,.172.172h1.719a.172.172,0,0,0,.172-.172v-7.219a.172.172,0,0,0-.172-.172Z" transform="translate(0 -260.313)" fill="currentColor"/>
                    <path id="Path_11" data-name="Path 11" d="M285.292,22h-1.719a1.549,1.549,0,0,1-1.547-1.547V6.187h-.944a1.117,1.117,0,0,1-.848-1.844l.01-.011,3.677-4.1a.688.688,0,0,1,1.024,0l3.677,4.1.01.011a1.117,1.117,0,0,1-.848,1.844h-.944V20.453A1.549,1.549,0,0,1,285.292,22ZM281.66,4.812h1.055a.688.688,0,0,1,.688.688V20.453a.172.172,0,0,0,.172.172h1.719a.172.172,0,0,0,.172-.172V5.5a.688.688,0,0,1,.688-.687h1.055l-2.773-3.095Z" transform="translate(-244.976)" fill="currentColor"/>
                </g>
            </svg>
        </div>
    );
}

export default Statistics;