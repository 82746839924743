import { ApiUrl } from "../../components/utils/Urls";
import { bookActions } from "./bookSlice";
import { toast } from "react-toastify";

export const fetchBookData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'book/list', {
                method: 'POST', body: 'type=2',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch book data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const bookData = await fetchData()
            dispatch(bookActions.getBooks(bookData))
        } catch (error) { }
    }
}

export const updateBookData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/bookEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch book data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(bookActions.getIsBook(2))
            toast.success("Book updated successfully")
        } catch (error) { }
    }
}

export const deleteBookData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'book/bookDeleteOnline', {
                method: 'POST', body: 'bookId=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not delete book data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(bookActions.getIsBook(2))
            toast.success("Book deleted successfully")
        } catch (error) { }
    }
}

export const addBookData = (inputs,selectedAuthors,selectedCategories,selectedSubjects,history) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'book/bookAddOnline', {
                method: 'POST',
                body:
                    // 'name=' + book.name + '&category='+book.category+'&grade=' + book.grade + '&author=' + book.author + '&subject=' + book.subject + '&img=' + book.img + '&paper='+book.paper+'&level='+book.level+'&language='+book.language,
                    'name=' + inputs.name + '&category=' + selectedCategories[0].id + '&grade=&author=1069&subject=' + selectedSubjects[0].id +  '&img=' + inputs.file.name + '&paper=' + inputs.number_of_pages +  '&level=' + inputs.level + '&language=' + inputs.language,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch book data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const response = await addData()
            toast.success("Book added successfully")
            dispatch(bookActions.getIsBook(2))
            history.push("/admin/books")
        } catch (error) {
            console.log(error)
        }
    }
}