import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { tableTheme } from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import { bookActions } from "../../store/book/bookSlice";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import { StaticTableOptions } from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import { addBookData, deleteBookData, fetchBookData, updateBookData } from "../../store/book/bookActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import { NavLink } from "react-router-dom";
import { CollectionIcon } from "@heroicons/react/solid";
import { subjectActions } from "../../store/subject/subjectSlice";
import { FileUrl } from "../../components/utils/Urls";
import DialogHeader from "../../components/dialogs/DialogHeader";

function Books({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const bookList = useSelector(state => state.books.books)
    // const books = bookInfos.list
    const isBook = useSelector(state => state.books.isBook)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [bookId, setBookId] = useState(false)

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [book, setBook] = useState({})

    const cols = [
        {
            name: "name",
            label: "Book Information",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <div className="flex items-center">
                            <img className="h-6 object-contain" src={FileUrl + bookList[tableMeta.rowIndex].img} alt="" />
                            <div className="flex flex-col mx-2">
                                <div className="font-semibold">{value}</div>
                                <div className="text-xs">{bookList[tableMeta.rowIndex].authors[0] && bookList[tableMeta.rowIndex].authors[0].name}</div>
                            </div>
                        </div>
                    );
                }
            }
        },
        {
            name: "language",
            label: "Language",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <div className="flex items-center">
                            {value}
                        </div>
                    );
                }
            }
        },
        {
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <NavLink to={`/admin/books/add-pages/${bookList[tableMeta.rowIndex].id}`}>
                                <div className="bg-gray-200 opacity-70 hover:opacity-100 transition-all cursor-pointer h-6 w-6 text-blue-700 rounded-md p-1">
                                    <CollectionIcon />
                                </div>
                            </NavLink>
                            <NavLink to={`/admin/books/edit-book/${bookList[tableMeta.rowIndex].id}`}>
                                <EditButton />
                            </NavLink>
                            <DeleteButton
                                onClick={() => {
                                    setBookId(bookList[tableMeta.rowIndex].id)
                                    dispatch(subjectActions.getSubject(bookList[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }}
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <NavLink to="/admin/books/add-book">
                    <AddButton />
                </NavLink>
            )
        }
    }

    const saveBook = () => {
        dispatch(bookActions.getBook(''))
        dispatch(addBookData(book.name))
        dispatch(fetchBookData())
        setOpenAdd(false)
    }

    const deleteBook = () => {
        dispatch(deleteBookData(bookId))
        setOpenDelete(false)
    }

    const editBook = () => {
        dispatch(updateBookData(bookId, book.name))
        dispatch(fetchBookData())
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchBookData())
    }, [dispatch, isBook])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.books}
                        data={bookList}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteBook}
                title={language.delete_book}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={setOpenAdd}
                cancelAddRef={cancelAddRef}
                saveItem={saveBook}
                editItem={editBook}
            >
                <DialogHeader
                    title={openEdit ? language.edit_book : language.add_book}
                    closeModal={() => setOpenAdd(false)}
                />
                <div className="flex flex-col mt-3">
                    <div className="flex space-x-1 mb-1">
                        <span>{language.book_name}</span>
                        <span className="text-red-400">*</span>
                        <GrayQuestionMark />
                    </div>
                    <input value={openEdit ? book.name : null} onChange={(e) => setBook({ ...book, name: e.target.value })} placeholder={language.book_name} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                </div>
            </AddDialog>
        </div>
    );
}

export default Books;