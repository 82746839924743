const AdminLanguage = {
    Deutsch: {
        "id": "Deutsch",
        "are_you_sure": "Bist du sicher",
        "add": "hinzufügen",
        "filter": "Filter",
        "search": "Suche",
        "continue": "Fortsetzen",
        "cancel": "Abbrechen",
        "edit": "Bearbeiten",
        "delete": "Löschen",
        "show_hide_columns": "Spalten ausblenden anzeigen",
        "rows_selected": "Zeilen ausgewählt",
        "delete_selected_rows": "Ausgewählte Zeilen löschen",
        "order": "Befehl",
        "show_columns": "Spalten anzeigen",
        "view_columns": "Spalten anzeigen",
        "previous": "Vorherige",
        "next": "Nächste",
        "of": "Von",
        "showing": "Zeigen",
        "order_for": "Bestellung für",
        "no_result_found": "Keine Einträge gefunden",
        "actions": "Aktionen",
        "regions": "Regionen",
        "books": "Bücher",
        "library": "Bücherei",
        "region_name": "Regionsname",
        "add_region": "Region hinzufügen",
        "delete_region": "Region löschen",
        "edit_region": "Region bearbeiten",
        "cities": "Städte",
        "idd": "idd",
        "city_name": "Stadtname",
        "add_city": "Stadt hinzufügen",
        "delete_city": "Stadt löschen",
        "edit_city": "Stadt bearbeiten",
        "ministries": "Ministerien",
        "ministry_name": "Name des Ministeriums",
        "add_ministry": "Ministerium hinzufügen",
        "delete_ministry": "Ministerium löschen",
        "edit_ministry": "Ministerium bearbeiten",
        "directorates": "Direktionen",
        "directorate_name": "Direktionsname",
        "add_directorate": "Direktion hinzufügen",
        "delete_directorate": "Direktion löschen",
        "edit_directorate": "Direktion bearbeiten",
        "corporations": "Unternehmen",
        "corporate_name": "Firmenbezeichnung",
        "add_corporation": "Unternehmen hinzufügen",
        "delete_corporation": "Unternehmen löschen",
        "edit_corporation": "Korporation bearbeiten",
        "education_types": "Bildungsarten",
        "statistics": "Statistiken",
        "employees": "Angestellte",
        "students": "Studentinnen",
        "readings": "Lesungen",
        "employee_name": "Mitarbeitername",
        "department": "Abteilung",
        "contact": "Kontakt",
        "add_employee": "Mitarbeiter hinzufügen",
        "delete_employee": "Mitarbeiter löschen",
        "edit_employee": "Mitarbeiter bearbeiten",
        "name": "Name",
        "surname": "Familien-oder Nachname",
        "phone": "Telefon",
        "e_mail": "Email",
        "main_department": "Hauptabteilung",
        "choose": "Wählen",
        "authorized_department": "Autorisierte Abteilung",
        "role": "Rolle",
        "name_surname": "Vorname Nachname",
        "add_student": "Schüler hinzufügen",
        "delete_student": "Schüler löschen",
        "book_levels": "Buchstufen",
        "book_level": "Buchebene",
        "add_book_level": "Buchstufe hinzufügen",
        "delete_book_level": "Buchebene löschen",
        "edit_book_level": "Buchebene bearbeiten",
        "level_name": "Levelname",
        "publishing_houses": "Verlage",
        "publishing_house_name": "Verlagsname",
        "add_publishing_house": "Verlag hinzufügen",
        "edit_publishing_house": "Verlag bearbeiten",
        "delete_publishing_house": "Verlag löschen",
        "authors": "Autorinnen",
        "add_author": "Autor hinzufügen",
        "delete_author": "Autor löschen",
        "edit_author": "Autor bearbeiten",
        "education_levels": "Bildungsstufen",
        "description": "Beschreibung",
        "add_education_level": "Bildungsstufe hinzufügen",
        "delete_education_level": "Bildungsstufe löschen",
        "edit_education_level": "Bildungsstufe bearbeiten",
        "education_level_name": "Name der Bildungsstufe",
        "education_type_name": "Name des Bildungstyps",
        "add_education_type": "Bildungstyp hinzufügen",
        "delete_education_type": "Bildungstyp löschen",
        "edit_education_type": "Bildungstyp bearbeiten",
        "categories": "Kategorien",
        "category_name": "Kategoriename",
        "add_category": "Kategorie hinzufügen",
        "delete_category": "Kategorie löschen",
        "edit_category": "Kategorie bearbeiten",
        //settings
        "manage_menus": "Menüs verwalten",
        "icon": "Symbol",
        "menu_name": "Menüname",
        "link_type": "Linktyp",
        "active": "Aktiv",
        "passive": "Passiv",
        "direct_routing": "Direktes Routing",
        "add_menu": "Menü hinzufügen",
        "delete_menu": "Menü löschen",
        "edit_menu": "Menü bearbeiten",
        "seo_link": "SEO-Link",
        "folder_path": "Ordnerpfad",
        "publishing_status": "Veröffentlichungsstatus",
        //
        "manage_departments": "Abteilungen verwalten",
        "department_name": "Abteilungsname",
        "type": "Typ",
        "access": "Zugriff",
        "special_authorization": "Sondergenehmigung",
        "dashboard": "Armaturenbrett",
        "action": "Aktion",
        "add_department": "Abteilung hinzufügen",
        "delete_department": "Abteilung löschen",
        "edit_department": "Edit Department",
        "authorize": "Autorisieren",
        "allowed_pages": "Zulässige Seiten",
        "pages": "Seiten",
        //
        "notification_name": "Benachrichtigungsname",
        "title": "Titel",
        "message": "Nachricht",
        "category": "Kategorie",
        "allowed_notifications": "Zulässige Benachrichtigungen",
        "sms": "SMS",
        "e_posta": "E-Posta",
        "system_notification": "Systembenachrichtigung",
        "mobile_notification": "Mobile Benachrichtigung",

        //son eklenenler
        "classes": "Classes",
        "avatars": "Avatars",
        "backgrounds": "Backgrounds",
        "notifications": "Notifications",
        "faq": "Help", //sıkça sorulan sorular kısaltması frequently asking questions
        "add_notification": "Add Notification",
        "dropzone_text": "Drag and drop some files here, or click to select files",
        "book_cover": "Book Cover",
        "class_name": "Class Name",
        "updated_successfully": "Updated Successfully",
        "deleted_successfully": "Deleted Successfully",
        "added_successfully": "Added Successfully",
        "region": "Region",
        "corporation": "Corporation",
        "city": "City",
        "ministry": "Ministry",
        "directorate": "Directorate",
        "employee": "Employee",
        "student": "Student",
        "book": "Book",
        "publishing_house": "Publishing House",
        "author": "Author",
        "author_name": "Author Name",
        "education_type": "Education Type",
        "education_level": "Education Level",
        "class": "Class",
        "avatar": "Avatar",
        "background": "Background",
        "menu": "Menu",
        "notification": "Notification",
        "add_class": "Add Class",
        "edit_class": "Edit Class",
        "add_subject": "Add Subject",
        "edit_subject": "Edit Subject",
        "subject_name": "Subject Name",
        "add_book": "Add Book",
        "edit_book": "Edit Book",
        "book_name": "Book Name",
        "subjects": "Subjects",
        "delete_book": "Delete Book",
        "languages": "Languages",
        "language_name": "Language Name",
        "language_code": "Language Code",
        "add_language": "Add Language",
        "edit_language": "Edit Language",
        "delete_language": "Delete Language",
        "edit_student": "Edit Student",
        "question": "Question",
        "answer": "Answer",
        "add_faq": "Add Help",
        "edit_faq": "Edit Help",
        "delete_faq": "Delete Help",
        "listening": "Listening",
        "sound_name": "Sound Name",
        "add_sound": "Add Sound",
        "edit_sound": "Edit Sound",
        "delete_sound": "Delete Sound",
        "sound_file": "Sound File",
        "click_to_upload": "Click to upload sound file",
        "book_level_warning": "You have to choose at least 1 book level!",
        "middle_name": "Middle Name",
        "last_name": "Last Name",
        "assign_teacher": "Assign Teacher",
        "teachers": "Teachers",
        "grade_name": "Grade Name",
        "student_number": "Student Number",
        "gender": "Gender",
        "parents": "Parents",
        "add_parent": "Add Parent",
        "delete_parent": "Delete Parent",
        "edit_parent": "Edit Parent",
        "assign_student": "Assign Student",
        "teacher_pages": "Teacher Pages",
        "region_delete_warning": "The cities,ministries,directorates,corporations under this region and all the data related to those will be deleted! Are you really sure?",
        "region_added": "Region added successfully",
        "region_deleted": "Region deleted successfully",
        "region_updated": "Region updated successfully",
        "city_delete_warning": "The ministries,directorates,corporations under this city and all the data related to those will be deleted! Are you really sure?",
        "city_added": "City added successfully",
        "city_deleted": "City deleted successfully",
        "city_updated": "City updated successfully",
        "ministry_delete_warning": "The directorates,corporations under this ministry and all the data related to those will be deleted! Are you really sure?",
        "ministry_added": "Ministry added successfully",
        "ministry_deleted": "Ministry deleted successfully",
        "ministry_updated": "Ministry updated successfully",
        "directorate_delete_warning": "The corporations under this directorate and all the data related to those will be deleted! Are you really sure?",
        "directorate_added": "Directorate added successfully",
        "directorate_deleted": "Directorate deleted successfully",
        "directorate_updated": "Directorate updated successfully",
        "corporation_delete_warning": "The employees, classes, students under this corporation and all the data related to those will be deleted! Are you really sure?",
        "corporation_added": "Corporation added successfully",
        "corporation_deleted": "Corporation deleted successfully",
        "corporation_updated": "Corporation updated successfully",
        "employee_delete_warning": "The employee will be deleted! Are you really sure?",
        "employee_added": "Employee added successfully",
        "employee_deleted": "Employee deleted successfully",
        "employee_updated": "Employee updated successfully",
        "student_delete_warning": "The student will be deleted! Are you really sure?",
        "student_added": "Student added successfully",
        "student_deleted": "Student deleted successfully",
        "student_updated": "Student updated successfully",
        "parent_delete_warning": "The parent will be deleted! Are you really sure?",
        "parent_added": "Parent added successfully",
        "parent_deleted": "Parent deleted successfully",
        "parent_updated": "Parent updated successfully",
        "student_assigned_to_parent": "Student assigned to parent successfully",
        "delete_class": "Delete Class",
        "class_delete_warning": "The class will be deleted! Are you really sure?",
        "class_added": "Class added successfully",
        "class_deleted": "Class deleted successfully",
        "class_updated": "Class updated successfully",
        "teacher_assigned_to_class": "Teacher assigned to class successfully",
        "publishing_house_delete_warning": "The publishing house will be deleted! Are you really sure?",
        "publishing_house_added": "Publishing House added successfully",
        "publishing_house_deleted": "Publishing House deleted successfully",
        "publishing_house_updated": "Publishing House updated successfully",
        "author_delete_warning": "The author will be deleted! Are you really sure?",
        "author_added": "Author added successfully",
        "author_deleted": "Author deleted successfully",
        "author_updated": "Author updated successfully",
        "book_level_delete_warning": "The book level will be deleted! Are you really sure?",
        "book_level_added": "Book level added successfully",
        "book_level_deleted": "Book level deleted successfully",
        "book_level_updated": "Book level updated successfully",
        "category_delete_warning": "The category will be deleted! Are you really sure?",
        "category_added": "Category added successfully",
        "category_deleted": "Category deleted successfully",
        "category_updated": "Category updated successfully",
        "delete_subject": "Delete Subject",
        "subject_delete_warning": "The subject will be deleted! Are you really sure?",
        "subject_added": "Subject added successfully",
        "subject_deleted": "Subject deleted successfully",
        "subject_updated": "Subject updated successfully",
        "education_type_delete_warning": "The education type will be deleted! Are you really sure?",
        "education_type_added": "Education type added successfully",
        "education_type_deleted": "Education type deleted successfully",
        "education_type_updated": "Education type updated successfully",
        "education_level_delete_warning": "The education level will be deleted! Are you really sure?",
        "education_level_added": "Education level added successfully",
        "education_level_deleted": "Education level deleted successfully",
        "education_level_updated": "Education level updated successfully",
        "language_delete_warning": "The language will be deleted! Are you really sure?",
        "language_added": "Language added successfully",
        "language_deleted": "Language deleted successfully",
        "language_updated": "Language updated successfully",
        "faq_delete_warning": "The f.a.q. will be deleted! Are you really sure?",
        "faq_added": "F.A.Q. added successfully",
        "faq_deleted": "F.A.Q. deleted successfully",
        "faq_updated": "F.A.Q. updated successfully",
    },
    English: {
        "id": "English",
        "are_you_sure": "Are You Sure?",
        "add": "Add",
        "filter": "Filter",
        "search": "Search",
        "continue": "Continue",
        "cancel": "Cancel",
        "edit": "Edit",
        "delete": "Delete",
        "show_hide_columns": "Show/Hide Columns",
        "rows_selected": "rows(s) selected",
        "delete_selected_rows": "Delete Selected Rows",
        "order": "Order",
        "show_columns": "Show Columns",
        "view_columns": "View Columns",
        "previous": "Previous",
        "next": "Next",
        "of": "Of",
        "showing": "Showing",
        "order_for": "Order for",
        "no_result_found": "No Result Found",
        "actions": "Actions",
        "regions": "Regions",
        "books": "Books",
        "library": "Library",
        "region_name": "Region Name",
        "add_region": "Add Region",
        "delete_region": "Delete Region",
        "edit_region": "Edit Region",
        "cities": "Cities",
        "idd": "Id",
        "city_name": "City Name",
        "add_city": "Add City",
        "delete_city": "Delete City",
        "edit_city": "Edit City",
        "ministries": "Ministries",
        "ministry_name": "Ministry Name",
        "add_ministry": "Add Ministry",
        "delete_ministry": "Delete Ministry",
        "edit_ministry": "Edit Ministry",
        "directorates": "Directorates",
        "directorate_name": "Directorate Name",
        "add_directorate": "Add Directorate",
        "delete_directorate": "Delete Directorate",
        "edit_directorate": "Edit Directorate",
        "corporations": "Corporations",
        "corporation_name": "Corporate Name",
        "add_corporation": "Add Corporation",
        "delete_corporation": "Delete Corporation",
        "edit_corporation": "Edit Corporation",
        "education_types": "Education Types",
        "statistics": "Statistics",
        "employees": "Employees",
        "students": "Students",
        "readings": "Readings",
        "employee_name": "Employee Name",
        "department": "Department",
        "contact": "Contact",
        "add_employee": "Add Employee",
        "delete_employee": "Delete Employee",
        "edit_employee": "Edit Employee",
        "name": "Name",
        "surname": "Surname",
        "phone": "Phone",
        "e_mail": "E-mail",
        "main_department": "Main Department",
        "choose": "Choose",
        "authorized_department": "Authorized Department",
        "role": "Role",
        "name_surname": "Name Surname",
        "add_student": "Add Student",
        "delete_student": "Delete Student",
        "book_levels": "Book Levels",
        "book_level": "Book Level",
        "add_book_level": "Add Book Level",
        "delete_book_level": "Delete Book Level",
        "edit_book_level": "Edit Book Level",
        "level_name": "Level Name",
        "publishing_houses": "Publishing Houses",
        "publishing_house_name": "Publishing House Name",
        "add_publishing_house": "Add Publishing House",
        "edit_publishing_house": "Edit Publishing House",
        "delete_publishing_house": "Delete Publishing House",
        "authors": "Authors",
        "add_author": "Add Author",
        "delete_author": "Delete Author",
        "edit_author": "Edit Author",
        "education_levels": "Education Levels",
        "description": "Description",
        "add_education_level": "Add Education Level",
        "delete_education_level": "Delete Education Level",
        "edit_education_level": "Edit Education Level",
        "education_level_name": "Education Level Name",
        "education_type_name": "Education Type Name",
        "add_education_type": "Add Education Type",
        "delete_education_type": "Delete Education Type",
        "edit_education_type": "Edit Education Type",
        "categories": "Categories",
        "category_name": "Category Name",
        "add_category": "Add Category",
        "delete_category": "Delete Category",
        "edit_category": "Edit Category",
        //settings
        "manage_menus": "Manage Menus",
        "icon": "Icon",
        "menu_name": "Menu Name",
        "link_type": "Link Type",
        "active": "Active",
        "passive": "Passive",
        "direct_routing": "Direct Routing",
        "add_menu": "Add Menu",
        "delete_menu": "Delete Menu",
        "edit_menu": "Edit Menu",
        "seo_link": "Seo Link",
        "folder_path": "Folder Path",
        "publishing_status": "Publishing Status",
        //
        "manage_departments": "Manage Departments",
        "department_name": "Department Name",
        "type": "Type",
        "access": "Access",
        "special_authorization": "Special Authorization",
        "dashboard": "Dashboard",
        "action": "Action",
        "add_department": "Add Department",
        "delete_department": "Delete Department",
        "edit_department": "Edit Department",
        "authorize": "Authorize",
        "allowed_pages": "Allowed Pages",
        "pages": "Pages",
        //
        "notification_name": "Notification Name",
        "title": "Title",
        "message": "Message",
        "category": "Category",
        "allowed_notifications": "Allowed Notifications",
        "sms": "SMS",
        "e_posta": "E-Posta",
        "system_notification": "System Notification",
        "mobile_notification": "Mobile Notification",

        //son eklenenler
        "classes": "Classes",
        "avatars": "Avatars",
        "backgrounds": "Backgrounds",
        "notifications": "Notifications",
        "faq": "Help", //sıkça sorulan sorular kısaltması frequently asking questions
        "add_notification": "Add Notification",
        "dropzone_text": "Drag and drop some files here, or click to select files",
        "book_cover": "Book Cover",
        "class_name": "Class Name",
        "updated_successfully": "Updated Successfully",
        "deleted_successfully": "Deleted Successfully",
        "added_successfully": "Added Successfully",
        "region": "Region",
        "corporation": "Corporation",
        "city": "City",
        "ministry": "Ministry",
        "directorate": "Directorate",
        "employee": "Employee",
        "student": "Student",
        "book": "Book",
        "publishing_house": "Publishing House",
        "author": "Author",
        "author_name": "Author Name",
        "education_type": "Education Type",
        "education_level": "Education Level",
        "class": "Class",
        "avatar": "Avatar",
        "background": "Background",
        "menu": "Menu",
        "notification": "Notification",
        "add_class": "Add Class",
        "edit_class": "Edit Class",
        "add_subject": "Add Subject",
        "edit_subject": "Edit Subject",
        "subject_name": "Subject Name",
        "add_book": "Add Book",
        "edit_book": "Edit Book",
        "book_name": "Book Name",
        "subjects": "Subjects",
        "delete_book": "Delete Book",
        "languages": "Languages",
        "language_name": "Language Name",
        "language_code": "Language Code",
        "add_language": "Add Language",
        "edit_language": "Edit Language",
        "delete_language": "Delete Language",
        "edit_student": "Edit Student",
        "question": "Question",
        "answer": "Answer",
        "add_faq": "Add Help",
        "edit_faq": "Edit Help",
        "delete_faq": "Delete Help",
        "listening": "Listening",
        "sound_name": "Sound Name",
        "add_sound": "Add Sound",
        "edit_sound": "Edit Sound",
        "delete_sound": "Delete Sound",
        "sound_file": "Sound File",
        "click_to_upload": "Click to upload sound file",
        "book_level_warning": "You have to choose at least 1 book level!",
        "middle_name": "Middle Name",
        "last_name": "Last Name",
        "assign_teacher": "Assign Teacher",
        "teachers": "Teachers",
        "grade_name": "Grade Name",
        "student_number": "Student Number",
        "gender": "Gender",
        "parents": "Parents",
        "add_parent": "Add Parent",
        "delete_parent": "Delete Parent",
        "edit_parent": "Edit Parent",
        "assign_student": "Assign Student",
        "teacher_pages": "Teacher Pages",
        "region_delete_warning": "The cities,ministries,directorates,corporations under this region and all the data related to those will be deleted! Are you really sure?",
        "region_added": "Region added successfully",
        "region_deleted": "Region deleted successfully",
        "region_updated": "Region updated successfully",
        "city_delete_warning": "The ministries,directorates,corporations under this city and all the data related to those will be deleted! Are you really sure?",
        "city_added": "City added successfully",
        "city_deleted": "City deleted successfully",
        "city_updated": "City updated successfully",
        "ministry_delete_warning": "The directorates,corporations under this ministry and all the data related to those will be deleted! Are you really sure?",
        "ministry_added": "Ministry added successfully",
        "ministry_deleted": "Ministry deleted successfully",
        "ministry_updated": "Ministry updated successfully",
        "directorate_delete_warning": "The corporations under this directorate and all the data related to those will be deleted! Are you really sure?",
        "directorate_added": "Directorate added successfully",
        "directorate_deleted": "Directorate deleted successfully",
        "directorate_updated": "Directorate updated successfully",
        "corporation_delete_warning": "The employees, classes, students under this corporation and all the data related to those will be deleted! Are you really sure?",
        "corporation_added": "Corporation added successfully",
        "corporation_deleted": "Corporation deleted successfully",
        "corporation_updated": "Corporation updated successfully",
        "employee_delete_warning": "The employee will be deleted! Are you really sure?",
        "employee_added": "Employee added successfully",
        "employee_deleted": "Employee deleted successfully",
        "employee_updated": "Employee updated successfully",
        "student_delete_warning": "The student will be deleted! Are you really sure?",
        "student_added": "Student added successfully",
        "student_deleted": "Student deleted successfully",
        "student_updated": "Student updated successfully",
        "parent_delete_warning": "The parent will be deleted! Are you really sure?",
        "parent_added": "Parent added successfully",
        "parent_deleted": "Parent deleted successfully",
        "parent_updated": "Parent updated successfully",
        "student_assigned_to_parent": "Student assigned to parent successfully",
        "delete_class": "Delete Class",
        "class_delete_warning": "The class will be deleted! Are you really sure?",
        "class_added": "Class added successfully",
        "class_deleted": "Class deleted successfully",
        "class_updated": "Class updated successfully",
        "teacher_assigned_to_class": "Teacher assigned to class successfully",
        "publishing_house_delete_warning": "The publishing house will be deleted! Are you really sure?",
        "publishing_house_added": "Publishing House added successfully",
        "publishing_house_deleted": "Publishing House deleted successfully",
        "publishing_house_updated": "Publishing House updated successfully",
        "author_delete_warning": "The author will be deleted! Are you really sure?",
        "author_added": "Author added successfully",
        "author_deleted": "Author deleted successfully",
        "author_updated": "Author updated successfully",
        "book_level_delete_warning": "The book level will be deleted! Are you really sure?",
        "book_level_added": "Book level added successfully",
        "book_level_deleted": "Book level deleted successfully",
        "book_level_updated": "Book level updated successfully",
        "category_delete_warning": "The category will be deleted! Are you really sure?",
        "category_added": "Category added successfully",
        "category_deleted": "Category deleted successfully",
        "category_updated": "Category updated successfully",
        "delete_subject": "Delete Subject",
        "subject_delete_warning": "The subject will be deleted! Are you really sure?",
        "subject_added": "Subject added successfully",
        "subject_deleted": "Subject deleted successfully",
        "subject_updated": "Subject updated successfully",
        "education_type_delete_warning": "The education type will be deleted! Are you really sure?",
        "education_type_added": "Education type added successfully",
        "education_type_deleted": "Education type deleted successfully",
        "education_type_updated": "Education type updated successfully",
        "education_level_delete_warning": "The education level will be deleted! Are you really sure?",
        "education_level_added": "Education level added successfully",
        "education_level_deleted": "Education level deleted successfully",
        "education_level_updated": "Education level updated successfully",
        "language_delete_warning": "The language will be deleted! Are you really sure?",
        "language_added": "Language added successfully",
        "language_deleted": "Language deleted successfully",
        "language_updated": "Language updated successfully",
        "faq_delete_warning": "The f.a.q. will be deleted! Are you really sure?",
        "faq_added": "Help added successfully",
        "faq_deleted": "Help deleted successfully",
        "faq_updated": "Help updated successfully",

        //daha sonra eklenenler
        "save" : "Save",
        "image" : "Image",
        "upload_image" : "Upload Image",
        "upload_cover_image" : "Upload Cover Image",
        "avatar_delete_warning": "The avatar will be deleted! Are you really sure?",
        "avatar_added": "Avatar added successfully",
        "avatar_deleted": "Avatar deleted successfully",
        "avatar_updated": "Avatar updated successfully",
        "background_delete_warning": "The background will be deleted! Are you really sure?",
        "background_added": "Background added successfully",
        "background_deleted": "Background deleted successfully",
        "background_updated": "Background updated successfully",
        "add_avatar" : "Add Avatar",
        "edit_avatar" : "Edit Avatar",
        "delete_avatar" : "Delete Avatar",
        "add_background" : "Add Background",
        "edit_background" : "Edit Background",
        "delete_background" : "Delete Background",
        "change_image" : "Change Image",
        "language" : "Language",
        "number_of_pages" : "Number of Pages",
        "level" : "Level",
    },
    Türkçe: {
        "id": "Türkçe",
        "are_you_sure": "Emin Misiniz?",
        "add": "Ekle",
        "filter": "Filtre",
        "search": "Ara",
        "continue": "Devam",
        "cancel": "İptal",
        "edit": "Düzenle",
        "delete": "Sil",
        "show_hide_columns": "Tablo sütunlarını göster/gizle",
        "rows_selected": "Seçilen satırlar",
        "delete_selected_rows": "Seçilen satırları sil",
        "order": "Sırala",
        "show_columns": "Sütunları Göster",
        "view_columns": "Sütunları Görüntüle",
        "previous": "Önceki",
        "next": "Sonraki",
        "of": "Arası",
        "showing": "Gösteriliyor",
        "order_for": "için Sırala",
        "no_result_found": "Sonuç Bulunamadı",
        "actions": "İşlemler",
        "regions": "Bölgeler",
        "books": "Kitaplar",
        "library": "Kütüphane",
        "region_name": "Bölge Adı",
        "add_region": "Bölge Ekle",
        "delete_region": "Bölge Sil",
        "edit_region": "Bölge Düzenle",
        "cities": "Şehirler",
        "idd": "Id",
        "city_name": "Şehir Adı",
        "add_city": "Şehir Ekle",
        "delete_city": "Şehir Sil",
        "edit_city": "Şehir Düzenle",
        "ministries": "Bakanlıklar",
        "ministry_name": "Bakanlık Adı",
        "add_ministry": "Bakanlık Ekle",
        "delete_ministry": "Bakanlık Sil",
        "edit_ministry": "Bakanlık Düzenle",
        "directorates": "Müdürlükler",
        "directorate_name": "Müdürlük Adı",
        "add_directorate": "Müdürlük Ekle",
        "delete_directorate": "Müdürlük Sil",
        "edit_directorate": "Müdürlük Düzenle",
        "corporations": "Kurumlar",
        "corporation_name": "Kurum Adı",
        "add_corporation": "Kurum Ekle",
        "delete_corporation": "Kurum Sil",
        "edit_corporation": "Kurum Düzenle",
        "education_types": "Eğitim Türleri",
        "statistics": "İstatistikler",
        "employees": "Çalışanlar",
        "students": "Öğrenciler",
        "readings": "Okumalar",
        "employee_name": "Çalışan Adı",
        "department": "Departman",
        "contact": "İletişim",
        "add_employee": "Çalışan Ekle",
        "delete_employee": "Çalışan Sil",
        "edit_employee": "Çalışan Düzenle",
        "name": "Ad",
        "surname": "Soyad",
        "phone": "Telefon",
        "e_mail": "E-posta",
        "main_department": "Ana Departman",
        "choose": "Seç",
        "authorized_department": "Yetkili Departman",
        "role": "Rol",
        "name_surname": "İsim Soyisim",
        "add_student": "Öğrenci Ekle",
        "delete_student": "Öğrenci Sil",
        "book_levels": "Kitap Seviyeleri",
        "book_level": "Kitap Seviyesi",
        "add_book_level": "Kitap Seviyesi Ekle",
        "delete_book_level": "Kitap Seviyesi Sil",
        "edit_book_level": "Kitap Seviyesi Düzenle",
        "level_name": "Seviye Adı",
        "publishing_houses": "Yayın Evleri",
        "publishing_house_name": "Yayın Evi Adı",
        "add_publishing_house": "Yayın Evi Ekle",
        "delete_publishing_house": "Yayın Evi Sil",
        "edit_publishing_house": "Yayın Evi Düzenle",
        "authors": "Yazarlar",
        "add_author": "Yazar Ekle",
        "delete_author": "Yazar Sil",
        "edit_author": "Yazar Düzenle",
        "education_levels": "Eğitim Seviyeleri",
        "description": "Açıklama",
        "add_education_level": "Eğitim Seviyesi Ekle",
        "delete_education_level": "Eğitim Seviyesi Sil",
        "edit_education_level": "Eğitim Seviyesi Düzenle",
        "education_level_name": "Eğitim Seviyesi Adı",
        "education_type_name": "Eğitim Türü Adı",
        "add_education_type": "Eğitim Türü Ekle",
        "delete_education_type": "Eğitim Türü Sil",
        "edit_education_type": "Eğitim Türü Düzenle",
        "categories": "Kategoriler",
        "category_name": "Kategori Adı",
        "add_category": "Kategori Ekle",
        "delete_category": "Kategori Sil",
        "edit_category": "Kategori Düzenle",
        //settings
        "manage_menus": "Menü Yönetimi",
        "icon": "İkon",
        "menu_name": "Menü Adı",
        "link_type": "Link Türü",
        "active": "Aktif",
        "passive": "Pasif",
        "direct_routing": "Direkt Yönlendirme",
        "add_menu": "Menü Ekle",
        "delete_menu": "Menü Sil",
        "edit_menu": "Menü Düzenle",
        "seo_link": "Seo Linki",
        "folder_path": "Dosya Yolu",
        "publishing_status": "Yayınlama Durumu",
        //
        "manage_departments": "Departman Yönetimi",
        "department_name": "Departman Adı",
        "type": "Tür",
        "access": "Erişim",
        "special_authorization": "Özel Yetki",
        "dashboard": "Gösterge Paneli",
        "action": "İşlem",
        "add_department": "Departman Ekle",
        "delete_department": "Departman Sil",
        "edit_department": "Departman Düzenle",
        "authorize": "Yetki",
        "allowed_pages": "Yetkili Sayfalar",
        "pages": "Sayfalar",
        //
        "notification_name": "Bildirim Adı",
        "title": "Başlık",
        "message": "Mesaj",
        "category": "Kategori",
        "allowed_notifications": "İzin Verilen Bildirimler",
        "sms": "SMS",
        "e_posta": "E-Posta",
        "system_notification": "Sistem Bildirimleri",
        "mobile_notification": "Mobil Bildirimleri",

        //son eklenenler
        "classes": "Classes",
        "avatars": "Avatars",
        "backgrounds": "Backgrounds",
        "notifications": "Notifications",
        "faq": "Help", //sıkça sorulan sorular kısaltması frequently asking questions
        "add_notification": "Add Notification",
        "dropzone_text": "Drag and drop some files here, or click to select files",
        "book_cover": "Book Cover",
        "class_name": "Class Name",
        "updated_successfully": "Updated Successfully",
        "deleted_successfully": "Deleted Successfully",
        "added_successfully": "Added Successfully",
        "region": "Region",
        "corporation": "Corporation",
        "city": "City",
        "ministry": "Ministry",
        "directorate": "Directorate",
        "employee": "Employee",
        "student": "Student",
        "book": "Book",
        "publishing_house": "Publishing House",
        "author": "Author",
        "author_name": "Author Name",
        "education_type": "Education Type",
        "education_level": "Education Level",
        "class": "Class",
        "avatar": "Avatar",
        "background": "Background",
        "menu": "Menu",
        "notification": "Notification",
        "add_class": "Add Class",
        "edit_class": "Edit Class",
        "add_subject": "Add Subject",
        "edit_subject": "Edit Subject",
        "subject_name": "Subject Name",
        "add_book": "Add Book",
        "edit_book": "Edit Book",
        "book_name": "Book Name",
        "subjects": "Subjects",
        "delete_book": "Delete Book",
        "languages": "Languages",
        "language_name": "Language Name",
        "language_code": "Language Code",
        "add_language": "Add Language",
        "edit_language": "Edit Language",
        "delete_language": "Delete Language",
        "edit_student": "Edit Student",
        "question": "Question",
        "answer": "Answer",
        "add_faq": "Add Help",
        "edit_faq": "Edit Help",
        "delete_faq": "Delete Help",
        "listening": "Listening",
        "sound_name": "Sound Name",
        "add_sound": "Add Sound",
        "edit_sound": "Edit Sound",
        "delete_sound": "Delete Sound",
        "sound_file": "Sound File",
        "click_to_upload": "Click to upload sound file",
        "book_level_warning": "You have to choose at least 1 book level!",
        "middle_name": "Middle Name",
        "last_name": "Last Name",
        "assign_teacher": "Assign Teacher",
        "teachers": "Teachers",
        "grade_name": "Grade Name",
        "student_number": "Student Number",
        "gender": "Gender",
        "parents": "Parents",
        "add_parent": "Add Parent",
        "delete_parent": "Delete Parent",
        "edit_parent": "Edit Parent",
        "assign_student": "Assign Student",
        "teacher_pages": "Teacher Pages",
        "region_delete_warning": "The cities,ministries,directorates,corporations under this region and all the data related to those will be deleted! Are you really sure?",
        "region_added": "Region added successfully",
        "region_deleted": "Region deleted successfully",
        "region_updated": "Region updated successfully",
        "city_delete_warning": "The ministries,directorates,corporations under this city and all the data related to those will be deleted! Are you really sure?",
        "city_added": "City added successfully",
        "city_deleted": "City deleted successfully",
        "city_updated": "City updated successfully",
        "ministry_delete_warning": "The directorates,corporations under this ministry and all the data related to those will be deleted! Are you really sure?",
        "ministry_added": "Ministry added successfully",
        "ministry_deleted": "Ministry deleted successfully",
        "ministry_updated": "Ministry updated successfully",
        "directorate_delete_warning": "The corporations under this directorate and all the data related to those will be deleted! Are you really sure?",
        "directorate_added": "Directorate added successfully",
        "directorate_deleted": "Directorate deleted successfully",
        "directorate_updated": "Directorate updated successfully",
        "corporation_delete_warning": "The employees, classes, students under this corporation and all the data related to those will be deleted! Are you really sure?",
        "corporation_added": "Corporation added successfully",
        "corporation_deleted": "Corporation deleted successfully",
        "corporation_updated": "Corporation updated successfully",
        "employee_delete_warning": "The employee will be deleted! Are you really sure?",
        "employee_added": "Employee added successfully",
        "employee_deleted": "Employee deleted successfully",
        "employee_updated": "Employee updated successfully",
        "student_delete_warning": "The student will be deleted! Are you really sure?",
        "student_added": "Student added successfully",
        "student_deleted": "Student deleted successfully",
        "student_updated": "Student updated successfully",
        "parent_delete_warning": "The parent will be deleted! Are you really sure?",
        "parent_added": "Parent added successfully",
        "parent_deleted": "Parent deleted successfully",
        "parent_updated": "Parent updated successfully",
        "student_assigned_to_parent": "Student assigned to parent successfully",
        "delete_class": "Delete Class",
        "class_delete_warning": "The class will be deleted! Are you really sure?",
        "class_added": "Class added successfully",
        "class_deleted": "Class deleted successfully",
        "class_updated": "Class updated successfully",
        "teacher_assigned_to_class": "Teacher assigned to class successfully",
        "publishing_house_delete_warning": "The publishing house will be deleted! Are you really sure?",
        "publishing_house_added": "Publishing House added successfully",
        "publishing_house_deleted": "Publishing House deleted successfully",
        "publishing_house_updated": "Publishing House updated successfully",
        "author_delete_warning": "The author will be deleted! Are you really sure?",
        "author_added": "Author added successfully",
        "author_deleted": "Author deleted successfully",
        "author_updated": "Author updated successfully",
        "book_level_delete_warning": "The book level will be deleted! Are you really sure?",
        "book_level_added": "Book level added successfully",
        "book_level_deleted": "Book level deleted successfully",
        "book_level_updated": "Book level updated successfully",
        "category_delete_warning": "The category will be deleted! Are you really sure?",
        "category_added": "Category added successfully",
        "category_deleted": "Category deleted successfully",
        "category_updated": "Category updated successfully",
        "delete_subject": "Delete Subject",
        "subject_delete_warning": "The subject will be deleted! Are you really sure?",
        "subject_added": "Subject added successfully",
        "subject_deleted": "Subject deleted successfully",
        "subject_updated": "Subject updated successfully",
        "education_type_delete_warning": "The education type will be deleted! Are you really sure?",
        "education_type_added": "Education type added successfully",
        "education_type_deleted": "Education type deleted successfully",
        "education_type_updated": "Education type updated successfully",
        "education_level_delete_warning": "The education level will be deleted! Are you really sure?",
        "education_level_added": "Education level added successfully",
        "education_level_deleted": "Education level deleted successfully",
        "education_level_updated": "Education level updated successfully",
        "language_delete_warning": "The language will be deleted! Are you really sure?",
        "language_added": "Language added successfully",
        "language_deleted": "Language deleted successfully",
        "language_updated": "Language updated successfully",
        "faq_delete_warning": "The f.a.q. will be deleted! Are you really sure?",
        "faq_added": "Help added successfully",
        "faq_deleted": "Help deleted successfully",
        "faq_updated": "Help updated successfully",
    },
    عربي: {
        "id": "عربي",
        "are_you_sure": "هل أنت متأكد?",
        "add": "يضيف",
        "filter": "منقي",
        "search": "بحث",
        "continue": "استمر",
        "cancel": "إلغاء",
        "edit": "تعديل",
        "delete": "حذف",
        "show_hide_columns": "إظهار إخفاء الأعمدة",
        "rows_selected": "الصفوف المختارة",
        "delete_selected_rows": "حذف الصفوف المختارة",
        "order": "طلب",
        "show_columns": "عرض الأعمدة",
        "view_columns": "عرض الأعمدة",
        "previous": "السابق",
        "next": "التالي",
        "of": "من",
        "showing": "عرض",
        "order_for": "طلب من أجل",
        "no_result_found": "طلب العثور على أي نتيجة ل",
        "actions": "أجراءات",
        "regions": "المناطق",
        "books": "الكتب",
        "library": "مكتبة",
        "region_name": "اسم المنطقة",
        "add_region": "أضف المنطقة",
        "delete_region": "حذف المنطقة",
        "edit_region": "تحرير المنطقة",
        "cities": "مدن",
        "idd": "Id",
        "city_name": "اسم المدينة",
        "add_city": "أضف مدينة",
        "delete_city": "حذف المدينة",
        "edit_city": "تحرير المدينة",
        "ministries": "الوزارات",
        "ministry_name": "اسم الوزارة",
        "add_ministry": "إضافة وزارة",
        "delete_ministry": "حذف الوزارة",
        "edit_ministry": "تعديل الوزارة",
        "directorates": "المديريات",
        "directorate_name": "اسم المديرية",
        "add_directorate": "إضافة مديرية",
        "delete_directorate": "حذف المديرية",
        "edit_directorate": "تحرير المديرية",
        "corporations": "الشركات",
        "corporation_name": "اسم الشركة",
        "add_corporation": "إضافة شركة",
        "delete_corporation": "حذف الشركة",
        "edit_corporation": "تحرير شركة",
        "education_types": "أنواع التعليم",
        "statistics": "الإحصاء",
        "employees": "الموظفين",
        "students": "الطلاب",
        "readings": "قراءة٪ s",
        "employee_name": "اسم الموظف",
        "department": "قسم، أقسام",
        "contact": "اتصل",
        "add_employee": "إضافة موظف",
        "delete_employee": "حذف الموظف",
        "edit_employee": "تعديل الموظف",
        "name": "اسم",
        "surname": "لقب",
        "phone": "هاتف",
        "e_mail": "البريد الإلكتروني",
        "main_department": "القسم الرئيسي",
        "choose": "أختر",
        "authorized_department": "القسم المعتمد",
        "role": "وظيفة",
        "name_surname": "اسم اللقب",
        "add_student": "اضافة طالب",
        "delete_student": "حذف الطالب",
        "book_levels": "مستويات الكتاب",
        "book_level": "مستوى الكتاب",
        "add_book_level": "إضافة مستوى الكتاب",
        "delete_book_level": "حذف مستوى الكتاب",
        "edit_book_level": "تحرير مستوى الكتاب",
        "level_name": "اسم المستوى",
        "publishing_houses": "انشاء المنازل",
        "publishing_house_name": "اسم دار النشر",
        "add_publishing_house": "اضافة دار نشر",
        "delete_publishing_house": "حذف دار النشر",
        "edit_publishing_house": "دار نشر إيديتا",
        "authors": "المؤلفون",
        "add_author": "إضافة مؤلف",
        "delete_author": "حذف المؤلف",
        "edit_author": "تحرير المؤلف",
        "education_levels": "مستويات التعليم",
        "description": "وصف",
        "add_education_level": "أضف مستوى التعليم",
        "delete_education_level": "حذف مستوى التعليم",
        "edit_education_level": "تحرير مستوى التعليم",
        "education_level_name": "اسم مستوى التعليم",
        "education_type_name": "اسم نوع التعليم",
        "add_education_type": "أضف نوع التعليم",
        "delete_education_type": "حذف نوع التعليم",
        "edit_education_type": "تحرير نوع التعليم",
        "categories": "التصنيفات",
        "category_name": "اسم التصنيف",
        "add_category": "إضافة فئة",
        "delete_category": "حذف الفئة",
        "edit_category": "تحرير الفئة",
        //settings
        "manage_menus": "إدارة القوائم",
        "icon": "أيقونة",
        "menu_name": "اسم القائمة",
        "link_type": "نوع الارتباط",
        "active": "نشيط",
        "passive": "سلبي",
        "direct_routing": "التوجيه المباشر",
        "add_menu": "إضافة قائمة",
        "delete_menu": "قائمة الحذف",
        "edit_menu": "عدل القائمة",
        "seo_link": "رابط سيو",
        "folder_path": "مسار الملف",
        "publishing_status": "حالة النشر",
        //
        "manage_departments": "إدارة الأقسام",
        "department_name": "اسم القسم",
        "type": "نوع",
        "access": "التمكن من",
        "special_authorization": "إذن خاص",
        "dashboard": "لوحة القيادة",
        "action": "عمل",
        "add_department": "إضافة قسم",
        "delete_department": "حذف القسم",
        "edit_department": "قسم التحرير",
        "authorize": "يأذن",
        "allowed_pages": "الصفحات المسموح بها",
        "pages": "الصفحات",
        //
        "notification_name": "اسم الإخطار",
        "title": "لقب",
        "message": "رسالة",
        "category": "الفئة",
        "allowed_notifications": "الإخطارات المسموح بها",
        "sms": "SMS",
        "e_posta": "إي بوستا",
        "system_notification": "إخطار النظام",
        "mobile_notification": "إخطار المحمول",

        //son eklenenler
        "classes": "Classes",
        "avatars": "Avatars",
        "backgrounds": "Backgrounds",
        "notifications": "Notifications",
        "faq": "Help", //sıkça sorulan sorular kısaltması frequently asking questions
        "add_notification": "Add Notification",
        "dropzone_text": "Drag and drop some files here, or click to select files",
        "book_cover": "Book Cover",
        "class_name": "Class Name",
        "updated_successfully": "Updated Successfully",
        "deleted_successfully": "Deleted Successfully",
        "added_successfully": "Added Successfully",
        "region": "Region",
        "corporation": "Corporation",
        "city": "City",
        "ministry": "Ministry",
        "directorate": "Directorate",
        "employee": "Employee",
        "student": "Student",
        "book": "Book",
        "publishing_house": "Publishing House",
        "author": "Author",
        "author_name": "Author Name",
        "education_type": "Education Type",
        "education_level": "Education Level",
        "class": "Class",
        "avatar": "Avatar",
        "background": "Background",
        "menu": "Menu",
        "notification": "Notification",
        "add_class": "Add Class",
        "edit_class": "Edit Class",
        "add_subject": "Add Subject",
        "edit_subject": "Edit Subject",
        "subject_name": "Subject Name",
        "add_book": "Add Book",
        "edit_book": "Edit Book",
        "book_name": "Book Name",
        "subjects": "Subjects",
        "delete_book": "Delete Book",
        "languages": "Languages",
        "language_name": "Language Name",
        "language_code": "Language Code",
        "add_language": "Add Language",
        "edit_language": "Edit Language",
        "delete_language": "Delete Language",
        "edit_student": "Edit Student",
        "question": "Question",
        "answer": "Answer",
        "add_faq": "Add Help",
        "edit_faq": "Edit Help",
        "delete_faq": "Delete Help",
        "listening": "Listening",
        "sound_name": "Sound Name",
        "add_sound": "Add Sound",
        "edit_sound": "Edit Sound",
        "delete_sound": "Delete Sound",
        "sound_file": "Sound File",
        "click_to_upload": "Click to upload sound file",
        "book_level_warning": "You have to choose at least 1 book level!",
        "middle_name": "Middle Name",
        "last_name": "Last Name",
        "assign_teacher": "Assign Teacher",
        "teachers": "Teachers",
        "grade_name": "Grade Name",
        "student_number": "Student Number",
        "gender": "Gender",
        "parents": "Parents",
        "add_parent": "Add Parent",
        "delete_parent": "Delete Parent",
        "edit_parent": "Edit Parent",
        "assign_student": "Assign Student",
        "teacher_pages": "Teacher Pages",
        "region_delete_warning": "The cities,ministries,directorates,corporations under this region and all the data related to those will be deleted! Are you really sure?",
        "region_added": "Region added successfully",
        "region_deleted": "Region deleted successfully",
        "region_updated": "Region updated successfully",
        "city_delete_warning": "The ministries,directorates,corporations under this city and all the data related to those will be deleted! Are you really sure?",
        "city_added": "City added successfully",
        "city_deleted": "City deleted successfully",
        "city_updated": "City updated successfully",
        "ministry_delete_warning": "The directorates,corporations under this ministry and all the data related to those will be deleted! Are you really sure?",
        "ministry_added": "Ministry added successfully",
        "ministry_deleted": "Ministry deleted successfully",
        "ministry_updated": "Ministry updated successfully",
        "directorate_delete_warning": "The corporations under this directorate and all the data related to those will be deleted! Are you really sure?",
        "directorate_added": "Directorate added successfully",
        "directorate_deleted": "Directorate deleted successfully",
        "directorate_updated": "Directorate updated successfully",
        "corporation_delete_warning": "The employees, classes, students under this corporation and all the data related to those will be deleted! Are you really sure?",
        "corporation_added": "Corporation added successfully",
        "corporation_deleted": "Corporation deleted successfully",
        "corporation_updated": "Corporation updated successfully",
        "employee_delete_warning": "The employee will be deleted! Are you really sure?",
        "employee_added": "Employee added successfully",
        "employee_deleted": "Employee deleted successfully",
        "employee_updated": "Employee updated successfully",
        "student_delete_warning": "The student will be deleted! Are you really sure?",
        "student_added": "Student added successfully",
        "student_deleted": "Student deleted successfully",
        "student_updated": "Student updated successfully",
        "parent_delete_warning": "The parent will be deleted! Are you really sure?",
        "parent_added": "Parent added successfully",
        "parent_deleted": "Parent deleted successfully",
        "parent_updated": "Parent updated successfully",
        "student_assigned_to_parent": "Student assigned to parent successfully",
        "delete_class": "Delete Class",
        "class_delete_warning": "The class will be deleted! Are you really sure?",
        "class_added": "Class added successfully",
        "class_deleted": "Class deleted successfully",
        "class_updated": "Class updated successfully",
        "teacher_assigned_to_class": "Teacher assigned to class successfully",
        "publishing_house_delete_warning": "The publishing house will be deleted! Are you really sure?",
        "publishing_house_added": "Publishing House added successfully",
        "publishing_house_deleted": "Publishing House deleted successfully",
        "publishing_house_updated": "Publishing House updated successfully",
        "author_delete_warning": "The author will be deleted! Are you really sure?",
        "author_added": "Author added successfully",
        "author_deleted": "Author deleted successfully",
        "author_updated": "Author updated successfully",
        "book_level_delete_warning": "The book level will be deleted! Are you really sure?",
        "book_level_added": "Book level added successfully",
        "book_level_deleted": "Book level deleted successfully",
        "book_level_updated": "Book level updated successfully",
        "category_delete_warning": "The category will be deleted! Are you really sure?",
        "category_added": "Category added successfully",
        "category_deleted": "Category deleted successfully",
        "category_updated": "Category updated successfully",
        "delete_subject": "Delete Subject",
        "subject_delete_warning": "The subject will be deleted! Are you really sure?",
        "subject_added": "Subject added successfully",
        "subject_deleted": "Subject deleted successfully",
        "subject_updated": "Subject updated successfully",
        "education_type_delete_warning": "The education type will be deleted! Are you really sure?",
        "education_type_added": "Education type added successfully",
        "education_type_deleted": "Education type deleted successfully",
        "education_type_updated": "Education type updated successfully",
        "education_level_delete_warning": "The education level will be deleted! Are you really sure?",
        "education_level_added": "Education level added successfully",
        "education_level_deleted": "Education level deleted successfully",
        "education_level_updated": "Education level updated successfully",
        "language_delete_warning": "The language will be deleted! Are you really sure?",
        "language_added": "Language added successfully",
        "language_deleted": "Language deleted successfully",
        "language_updated": "Language updated successfully",
        "faq_delete_warning": "The f.a.q. will be deleted! Are you really sure?",
        "faq_added": "F.A.Q. added successfully",
        "faq_deleted": "F.A.Q. deleted successfully",
        "faq_updated": "F.A.Q. updated successfully",
    },
    بادینی: {
        "id": "بادینی",
        "are_you_sure": "Emin Misiniz?",
        "add": "Ekle",
        "filter": "Filtre",
        "search": "Ara",
        "continue": "Devam",
        "cancel": "İptal",
        "edit": "Düzenle",
        "delete": "Sil",
        "show_hide_columns": "Tablo sütunlarını göster/gizle",
        "rows_selected": "Seçilen satırlar",
        "delete_selected_rows": "Seçilen satırları sil",
        "order": "Sırala",
        "show_columns": "Sütunları Göster",
        "view_columns": "Sütunları Görüntüle",
        "previous": "Önceki",
        "next": "Sonraki",
        "of": "Arası",
        "showing": "Gösteriliyor",
        "order_for": "için Sırala",
        "no_result_found": "Sonuç Bulunamadı",
        "actions": "İşlemler",
        "regions": "Bölgeler",
        "books": "Kitaplar",
        "library": "Kütüphane",
        "region_name": "Bölge Adı",
        "add_region": "Bölge Ekle",
        "delete_region": "Bölge Sil",
        "edit_region": "Bölge Düzenle",
        "cities": "Şehirler",
        "idd": "Id",
        "city_name": "Şehir Adı",
        "add_city": "Şehir Ekle",
        "delete_city": "Şehir Sil",
        "edit_city": "Şehir Düzenle",
        "ministries": "Bakanlıklar",
        "ministry_name": "Bakanlık Adı",
        "add_ministry": "Bakanlık Ekle",
        "delete_ministry": "Bakanlık Sil",
        "edit_ministry": "Bakanlık Düzenle",
        "directorates": "Müdürlükler",
        "directorate_name": "Müdürlük Adı",
        "add_directorate": "Müdürlük Ekle",
        "delete_directorate": "Müdürlük Sil",
        "edit_directorate": "Müdürlük Düzenle",
        "corporations": "Kurumlar",
        "corporation_name": "Kurum Adı",
        "add_corporation": "Kurum Ekle",
        "delete_corporation": "Kurum Sil",
        "edit_corporation": "Kurum Düzenle",
        "education_types": "Eğitim Türleri",
        "statistics": "İstatistikler",
        "employees": "Çalışanlar",
        "students": "Öğrenciler",
        "readings": "Okumalar",
        "employee_name": "Çalışan Adı",
        "department": "Departman",
        "contact": "İletişim",
        "add_employee": "Çalışan Ekle",
        "delete_employee": "Çalışan Sil",
        "edit_employee": "Çalışan Düzenle",
        "name": "Ad",
        "surname": "Soyad",
        "phone": "Telefon",
        "e_mail": "E-posta",
        "main_department": "Ana Departman",
        "choose": "Seç",
        "authorized_department": "Yetkili Departman",
        "role": "Rol",
        "name_surname": "İsim Soyisim",
        "add_student": "Öğrenci Ekle",
        "delete_student": "Öğrenci Sil",
        "book_levels": "Kitap Seviyeleri",
        "book_level": "Kitap Seviyesi",
        "add_book_level": "Kitap Seviyesi Ekle",
        "delete_book_level": "Kitap Seviyesi Sil",
        "edit_book_level": "Kitap Seviyesi Düzenle",
        "level_name": "Seviye Adı",
        "publishing_houses": "Yayın Evleri",
        "publishing_house_name": "Yayın Evi Adı",
        "add_publishing_house": "Yayın Evi Ekle",
        "delete_publishing_house": "Yayın Evi Sil",
        "edit_publishing_house": "Yayın Evi Düzenle",
        "authors": "Yazarlar",
        "add_author": "Yazar Ekle",
        "delete_author": "Yazar Sil",
        "edit_author": "Yazar Düzenle",
        "education_levels": "Eğitim Seviyeleri",
        "description": "Açıklama",
        "add_education_level": "Eğitim Seviyesi Ekle",
        "delete_education_level": "Eğitim Seviyesi Sil",
        "edit_education_level": "Eğitim Seviyesi Düzenle",
        "education_level_name": "Eğitim Seviyesi Adı",
        "education_type_name": "Eğitim Türü Adı",
        "add_education_type": "Eğitim Türü Ekle",
        "delete_education_type": "Eğitim Türü Sil",
        "edit_education_type": "Eğitim Türü Düzenle",
        "categories": "Kategoriler",
        "category_name": "Kategori Adı",
        "add_category": "Kategori Ekle",
        "delete_category": "Kategori Sil",
        "edit_category": "Kategori Düzenle",
        //settings
        "manage_menus": "Menü Yönetimi",
        "icon": "İkon",
        "menu_name": "Menü Adı",
        "link_type": "Link Türü",
        "active": "Aktif",
        "passive": "Pasif",
        "direct_routing": "Direkt Yönlendirme",
        "add_menu": "Menü Ekle",
        "delete_menu": "Menü Sil",
        "edit_menu": "Menü Düzenle",
        "seo_link": "Seo Linki",
        "folder_path": "Dosya Yolu",
        "publishing_status": "Yayınlama Durumu",
        //
        "manage_departments": "Departman Yönetimi",
        "department_name": "Departman Adı",
        "type": "Tür",
        "access": "Erişim",
        "special_authorization": "Özel Yetki",
        "dashboard": "Dashboard",
        "action": "İşlem",
        "add_department": "Departman Ekle",
        "delete_department": "Departman Sil",
        "edit_department": "Departman Düzenle",
        "authorize": "Yetki",
        "allowed_pages": "Yetkili Sayfalar",
        "pages": "Sayfalar",
        //
        "notification_name": "Bildirim Adı",
        "title": "Başlık",
        "message": "Mesaj",
        "category": "Kategori",
        "allowed_notifications": "İzin Verilen Bildirimler",
        "sms": "SMS",
        "e_posta": "E-Posta",
        "system_notification": "Sistem Bildirimleri",
        "mobile_notification": "Mobil Bildirimleri",

        //son eklenenler
        "classes": "Classes",
        "avatars": "Avatars",
        "backgrounds": "Backgrounds",
        "notifications": "Notifications",
        "faq": "F.A.Q.", //sıkça sorulan sorular kısaltması frequently asking questions
        "add_notification": "Add Notification",
        "dropzone_text": "Drag and drop some files here, or click to select files",
        "book_cover": "Book Cover",
        "class_name": "Class Name",
        "updated_successfully": "Updated Successfully",
        "deleted_successfully": "Deleted Successfully",
        "added_successfully": "Added Successfully",
        "region": "Region",
        "corporation": "Corporation",
        "city": "City",
        "ministry": "Ministry",
        "directorate": "Directorate",
        "employee": "Employee",
        "student": "Student",
        "book": "Book",
        "publishing_house": "Publishing House",
        "author": "Author",
        "author_name": "Author Name",
        "education_type": "Education Type",
        "education_level": "Education Level",
        "class": "Class",
        "avatar": "Avatar",
        "background": "Background",
        "menu": "Menu",
        "notification": "Notification",
        "add_class": "Add Class",
        "edit_class": "Edit Class",
        "add_subject": "Add Subject",
        "edit_subject": "Edit Subject",
        "subject_name": "Subject Name",
        "add_book": "Add Book",
        "edit_book": "Edit Book",
        "book_name": "Book Name",
        "subjects": "Subjects",
        "delete_book": "Delete Book",
        "languages": "Languages",
        "language_name": "Language Name",
        "language_code": "Language Code",
        "add_language": "Add Language",
        "edit_language": "Edit Language",
        "delete_language": "Delete Language",
        "edit_student": "Edit Student",
        "question": "Question",
        "answer": "Answer",
        "add_faq": "Add Help",
        "edit_faq": "Edit Help",
        "delete_faq": "Delete Help",
        "listening": "Listening",
        "sound_name": "Sound Name",
        "add_sound": "Add Sound",
        "edit_sound": "Edit Sound",
        "delete_sound": "Delete Sound",
        "sound_file": "Sound File",
        "click_to_upload": "Click to upload sound file",
        "book_level_warning": "You have to choose at least 1 book level!",
        "middle_name": "Middle Name",
        "last_name": "Last Name",
        "assign_teacher": "Assign Teacher",
        "teachers": "Teachers",
        "grade_name": "Grade Name",
        "student_number": "Student Number",
        "gender": "Gender",
        "parents": "Parents",
        "add_parent": "Add Parent",
        "delete_parent": "Delete Parent",
        "edit_parent": "Edit Parent",
        "assign_student": "Assign Student",
        "teacher_pages": "Teacher Pages",
        "region_delete_warning": "The cities,ministries,directorates,corporations under this region and all the data related to those will be deleted! Are you really sure?",
        "region_added": "Region added successfully",
        "region_deleted": "Region deleted successfully",
        "region_updated": "Region updated successfully",
        "city_delete_warning": "The ministries,directorates,corporations under this city and all the data related to those will be deleted! Are you really sure?",
        "city_added": "City added successfully",
        "city_deleted": "City deleted successfully",
        "city_updated": "City updated successfully",
        "ministry_delete_warning": "The directorates,corporations under this ministry and all the data related to those will be deleted! Are you really sure?",
        "ministry_added": "Ministry added successfully",
        "ministry_deleted": "Ministry deleted successfully",
        "ministry_updated": "Ministry updated successfully",
        "directorate_delete_warning": "The corporations under this directorate and all the data related to those will be deleted! Are you really sure?",
        "directorate_added": "Directorate added successfully",
        "directorate_deleted": "Directorate deleted successfully",
        "directorate_updated": "Directorate updated successfully",
        "corporation_delete_warning": "The employees, classes, students under this corporation and all the data related to those will be deleted! Are you really sure?",
        "corporation_added": "Corporation added successfully",
        "corporation_deleted": "Corporation deleted successfully",
        "corporation_updated": "Corporation updated successfully",
        "employee_delete_warning": "The employee will be deleted! Are you really sure?",
        "employee_added": "Employee added successfully",
        "employee_deleted": "Employee deleted successfully",
        "employee_updated": "Employee updated successfully",
        "student_delete_warning": "The student will be deleted! Are you really sure?",
        "student_added": "Student added successfully",
        "student_deleted": "Student deleted successfully",
        "student_updated": "Student updated successfully",
        "parent_delete_warning": "The parent will be deleted! Are you really sure?",
        "parent_added": "Parent added successfully",
        "parent_deleted": "Parent deleted successfully",
        "parent_updated": "Parent updated successfully",
        "student_assigned_to_parent": "Student assigned to parent successfully",
        "delete_class": "Delete Class",
        "class_delete_warning": "The class will be deleted! Are you really sure?",
        "class_added": "Class added successfully",
        "class_deleted": "Class deleted successfully",
        "class_updated": "Class updated successfully",
        "teacher_assigned_to_class": "Teacher assigned to class successfully",
        "publishing_house_delete_warning": "The publishing house will be deleted! Are you really sure?",
        "publishing_house_added": "Publishing House added successfully",
        "publishing_house_deleted": "Publishing House deleted successfully",
        "publishing_house_updated": "Publishing House updated successfully",
        "author_delete_warning": "The author will be deleted! Are you really sure?",
        "author_added": "Author added successfully",
        "author_deleted": "Author deleted successfully",
        "author_updated": "Author updated successfully",
        "book_level_delete_warning": "The book level will be deleted! Are you really sure?",
        "book_level_added": "Book level added successfully",
        "book_level_deleted": "Book level deleted successfully",
        "book_level_updated": "Book level updated successfully",
        "category_delete_warning": "The category will be deleted! Are you really sure?",
        "category_added": "Category added successfully",
        "category_deleted": "Category deleted successfully",
        "category_updated": "Category updated successfully",
        "delete_subject": "Delete Subject",
        "subject_delete_warning": "The subject will be deleted! Are you really sure?",
        "subject_added": "Subject added successfully",
        "subject_deleted": "Subject deleted successfully",
        "subject_updated": "Subject updated successfully",
        "education_type_delete_warning": "The education type will be deleted! Are you really sure?",
        "education_type_added": "Education type added successfully",
        "education_type_deleted": "Education type deleted successfully",
        "education_type_updated": "Education type updated successfully",
        "education_level_delete_warning": "The education level will be deleted! Are you really sure?",
        "education_level_added": "Education level added successfully",
        "education_level_deleted": "Education level deleted successfully",
        "education_level_updated": "Education level updated successfully",
        "language_delete_warning": "The language will be deleted! Are you really sure?",
        "language_added": "Language added successfully",
        "language_deleted": "Language deleted successfully",
        "language_updated": "Language updated successfully",
        "faq_delete_warning": "The f.a.q. will be deleted! Are you really sure?",
        "faq_added": "F.A.Q. added successfully",
        "faq_deleted": "F.A.Q. deleted successfully",
        "faq_updated": "F.A.Q. updated successfully",
    },
    سۆرانی: {
        "id": "سۆرانی",
        "are_you_sure": "ئایا دڵنیایت",
        "add": "زیادکردن",
        "filter": "پاڵێو",
        "search": "گەڕان",
        "continue": "بەردەوامبوون",
        "cancel": "هەڵوەشاندنەوه",
        "edit": "بژارکردن",
        "delete": "سڕینەوە",
        "show_hide_columns": "پیشاندانی شاردنەوەی ستوونەکان",
        "rows_selected": "ڕیزە ئاسۆییەکان دەستنیشانکراون",
        "delete_selected_rows": "سڕینەوەی ڕیزە دیاریکراوەکان",
        "order": "فەرمان",
        "show_columns": "ستوونەکان نیشان بدە",
        "view_columns": "پیشاندانی ستوونەکان",
        "previous": "پێشوو",
        "next": "دواتر",
        "of": "لە",
        "showing": "پیشاندان",
        "order_for": "داواکردن بۆ",
        "no_result_found": "هیچ ئەنجامێک نەدۆزرایەوە",
        "actions": "کارەکان",
        "regions": "ناوچەکان",
        "books": "کتێب",
        "library": "کتێبخانە",
        "region_name": "ناوی هەرێم",
        "add_region": "زیادکردنی هەرێم",
        "delete_region": "هەرێم بسڕەوە",
        "edit_region": "بژارکردنی هەرێم",
        "cities": "شارەکان",
        "idd": "پێناس",
        "city_name": "ناوی شار",
        "add_city": "زێدەکردنی شار",
        "delete_city": "شار بسڕەوە",
        "edit_city": "بژارکردنی شار",
        "ministries": "وەزارەتەکان",
        "ministry_name": "ناوی وەزارەت",
        "add_ministry": "وەزارەتی زیادبکە",
        "delete_ministry": "سڕینەوەی وەزارەت",
        "edit_ministry": "بژارکردنی وەزارەت",
        "directorates": "بەڕێوەبەرەکان",
        "directorate_name": "ناوی بەڕێوبەرایەتی",
        "add_directorate": "زیادکردنی بەڕێوەبەرایەتی",
        "delete_directorate": "سڕینەوەی بەڕێوەبەرایەتی",
        "edit_directorate": "بژارکردنی بەڕێوەبەرایەتی",
        "corporations": "کۆمپانیا",
        "corporation_name": "ناوی کۆمپانیا",
        "add_corporation": "زیادکردنی کۆمپانیا",
        "delete_corporation": "سڕینەوەی کۆمپانیا",
        "edit_corporation": "کۆمپانیای دیت",
        "education_types": "جۆرەکانی پەروەردە",
        "statistics": "ئامار",
        "employees": "کارمەندەکان",
        "students": "قوتابی",
        "readings": "خوێندنەوە",
        "employee_name": "ناوی کارمەند",
        "department": "بەش",
        "contact": "بەردەنگ",
        "add_employee": "زیادکردنی فەرمانبەر",
        "delete_employee": "فەرمانبەر بسڕەوە",
        "edit_employee": "بژارکردنی کارمەند",
        "name": "ناو",
        "surname": "پاشناو",
        "phone": "تەلەفۆن",
        "e_mail": "ئیمەیڵ",
        "main_department": "بەشی سەرەکی",
        "choose": "هەڵبژاردن",
        "authorized_department": "بەشی ڕێگەپێدراو",
        "role": "ڕۆڵ",
        "name_surname": "ناوی پاشناو",
        "add_student": "خوێندکار زیاد بکە",
        "delete_student": "سڕینەوەی قوتابی",
        "book_levels": "ئاستەکانی کتێب",
        "book_level": "ئاستی کتێب",
        "add_book_level": "ئاستی کتێب زیاد بکە",
        "delete_book_level": "سڕینەوەی ئاستی کتێب",
        "edit_book_level": "بژارکردنی ئاستی کتێب",
        "level_name": "ناوی ئاست",
        "publishing_houses": "خانوەکانی بڵاوکردنەوە",
        "publishing_house_name": "ناوی خانەی بڵاوکردنەوە",
        "add_publishing_house": "زیادکردنی خانەی بڵاوکردنەوە",
        "delete_publishing_house": "خانەی بڵاوکردنەوە بسڕەوە",
        "edit_publishing_house": "بڵاوکردنەوەی خانەی بڵاوکردنەوە",
        "authors": "نووسەران",
        "add_author": "زیادکردنی نووسەر",
        "delete_author": "سڕینەوەی نووسەر",
        "edit_author": "بژارکردنی نووسەر",
        "education_levels": "ئاستی پەروەردە",
        "description": "وەسف",
        "add_education_level": "ئاستی پەروەردە زیاد بکە",
        "delete_education_level": "سڕینەوەی ئاستی خوێندن",
        "edit_education_level": "بژارکردنی ئاستی پەروەردە",
        "education_level_name": "ناوی ئاستی پەروەردە",
        "education_type_name": "ناوی جۆری پەروەردە",
        "add_education_type": "زیادکردنی جۆری پەروەردە",
        "delete_education_type": "سڕینەوەی جۆری پەروەردە",
        "edit_education_type": "دەستکاری جۆری پەروەردە بکە",
        "categories": "هاوپۆلەکان",
        "category_name": "ناوی هاوپۆل",
        "add_category": "هاوپۆل زیاد بکە",
        "delete_category": "سڕینەوەی هاوپۆل",
        "edit_category": "بژارکردنی پۆلێن",
        //settings
        "manage_menus": "بەڕێوەبردنی لیستەکان",
        "icon": "ئایکۆن",
        "menu_name": "ناوی پێڕست",
        "link_type": "جۆری بەستەر",
        "active": "چالاک",
        "passive": "پاسیڤ",
        "direct_routing": "ڕاکردنێکی ڕاستەوخۆ",
        "add_menu": "پێڕست زیاد بکە",
        "delete_menu": "سڕینەوەی پێڕست",
        "edit_menu": "بژارکردنی پێڕست",
        "seo_link": "سیۆ لینکی",
        "folder_path": "ڕێڕەوی فۆڵدەر",
        "publishing_status": "ڕەوشی بڵاوکردنەوە",
        //
        "manage_departments": "بەڕێوەبردنی بەشەکان",
        "department_name": "ناوی بەش",
        "type": "جۆر",
        "access": "دەستگەیشتن",
        "special_authorization": "ڕێگەپێدانێکی تایبەت",
        "dashboard": "داشبۆرد",
        "action": "کردار",
        "add_department": "زیادکردنی بەش",
        "delete_department": "بەشی بسڕەوە",
        "edit_department": "بژارکردنی بەش",
        "authorize": "ڕێپێدان",
        "allowed_pages": "پەڕە ڕێگەپێدراوەکان",
        "pages": "پەڕەکان",
        //
        "notification_name": "ناوی ئاگانامە",
        "title": "ناونیشان",
        "message": "نامە",
        "category": "پۆلێن",
        "allowed_notifications": "ئاگانامەکانی ڕێگەپێدراو",
        "sms": "SMS",
        "e_posta": "ئی پۆستا",
        "system_notification": "ئاگانامەی سیستەم",
        "mobile_notification": "ئاگانامەی مۆبایل",

        //son eklenenler
        "classes": "Classes",
        "avatars": "Avatars",
        "backgrounds": "Backgrounds",
        "notifications": "Notifications",
        "faq": "F.A.Q.", //sıkça sorulan sorular kısaltması frequently asking questions
        "add_notification": "Add Notification",
        "dropzone_text": "Drag and drop some files here, or click to select files",
        "book_cover": "Book Cover",
        "class_name": "Class Name",
        "updated_successfully": "Updated Successfully",
        "deleted_successfully": "Deleted Successfully",
        "added_successfully": "Added Successfully",
        "region": "Region",
        "corporation": "Corporation",
        "city": "City",
        "ministry": "Ministry",
        "directorate": "Directorate",
        "employee": "Employee",
        "student": "Student",
        "book": "Book",
        "publishing_house": "Publishing House",
        "author": "Author",
        "author_name": "Author Name",
        "education_type": "Education Type",
        "education_level": "Education Level",
        "class": "Class",
        "avatar": "Avatar",
        "background": "Background",
        "menu": "Menu",
        "notification": "Notification",
        "add_class": "Add Class",
        "edit_class": "Edit Class",
        "add_subject": "Add Subject",
        "edit_subject": "Edit Subject",
        "subject_name": "Subject Name",
        "add_book": "Add Book",
        "edit_book": "Edit Book",
        "book_name": "Book Name",
        "subjects": "Subjects",
        "delete_book": "Delete Book",
        "languages": "Languages",
        "language_name": "Language Name",
        "language_code": "Language Code",
        "add_language": "Add Language",
        "edit_language": "Edit Language",
        "delete_language": "Delete Language",
        "edit_student": "Edit Student",
        "question": "Question",
        "answer": "Answer",
        "add_faq": "Add Help",
        "edit_faq": "Edit Help",
        "delete_faq": "Delete Help",
        "listening": "Listening",
        "sound_name": "Sound Name",
        "add_sound": "Add Sound",
        "edit_sound": "Edit Sound",
        "delete_sound": "Delete Sound",
        "sound_file": "Sound File",
        "click_to_upload": "Click to upload sound file",
        "book_level_warning": "You have to choose at least 1 book level!",
        "middle_name": "Middle Name",
        "last_name": "Last Name",
        "assign_teacher": "Assign Teacher",
        "teachers": "Teachers",
        "grade_name": "Grade Name",
        "student_number": "Student Number",
        "gender": "Gender",
        "parents": "Parents",
        "add_parent": "Add Parent",
        "delete_parent": "Delete Parent",
        "edit_parent": "Edit Parent",
        "assign_student": "Assign Student",
        "teacher_pages": "Teacher Pages",
        "region_delete_warning": "The cities,ministries,directorates,corporations under this region and all the data related to those will be deleted! Are you really sure?",
        "region_added": "Region added successfully",
        "region_deleted": "Region deleted successfully",
        "region_updated": "Region updated successfully",
        "city_delete_warning": "The ministries,directorates,corporations under this city and all the data related to those will be deleted! Are you really sure?",
        "city_added": "City added successfully",
        "city_deleted": "City deleted successfully",
        "city_updated": "City updated successfully",
        "ministry_delete_warning": "The directorates,corporations under this ministry and all the data related to those will be deleted! Are you really sure?",
        "ministry_added": "Ministry added successfully",
        "ministry_deleted": "Ministry deleted successfully",
        "ministry_updated": "Ministry updated successfully",
        "directorate_delete_warning": "The corporations under this directorate and all the data related to those will be deleted! Are you really sure?",
        "directorate_added": "Directorate added successfully",
        "directorate_deleted": "Directorate deleted successfully",
        "directorate_updated": "Directorate updated successfully",
        "corporation_delete_warning": "The employees, classes, students under this corporation and all the data related to those will be deleted! Are you really sure?",
        "corporation_added": "Corporation added successfully",
        "corporation_deleted": "Corporation deleted successfully",
        "corporation_updated": "Corporation updated successfully",
        "employee_delete_warning": "The employee will be deleted! Are you really sure?",
        "employee_added": "Employee added successfully",
        "employee_deleted": "Employee deleted successfully",
        "employee_updated": "Employee updated successfully",
        "student_delete_warning": "The student will be deleted! Are you really sure?",
        "student_added": "Student added successfully",
        "student_deleted": "Student deleted successfully",
        "student_updated": "Student updated successfully",
        "parent_delete_warning": "The parent will be deleted! Are you really sure?",
        "parent_added": "Parent added successfully",
        "parent_deleted": "Parent deleted successfully",
        "parent_updated": "Parent updated successfully",
        "student_assigned_to_parent": "Student assigned to parent successfully",
        "delete_class": "Delete Class",
        "class_delete_warning": "The class will be deleted! Are you really sure?",
        "class_added": "Class added successfully",
        "class_deleted": "Class deleted successfully",
        "class_updated": "Class updated successfully",
        "teacher_assigned_to_class": "Teacher assigned to class successfully",
        "publishing_house_delete_warning": "The publishing house will be deleted! Are you really sure?",
        "publishing_house_added": "Publishing House added successfully",
        "publishing_house_deleted": "Publishing House deleted successfully",
        "publishing_house_updated": "Publishing House updated successfully",
        "author_delete_warning": "The author will be deleted! Are you really sure?",
        "author_added": "Author added successfully",
        "author_deleted": "Author deleted successfully",
        "author_updated": "Author updated successfully",
        "book_level_delete_warning": "The book level will be deleted! Are you really sure?",
        "book_level_added": "Book level added successfully",
        "book_level_deleted": "Book level deleted successfully",
        "book_level_updated": "Book level updated successfully",
        "category_delete_warning": "The category will be deleted! Are you really sure?",
        "category_added": "Category added successfully",
        "category_deleted": "Category deleted successfully",
        "category_updated": "Category updated successfully",
        "delete_subject": "Delete Subject",
        "subject_delete_warning": "The subject will be deleted! Are you really sure?",
        "subject_added": "Subject added successfully",
        "subject_deleted": "Subject deleted successfully",
        "subject_updated": "Subject updated successfully",
        "education_type_delete_warning": "The education type will be deleted! Are you really sure?",
        "education_type_added": "Education type added successfully",
        "education_type_deleted": "Education type deleted successfully",
        "education_type_updated": "Education type updated successfully",
        "education_level_delete_warning": "The education level will be deleted! Are you really sure?",
        "education_level_added": "Education level added successfully",
        "education_level_deleted": "Education level deleted successfully",
        "education_level_updated": "Education level updated successfully",
        "language_delete_warning": "The language will be deleted! Are you really sure?",
        "language_added": "Language added successfully",
        "language_deleted": "Language deleted successfully",
        "language_updated": "Language updated successfully",
        "faq_delete_warning": "The f.a.q. will be deleted! Are you really sure?",
        "faq_added": "F.A.Q. added successfully",
        "faq_deleted": "F.A.Q. deleted successfully",
        "faq_updated": "F.A.Q. updated successfully",
    },
}

export default AdminLanguage