import { ApiUrl } from "../../components/utils/Urls";
import { parentActions } from "./parentSlice";
import {fetchStudentData} from "../student/studentActions";
import {callToaster} from "../../components/utils/ToastText";

export const fetchParentData = (id) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/companyParent', {
                method: 'POST',
                body:'companyId=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch parent data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const parentData = await fetchData()
            const parents = parentData.list
            dispatch(parentActions.getParents(parents))
            dispatch(fetchStudentData(id))
            dispatch(fetchParentChildrenData(id))
        } catch (error) { }
    }
}

export const fetchParentChildrenData = (id) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/companyParentStudent', {
                method: 'POST',
                body:'parentId=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch parent data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const childrenData = await fetchData()
            const parents = childrenData.list
            dispatch(parentActions.getChildren(parents))
        } catch (error) { }
    }
}

export const updateParentData = (parent,corporationId) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'system/companyParentEdit', {
                method: 'POST', body: 'id=' + parent.id + '&firstName=' + parent.firstName + '&middleName=' + parent.middleName + '&lastName=' + parent.lastName + '&phone=' + parent.phone + '&gender=' + parent.gender + '&companyId=' + corporationId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch parent data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(parentActions.getIsParent(2))
            callToaster("parent_updated")
        } catch (error) { }
    }
}

export const deleteParentData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'system/companyParentDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch parent data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(parentActions.getIsParent(2))
            callToaster("parent_deleted")
        } catch (error) { }
    }
}

export const addParentData = (parent,corporationId) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/companyParentAdd', {
                method: 'POST', body: 'firstName=' + parent.firstName + '&middleName=' + parent.middleName + '&lastName=' + parent.lastName + '&phone=' + parent.phone + '&gender=' + parent.gender + '&companyId=' + corporationId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch parent data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(parentActions.getIsParent(2))
            callToaster("parent_added")
        } catch (error) { }
    }
}

export const addParentChildData = (parentId,studentId) => {
    console.log(parentId,studentId)
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/companyParentAddChild', {
                method: 'POST', body: 'parentId=' + parentId + '&companyStudentId=' + studentId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch parent data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(parentActions.getIsParent(2))
            callToaster("student_assigned_to_parent")
        } catch (error) { }
    }
}

export const deleteParentChildData = (parentId,studentId) => {
    console.log(parentId,studentId)
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/companyParentDeleteChild', {
                method: 'POST', body: 'parentId=1707&companyStudentId=1532',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not delete parents child data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const response = await addData()
            console.log(response)
            callToaster("student_assigned_to_parent")
        } catch (error) { }
    }
}