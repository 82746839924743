import React from 'react';

function ArrowRightLg ({fill}) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="29.932" height="29.932" viewBox="0 0 29.932 29.932">
                <path id="Icon_ionic-ios-arrow-dropdown-circle" data-name="Icon ionic-ios-arrow-dropdown-circle" d="M3.375,18.341A14.966,14.966,0,1,0,18.341,3.375,14.964,14.964,0,0,0,3.375,18.341Zm20.8-3.123a1.394,1.394,0,0,1,1.964,0,1.372,1.372,0,0,1,.4.979,1.4,1.4,0,0,1-.41.986l-6.785,6.763a1.387,1.387,0,0,1-1.914-.043l-6.886-6.864a1.389,1.389,0,0,1,1.964-1.964l5.835,5.893Z" transform="translate(-3.375 33.307) rotate(-90)" fill={fill}/>
            </svg>
        </div>
    );
}

export default ArrowRightLg;