import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {
    addStudentData,
    deleteStudentData,
    fetchStudentClassData,
    fetchStudentData,
    updateStudentData
} from "../../store/student/studentActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import CustomUser from "../../assets/icons/CustomUser";
import CircleArrowDownward from "../../assets/icons/CircleArrowDownward";
import {useLocation} from "react-router-dom";
import SubHeader from "../../components/header/SubHeader";
import TextInput from "../../components/textInput/TextInput";
import {fetchSelfClassData} from "../../store/selfClass/selfClassActions";
import {studentActions} from "../../store/student/studentSlice";
import {FileUrl} from "../../components/utils/Urls";
import DialogHeader from "../../components/dialogs/DialogHeader";

function Students({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const students = useSelector(state => state.students.students)
    const selfClasses = useSelector(state => state.selfClasses.selfClasses)
    const isStudent = useSelector(state=>state.students.isStudent)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const currentClass = useSelector(state=>state.students.student)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [studentId, setStudentId] = useState(false)

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [student, setStudent] = useState({})

    const location = useLocation()
    const  { pathname } = location
    const url = pathname.split("/")
    const corporationId = url[11]

    const cols = [
        {
            name: "firstName",
            label: language.name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <div className="flex items-center">
                            {/*<CustomUser className="h-6 w-6" />*/}
                            {
                                students[tableMeta.rowIndex].avatar.length > 5 ?
                                    <img className="h-6" src={FileUrl + students[tableMeta.rowIndex].avatar} alt=""/> :
                                    <CustomUser className="h-6 w-6" />
                            }
                            <div className="flex flex-col ml-2">
                                <div className="font-semibold">{value + " " + students[tableMeta.rowIndex].middleName + " " + students[tableMeta.rowIndex].lastName}</div>
                                <div className="text-xs">{students[tableMeta.rowIndex].id}</div>
                            </div>
                        </div>
                    );
                }
            }
        },
        // {
        //     name: "student_authorized",
        //     label: language.department,
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (value, tableMeta, rowIndex) => {
        //             return (
        //                 <div className="flex items-center space-x-4 ml-1">
        //                     <div className="text-bold">{value}</div>
        //                     <span className="rounded-md bg-soft-blue text-white text-sm py-1 px-2">+5</span>
        //                 </div>
        //             );
        //         }
        //     }
        // },
        {
            name: "mail",
            label: language.contact,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <div className="flex flex-col ml-1">
                            <div className="font-semibold">{value}</div>
                            <div className="text-xs">{students[tableMeta.rowIndex].mail}</div>
                        </div>
                    );
                }
            }
        },
        {
            // name: language.actions,
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    setStudentId(students[tableMeta.rowIndex].id)
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setStudent(students[tableMeta.rowIndex])
                                    dispatch(fetchStudentClassData(students[tableMeta.rowIndex].id))
                                    console.log(selfClasses.indexOf(currentClass.id))
                                    console.log(currentClass.id)
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setStudentId(students[tableMeta.rowIndex].id)
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    const saveStudent = () => {
        dispatch(addStudentData(student,currentClass,corporationId))
        setOpenAdd(false)
    }

    const deleteStudent = () => {
        dispatch(deleteStudentData(studentId))
        setOpenDelete(false)
    }

    const editStudent = () => {
        dispatch(updateStudentData(student,currentClass))
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchStudentData(corporationId))
        dispatch(fetchSelfClassData(corporationId))
    }, [dispatch,isStudent])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <SubHeader/>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.students}
                        data={students}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteStudent}
                title={language.delete_student}
                warning={language.student_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={setOpenAdd}
                cancelAddRef={cancelAddRef}
                saveItem={saveStudent}
                editItem={editStudent}
            >
                <DialogHeader
                    closeModal={() => setOpenAdd(false)}
                    title={openEdit ? language.edit_student : language.add_student}
                />
                <div className="flex gap-2">
                    <TextInput
                        label={language.name}
                        value={openEdit ? student.firstName : null}
                        onChange={(e) => setStudent({ ...student, firstName: e.target.value })}
                        placeholder={language.name}
                    />
                    <TextInput
                        label={language.middle_name}
                        value={openEdit ? student.middleName : null}
                        onChange={(e) => setStudent({ ...student, middleName: e.target.value })}
                        placeholder={language.middle_name}
                    />
                </div>
                <div className="flex gap-2">
                    <TextInput
                        label={language.last_name}
                        value={openEdit ? student.lastName : null}
                        onChange={(e) => setStudent({ ...student, lastName: e.target.value })}
                        placeholder={language.last_name}
                    />
                    <div className="flex flex-col mt-3">
                        <div className="flex space-x-1 mb-1">
                            <span>{language.gender}</span>
                            <span className="text-red-400">*</span>
                            <GrayQuestionMark />
                        </div>
                        <div className="flex relative items-center">
                            <select value={openEdit ? student.gender : null} onChange={(e)=> {
                                setStudent({...student, gender: e.target.value})
                            }
                            } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                <option value="">{language.choose}</option>
                                <option value="2">Female</option>
                                <option value="1">Male</option>
                            </select>
                            <CircleArrowDownward />
                        </div>
                    </div>
                </div>
                <div className="flex gap-2">
                    <TextInput
                        label={language.student_number}
                        value={openEdit ? student.mail : null}
                        onChange={(e) => setStudent({ ...student, mail: e.target.value })}
                        placeholder={language.student_number}
                    />
                    <div className="flex flex-col mt-3">
                        <div className="flex space-x-1 mb-1">
                            <span>{language.class}</span>
                            <span className="text-red-400">*</span>
                            <GrayQuestionMark />
                        </div>
                        <div className="flex relative items-center">
                            <select value={openEdit ? selfClasses.map((item)=>{return item.id}).indexOf(currentClass.id) : null} onChange={(e)=> {
                                // setCurrentClass({...currentClass, })
                                dispatch(studentActions.getStudentClass({id: selfClasses[e.target.value].id,gradeId:selfClasses[e.target.value].gradeId}))
                                console.log(currentClass)
                            }
                            } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                <option value="">{language.choose}</option>
                                {
                                    selfClasses.map((item,index)=>{
                                        return(
                                            <option value={index}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <CircleArrowDownward />
                        </div>
                    </div>
                </div>
            </AddDialog>
        </div>
    );
}

export default Students;