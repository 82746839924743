import React, {useEffect} from 'react';
import FaqItem from "../components/faqItem/FaqItem";
import { useDispatch, useSelector} from "react-redux";
import {fetchFaqData} from "../store/faq/faqActions";

function Faq(props) {
    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const identity = useSelector(state=>state.userInfos.identity)
    const faqs = useSelector(state=>state.faqs.faqs)

    useEffect(()=>{
        dispatch(fetchFaqData(identity))
    },[dispatch,identity])
    return (
        <div className="container px-3 mx-auto max-w-screen-lg mt-5">
            <div className="flex gap-4 mt-5 mb-5">
                <div className="rounded-lg bg-white w-full">
                    {
                        faqs.length > 0 ?
                        <div className="px-5 pb-5 mt-5 flex flex-col relative">
                            {
                                faqs.map((item, index) => {
                                    return (
                                        <FaqItem title={item.question} content={item.answer} index={index} />
                                    )
                                })
                            }
                            <div className={`h-full w-0.5 bg-gray-200 absolute ${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "left-14" : "right-10"} -ml-1 opacity-70`}></div>
                        </div> :
                            <div className="px-5 pb-5 mt-5 text-center flex flex-col relative">{language.faq_not_found}</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Faq;