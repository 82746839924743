import React, {useEffect} from 'react';
import {NavLink, useHistory, useLocation} from "react-router-dom";
import Dash from "../../assets/icons/Dash";
import Library from "../../assets/icons/Library";
import {BookOpenIcon, FingerPrintIcon} from "@heroicons/react/solid";
import Question from "../../assets/icons/Question";
import {useDispatch, useSelector} from "react-redux";
import Statistics from "../../assets/icons/Statistics";
import Person from "../../assets/icons/Person";
import {fetchParentStudentData} from "../../store/parentStudent/parentStudentActions";

function Navigation(props) {
    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const isAuthorized = useSelector(state => state.userInfos.isAuthorized)
    const studentId = useSelector(state=>state.parentStudents.firstStudentId)

    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;
    const identity = useSelector(state=>state.userInfos.identity)
    const userInfos = useSelector(state=>state.userInfos.userInfos)

    const locateAdmin = () => {
        localStorage.setItem("userType",JSON.stringify("admin"))
        setTimeout(()=>{
            window.location.replace(`/admin/regions/${userInfos.regionId}/cities/${userInfos.cityId}/ministries/${userInfos.ministryId}/directorates/${userInfos.directorateId}/corporations/${userInfos.companyId}/statistics`)
        },1000)
    }

    useEffect(()=>{
        if(identity === 'parent'){
            dispatch(fetchParentStudentData(userInfos.id))
        }
    },[dispatch])
    return (
        <div className="bg-white pt-3">
            <div className="container px-3 mx-auto max-w-screen-lg">
                <div className="flex justify-between">
                    <div className="flex space-x-4">
                        <NavLink to={`/user/dashboard`} activeClassName="border-b-4 border-primary text-active-navbar" className={`${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "" : "ml-4"} pb-3 border-b-4 border-transparent flex items-center text-gray-navbar`}>
                            <Dash />
                            <div className="mx-2 text-lg hidden sm:block">{language.homepage}</div>
                        </NavLink>
                        {
                            identity === 'teacher' &&
                            <NavLink to={`/user/books`} activeClassName="border-b-4 border-primary text-active-navbar" className={`${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "" : "ml-4"} pb-3 border-b-4 border-transparent flex items-center text-gray-navbar`}>
                                    <Library />
                                <div className="mx-2 text-lg hidden sm:block">{language.library}</div>
                            </NavLink>
                        }
                        {
                            identity !== 'teacher' &&
                            <NavLink to={`/user/${identity === 'student' ? 'books' : `${identity === 'parent' ? `statistics/${studentId}` : 'statistics' }`}`} activeClassName="border-b-4 border-primary text-active-navbar" className={`${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "" : "ml-4"} pb-3 border-b-4 border-transparent flex items-center text-gray-navbar`}>
                                {
                                    identity === 'student' ? <Library /> : <Statistics />
                                }
                                <div className="mx-2 text-lg hidden sm:block">{identity === 'student' ? language.library : language.statistics}</div>
                            </NavLink>
                        }
                        <NavLink to={`/user/${identity === 'student' ? "statistics" : identity === 'teacher' ? "my-students" : "books"}`} activeClassName="border-b-4 border-primary text-active-navbar" className={`${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "" : "ml-4"} pb-3 border-b-4 border-transparent flex items-center text-gray-navbar`}>
                            {
                                identity === 'student' ? <BookOpenIcon className="h-6 w-6 text-secondary-dark" /> : identity === 'teacher' ? <Person fill={'currentColor'} /> : <Library />
                            }
                            <div className="mx-2 text-lg hidden sm:block">{identity === 'student' ? language.reading : identity === 'teacher' ? language.my_students : language.my_books}</div>
                        </NavLink>
                        <NavLink to={`/user/faq`} activeClassName="border-b-4 border-primary text-active-navbar" className={`${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "" : "ml-4"} pb-3 border-b-4 border-transparent flex items-center text-gray-navbar`}>
                            <Question width={18} height={15} fill={'currentColor'} />
                            <div className="mx-2 text-lg hidden sm:block">{language.faq}</div>
                        </NavLink>
                    </div>
                    {
                        !isAuthorized &&
                        <div onClick={locateAdmin} className={`${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "" : "ml-4"} pb-3 border-b-4 border-transparent cursor-pointer flex items-center text-gray-navbar`}>
                            <FingerPrintIcon className="h-6 w-6" />
                            <div className="mx-2 text-lg hidden sm:block">{language.admin_pages}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Navigation;