import { createSlice } from "@reduxjs/toolkit";

const faqSlice = createSlice({
    name: 'faqs',
    initialState: {
        faqs: [],
        faq: false
    },
    reducers: {
        getFaqs(state, action) {
            state.faqs = action.payload
        },
        getFaq(state, action) {
            state.faq = !state.faq
        }
    }
})

export const faqActions = faqSlice.actions

export default faqSlice