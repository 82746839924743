import { createSlice } from "@reduxjs/toolkit";

const studentSlice = createSlice({
    name: 'students',
    initialState: {
        students: [],
        student:{},
        isStudent: '',
        classroomId: '',
        gradeId:''
    },
    reducers: {
        getStudents(state, action) {
            state.students = action.payload
        },
        getStudentClass(state,action){
            state.student = action.payload
            // state.classroomId = action.payload.id
            // state.gradeId = action.payload.gradeId
        },
        getIsStudent(state, action) {
            state.isStudent = !state.isStudent
        }
    }
})

export const studentActions = studentSlice.actions

export default studentSlice