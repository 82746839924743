import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import CirclePlus from "../../assets/icons/CirclePlus";
import CrossCircle from "../../assets/icons/CrossCircle";
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {addEmployeeData, deleteEmployeeData, fetchEmployeeData, updateEmployeeData} from "../../store/employee/employeeActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import CustomUser from "../../assets/icons/CustomUser";
import CircleArrowDownward from "../../assets/icons/CircleArrowDownward";
import {useLocation} from "react-router-dom";
import SubHeader from "../../components/header/SubHeader";
import {fetchDepartmentData} from "../../store/department/departmentActions";
import Checkbox from "@material-ui/core/Checkbox";

function Employees({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const employees = useSelector(state => state.employees.employees)
    const departments = useSelector(state=>state.departments.departments)
    const isEmployee = useSelector(state=>state.employees.isEmployee)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [employeeId, setEmployeeId] = useState(false)
    const [selectedDepartments,setSelectedDepartments] = useState([])
    const [reset,setReset] = useState([])

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [employee, setEmployee] = useState({})

    const location = useLocation()
    const  { pathname } = location
    const url = pathname.split("/")
    const corporationId = url[11]

    const cols = [
        {
            name: "firstName",
            label: language.employee_name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <div className="flex items-center">
                            <CustomUser className="h-6 w-6" />
                            <div className="flex flex-col ml-1">
                                <div className="font-semibold">{value + " " + employees[tableMeta.rowIndex].middleName + " " + employees[tableMeta.rowIndex].lastName}</div>
                                <div className="text-xs">{employees[tableMeta.rowIndex].id}</div>
                            </div>
                        </div>
                    );
                }
            }
        },
        // {
        //     name: "employee_authorized",
        //     label: language.department,
        //     options: {
        //         filter: true,
        //         sort: true,
        //         customBodyRender: (value, tableMeta, rowIndex) => {
        //             return (
        //                 <div className="flex items-center space-x-4 ml-1">
        //                     <div className="text-bold">{value}</div>
        //                     <span className="rounded-md bg-soft-blue text-white text-sm py-1 px-2">+5</span>
        //                 </div>
        //             );
        //         }
        //     }
        // },
        {
            name: "mail",
            label: language.contact,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <div className="flex flex-col ml-1">
                            <div className="font-semibold">{value}</div>
                            <div className="text-xs">{employees[tableMeta.rowIndex].phone}</div>
                        </div>
                    );
                }
            }
        },
        {
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    setEmployeeId(employees[tableMeta.rowIndex].id)
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setEmployee(employees[tableMeta.rowIndex])
                                    const finalArray = []
                                    departments.map((item)=>{
                                        if(employees[tableMeta.rowIndex].departmentList.split(',').indexOf(item.id.toString()) !== -1){
                                            finalArray.push({
                                                status:true,
                                                id:item.id,
                                                name:item.name
                                            })
                                        }
                                        else{
                                            finalArray.push({
                                                status:false,
                                                id:item.id,
                                                name:item.name
                                            })
                                        }
                                    })
                                    setSelectedDepartments(finalArray)
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setEmployeeId(employees[tableMeta.rowIndex].id)
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    const saveEmployee = () => {
        dispatch(addEmployeeData(employee,corporationId,selectedDepartments.join(",")))
        setOpenAdd(false)
    }

    const deleteEmployee = () => {
        dispatch(deleteEmployeeData(employeeId))
        setOpenDelete(false)
    }

    const editEmployee = () => {
        dispatch(updateEmployeeData(employee))
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchEmployeeData(corporationId))
        dispatch(fetchDepartmentData())
    }, [dispatch,isEmployee])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <SubHeader/>
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.employees}
                        data={employees}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteEmployee}
                title={language.delete_employee}
                warning={language.employee_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={()=>{
                    setOpenEdit(false)
                    setSelectedDepartments([])
                }}
                setOpenAdd={()=>{
                    setOpenAdd(false)
                    setSelectedDepartments([])
                }}
                cancelAddRef={cancelAddRef}
                saveItem={saveEmployee}
                editItem={editEmployee}
            >
                <div className="flex justify-between items-center pb-2 border-b border-gray-200">
                    <div className="flex space-x-2 items-center">
                        <CirclePlus fillColor={"#0E9E57"} />
                        <h3>{openEdit ? language.edit_employee : language.add_employee}</h3>
                    </div>
                    <CrossCircle closeModal={() => setOpenAdd(false)} />
                </div>
                <div className="flex gap-2">
                    <div className="flex flex-1 flex-col mt-3">
                        <div className="flex space-x-1 mb-1">
                            <span>{language.name}</span>
                            <span className="text-red-400">*</span>
                            <GrayQuestionMark />
                        </div>
                        <input value={openEdit ? employee.firstName : null} onChange={(e) => setEmployee({ ...employee, firstName: e.target.value })} placeholder={language.name} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                    </div>
                    <div className="flex flex-1 flex-col mt-3">
                        <div className="flex space-x-1 mb-1">
                            <span>{language.middle_name}</span>
                            <span className="text-red-400">*</span>
                            <GrayQuestionMark />
                        </div>
                        <input value={openEdit ? employee.middleName : null} onChange={(e) => setEmployee({ ...employee, middleName: e.target.value })} placeholder={language.middle_name} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                    </div>
                </div>
                <div className="flex gap-2">
                    <div className="flex flex-1 flex-col mt-3">
                        <div className="flex space-x-1 mb-1">
                            <span>{language.last_name}</span>
                            <span className="text-red-400">*</span>
                            <GrayQuestionMark />
                        </div>
                        <input value={openEdit ? employee.lastName : null} onChange={(e) => setEmployee({ ...employee, lastName: e.target.value })} placeholder={language.last_name} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                    </div>
                    <div className="flex flex-1 flex-col mt-3">
                        <div className="flex space-x-1 mb-1">
                            <span>{language.phone}</span>
                            <span className="text-red-400">*</span>
                            <GrayQuestionMark />
                        </div>
                        <input value={openEdit ? employee.phone : null} onChange={(e) => setEmployee({ ...employee, phone: e.target.value })} placeholder={language.phone} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text" />
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <div className="flex space-x-1 mb-1">
                        <span>{language.e_mail}</span>
                        <span className="text-red-400">*</span>
                        <GrayQuestionMark />
                    </div>
                    <input value={openEdit ? employee.mail : null} onChange={(e) => setEmployee({ ...employee, mail: e.target.value })} placeholder={language.e_mail} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="mail" />
                </div>
                <div className="flex flex-col mt-3">
                    <div className="flex space-x-1 mb-1">
                        <span>{language.main_department}</span>
                        <span className="text-red-400">*</span>
                        <GrayQuestionMark />
                    </div>
                    <div className="flex relative items-center">
                        <select value={openEdit ? employee.departmentId : null} onChange={(e) => setEmployee({ ...employee, departmentId: e.target.value })} className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                            <option value="">{language.choose}</option>
                            {
                                departments.map((item)=>{
                                    return(
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                        <CircleArrowDownward />
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <div className="flex space-x-1 mb-1">
                        <span>{language.authorized_department}</span>
                        <span className="text-red-400">*</span>
                        <GrayQuestionMark />
                    </div>
                    <div className="flex items-center gap-2">
                        {
                            openEdit ?
                            selectedDepartments.map(function (item,index) {
                                return (
                                    <div className="flex items-center space-x mt-3 mr-3">
                                        <Checkbox onChange={(e) => {
                                                const changeStation = selectedDepartments
                                                if (e.target.checked) {
                                                    changeStation[index] = {id:item.id,name:item.name,status:true}
                                                    setSelectedDepartments(changeStation);
                                                    setReset(["name"])
                                                } else {
                                                    changeStation[index] = {id:item.id,name:item.name,status:false}
                                                    setSelectedDepartments(changeStation);
                                                    setReset(["name"])
                                                }
                                                setSelectedDepartments(selectedDepartments)
                                                let array = []
                                                let sendSelecteds = selectedDepartments.map((e)=> {
                                                    if(e.status){
                                                        array.push(e.id)
                                                    }
                                                })
                                                setEmployee({...employee,departmentList:array.join()})
                                                console.log(employee)
                                            }
                                        }
                                             checked={item.status}
                                             id={item.id} color="primary" value={item.id} className="h-6" />
                                        <label className="cursor-pointer" htmlFor={item.id} >{item.name}</label>
                                    </div>
                                )
                            })
                                :
                                departments.map(function (item,index) {
                                    return (
                                        <div className="flex items-center space-x mt-3 mr-3">
                                            <Checkbox onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedDepartments([
                                                        ...selectedDepartments,
                                                        item.id,
                                                    ]);
                                                } else {
                                                    setSelectedDepartments(
                                                        selectedDepartments.filter((department) => department !== item.id),
                                                    );
                                                }
                                                setEmployee({...employee,departmentList:selectedDepartments.join(",")})
                                                // console.log(selectedDepartments)
                                                // const stationary = selectedDepartments
                                                // console.log(stationary.join())
                                                console.log(employee)
                                            }
                                            }
                                                  id={item.id} color="primary" value={item.id} className="h-6" />
                                            <label className="cursor-pointer" htmlFor={item.id} >{item.name}</label>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </AddDialog>
        </div>
    );
}

export default Employees;