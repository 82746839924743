import { createSlice } from "@reduxjs/toolkit";
import TableFooter from "../components/customTable/TableFooter";
import React from "react";

// toolbar: false,
//     download: false,
//     print: false,
//     filter: false,
//     rowsPerPage: 10,
//     customFooter: (
//     count,
//     page,
//     rowsPerPage,
//     changeRowsPerPage,
//     changePage,
//     onChangePage,
//     onChangeRowsPerPage,
//     totalPage
// ) => {
//     return <TableFooter totalPage={totalPage} onChangePage={(e, page) => changePage(page)} onChangeRowsPerPage={event => changeRowsPerPage(event.target.value)} page={page} rowsPerPage={rowsPerPage} changePage={changePage} count={count} />;
// },
//     textLabels: {
//     body: {
//         noMatch: state.no_result_found,
//             toolTip: state.order,
//             columnHeaderTooltip: () => `${state.order}`
//     },
//     viewColumns: {
//         title: state.show_columns,
//     },
//     toolbar: {
//         search: state.search,
//             viewColumns: state.view_columns,
//             filterTable: state.filter,
//     },
//     selectedRows: {
//         text: state.rows_selected,
//             delete: state.delete,
//             deleteAria: state.delete_selected_rows,
//     }
// }

const tableOptionsSlice = createSlice({
    name: 'tableOptions',
    initialState: {
        options: null
    },
    reducers: {
        getTableOptions(state, action) {
            state.options = action.payload
        }
    }
})

export const tableOptionsActions = tableOptionsSlice.actions

export default tableOptionsSlice