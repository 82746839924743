import { createSlice } from "@reduxjs/toolkit";

const identitySlice = createSlice({
    name: 'identity',
    initialState: {
        identity: ''
    },
    reducers: {
        changeIdentity(state, action) {
            state.identity = action.payload
        }
    }
})

export const identityActions = identitySlice.actions

export default identitySlice