import { createSlice } from "@reduxjs/toolkit";

const bookLevelSlice = createSlice({
    name: 'bookLevels',
    initialState: {
        bookLevels: [],
        bookLevel: false
    },
    reducers: {
        getBookLevels(state, action) {
            state.bookLevels = action.payload
        },
        getBookLevel(state, action) {
            state.bookLevel = !state.bookLevel
        }
    }
})

export const bookLevelActions = bookLevelSlice.actions

export default bookLevelSlice