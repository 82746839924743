import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import {classActions} from "../../store/class/classSlice";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {addClassData, deleteClassData, fetchClassData, updateClassData} from "../../store/class/classActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import CircleArrowDownward from "../../assets/icons/CircleArrowDownward";
import {fetchEducationLevelData} from "../../store/educationLevel/educationLevelActions";
import TextInput from "../../components/textInput/TextInput";
import DialogHeader from "../../components/dialogs/DialogHeader";

function Classes({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const classes = useSelector(state => state.classes.classes)
    const educationLevels = useSelector(state => state.educationLevels.educationLevels)
    const isClass = useSelector(state=>state.classes.class)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [classId, setClassId] = useState(false)

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [currentClass, setCurrentClass] = useState({})

    const cols = [
        {
            name: "name",
            label: language.class_name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>{value}</div>
                    );
                }
            }
        },
        {
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    setClassId(classes[tableMeta.rowIndex].id)
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setCurrentClass({...classes[tableMeta.rowIndex],id:classes[tableMeta.rowIndex].gradeId})
                                    dispatch(classActions.getClass(classes[tableMeta.rowIndex]))
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setClassId(classes[tableMeta.rowIndex].id)
                                    dispatch(classActions.getClass(classes[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    const saveClass = () => {
        dispatch(addClassData(currentClass))
        setOpenAdd(false)
    }

    const deleteClass = () => {
        dispatch(deleteClassData(classId))
        setOpenDelete(false)
    }

    const editClass = () => {
        dispatch(updateClassData(classId, currentClass))
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        dispatch(fetchEducationLevelData())
        dispatch(fetchClassData())
    }, [dispatch,isClass])

    return (
        <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    className="w-full z-0"
                    title={language.classes}
                    data={classes}
                    columns={cols}
                    options={options}
                    components={components}
                />
            </MuiThemeProvider>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteClass}
                title={language.delete_class}
                warning={language.class_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={setOpenAdd}
                cancelAddRef={cancelAddRef}
                saveItem={saveClass}
                editItem={editClass}
            >
                <DialogHeader
                    closeModal={() => setOpenAdd(false)}
                    title={openEdit ? language.edit_class : language.add_class}
                />
                <div className="flex flex-col mt-3">
                    <div className="flex space-x-1 mb-1">
                        <span>{language.education_levels}</span>
                        <span className="text-red-400">*</span>
                        <GrayQuestionMark />
                    </div>
                    <div className="flex relative items-center">
                        <select value={openEdit ? currentClass.id : null} onChange={(e)=> {
                            setCurrentClass({...currentClass, id: e.target.value})
                            console.log(currentClass)
                        }
                        } className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                            <option value="">{language.choose}</option>
                            {
                                educationLevels.map((item)=>{
                                    return(
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                        <CircleArrowDownward />
                    </div>
                </div>
                <TextInput
                    label={language.class_name}
                    value={openEdit ? currentClass.name : null}
                    onChange={(e) => setCurrentClass({ ...currentClass, name: e.target.value })}
                    placeholder={language.class_name}
                />
            </AddDialog>
        </div>
    );
}

export default Classes;
                                                