import React from 'react';

function Tick ({fill}) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 40.703 28.923">
                <path id="Icon_feather-check" data-name="Icon feather-check" d="M41.046,9,16.952,33.094,6,22.142" transform="translate(-3.172 -6.172)" fill="none" stroke={fill} stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
            </svg>
        </div>
    );
}

export default Tick;