import React from 'react';

function ArrowLeft ({fill,opacity}) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.807" height="18.807" viewBox="0 0 18.807 18.807">
                <path id="Icon_ionic-ios-arrow-dropdown-circle" data-name="Icon ionic-ios-arrow-dropdown-circle" d="M3.375,12.778a9.4,9.4,0,1,0,9.4-9.4A9.4,9.4,0,0,0,3.375,12.778Zm13.07-1.962a.876.876,0,0,1,1.234,0,.862.862,0,0,1,.253.615.877.877,0,0,1-.258.619L13.411,16.3a.871.871,0,0,1-1.2-.027L7.882,11.96a.873.873,0,0,1,1.234-1.234l3.666,3.7Z" transform="translate(22.182 -3.375) rotate(90)" fill={fill} opacity={opacity}/>
            </svg>
        </div>
    );
}

export default ArrowLeft;