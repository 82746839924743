import { CalendarIcon, ChevronDoubleDownIcon, ChevronDoubleUpIcon } from "@heroicons/react/outline"
import { QuestionMarkCircleIcon } from "@heroicons/react/solid"
import { createTheme, MuiThemeProvider } from "@material-ui/core"
import MUIDataTable from "mui-datatables"
import { useState, useEffect } from "react"
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar"
import { useDispatch, useSelector } from "react-redux"
import HeadPhones from "../../assets/icons/HeadPhones"
import OpenBook from "../../assets/icons/OpenBook"
import Question from "../../assets/icons/Question"
import { fetchTeacherStudentLevelStatisticData } from "../../store/teacherStudent/teacherStudentActions"
import ActionsWrapper from "../customTable/ActionsWrapper"
import { Components } from "../customTable/Components"
import { extendNonSelectable } from "../customTable/ExtendNonSelectable"
import { StaticTableOptions } from "../customTable/StaticTableOptions"
import { tableTheme } from "../customTable/TableTheme"
import { FileUrl } from "../utils/Urls"

function Accordion(props, { getMuiTheme = () => createTheme(tableTheme, extendNonSelectable) }) {

    const [isAccordionOpen, setIsAccordionOpen] = useState(false)

    const { total, completed, bookList, levelStatistic, totalListened, totalReaded } = useSelector(state => state.teacherStudents)
    const { language, identity, userInfos } = useSelector(state => state.userInfos)
    const [statistic, setStatistic] = useState({})
    const [books, setBooks] = useState('')
    const [getOnce, setGetOnce] = useState(false)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const percentage = (completed / total) * 100;
    const dispatch = useDispatch()

    const cols = [
        {
            name: "img",
            label: language.book_information,
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    // console.log(rowData)
                    return (
                        <div className="flex items-center">
                            <img className="w-6 h-6" src={FileUrl + value} alt="" />
                            <div className="flex flex-col mx-2">
                                <div className="font-semibold">{bookList[tableMeta.rowIndex].name}</div>
                                <div className="text-xs">{bookList[tableMeta.rowIndex].authors[0].name}</div>
                            </div>
                        </div>
                    );
                }
            }
        },
        {
            name: "author",
            label: "Book Information",
            options: {
                filter: false,
                sort: false,
                display: false,
                viewColumns: false,
                customBodyRender: (value, tableMeta) => {
                    // console.log(rowData)
                    return (
                        <div className="hidden">{value}</div>
                    );
                }
            }
        },
        {
            name: "student_progress",
            label: language.progress_status,
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    let isListened = bookList[tableMeta.rowIndex].student.listen
                    let isReaded = bookList[tableMeta.rowIndex].student.read
                    let percentage = 0
                    if (isListened === 1) {
                        percentage = percentage + 33
                    }
                    if (isReaded === 1) {
                        percentage = percentage + 33
                    }
                    return (
                        <div className="flex items-center ml-1">
                            <div className="font-semibold">{percentage}%</div>
                            <div className="bg-gray-200 mx-2 h-4 w-32 rounded-md">
                                <div style={{ width: percentage + '%' }} className="bg-primary h-full rounded-md"></div>
                            </div>
                        </div>
                    );
                }
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                download: false,
                print: false,
                viewColumns: false,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <HeadPhones
                                width={15}
                                height={15}
                                fill={bookList[tableMeta.rowIndex].student.listen === 1 ? '#0E9E57' : '#e5e7eb'}
                            // fill={`#0E9E57`}
                            />
                            <OpenBook width={18.419} height={15}
                                fill={bookList[tableMeta.rowIndex].student.read === 1 ? '#0E9E57' : '#e5e7eb'}
                            />
                            <QuestionMarkCircleIcon
                                // className={`h-4 w-4 ${statistic[tableMeta.rowIndex].isReaded ? 'text-primary' : 'text-gray-200'}`}
                                className={`h-4 w-4 text-primary`}
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: false,
    };

    const fetchData = () => {
        if (!getOnce) {
            if (identity === 'student') {
                dispatch(fetchTeacherStudentLevelStatisticData(userInfos.id, props.item.id))
            }
            else {
                dispatch(fetchTeacherStudentLevelStatisticData(props.studentId, props.item.id))
            }
            setGetOnce(true)
        }
        setIsAccordionOpen(!isAccordionOpen)
        setStatistic(levelStatistic)
        setBooks(bookList)
    }

    useEffect(() => {
        if (!getOnce) {
            if (identity === 'student') {
                dispatch(fetchTeacherStudentLevelStatisticData(userInfos.id, props.item.id))
            }
            else {
                dispatch(fetchTeacherStudentLevelStatisticData(props.studentId, props.item.id))
            }
            setStatistic(levelStatistic)
            setBooks(bookList)
            setGetOnce(true)
        }
    }, [dispatch])

    return (
        <div>
            <h1 onClick={fetchData} className="bg-primary relative text-white p-3 flex mt-6 justify-center items-center">
                {language.level}
                <div
                    className="flex cursor-pointer items-center justify-center text-md font-bold text-white p-5 mx-3 h-8 w-8 rounded-md bg-white text-primary">
                    {props.item.name}
                </div>
                {!isAccordionOpen ? <ChevronDoubleDownIcon className="h-6 w-6 text-white absolute right-5" /> : <ChevronDoubleUpIcon className="h-6 w-6 text-white absolute right-5" />}
            </h1>
            {
                isAccordionOpen &&
                <div className="flex flex-col">
                    <div className="flex flex-col md:flex-row gap-4 mt-5">
                        <div className="bg-white p-5 flex flex-col justify-between items-center h-96 w-full md:w-4/12">
                            <div className="font-bold font-lg">
                                {language.level_up}
                            </div>
                            <div className="w-3/4 flex flex-col items-center justify-center">
                                <CircularProgressbarWithChildren
                                    value={percentage}
                                    styles={buildStyles({
                                        textSize: '16px',
                                        textColor: '#1b3638',
                                        pathColor: '#0E9E57',
                                        strokeLinecap: 'butt',
                                        strokeWidth: '6px'
                                    })}
                                >
                                    <div className="text-dark-primary flex flex-col justify-center items-center"> <div className="flex items-end"><span className="text-4xl font-bold">{completed}</span> <span className="text-xl font-bold">/{total}</span> </div> <span className="text-lg font-bold">{language.completed}</span></div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div className="font-bold font-lg flex items-center">
                                <div>{language.level}</div>
                                <div
                                    className="flex cursor-pointer items-center mx-1 justify-center text-xs font-bold text-white p-1 h-6 w-6 rounded-md bg-primary">
                                    {props.item.name}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full md:w-8/12">
                            <div className="bg-white h-48 flex flex-col items-center p-5">
                                <div className="font-bold font-lg">
                                    {language.current_reads}
                                </div>
                                <div className="flex justify-center space-x-4 mt-5">
                                    <div className="flex flex-col items-center">
                                        <div className="relative">
                                            <CalendarIcon className="h-16 w-16 text-dark-primary" />
                                            <div className="absolute right-0 top-0 bg-white rounded-full p-2 w-6 h-6 border border-primary flex items-center justify-center">
                                                {levelStatistic && statistic.year[0].book}
                                            </div>
                                        </div>
                                        <div className="w-3/4 mt-2 text-center leading-4 text-sm">{language.reads_this_year}</div>
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <div className="relative">
                                            <CalendarIcon className="h-16 w-16 text-dark-primary" />
                                            <div className="absolute right-0 top-0 bg-white rounded-full p-2 w-6 h-6 border border-primary flex items-center justify-center">
                                                {statistic.month[0].book}
                                            </div>
                                        </div>
                                        <div className="w-3/4 mt-2 text-center leading-4 text-sm">{language.reads_this_month}</div>
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <div className="relative">
                                            <CalendarIcon className="h-16 w-16 text-dark-primary" />
                                            <div className="absolute right-0 top-0 bg-white rounded-full p-2 w-6 h-6 border border-primary flex items-center justify-center">
                                                {statistic.week[0].book}
                                            </div>
                                        </div>
                                        <div className="w-3/4 mt-2 text-center leading-4 text-sm">{language.reads_this_week}</div>
                                    </div>
                                    {/* <div className="flex flex-col items-center">
                                        <div className="relative">
                                            <CalendarIcon className="h-16 w-16 text-dark-primary" />
                                            <div className="absolute right-0 top-0 bg-white rounded-full p-2 w-6 h-6 border border-primary flex items-center justify-center">
                                                1
                                            </div>
                                        </div>
                                        <div className="w-3/4 mt-2 text-center leading-4 text-sm">{language.reads_this_day}</div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="bg-white h-48 flex flex-col items-center p-5 mt-2">
                                <div className="font-bold font-lg">
                                    {language.completed_activities}
                                </div>
                                <div className="flex justify-center space-x-8 mt-5">
                                    <div className="flex flex-col items-center">
                                        <div className="relative">
                                            <OpenBook width={40} height={40} fill={"#1b3638"} />
                                            <div className="absolute -right-3 -top-2 bg-white rounded-full p-2 w-6 h-6 border border-primary flex items-center justify-center">
                                                {totalReaded}
                                            </div>
                                        </div>
                                        <div className="w-3/4 mt-2 text-center leading-4 text-sm">{language.total_reads}</div>
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <div className="relative">
                                            <HeadPhones width={40} height={40} fill={"#1b3638"} />
                                            <div className="absolute -right-3 -top-2 bg-white rounded-full p-2 w-6 h-6 border border-primary flex items-center justify-center">
                                                {totalListened}
                                            </div>
                                        </div>
                                        <div className="mt-2 text-center leading-4 text-sm">{language.total_listens}</div>
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <div className="relative">
                                            <Question width={40} height={40} fill={"#1b3638"} />
                                            <div className="absolute -right-3 -top-2 bg-white rounded-full p-2 w-6 h-6 border border-primary flex items-center justify-center">
                                                13
                                            </div>
                                        </div>
                                        <div className="mt-2 text-center leading-4 text-sm">{language.total_quizzes}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        bookList.length > 0 &&
                        <div className="my-3 px-3 data-table">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    className="w-full z-0"
                                    title={language.my_students}
                                    data={books}
                                    columns={cols}
                                    options={options}
                                    components={Components}
                                />
                            </MuiThemeProvider>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Accordion
