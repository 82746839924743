import { ApiUrl } from "../../components/utils/Urls";
import { authorActions } from "./authorSlice";
import {toast} from "react-toastify";

export const fetchAuthorData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/author', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch author data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const authorData = await fetchData()
            dispatch(authorActions.getAuthors(authorData))
        } catch (error) { }
    }
}

export const updateAuthorData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/authorEdit', {
                method: 'POST', body: 'id=' + id + '&' + 'name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch author data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(authorActions.getAuthor(''))
            toast.success("Author updated successfully")
        } catch (error) { }
    }
}

export const deleteAuthorData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'library/authorDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch author data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(authorActions.getAuthor(''))
            toast.success("Author deleted successfully")
        } catch (error) { }
    }
}

export const addAuthorData = author => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'library/authorAdd', {
                method: 'POST', body: 'name=' + author,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch author data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            toast.success("Author added successfully")
            dispatch(authorActions.getAuthor(''))
            // dispatch(fetchAuthorData())
        } catch (error) { }
    }
}