import { ApiUrl } from "../../components/utils/Urls";
import { regionActions } from "./regionSlice";
import {toast} from "react-toastify";
import AdminLanguage from "../../components/language/AdminLanguage";
import {callToaster} from "../../components/utils/ToastText";

// export const callToaster = (toastText) =>{
//     let language = AdminLanguage[JSON.parse(localStorage.getItem("userInfos")).language]
//     toast.success(language[toastText])
// }

export const fetchRegionData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/region', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const regionData = await fetchData()
            dispatch(regionActions.getRegions(regionData))
        } catch (error) { }
    }
}

export const updateRegionData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'system/regionEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch region data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(regionActions.getRegion(''))
            callToaster("region_updated")
        } catch (error) { }
    }
}

export const deleteRegionData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'system/regionDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch region data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(regionActions.getRegion(''))
            dispatch(fetchRegionData())
            callToaster("region_deleted")
        } catch (error) { }
    }
}

export const addRegionData = region => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/regionAdd', {
                method: 'POST', body: 'name=' + region,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch region data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(regionActions.getRegion(''))
            dispatch(fetchRegionData())
            callToaster("region_added")
        } catch (error) { }
    }
}