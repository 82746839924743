import { ApiUrl } from "../../components/utils/Urls";
import { educationLevelActions } from "./educationLevelSlice";
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

export const fetchEducationLevelData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/grade', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch education level data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const educationLevelData = await fetchData()
            dispatch(educationLevelActions.getEducationLevels(educationLevelData))
        } catch (error) { }
    }
}

export const updateEducationLevelData = (id, name, bookLevels) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/gradeEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name + '&level=' + bookLevels,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch educationLevel data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(educationLevelActions.getEducationLevel(''))
            callToaster("education_level_updated")
        } catch (error) { }
    }
}

export const deleteEducationLevelData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'library/gradeDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch education level data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(educationLevelActions.getEducationLevel(''))
            callToaster("education_level_deleted")
        } catch (error) { }
    }
}

export const addEducationLevelData = (name,bookLevels) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'library/gradeAdd', {
                method: 'POST', body: 'name=' + name + '&level=' + bookLevels,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch education level data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(educationLevelActions.getEducationLevel(''))
            callToaster("education_level_added")
        } catch (error) { }
    }
}