import { ApiUrl } from "../../components/utils/Urls";
import { educationTypeActions } from "./educationTypeSlice";
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

export const fetchEducationTypeData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/education', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch education type data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const educationTypeData = await fetchData()
            dispatch(educationTypeActions.getEducationTypes(educationTypeData))
        } catch (error) { }
    }
}

export const updateEducationTypeData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/educationEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch education type data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(educationTypeActions.getIsEducationType(''))
            callToaster("education_type_updated")
        } catch (error) { }
    }
}

export const deleteEducationTypeData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'library/educationDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch education type data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(educationTypeActions.getIsEducationType(''))
            callToaster("education_type_deleted")
        } catch (error) { }
    }
}

export const addEducationTypeData = educationType => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'library/educationAdd', {
                method: 'POST', body: 'name=' + educationType,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch education type data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(educationTypeActions.getIsEducationType(''))
            callToaster("education_type_added")
        } catch (error) { }
    }
}