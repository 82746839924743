import { ApiUrl } from "../../components/utils/Urls";
import cookie from "js-cookie";
import {loginActions} from "./loginSlice";

export const fetchLoginData = (userInfos) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'user/login', {
                method: 'POST',
                body:'mail=' + userInfos.username + '&password=' + userInfos.password + '&language=English',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch login data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const loginData = await fetchData()
            // dispatch(loginActions.getUserInfos(loginData))
            // cookie.set("userType",JSON.stringify(loginData.type));
            // cookie.set("userInfos",JSON.stringify(loginData.user))
            localStorage.setItem("userInfos", JSON.stringify(loginData.user));
            localStorage.setItem("userType", JSON.stringify(loginData.type));
            if(loginData.type === 'admin'){
                window.location.replace('/admin/regions')
            }
            else{
                window.location.replace('/user/dashboard')
            }
        } catch (error) {}
    }
}