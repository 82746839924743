import React from 'react';

function Search(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.165" height="19.165" viewBox="0 0 19.165 19.165">
                <path id="fi-rr-search" d="M18.9,17.772l-4.76-4.76a7.988,7.988,0,1,0-1.128,1.128l4.76,4.76A.8.8,0,1,0,18.9,17.772ZM7.968,14.348a6.38,6.38,0,1,1,6.38-6.38,6.38,6.38,0,0,1-6.38,6.38Z" transform="translate(0.032 0.032)" fill="#1b3638"/>
            </svg>
        </div>
    );
}

export default Search;