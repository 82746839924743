import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import {educationLevelActions} from "../../store/educationLevel/educationLevelSlice";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {addEducationLevelData, deleteEducationLevelData, fetchEducationLevelData, updateEducationLevelData} from "../../store/educationLevel/educationLevelActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";
import Checkbox from "@material-ui/core/Checkbox";
import {fetchBookLevelData} from "../../store/bookLevel/bookLevelActions";
import TextInput from "../../components/textInput/TextInput";
import DialogHeader from "../../components/dialogs/DialogHeader";

function EducationLevels({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const educationLevels = useSelector(state => state.educationLevels.educationLevels)
    const bookLevels = useSelector(state=>state.bookLevels.bookLevels)
    const isEducationLevel = useSelector(state=>state.educationLevels.educationLevel)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [educationLevelId, setEducationLevelId] = useState(false)
    const [editBookLevels,setEditBookLevels] = useState([])
    const [errorMessage,setErrorMessage] = useState(false)

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [educationLevel, setEducationLevel] = useState({})
    const [selectedBookLevels,setSelectedBookLevels] = useState([])
    const [editSelectedBookLevels,setEditSelectedBookLevels] = useState([])

    const cols = [
        {
            name: "name",
            label: language.education_level_name,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>{value}</div>
                    );
                }
            }
        },
        {
            // name: language.actions,
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    const finalArray = []
                                    bookLevels.map((item,outerIndex)=>{
                                        if(educationLevels[tableMeta.rowIndex].level.split(',').indexOf(item.id.toString()) !== -1){
                                            finalArray.push({
                                                status:true,
                                                id:item.id,
                                                name:item.name
                                            })
                                        }
                                        else{
                                            finalArray.push({
                                                status:false,
                                                id:item.id,
                                                name:item.name
                                            })
                                            console.log(item.name)
                                        }
                                        setEditSelectedBookLevels(finalArray)
                                    })
                                    setEducationLevelId(educationLevels[tableMeta.rowIndex].id)
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setEducationLevel(educationLevels[tableMeta.rowIndex])

                                    dispatch(educationLevelActions.getEducationLevel(educationLevels[tableMeta.rowIndex]))
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setEducationLevelId(educationLevels[tableMeta.rowIndex].id)
                                    dispatch(educationLevelActions.getEducationLevel(educationLevels[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <AddButton onClick={() => {
                    setOpenAdd(true)
                    setOpenEdit(false)
                }} />
            )
        }
    }

    const saveEducationLevel = () => {
        if(selectedBookLevels.length > 0){
            dispatch(educationLevelActions.getEducationLevel(''))
            dispatch(addEducationLevelData(educationLevel.name,selectedBookLevels))
            setOpenAdd(false)
            setErrorMessage(false)
        }
        else{
            setErrorMessage(true)
        }
    }

    const deleteEducationLevel = () => {
        dispatch(deleteEducationLevelData(educationLevelId))
        setOpenDelete(false)
    }

    const editEducationLevel = () => {
        let sendBookLevels = ''
        editSelectedBookLevels.map((item,index)=>{
            if(item.status){
                sendBookLevels += (index>0 ? ',' : '') + item.id
            }
            return sendBookLevels
        })
        if(sendBookLevels.length > 0){
            dispatch(updateEducationLevelData(educationLevelId, educationLevel.name,sendBookLevels))
            setOpenEdit(false)
            setOpenAdd(false)
            setErrorMessage(false)
        }
        else{
            setErrorMessage(true)
        }
    }

    useEffect(() => {
        dispatch(fetchEducationLevelData())
        dispatch(fetchBookLevelData())
    }, [dispatch,isEducationLevel])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.educationLevels}
                        data={educationLevels}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteEducationLevel}
                title={language.delete_education_level}
                warning={language.education_level_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={()=>{
                    setOpenEdit(false)
                    setEditSelectedBookLevels([])
                }}
                reset
                setOpenAdd={()=> {
                    setOpenAdd(false)
                    setSelectedBookLevels([])
                }}
                cancelAddRef={cancelAddRef}
                saveItem={saveEducationLevel}
                editItem={editEducationLevel}
            >
                <DialogHeader
                    closeModal={() => setOpenAdd(false)}
                    title={openEdit ? language.edit_education_level : language.add_education_level}
                />
                <TextInput
                    label={language.education_level_name}
                    value={openEdit ? educationLevel.name : null}
                    onChange={(e) => setEducationLevel({ ...educationLevel, name: e.target.value })}
                    placeholder={language.education_level_name}
                />
                <div className="flex flex-col mt-3">
                    <div className="flex space-x-1 mb-1">
                        <span>{language.book_levels}</span>
                        <span className="text-red-400">*</span>
                        <GrayQuestionMark />
                    </div>
                    <div className="flex flex-wrap space-y-2 relative items-center">
                        {
                            openEdit ?
                            editSelectedBookLevels.map((item,index) => {
                                return (
                                    <div className="flex items-center space-x mt-3 mr-3">
                                        <Checkbox onChange={(e) => {
                                            const changeStation = editSelectedBookLevels
                                            if (e.target.checked) {
                                                changeStation[index] = {id:item.id,name:item.name,status:true}
                                                setEditSelectedBookLevels(changeStation);
                                                setEditBookLevels(['reset'])
                                            } else {
                                                changeStation[index] = {id:item.id,name:item.name,status:false}
                                                setEditSelectedBookLevels(changeStation);
                                                setEditBookLevels(['reset'])
                                            }
                                            setEditSelectedBookLevels(editSelectedBookLevels)
                                        }}
                                                  checked={item.status}
                                                  id={item.name} color="primary" value={item.name} className="h-6" />
                                        <label className="cursor-pointer" htmlFor={item.name} >{item.name}</label>
                                    </div>
                                )
                            }) :
                                bookLevels.map((item)=>{
                                    return(
                                        <div className="flex items-center space-x mt-3 mr-3">
                                            <Checkbox onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedBookLevels([
                                                        ...selectedBookLevels,
                                                        item.id,
                                                    ]);
                                                } else {
                                                    setSelectedBookLevels(
                                                        selectedBookLevels.filter((bookLevel) => bookLevel !== item.id),
                                                    );
                                                }
                                                console.log(selectedBookLevels)
                                            }} id={item.name} color="primary" value={item.name} className="h-6" />
                                            <label className="cursor-pointer" htmlFor={item.name} >{item.name}</label>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
                {
                    errorMessage &&
                    <div className="text-md text-red-600 bg-red-300 text-center p-2 rounded-md">
                        {language.book_level_warning}
                    </div>
                }
            </AddDialog>
        </div>
    );
}

export default EducationLevels;