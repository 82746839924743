import React from 'react';

function PenBox(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.154" height="19.693" viewBox="0 0 16.154 19.693">
                <g id="pencil-holder" transform="translate(-46)">
                    <path id="Path_54" data-name="Path 54" d="M62.154,12.945a2.88,2.88,0,0,0-2.692-2.87V8.922h1.154V7.768H57.838L58.6,5.244A2.363,2.363,0,0,0,59.727,2.16L58.768,0l-2.16.958A2.366,2.366,0,0,0,55.376,4L53.592,7.768h-.042L52.549,0,47.436.662l.915,7.106H46V8.922h1.154v8.044a2.73,2.73,0,0,0,2.727,2.727h6.855a2.73,2.73,0,0,0,2.727-2.727V15.815a2.88,2.88,0,0,0,2.692-2.87ZM56.439,2.684a1.2,1.2,0,0,1,.638-.67l1.105-.49.49,1.105a1.209,1.209,0,1,1-2.233.056ZM56.17,5.024a2.38,2.38,0,0,0,1.155.445l-.693,2.3H54.869ZM49.318,6.237l1.319-.17-.147-1.145-1.319.17-.147-1.145,1.314-.169L50.19,2.634,48.876,2.8l-.147-1.145,2.824-.364.834,6.473H49.515Zm7.417,12.3H49.881a1.575,1.575,0,0,1-1.573-1.573V8.922h10v8.044A1.575,1.575,0,0,1,56.735,18.539Zm2.727-3.882V11.233a1.722,1.722,0,0,1,0,3.424Z" fill="#fff"/>
                </g>
            </svg>
        </div>
    );
}

export default PenBox;