import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { ChatIcon } from "@heroicons/react/solid";
import "react-circular-progressbar/dist/styles.css";
import OpenBook from "../assets/icons/OpenBook";
import HeadPhones from "../assets/icons/HeadPhones";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchSingleStudentData,
    fetchTeacherStudentData,
    fetchTeacherStudentStatisticData
} from "../store/teacherStudent/teacherStudentActions";
import { FileUrl } from "../components/utils/Urls";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import SwiperCore, {
    Navigation
} from 'swiper';
import Accordion from '../components/accordion/Accordion';
import {fetchParentStudentData} from "../store/parentStudent/parentStudentActions";
import {parentStudentActions} from "../store/parentStudent/parentStudentSlice";
SwiperCore.use([Navigation]);
function Statistics(props) {

    const dispatch = useDispatch()

    const prevRef = useRef(null)
    const nextRef = useRef(null)
    const {levels,statistic,year,month,week,students,student} = useSelector(state=>state.teacherStudents)
    const level = useSelector(state=>state.readBook.level)
    const parentStudents = useSelector(state=>state.parentStudents.students)
    const {language,identity,userInfos} = useSelector(state => state.userInfos)

    const location = useLocation();
    const { pathname } = location;
    const url = pathname.split("/")[3];
    const studentUrl = pathname.split("/")[4]

    useEffect(() => {
        if(identity === 'teacher'){
            dispatch(fetchSingleStudentData(studentUrl))
            dispatch(fetchTeacherStudentData(userInfos.id,url))
            // dispatch(fetchTeacherStudentStatisticData(studentUrl))
        }
        else if(identity === 'parent'){
            // dispatch(fetchParentStudentData())
            // dispatch(fetchTeacherStudentStatisticData(url))
            dispatch(fetchSingleStudentData(url))
        }
        else{
            dispatch(fetchSingleStudentData(userInfos.id))
        }

    }, [dispatch, location])

    return (
        <div className="container relative px-3 mx-auto max-w-screen-lg mt-5 cursor-pointer">
            {
                identity !== 'student' &&
                <div>
                    <div className="flex flex-col bg-white rounded-md space-y-4 p-5 mb-5">
                        <div className="text-dark-primary text-md font-bold">{language.students}</div>
                        <div className="flex space-x-4">
                            <Swiper
                                loop={false} loopFillGroupWithBlank={true}
                                slidesPerView={5.5}
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current
                                }}
                                className="mySwiper w-full"
                            >
                                {
                                    identity === 'teacher' ?
                                    students.map((item, index) => {
                                        return (
                                            <SwiperSlide>
                                                <NavLink to={`/user/statistics/${url}/${item.id}`}
                                                    // onClick={() => {
                                                    // dispatch(teacherStudentActions.getStudent(item))
                                                    // dispatch(fetchTeacherStudentStatisticData(item.id))}}
                                                    className={`mx-4 flex flex-col w-48 cursor-pointer items-center border p-1 rounded-md ${item.id === student.id ? "border-dark-primary" : "border-gray-300"}`}>
                                                    <img className="px-1 mt-2 h-6 w-6" src={FileUrl + item.avatar} alt="" />
                                                    <h3 className="text-xs mt-3 h-8 text-center">{item.firstName}</h3>
                                                </NavLink>
                                            </SwiperSlide>
                                        )
                                    }) :
                                        parentStudents.map((item, index) => {
                                            return (
                                                <SwiperSlide>
                                                    <NavLink to={`/user/statistics/${item.id}`}
                                                        // onClick={() => {
                                                        // dispatch(teacherStudentActions.getStudent(item))
                                                        // dispatch(fetchTeacherStudentStatisticData(item.id))}}
                                                             className={`mx-4 flex flex-col w-48 cursor-pointer items-center border p-1 rounded-md ${item.id === student.id ? "border-dark-primary" : "border-gray-300"}`}>
                                                        <img className="px-1 mt-2 h-6 w-6" src={FileUrl + item.avatar} alt="" />
                                                        <h3 className="text-xs mt-3 h-8 text-center">{item.firstName}</h3>
                                                    </NavLink>
                                                </SwiperSlide>
                                            )
                                        })
                                }
                            </Swiper>
                        </div>
                    </div>
                    <div className="flex bg-white rounded-md mb-5">
                        <img className={`${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "border-r" : "border-l"} w-40 h-40 object-contain border-primary p-3 rounded-md`} src={FileUrl + student.avatar} alt="" />
                        <div className="flex flex-col flex-grow">
                            <div className="flex justify-between flex-1 border-b border-gray-200 m-4 pb-2">
                                <div className="text-primary-dark">{student.firstName}</div>
                                <NavLink to="/user/chat" className="flex items-center rounded-md gap-2 bg-primary p-3 text-white h-6">
                                    <ChatIcon className="h-4 w-4 text-white" />
                                    <div className="text-sm">{language.send_message}</div>
                                </NavLink>
                            </div>
                            <div className="flex justify-center items-center pb-3">
                                <div className="flex flex-col items-center">
                                    <div className="text font-bold mb-2">{language.this_year}</div>
                                    <div className="flex px-3">
                                        <div className="flex flex-col mx-1 items-center space-y border border-gray-200 rounded-md px-2 py-1">
                                            <div className="flex">
                                                <OpenBook width={15} height={15} fill={'#EB792D'} />
                                                <div className="text-xs mx-1 text-gray-200">{language.read}</div>
                                            </div>
                                            <div className="text text-sm text-dark-primary">{year && year.book}</div>
                                        </div>
                                        <div className="flex flex-col mx-1 items-center space-y border border-gray-200 rounded-md px-2 py-1">
                                            <div className="flex">
                                                <HeadPhones width={15} height={15} fill={'#FFAA00'} />
                                                <div className="text-xs mx-1 text-gray-200">{language.listen}</div>
                                            </div>
                                            <div className="text text-sm text-dark-primary">{year && year.listen}</div>
                                        </div>
                                        <div className="flex flex-col mx-1 items-center space-y border border-gray-200 rounded-md px-2 py-1">
                                            <div className="flex">
                                                <QuestionMarkCircleIcon className={`h-4 w-4 text-dark-primary`} />
                                                <div className="text-xs mx-1 text-gray-200">{language.quiz}</div>
                                            </div>
                                            <div className="text text-sm text-dark-primary">22</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center">
                                    <div className="text font-bold mb-2">{language.this_month}</div>
                                    <div className="flex px-3">
                                        <div className="flex flex-col mx-1 items-center space-y border border-gray-200 rounded-md px-2 py-1">
                                            <div className="flex">
                                                <OpenBook width={15} height={15} fill={'#EB792D'} />
                                                <div className="text-xs mx-1 text-gray-200">{language.read}</div>
                                            </div>
                                            <div className="text text-sm text-dark-primary">{month && month.book}</div>
                                        </div>
                                        <div className="flex flex-col mx-1 items-center space-y border border-gray-200 rounded-md px-2 py-1">
                                            <div className="flex">
                                                <HeadPhones width={15} height={15} fill={'#FFAA00'} />
                                                <div className="text-xs mx-1 text-gray-200">{language.listen}</div>
                                            </div>
                                            <div className="text text-sm text-dark-primary">{month && month.listen}</div>
                                        </div>
                                        <div className="flex flex-col mx-1 items-center space-y border border-gray-200 rounded-md px-2 py-1">
                                            <div className="flex">
                                                <QuestionMarkCircleIcon className={`h-4 w-4 text-dark-primary`} />
                                                <div className="text-xs mx-1 text-gray-200">{language.quiz}</div>
                                            </div>
                                            <div className="text text-sm text-dark-primary">22</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center">
                                    <div className="text font-bold mb-2">{language.this_week}</div>
                                    <div className="flex px-3">
                                        <div className="flex flex-col mx-1 items-center space-y border border-gray-200 rounded-md px-2 py-1">
                                            <div className="flex">
                                                <OpenBook width={15} height={15} fill={'#EB792D'} />
                                                <div className="text-xs mx-1 text-gray-200">{language.read}</div>
                                            </div>
                                            <div className="text text-sm text-dark-primary">{week && week.book}</div>
                                        </div>
                                        <div className="flex flex-col mx-1 items-center space-y border border-gray-200 rounded-md px-2 py-1">
                                            <div className="flex">
                                                <HeadPhones width={15} height={15} fill={'#FFAA00'} />
                                                <div className="text-xs mx-1 text-gray-200">{language.listen}</div>
                                            </div>
                                            <div className="text text-sm text-dark-primary">{week && week.listen}</div>
                                        </div>
                                        <div className="flex flex-col mx-1 items-center space-y border border-gray-200 rounded-md px-2 py-1">
                                            <div className="flex">
                                                <QuestionMarkCircleIcon className={`h-4 w-4 text-dark-primary`} />
                                                <div className="text-xs mx-1 text-gray-200">{language.quiz}</div>
                                            </div>
                                            <div className="text text-sm text-dark-primary">22</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                levels.map((item, index) => {
                    return (
                        <Accordion key={item.id} item={item} studentId={identity === 'teacher' ? studentUrl : url} />
                    )
                })
            }
        </div>
    );
}

export default Statistics;