import { createSlice } from "@reduxjs/toolkit";

const subjectSlice = createSlice({
    name: 'subjects',
    initialState: {
        subjects: [],
        subject: false
    },
    reducers: {
        getSubjects(state, action) {
            state.subjects = action.payload
        },
        getSubject(state, action) {
            state.subject = !state.subject
        }
    }
})

export const subjectActions = subjectSlice.actions

export default subjectSlice