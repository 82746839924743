import { createSlice } from "@reduxjs/toolkit";

const employeeSlice = createSlice({
    name: 'employees',
    initialState: {
        employees: [],
        isEmployee:false,
    },
    reducers: {
        getEmployees(state, action) {
            state.employees = action.payload
        },
        getIsEmployee(state, action) {
            state.isEmployee = !state.isEmployee
        }
    }
})

export const employeeActions = employeeSlice.actions

export default employeeSlice