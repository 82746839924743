import React from 'react';

function Edit(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="19.693" height="20.028" viewBox="0 0 19.693 20.028">
                <g id="write" transform="translate(-3.829)">
                    <g id="Group_230" data-name="Group 230" transform="translate(3.829)">
                        <g id="Group_229" data-name="Group 229" transform="translate(0)">
                            <path id="Path_42" data-name="Path 42" d="M17.114,18.617h0a.123.123,0,0,1-.123.123H5.241a.123.123,0,0,1-.123-.123V1.412a.123.123,0,0,1,.123-.123h11.75a.123.123,0,0,1,.123.123v1.2L18.4,1.368A1.413,1.413,0,0,0,16.991,0H5.241A1.413,1.413,0,0,0,3.829,1.412V18.617a1.413,1.413,0,0,0,1.412,1.412h11.75A1.413,1.413,0,0,0,18.4,18.617V12.56l-1.289,1.289Z" transform="translate(-3.829)" fill="#fff"/>
                            <path id="Path_43" data-name="Path 43" d="M55.319,331.419a.644.644,0,0,0,.644.644h2.253a1.921,1.921,0,0,1-.276-1.289H55.963A.644.644,0,0,0,55.319,331.419Z" transform="translate(-53.067 -316.311)" fill="#fff"/>
                            <path id="Path_44" data-name="Path 44" d="M58.634,256.828H55.963a.644.644,0,0,0,0,1.289h2.392Z" transform="translate(-53.067 -245.597)" fill="#fff"/>
                            <path id="Path_45" data-name="Path 45" d="M60.965,182.882h-5a.644.644,0,0,0,0,1.289h3.714Z" transform="translate(-53.067 -174.885)" fill="#fff"/>
                            <path id="Path_46" data-name="Path 46" d="M64.2,108.936H55.963a.644.644,0,0,0,0,1.289H62.91Z" transform="translate(-53.067 -104.172)" fill="#fff"/>
                            <path id="Path_47" data-name="Path 47" d="M157.1,42.455a2.681,2.681,0,0,0-3.824-.029l-7.98,7.98a.649.649,0,0,0-.174.319l-.8,3.684a.644.644,0,0,0,.766.766l3.684-.8a.656.656,0,0,0,.319-.174l7.98-7.98A2.681,2.681,0,0,0,157.1,42.455ZM145.791,53.7l.336-1.552,1.215,1.215Zm10.366-8.392-7.524,7.524-1.975-1.975,7.524-7.524a1.4,1.4,0,0,1,1.975,1.975Z" transform="translate(-138.163 -39.818)" fill="#fff"/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default Edit;