import React from 'react';

function Lock(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16.264" height="17.905" viewBox="0 0 16.264 17.905">
                <g id="Icon_feather-lock" data-name="Icon feather-lock" transform="translate(-3.75 -2.25)">
                    <path id="Path_1134" data-name="Path 1134" d="M6.14,16.5H17.624a1.64,1.64,0,0,1,1.64,1.64v5.742a1.64,1.64,0,0,1-1.64,1.64H6.14a1.64,1.64,0,0,1-1.64-1.64V18.14A1.64,1.64,0,0,1,6.14,16.5Z" transform="translate(0 -6.118)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <path id="Path_1135" data-name="Path 1135" d="M10.5,10.382V7.1a4.1,4.1,0,1,1,8.2,0v3.281" transform="translate(-2.719)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                </g>
            </svg>
        </div>
    );
}

export default Lock;