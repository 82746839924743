import React, {useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import Statistics from "../../assets/icons/Statistics";
import Person from "../../assets/icons/Person";
import Student from "../../assets/icons/Student";
import Book from "../../assets/icons/Book";
import Parent from "../../assets/icons/Parent"
import {FingerPrintIcon, OfficeBuildingIcon} from "@heroicons/react/solid";
import {useSelector} from "react-redux";

function SubHeader () {
    const language = useSelector(state => state.userInfos.language)
    const userInfos = useSelector(state => state.userInfos.userInfos)
    const isAuthorized = useSelector(state => state.userInfos.isAuthorized)

    const [availableMenus,setAvailableMenus] = useState(false)

    const splitList = userInfos.departmentList.split(",")

    const location = useLocation()
    const  { pathname } = location
    const url = pathname.split("/")
    const regionId = url[3]
    const cityId = url[5]
    const ministryId = url[7]
    const directorateId = url[9]
    const corporationId = url[11]

    const studentsNav = <NavLink to={`/admin/regions/${regionId}/cities/${cityId}/ministries/${ministryId}/directorates/${directorateId}/corporations/${corporationId}/students`} activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
        <Student/>
        <div className="ml-2 text-lg">{language.students}</div>
    </NavLink>

    const locateTeacher = () =>{
        localStorage.setItem("userType",JSON.stringify("teacher"))
        setTimeout(()=>{
            window.location.replace("/user/dashboard")
        },1000)
    }

    return (
        <div>
            <div className="flex flex-col bg-white rounded-md mt-5 p-5 pb-0">
                {/*<h2>Ronaki Duhok</h2>*/}
                {/*<div className="flex items-center space-x-2">*/}
                {/*    <div>Süleymaniye</div>*/}
                {/*    <ArrowRight fill={"#919e9f"}/>*/}
                {/*    <div>Süleymaniye</div>*/}
                {/*    <div className="flex items-center space-x-1">*/}
                {/*        <ArrowRight fill={"#0a9852"}/>*/}
                {/*        <div className="text-primary">Süleymaniye</div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="flex justify-between items-center">
                    <div className="flex space-x-4">
                        <NavLink to={`/admin/regions/${regionId}/cities/${cityId}/ministries/${ministryId}/directorates/${directorateId}/corporations/${corporationId}/statistics`} activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                            <Statistics/>
                            <div className="ml-2 text-lg">{language.statistics}</div>
                        </NavLink>
                        <NavLink to={`/admin/regions/${regionId}/cities/${cityId}/ministries/${ministryId}/directorates/${directorateId}/corporations/${corporationId}/employees`} activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                            <Person fill={"currentColor"} />
                            <div className="ml-2 text-lg">{language.employees}</div>
                        </NavLink>
                        {studentsNav}
                        <NavLink to={`/admin/regions/${regionId}/cities/${cityId}/ministries/${ministryId}/directorates/${directorateId}/corporations/${corporationId}/parents`} activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                            <Parent/>
                            <div className="ml-2 text-lg">{language.parents}</div>
                        </NavLink>
                        <NavLink to={`/admin/regions/${regionId}/cities/${cityId}/ministries/${ministryId}/directorates/${directorateId}/corporations/${corporationId}/classes`} activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                            <OfficeBuildingIcon className="h-6 w-6"/>
                            <div className="ml-2 text-lg">{language.classes}</div>
                        </NavLink>
                        <NavLink to={`/admin/regions/${regionId}/cities/${cityId}/ministries/${ministryId}/directorates/${directorateId}/corporations/${corporationId}/readings`} activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                            <Book fill={'currentColor'}/>
                            <div className="ml-2 text-lg">{language.readings}</div>
                        </NavLink>
                    </div>
                    {
                        !isAuthorized &&
                        <div className="hover:text-primary cursor-pointer flex items-center gap-2" onClick={locateTeacher}><FingerPrintIcon className="h-6 w-6" />{language.teacher_pages}</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default SubHeader;