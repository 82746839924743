import { ApiUrl } from "../../components/utils/Urls";
import { parentStudentActions } from "./parentStudentSlice";
import { readBookActions } from "../readBook/readBookSlice";
import {dashboardActions} from "../dashboard/dashboardSlice";
import {fetchDashboardData, fetchStudentGraphicData} from "../dashboard/dashboardActions";

export const fetchParentStudentData = parentId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'parent/students', {
                method: 'POST',
                body: 'parentId=' + parentId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch parent student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const parentStudentData = await fetchData()
            const parentStudents = parentStudentData.list
            dispatch(parentStudentActions.getStudents(parentStudents))
            dispatch(fetchDashboardData(parentStudents[0].id))
            dispatch(fetchStudentGraphicData(parentStudents[0].id))
        } catch (error) { }
    }
}

export const fetchSingleStudentData = studentUrl => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'parent/student', {
                method: 'POST',
                body: 'studentId=' + studentUrl,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch parent student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const singleStudentData = await fetchData()
            const currentStudent = singleStudentData.student
            const currentLevel = singleStudentData.levels[0]



        } catch (error) { }
    }
}

export const fetchParentClassData = parentId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'parent/schoolroom', {
                method: 'POST',
                body: 'parentId=' + parentId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            if (!response.ok) {
                throw new Error('Could not fetch parent student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const parentClassData = await fetchData()
            const singleClassData = parentClassData.list[0]
            dispatch(parentStudentActions.getClasses(parentClassData.list))
            dispatch(parentStudentActions.getClass(singleClassData))
            dispatch(fetchParentStudentData(singleClassData.id))
        } catch (error) { }
    }
}

export const fetchParentStudentStatisticData = studentId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'parent/student', {
                method: 'POST',
                body: 'studentId=' + studentId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch parent student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const parentStudentStatisticData = await fetchData()
            const studentStatistic = parentStudentStatisticData.statistic[0];

            // parentStudentStatisticData.levels.map(item=>dispatch(fetchParentStudentLevelData(studentId,item.id)))

            dispatch(parentStudentActions.getStatistic(studentStatistic))
        } catch (error) { }
    }
}

export const fetchParentStudentLevelStatisticData = (studentId, levelId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'student/levelReport', {
                method: 'POST',
                body: 'studentId=' + studentId + '&levelId=' + levelId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch parent student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const parentStudentLevelStatisticData = await fetchData()
            dispatch(parentStudentActions.getLevelStatistic(parentStudentLevelStatisticData))
            // dispatch(parentStudentActions.getLevelBooks(parentStudentLevelStatisticData))
        } catch (error) { }
    }
}