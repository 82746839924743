import { ApiUrl } from "../../components/utils/Urls";
import { teacherStudentActions } from "./teacherStudentSlice";
import { readBookActions } from "../readBook/readBookSlice";

export const fetchTeacherStudentData = (teacherId, classId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'teacher/schoolroomStudent', {
                method: 'POST',
                body: 'teacherId=' + teacherId + '&schoolroomId=' + classId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch teacher student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const teacherStudentData = await fetchData()
            const teacherStudents = teacherStudentData.list
            dispatch(teacherStudentActions.getStudents(teacherStudents))
        } catch (error) { }
    }
}

export const fetchSingleStudentData = studentUrl => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'teacher/student', {
                method: 'POST',
                body: 'studentId=' + studentUrl,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch teacher student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const singleStudentData = await fetchData()
            const currentStudent = singleStudentData.student
            const currentLevel = singleStudentData.levels[0]
            dispatch(teacherStudentActions.getStudent(currentStudent))
            dispatch(teacherStudentActions.getLevels(singleStudentData))
            dispatch(readBookActions.getLevel(currentLevel))
            // dispatch(teacherStudentActions.getStatistic(singleStudentData.statistic[0]))
        } catch (error) { }
    }
}

export const fetchTeacherClassData = teacherId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'teacher/schoolroom', {
                method: 'POST',
                body: 'teacherId=' + teacherId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            if (!response.ok) {
                throw new Error('Could not fetch teacher student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const teacherClassData = await fetchData()
            const singleClassData = teacherClassData.list[0]
            dispatch(teacherStudentActions.getClasses(teacherClassData.list))
            dispatch(teacherStudentActions.getClass(singleClassData))
            dispatch(fetchTeacherStudentData(teacherId, singleClassData.id))
        } catch (error) { }
    }
}

export const fetchTeacherStudentStatisticData = studentId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'teacher/student', {
                method: 'POST',
                body: 'studentId=' + studentId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch teacher student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const teacherStudentStatisticData = await fetchData()
            const studentStatistic = teacherStudentStatisticData.statistic[0];
            dispatch(teacherStudentActions.getStatistic(studentStatistic))
        } catch (error) { }
    }
}

export const fetchTeacherStudentLevelStatisticData = (studentId, levelId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'student/levelReport', {
                method: 'POST',
                body: 'studentId=' + studentId + '&levelId=' + levelId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch teacher student data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const teacherStudentLevelStatisticData = await fetchData()
            console.log(teacherStudentLevelStatisticData)
            dispatch(teacherStudentActions.getLevelStatistic(teacherStudentLevelStatisticData))
            // dispatch(teacherStudentActions.getLevelBooks(teacherStudentLevelStatisticData))
        } catch (error) { }
    }
}

// export const fetchTeacherStudentLevelData = (studentId,levelId) => {
//     return async (dispatch) => {
//         const fetchData = async () => {
//             const response = await fetch(ApiUrl + 'student/levelReport', {
//                 method: 'POST',
//                 body:'studentId=' + studentId +'&levelId=' + levelId,
//                 headers: {
//                     "Content-Type": "application/x-www-form-urlencoded"
//                 }
//             })
//
//             if (!response.ok) {
//                 throw new Error('Could not fetch teacher student data!')
//             }
//             const data = await response.json()
//             return data;
//         }
//
//         try {
//             const levelData = await fetchData()
//             dispatch(teacherStudentActions.getLevels(levelData))
//         } catch (error) { }
//     }
// }