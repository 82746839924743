import React  from 'react';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Language from '../language/Language'
import AdminLanguage from '../language/AdminLanguage'
import { LogoutIcon } from "@heroicons/react/outline";
import defaultPicture from '../../assets/icons/icon.svg'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {FileUrl} from "../utils/Urls";
import {loginActions} from "../../store/login/loginSlice";



function Header(props) {
    // const languages = ["Deutsch", "English", "Türkçe", "عربي", "بادینی", "سۆرانی"]
    const languages = ["Deutsch", "English", "Türkçe", "عربي", "سۆرانی"]

    const dispatch = useDispatch()
    const {language,userInfos,identity} = useSelector(state => state.userInfos)
    const profile = useSelector(state => state.image.avatarImage)


    const localInfos = JSON.parse(localStorage.getItem("userInfos"))

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const open = Boolean(anchorEl);
    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        languages.map(item => item === languages[index] ? dispatch(loginActions.changeLanguage(identity === 'admin' ? AdminLanguage[item] : Language[item])) : console.log("değiliz"))
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="bg-primary">
            <div className="container px-3 mx-auto max-w-screen-lg">
                <div className="flex justify-between items-center">
                    <NavLink to={identity === 'admin' ? "/admin/regions" : "/user/dashboard"}>
                        <h3 className="text-white">My Lesson</h3>
                    </NavLink>
                    <div className="flex space-x-3">
                        <div>
                            <List
                                component="nav"
                                aria-label="Device settings"
                                sx={{
                                    bgcolor: 'transparent', color: '#fff', padding: '5px',
                                    // '& .MuiButtonBase-root': {
                                    //     padding: '0 10px 0 10px',
                                    // },
                                }}
                            >
                                <ListItem
                                    button
                                    id="lock-button"
                                    aria-haspopup="listbox"
                                    aria-controls="lock-menu"
                                    // aria-label="when device is locked"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClickListItem}
                                >
                                    <ListItemText
                                        primary={language.id}
                                    />
                                </ListItem>
                            </List>
                            <Menu
                                id="lock-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                            >
                                {languages.map((language, index) => (
                                    <MenuItem
                                        key={language}
                                        selected={language === language.id}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                        {language}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                        {/*{*/}
                        {/*    identity === "admin" &&*/}
                        {/*    <NavLink to="/admin-settings/manage-menus" className="flex items-center cursor-pointer">*/}
                        {/*        <Settings fill={"#fff"} />*/}
                        {/*    </NavLink>*/}
                        {/*}*/}
                        {
                            identity !== "admin" &&
                            <NavLink to={`/user/settings`} className="flex items-center cursor-pointer">
                                <img className="h-6 w-6 object-contain" src={profile.length > 4 ? (FileUrl+profile) : defaultPicture} alt="" />
                            </NavLink>
                        }
                        <NavLink onClick={() => {
                            const removeKeys = ["userInfos", "userType"]
                            for (let keys of removeKeys){
                                localStorage.removeItem(keys)
                            }
                        }} to="/login" className="flex items-center cursor-pointer">
                            <LogoutIcon className="h-6 w-6 text-white" />
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;