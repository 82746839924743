import { createSlice } from "@reduxjs/toolkit";

const corporationSlice = createSlice({
    name: 'corporations',
    initialState: {
        corporations: [],
        isCorporation:true,
    },
    reducers: {
        getCorporations(state, action) {
            state.corporations = action.payload
        },
        getIsCorporation(state, action) {
            state.isCorporation = !state.isCorporation
        }
    }
})

export const corporationActions = corporationSlice.actions

export default corporationSlice