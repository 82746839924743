import { createSlice } from "@reduxjs/toolkit";

const authorSlice = createSlice({
    name: 'authors',
    initialState: {
        authors: [],
        author: false
    },
    reducers: {
        getAuthors(state, action) {
            state.authors = action.payload
        },
        getAuthor(state, action) {
            state.author = !state.author
        }
    }
})

export const authorActions = authorSlice.actions

export default authorSlice