import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/header/Header";
import Navigation from "../components/navigation/Navigation";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Library from "../pages/Library";
import Statistics from "../pages/Statistics";
import Faq from "../pages/Faq";
import Settings from "../pages/Settings";
import AvatarBuilder from "../pages/student/AvatarBuilder";
import BackgroundBuilder from "../pages/student/BackgroundBuilder";
import HelpWindow from "../components/helpwindow/HelpWindow";
import StudentChatWindow from "../components/chatWindow/StudentChatWindow";
import { ToastContainer } from "react-toastify";
import MyStudents from "../pages/teacher/MyStudents";
import Chat from '../pages/teacher/Chat';
import { FileUrl } from "../components/utils/Urls";
import Regions from "../pages/admin/Regions";
import {loginActions} from "../store/login/loginSlice";

function Layout(props) {
    const language = useSelector(state => state.userInfos.language)
    const userInfos = useSelector(state=>state.userInfos.userInfos)
    const identity = useSelector(state=>state.userInfos.identity)
    const background = useSelector(state => state.image.backgroundImage)
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)

    const splitList = userInfos.departmentList.split(",")

    useEffect(() => {

        if((splitList.indexOf("1012") > -1 && splitList.length > 1)){
            dispatch(loginActions.getIsAuthorized(false))
        }

        if (language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe") {
            document.body.style.direction = "ltr";
        }
        else if (language.id === 'عربي' || language.id === 'بادینی' || language.id === 'سۆرانی') {
            document.body.style.direction = "rtl";
        }
        else {
            document.body.style.direction = "ltr";
        }
        setIsLoading(true)
    }, [language, dispatch])

    if (!isLoading) {
        return (
            <div>Loading...</div>
        )
    }
    else {
        return (
            <div className={`min-h-screen relative flex flex-col ${identity === 'student' ? 'bg-gray-100' : 'bg-gray-200 bg-login-image'}`} style={identity === 'student' ? { backgroundImage: `url(${FileUrl + background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' } : {}}>
                <Header />
                <Navigation />
                <Switch>
                    <Route exact path={`/user/dashboard`} component={Dashboard} />
                    <Route exact path={`/user/books`} component={Library} />
                    <Route exact path={`/user/statistics`} component={Statistics} />
                    <Route exact path={`/user/statistics/:id/:id`} component={Statistics} />
                    <Route exact path={`/user/statistics/:id`} component={Statistics} />
                    <Route exact path={`/user/my-students`} component={MyStudents} />
                    <Route exact path={`/user/faq`} component={Faq} />
                    <Route exact path={`/user/chat`} component={Chat} />
                    <Route exact path={`/user/settings`} component={Settings} />
                    <Route exact path={`/user/settings/avatar-builder`} component={AvatarBuilder} />
                    <Route exact path={`/user/settings/background-builder`} component={BackgroundBuilder} />
                    {
                        identity === 'teacher' &&
                        <Route exact path={`/admin/regions`} component={Regions} />
                    }
                    {/*<Redirect from="*" to="/login"/>*/}
                </Switch>
                {
                    identity === 'student' &&
                    <div>
                        <HelpWindow />
                        <StudentChatWindow />
                    </div>
                }
                <ToastContainer />
            </div>
        );
    }

}

export default Layout;