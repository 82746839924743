import React from 'react';
import { useSelector } from 'react-redux';
import Search from "../../assets/icons/Search";

const SearchButton = () => {
    const language = useSelector(state => state.userInfos.language)
    return (
        <button className="rounded-md py-2 px-4 flex items-center bg-white h-10">
            <Search />
            <span className="ml-2">{language.search}</span>
        </button>
    );
}

export default SearchButton;