import React from 'react';
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";

function TextInput(props) {

    return (
        <div className="flex flex-1 flex-col mt-3">
            <div className="flex space-x-1 mb-1">
                <span>{props.label}</span>
                <span className="text-red-400">*</span>
                <GrayQuestionMark />
            </div>
            <input value={props.value} onChange={props.onChange} placeholder={props.placeholder} className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type={props.type ? props.type : "text "} />
        </div>
    );
}

export default TextInput;