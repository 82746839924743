import React from 'react';

function Actions (){
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                <g id="Rectangle_23" data-name="Rectangle 23" fill="none" stroke="#929fa0" stroke-width="1">
                    <rect width="23" height="23" rx="4" stroke="none"/>
                    <rect x="0.5" y="0.5" width="22" height="22" rx="3.5" fill="none"/>
                </g>
            </svg>
        </div>
    );
}

export default Actions;