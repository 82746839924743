import React, { Fragment, useRef, useState} from 'react';
import SubHeader from "../../components/header/SubHeader";
import Search from "../../assets/icons/Search";
import Filter from "../../assets/icons/Filter";
import CirclePlus from "../../assets/icons/CirclePlus";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDownward from "../../assets/icons/ArrowDownward";
import CustomUser from "../../assets/icons/CustomUser";
import Actions from "../../assets/icons/Actions";
import PaginationLeft from "../../assets/icons/PaginationLeft";
import PaginationRight from "../../assets/icons/PaginationRight";
import {Dialog, Transition} from "@headlessui/react";
import CrossCircle from "../../assets/icons/CrossCircle";
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import CircleArrowDownward from "../../assets/icons/CircleArrowDownward";

function Readings () {
        const [open, setOpen] = useState(false)
        const cancelButtonRef = useRef(null)
        const [book,setBook] = useState("Book One")
        const books = [ "Book One", "Book Two", "Book Three"]

        return (
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg">
                <SubHeader/>
                <div className="container mt-3 px-3 mx-auto max-w-screen-lg">
                    <div className="flex justify-between">
                        <h1>Readings</h1>
                        <div className="flex space-x-3">
                            <button className="rounded-md py-2 px-4 flex items-center bg-white h-10">
                                <Search/>
                                <span className="ml-2">Search</span>
                            </button>
                            <button className="rounded-md py-2 px-4 flex items-center bg-dark-primary text-white h-10">
                                <Filter/>
                                <span className="ml-2">Filter</span>
                            </button>
                            <button onClick={()=>setOpen(true)} className="rounded-md py-2 px-4 flex items-center bg-primary text-white h-10">
                                <CirclePlus fillColor={"#fff"}/>
                                <span className="ml-2">Add</span>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-2 mt-3">
                        <div className="flex items-center">
                            <div className="w-5/12 flex items-center justify-between border border-gray-400 p-2">
                                <div className="flex items-center">
                                    <label htmlFor="checkbox">Name Surname</label>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <MenuIcon className="p-0.5"/>
                                    <ArrowDownward className="h-6"/>
                                </div>
                            </div>
                            <div className="w-3/12 flex items-center justify-between border border-l-0 border-gray-400 p-2">
                                <div className="flex items-center">
                                    <div>Department</div>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <MenuIcon className="p-0.5"/>
                                    <ArrowDownward className="h-6"/>
                                </div>
                            </div>
                            <div className="w-4/12 flex items-center justify-between border border-l-0 border-gray-400 p-2">
                                <div className="flex items-center">
                                    <div>Contact</div>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <MenuIcon className="p-0.5"/>
                                    <ArrowDownward className="h-6"/>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                            <div className="w-5/12 flex items-center justify-between p-1">
                                <div className="flex items-center">
                                    <CustomUser className="h-6 w-6"/>
                                    <div className="flex flex-col ml-1">
                                        <div className="font-semibold">Veysel Akbulut</div>
                                        <div className="text-xs">202134</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 flex items-center justify-between p-1">
                                <div className="flex items-center space-x-4 ml-1">
                                    <div className="text-bold">System Manager</div>
                                    <span className="rounded-md bg-soft-blue text-white text-sm py-1 px-2">+5</span>
                                </div>
                            </div>
                            <div className="w-4/12 flex items-center justify-between p-1">
                                <div className="flex flex-col ml-1">
                                    <div className="font-semibold">a.mehmet@ronakiduhok.org</div>
                                    <div className="text-xs">+90546 564 65 65</div>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <div className="p-2 mr-2"><Actions/></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                            <div className="w-5/12 flex items-center justify-between p-1">
                                <div className="flex items-center">
                                    <CustomUser className="h-6 w-6"/>
                                    <div className="flex flex-col ml-1">
                                        <div className="font-semibold">Veysel Akbulut</div>
                                        <div className="text-xs">202134</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 flex items-center justify-between p-1">
                                <div className="flex items-center space-x-4 ml-1">
                                    <div className="text-bold">System Manager</div>
                                    <span className="rounded-md bg-soft-blue text-white text-sm py-1 px-2">+5</span>
                                </div>
                            </div>
                            <div className="w-4/12 flex items-center justify-between p-1">
                                <div className="flex flex-col ml-1">
                                    <div className="font-semibold">a.mehmet@ronakiduhok.org</div>
                                    <div className="text-xs">+90546 564 65 65</div>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <div className="p-2 mr-2"><Actions/></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                            <div className="w-5/12 flex items-center justify-between p-1">
                                <div className="flex items-center">
                                    <CustomUser className="h-6 w-6"/>
                                    <div className="flex flex-col ml-1">
                                        <div className="font-semibold">Veysel Akbulut</div>
                                        <div className="text-xs">202134</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 flex items-center justify-between p-1">
                                <div className="flex items-center space-x-4 ml-1">
                                    <div className="text-bold">System Manager</div>
                                    <span className="rounded-md bg-soft-blue text-white text-sm py-1 px-2">+5</span>
                                </div>
                            </div>
                            <div className="w-4/12 flex items-center justify-between p-1">
                                <div className="flex flex-col ml-1">
                                    <div className="font-semibold">a.mehmet@ronakiduhok.org</div>
                                    <div className="text-xs">+90546 564 65 65</div>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <div className="p-2 mr-2"><Actions/></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                            <div className="w-5/12 flex items-center justify-between p-1">
                                <div className="flex items-center">
                                    <CustomUser className="h-6 w-6"/>
                                    <div className="flex flex-col ml-1">
                                        <div className="font-semibold">Veysel Akbulut</div>
                                        <div className="text-xs">202134</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 flex items-center justify-between p-1">
                                <div className="flex items-center space-x-4 ml-1">
                                    <div className="text-bold">System Manager</div>
                                    <span className="rounded-md bg-soft-blue text-white text-sm py-1 px-2">+5</span>
                                </div>
                            </div>
                            <div className="w-4/12 flex items-center justify-between p-1">
                                <div className="flex flex-col ml-1">
                                    <div className="font-semibold">a.mehmet@ronakiduhok.org</div>
                                    <div className="text-xs">+90546 564 65 65</div>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <div className="p-2 mr-2"><Actions/></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                            <div className="w-5/12 flex items-center justify-between p-1">
                                <div className="flex items-center">
                                    <CustomUser className="h-6 w-6"/>
                                    <div className="flex flex-col ml-1">
                                        <div className="font-semibold">Veysel Akbulut</div>
                                        <div className="text-xs">202134</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 flex items-center justify-between p-1">
                                <div className="flex items-center space-x-4 ml-1">
                                    <div className="text-bold">System Manager</div>
                                    <span className="rounded-md bg-soft-blue text-white text-sm py-1 px-2">+5</span>
                                </div>
                            </div>
                            <div className="w-4/12 flex items-center justify-between p-1">
                                <div className="flex flex-col ml-1">
                                    <div className="font-semibold">a.mehmet@ronakiduhok.org</div>
                                    <div className="text-xs">+90546 564 65 65</div>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <div className="p-2 mr-2"><Actions/></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                            <div className="w-5/12 flex items-center justify-between p-1">
                                <div className="flex items-center">
                                    <CustomUser className="h-6 w-6"/>
                                    <div className="flex flex-col ml-1">
                                        <div className="font-semibold">Veysel Akbulut</div>
                                        <div className="text-xs">202134</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 flex items-center justify-between p-1">
                                <div className="flex items-center space-x-4 ml-1">
                                    <div className="text-bold">System Manager</div>
                                    <span className="rounded-md bg-soft-blue text-white text-sm py-1 px-2">+5</span>
                                </div>
                            </div>
                            <div className="w-4/12 flex items-center justify-between p-1">
                                <div className="flex flex-col ml-1">
                                    <div className="font-semibold">a.mehmet@ronakiduhok.org</div>
                                    <div className="text-xs">+90546 564 65 65</div>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <div className="p-2 mr-2"><Actions/></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center bg-white border border-white hover:border-dark-primary">
                            <div className="w-5/12 flex items-center justify-between p-1">
                                <div className="flex items-center">
                                    <CustomUser className="h-6 w-6"/>
                                    <div className="flex flex-col ml-1">
                                        <div className="font-semibold">Veysel Akbulut</div>
                                        <div className="text-xs">202134</div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-3/12 flex items-center justify-between p-1">
                                <div className="flex items-center space-x-4 ml-1">
                                    <div className="text-bold">System Manager</div>
                                    <span className="rounded-md bg-soft-blue text-white text-sm py-1 px-2">+5</span>
                                </div>
                            </div>
                            <div className="w-4/12 flex items-center justify-between p-1">
                                <div className="flex flex-col ml-1">
                                    <div className="font-semibold">a.mehmet@ronakiduhok.org</div>
                                    <div className="text-xs">+90546 564 65 65</div>
                                </div>
                                <div className="flex items-center space-x-1">
                                    <div className="p-2 mr-2"><Actions/></div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-between items-center bg-dark-primary border border-dark-primary px-2">
                            <div className="flex items-center justify-between p-1">
                                <div className="flex items-center">
                                    <label className="text-white">Showing 10 of 500</label>
                                </div>
                            </div>
                            <div className="flex items-center justify-between p-1 space-x-1">
                                <PaginationLeft/>
                                <div className="text-sm text-bold text-white">Previous</div>
                                <div className="text-sm text-bold text-white border-opacity-30 p-2 border border-white rounded-md">10</div>
                                <div className="text-sm text-bold opacity-30 text-white">/ 70</div>
                                <div className="text-sm text-bold text-white">Next</div>
                                <PaginationRight/>
                            </div>
                        </div>
                    </div>
                </div>
                <Transition.Root show={open} as={Fragment}>
                    <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                              &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                        <div className="sm:flex flex-col">
                                            <div className="flex justify-between items-center pb-2 border-b border-gray-200">
                                                <div className="flex space-x-2 items-center">
                                                    <CirclePlus fillColor={"#0E9E57"}/>
                                                    <h3>Kitap Ekle</h3>
                                                </div>
                                                <CrossCircle closeModal={()=>setOpen(false)} />
                                            </div>
                                            <div className="flex gap-2">
                                                <div className="flex flex-1 flex-col mt-3">
                                                    <div className="flex space-x-1 mb-1">
                                                        <span>Adı</span>
                                                        <span className="text-red-400">*</span>
                                                        <GrayQuestionMark/>
                                                    </div>
                                                    <input placeholder="Adı" className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text"/>
                                                </div>
                                                <div className="flex flex-1 flex-col mt-3">
                                                    <div className="flex space-x-1 mb-1">
                                                        <span>Soyadı</span>
                                                        <span className="text-red-400">*</span>
                                                        <GrayQuestionMark/>
                                                    </div>
                                                    <input placeholder="Soyadı" className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text"/>
                                                </div>
                                            </div>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex space-x-1 mb-1">
                                                    <span>E-Posta</span>
                                                    <span className="text-red-400">*</span>
                                                    <GrayQuestionMark/>
                                                </div>
                                                <input placeholder="Telefon" className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text"/>
                                            </div>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex space-x-1 mb-1">
                                                    <span>Telefon</span>
                                                    <span className="text-red-400">*</span>
                                                    <GrayQuestionMark/>
                                                </div>
                                                <input placeholder="E-Posta" className="text-gray-400 text-sm rounded-md bg-gray-100 outline-none p-2" type="text"/>
                                            </div>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex space-x-1 mb-1">
                                                    <span>Ana Departman</span>
                                                    <span className="text-red-400">*</span>
                                                    <GrayQuestionMark/>
                                                </div>
                                                <div className="flex relative items-center">
                                                    <select className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2" type="text">
                                                        <option value="">Seç</option>
                                                        <option value="">Departman-1</option>
                                                        <option value="">Departman-2</option>
                                                    </select>
                                                    <CircleArrowDownward/>
                                                </div>
                                            </div>
                                            <div className="flex flex-col mt-3">
                                                <div className="flex space-x-1 mb-1">
                                                    <span>Yetkili Olduğu Departmanlar</span>
                                                    <span className="text-red-400">*</span>
                                                    <GrayQuestionMark/>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    {
                                                        books.map(function(e){
                                                            return(
                                                                <label onClick={()=>setBook(e)} className={`rounded-lg p-2 border border-dashed text-sm flex justify-center border-gray-200 flex-1 ${e === book ? "border-primary": ""}`}>{e}</label>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 mb-2 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                        <button
                                            type="button"
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Devam Et
                                        </button>
                                        <button
                                            type="button"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-primary text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            Vazgeç
                                        </button>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>
            </div>
        );
}

export default Readings;