import { ApiUrl } from "../../components/utils/Urls";
import { readBookActions } from "./readBookSlice";

export const fetchReadBooksData = (levelId, studentId) => {
    return async (dispatch) => {
        const fetchData = async () => {
            let body = 'type=2'
            if (levelId) {
                body += '&levelId=' + levelId
            }
            if (studentId) {
                body += '&studentId=' + studentId
            }
            const response = await fetch(ApiUrl + 'book/list', {
                method: 'POST', body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const bookData = await fetchData()
            const singleBook = bookData.list[0]
            dispatch(readBookActions.getBooks(bookData))
            dispatch(readBookActions.getBook(singleBook))
        } catch (error) { }
    }
}

export const fetchReadBooksWoLevelData = (id, identity) => {
    return async (dispatch) => {
        const fetchData = async () => {
            let body = 'type=2'
            if (identity === 'teacher') {
                body += '&teacherId=' + id
            }
            if (identity === 'parent') {
                body += '&parentId=' + id
            }
            const response = await fetch(ApiUrl + 'book/list', {
                method: 'POST', body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const bookData = await fetchData()
            const singleBook = bookData.list[0]
            dispatch(readBookActions.getBooks(bookData))
            let array = []
            bookData.list.map((e) => {
                if (e.authors[0] !== undefined) {
                    array.push(e.authors[0])
                }
                else {
                    array.push({ id: (Math.random() + 1).toString(36).substring(7), name: "" })
                }
            })
            dispatch(readBookActions.getAuthors(array))
            console.log(array)
            // dispatch(readBookActions.getBook(singleBook))
        } catch (error) { }
    }
}

export const fetchBookPagesData = (id) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'book/view', {
                method: 'POST', body: 'id=' + id + '&language=',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const bookData = await fetchData()
            dispatch(readBookActions.getPages(bookData))
        } catch (error) { }
    }
}

export const fetchBookIdData = (id, bookId, identity, language) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + `${identity}/bookDetail`, {
                method: 'POST', body: `${identity}Id=` + id + '&bookId=' + bookId + '&type=1' + '&language=' + language,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const bookId = await fetchData()
            dispatch(readBookActions.getResponseId(bookId.id))
            console.log(bookId.id)
        } catch (error) { }
    }
}

export const updatePageData = (id, page, identity) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + `${identity}/bookPage`, {
                method: 'POST', body: 'id=' + id + '&page=' + (page + 1),
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await fetchData()
        } catch (error) {
        }
    }
}

export const bookReadedData = (id, identity) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + `${identity}/bookReadEnd`, {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await fetchData()
        } catch (error) {
        }
    }
}

export const updateListeningData = (id, time, identity) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + `${identity}/bookTime`, {
                method: 'POST', body: 'id=' + id + '&time=' + time,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not update listening data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await fetchData()
        } catch (error) {
        }
    }
}

export const bookListenedData = (id, identity) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + `${identity}/bookListenEnd`, {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await fetchData()
        } catch (error) {
        }
    }
}