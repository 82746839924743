import React, { useState } from 'react';
import { ChatIcon } from "@heroicons/react/solid";
import { XIcon } from "@heroicons/react/outline";
import SendMessage from "../../assets/icons/SendMessage";
import { useSelector } from "react-redux";

function StudentChatWindow(props) {
    const language = useSelector(state => state.userInfos.language)
    const [openChat, setOpenChat] = useState(false)
    return (
        <div className={`fixed bottom-0 right-16 cursor-pointer border-r border-l border-t border-primary ${openChat ? "w-64" : "w-12"}`}>
            <div onClick={() => setOpenChat(!openChat)} className="flex space-x-4 p-3 bg-primary text-white">
                {
                    openChat ? <XIcon className="h-6 w-6 text-white" /> : <ChatIcon className="h-6 w-6 text-white" />
                }
                {
                    openChat &&
                    <div className="px-2">{language.write_to_teacher}</div>
                }
            </div>
            <div className={`flex flex-col bg-white ${openChat ? 'h-auto' : 'h-0 overflow-hidden'}`}>
                <div className="chat-teacher flex bg-gray-100 text-dark-primary mr-auto relative p-3 text-sm w-3/4 ml-3 mt-3 rounded-md">
                    Hello dear student, did you complete your last homework?
                </div>
                <div className="w-8 bg-gray-100 rounded-lg h-2 ml-3 mr-auto mt-2"></div>
                <div className="w-4 bg-gray-100 rounded-lg h-2 ml-3 mr-auto mt-2"></div>
                <div className="chat-student flex justify-end bg-primary text-white ml-auto mr-3 relative p-3 text-sm w-3/4 ml-3 mt-3 rounded-md">
                    Yes sir, i completed my homework.
                </div>
                <div className="w-8 ml-auto bg-primary rounded-lg h-2 mr-3 mt-2"></div>
                <div className="w-4 ml-auto bg-primary rounded-lg h-2 mr-3 my-2"></div>
            </div>
            <div className={`flex justify-between w-full space-x-4 bg-gray-100 items-center pr-3 ${openChat ? 'h-auto' : 'h-0 overflow-hidden'}`}>
                <input placeholder="Type your message" className="text-gray-400 flex-1 text-sm bg-transparent  outline-none p-2" type="text" />
                <SendMessage className="text-primary-dark bg-primary-dark" />
            </div>
        </div>
    );
}

export default StudentChatWindow;