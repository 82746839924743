import React, { Fragment, useEffect, useRef, useState } from 'react';
import Search from "../assets/icons/Search";
import PageNeedle from "../assets/icons/PageNeedle";
import FlagDe from "../assets/imgs/flag-de.png";
import FlagEn from "../assets/imgs/flag-en.png";
import PropTypes from 'prop-types';
import HeadPhones from "../assets/icons/HeadPhones";
import OpenBook from "../assets/icons/OpenBook";
import { NavLink, useLocation } from "react-router-dom";
import Tick from "../assets/icons/Tick";
import { Bookmark } from "@material-ui/icons";
import { Dialog, Transition } from "@headlessui/react";
import CrossCircle from "../assets/icons/CrossCircle";
import { useDispatch, useSelector } from "react-redux";
import { XIcon } from "@heroicons/react/solid";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import SwiperCore, {
    Navigation
} from 'swiper';

import { fetchReadBooksData, fetchReadBooksWoLevelData } from "../store/readBook/readBookActions";
import { FileUrl } from "../components/utils/Urls";
import { fetchSingleStudentData } from "../store/teacherStudent/teacherStudentActions";
import { readBookActions } from "../store/readBook/readBookSlice";

// install Swiper modules
SwiperCore.use([Navigation]);
function Library(props) {
    const dispatch = useDispatch()
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const userInfos = useSelector(state => state.userInfos.userInfos)
    const levels = useSelector(state => state.teacherStudents.levels)
    const level = useSelector(state => state.readBook.level)
    const [pageNumber, setPageNumber] = useState("01")
    const contents = useSelector(state => state.readBook.books)
    const [greenBarWidth, setGreenBarWidth] = useState("24")
    const [showModal, setShowModal] = useState(false)
    const [modalInfos, setModalInfos] = useState({})
    const [searchBar, setSearchBar] = useState(false);
    const searchRef = useRef(null)
    const ref = useRef(null);
    const cancelModalRef = useRef(null)
    const language = useSelector(state => state.userInfos.language)
    const [searchParam] = useState(["name", "author"]);
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const isChanged = useSelector(state => state.readBook.hasChanged)
    const authors = useSelector(state => state.readBook.authors)

    function handleBarWidth(e, object) {
        //calculate green bar width, first one is multiplying the nth element with element width
        //second one is subs gap width to green bar
        //it's not dynamic manually calculated
        //to make this dynamic : first-> get box width , second-> get number of the box, third->multiply first and second
        //four-> get gap width and add it to third one.
        const barWidth = e * 24 + (e - 1) * 8
        setGreenBarWidth(barWidth)
        setPageNumber(e)
    }

    function search(contents) {
        return contents.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) > -1
                );
            });
        });
    }

    const location = useLocation();
    const { pathname } = location;
    // const identity = pathname.split("/")[1];
    const identity = useSelector(state => state.userInfos.identity)

    function PageNeedleThumbComponent(props) {
        const { children, ...other } = props;
        return (
            <PageNeedle {...other}>
                {children}
            </PageNeedle>
        );
    }

    PageNeedleThumbComponent.propTypes = {
        children: PropTypes.node,
    };

    useEffect(() => {
        if (identity === 'student') {
            dispatch(fetchSingleStudentData(userInfos.id))
            if (level) {
                dispatch(fetchReadBooksData(level,userInfos.id))
            }
        }
        else {
            dispatch(fetchReadBooksWoLevelData(userInfos.id,identity))
        }
        setTimeout(() => {
            setIsLoading(true)
        }, 1000)

    }, [dispatch, level, isChanged])

    if (!isChanged) {
        return (
            <div>...Loading</div>
        )
    }

    return (
        <div className="container flex flex-col px-3 mx-auto max-w-screen-lg">
            <div className="bg-white p-5 flex flex-col mt-5">
                <div className="flex justify-between">
                    {language.my_library &&
                        <h1>{language.my_library}</h1>
                    }
                    {/*<h1>{language.id}</h1>*/}
                    <button className="rounded-md py-2 px-4 flex items-center bg-white h-10 border border-gray-300">
                        <div onClick={() => {
                            setSearchBar(true)
                            setTimeout(() => {
                                searchRef.current.focus()
                            }, 100)
                        }}
                        >
                            <Search />
                        </div>
                        <div className={`flex items-center ${searchBar ? '' : 'hidden'}`}>
                            <input onChange={(e) => setQuery(e.target.value)} ref={searchRef} type="text" placeholder="Type Your Word" className="outline-none px-5" />
                            <XIcon onClick={() => setSearchBar(!searchBar)} className="h-6 w-6 text-black" />
                        </div>
                        <span onClick={() => {
                            setSearchBar(!searchBar)
                            setTimeout(() => {
                                searchRef.current.focus()
                            }, 100)
                        }} className={`mx-2 ${searchBar ? 'hidden' : ''}`}>{language.search}</span>
                    </button>
                </div>
                {
                    identity === 'student' &&
                    <div className="flex flex-col">
                        <div className="mt-3">
                            <Swiper
                                loop={false} loopFillGroupWithBlank={true}
                                slidesPerView={20.3}
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current
                                }}
                                onSlideChange={(swiper) => {
                                    console.log(swiper)
                                    // console.log(swiper.touches.currentX - swiper.touches.startX)
                                    // console.log(prevRef.current.offsetLeft)
                                }}
                                onSwiper={(swiper) => console.log(swiper)}
                                // onBeforeInit={(swiper) => {
                                //     swiper.params.navigation.prevEl = prevRef.current;
                                //     swiper.params.navigation.nextEl = nextRef.current;
                                //     swiper.navigation.update();
                                // }}
                                className="mySwiper"
                            >
                                {
                                    levels.map((page, e) => {
                                        return (
                                            <SwiperSlide key={page.id}>
                                                {/* <div className="h-10 relative"> */}
                                                <div ref={ref} onClick={(object) => {
                                                    dispatch(readBookActions.getLevel(page))
                                                    // handleBarWidth(page, object);
                                                    // const mod = (e % 2)
                                                    // console.log(mod)
                                                    // mod === 1 ? setContents(contentsTwo) : setContents(contentsOne)
                                                }} className={`flex mx-8 items-center justify-center h-16 w-6 `}>
                                                    <div className={`w-full cursor-pointer text-xs font-bold text-white rounded-md p-1  h-6 ${page.id === level ? "bg-primary" : "bg-gray-300"}`}>
                                                        {page.name}
                                                    </div>
                                                    <div className={`absolute ${page.id === level ? "" : "hidden"} bottom-1`}>
                                                        <PageNeedle />
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>

                        </div>
                        {/* <Swiper
                        loop={false} loopFillGroupWithBlank={true}
                        slidesPerView={30 / 34}
                        onSlideChange={(swiper) => {
                            console.log(swiper)
                            console.log(prevRef.current.offsetLeft)
                        }}
                        onSwiper={(swiper) => console.log(swiper)}
                        style={{ width: '960px' }}
                        ref={prevRef}
                    >
                        <SwiperSlide>
                            <div className="h-3 bg-gray-100 mx-1 mt-4 w-full rounded-full">
                                <div style={{ width: greenBarWidth + "px" }} className={`h-full relative bg-primary rounded-full`}>
                                    <div className={`absolute -top-2.5 ${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? 'right-1.5' : 'left-1.5'}`}>
                                        <PageNeedle />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper> */}
                    </div>
                }
            </div>
            <div className="bg-white p-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 mt-5">
                {
                    search(contents).map((item, index) => {
                        return (
                            <div key={item.id} onClick={() => {
                                setShowModal(true)
                                setModalInfos({ id: item.id, img: item.img, authors: item.authors[0].name, title: item.name, pages: item.paper })
                                dispatch(readBookActions.getBook(item))
                            }} className="flex flex-col cursor-pointer text-center border border-gray-100">
                                <div className="flex justify-center gap-1 mt-3">
                                    {/*<img className="h-4" src={FlagDe} alt="" />*/}
                                    {/*<img className="h-4" src={FlagEn} alt="" />*/}
                                    {item.language}
                                </div>
                                <div className="w-full h-full d-flex justify-between mx-auto px-3 rounded-lg overflow-hidden">
                                    <img className="mt-2 rounded-xl h-36 mx-auto" src={FileUrl + item.img} alt="" />
                                </div>
                                <h3 className="text-sm mt-3">{item.name}</h3>

                                <span className="text-gray-400 text-xs">{item.authors.length > 0 && item.authors[0].name}</span>
                                {/* <span className="text-gray-400 text-xs">{authors.length > 1 && authors[index].name}</span> */}
                                <div className="flex bg-gray-100 p-2 mt-3 items-center justify-center">
                                    {
                                        item.isReaded ?
                                            <div className="flex relative items-center justify-center space-x-1">
                                                <div className="flex items-center opacity-10">
                                                    <OpenBook width={18.419} height={15} fill={"#1b3638"} />
                                                    <span className="text-xs mx-1">{language.read}</span>
                                                </div>
                                                <div className="flex items-center justify-center absolute rounded-sm h-4 w-4 p-4">
                                                    <Bookmark className="text-yellow-300" />
                                                </div>
                                            </div> :
                                            <div className="flex items-center">
                                                <OpenBook width={18.419} height={15} fill={"#1b3638"} />
                                                <span className="text-xs mx-1">{language.read}</span>
                                            </div>
                                    }
                                    {
                                        item.isListened ?
                                            <div className="flex relative items-center justify-center">
                                                <div className="flex items-center opacity-10">
                                                    <HeadPhones width={15} height={15} fill={"#1b3638"} />
                                                    <span className="text-xs mx-1">{language.listen}</span>
                                                </div>
                                                <div className="flex items-center justify-center absolute bg-primary rounded-sm h-5 w-5 p-2">
                                                    <Tick fill={"#fff"} />
                                                </div>
                                            </div> :
                                            <div className="flex items-center">
                                                <HeadPhones className="h-6" width={15} height={15} fill={"#1b3638"} />
                                                <span className="text-xs mx-1">{language.listen}</span>
                                            </div>
                                    }
                                    {
                                        identity === 'student' &&
                                        <div className="flex items-center">
                                            <QuestionMarkCircleIcon className="h-4 w-4 text-dark-primary" />
                                            <span className="text-xs mx-1">{language.quiz}</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Transition.Root show={showModal} as={Fragment}>
                <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelModalRef} onClose={setShowModal}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-4 sm:align-middle sm:max-w-xl sm:w-full">
                                <div className="bg-white p-4 sm:p-6">
                                    <div className="sm:flex flex-col">
                                        <div className="flex w-full justify-end items-center pb-2">
                                            <CrossCircle closeModal={() => setShowModal(false)} />
                                        </div>
                                        <div className="flex justify-between mt-3">
                                            <div className="flex w-5/12 flex-col mx-2">
                                                <img className="rounded-lg h-44" src={FileUrl + modalInfos.img} alt="" />
                                            </div>
                                            <div className={`flex flex-col w-7/12 ${language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe" ? "mx-2" : "text-right mx-4"}`}>
                                                <h3 className="text-md">{modalInfos.title}</h3>
                                                <span className="text-gray-400 text-xs mt-3">{language.author}</span>
                                                <span className="text-dark-primary text-md ml-auto w-full">{modalInfos.authors}</span>
                                                <div className="text-gray-400 text-xs mt-3">{language.languages}</div>
                                                <div className="flex gap-1 mt-1">
                                                    <img className="h-4" src={FlagDe} alt="" />
                                                    <img className="h-4" src={FlagEn} alt="" />
                                                </div>
                                                <span className="text-gray-400 text-xs mt-3">{language.page}</span>
                                                <span className="text-dark-primary text-md">{modalInfos.pages}</span>
                                                <div className="flex mt-2">
                                                    <NavLink to={`/books/${modalInfos.id}`} className="flex items-center outline-none border-b-4 border-fourth bg-soft-blue p-2 rounded-md cursor-pointer">
                                                        <OpenBook width={18.419} height={15} fill={"#fff"} />
                                                        <span className="text-sm text-white mx-2">{language.read}</span>
                                                    </NavLink>
                                                    <div className="flex items-center mx-2  outline-none border-b-4 border-tertiary bg-tertiary text-white p-2 rounded-md cursor-pointer">
                                                        <HeadPhones width={15} height={15} fill={"#fff"} />
                                                        <span className="text-sm group-hover:text-white mx-2">{language.listen}</span>
                                                    </div>
                                                    {
                                                        identity === 'student' &&
                                                        <NavLink to="/quiz" className="flex items-center outline-none border-b-4 border-green-800 bg-primary p-2 rounded-md cursor-pointer">
                                                            <QuestionMarkCircleIcon className="h-4 w-4 text-white" />
                                                            <span className="text-sm text-white mx-2">{language.quiz}</span>
                                                        </NavLink>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            {/*<div className="flex justify-between items-center bg-dark-primary border border-dark-primary px-2 mb-8">*/}
            {/*    <div className="flex items-center justify-between p-1">*/}
            {/*        <div className="flex items-center">*/}
            {/*            <label className="text-white">5000 veriden 100 verisini görüyorsunuz</label>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="flex items-center justify-between p-1 space-x-1">*/}
            {/*        <PaginationLeft/>*/}
            {/*        <div className="text-sm text-bold text-white">Önceki</div>*/}
            {/*        <div className="text-sm text-bold text-white border-opacity-30 p-2 border border-white rounded-md">10</div>*/}
            {/*        <div className="text-sm text-bold opacity-30 text-white">/ 70</div>*/}
            {/*        <PaginationRight/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div >
    );
}

export default Library;