import { createSlice } from "@reduxjs/toolkit";

const publishingHouseSlice = createSlice({
    name: 'publishingHouses',
    initialState: {
        publishingHouses: [],
        publishingHouse: ''
    },
    reducers: {
        getPublishingHouses(state, action) {
            state.publishingHouses = action.payload
        },
        getPublishingHouse(state, action) {
            state.publishingHouse = action.payload
        }
    }
})

export const publishingHouseActions = publishingHouseSlice.actions

export default publishingHouseSlice