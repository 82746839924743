import React, { useEffect, useRef, useState } from 'react';
import PaintBrush from "../../assets/icons/PaintBrush";
import Avatar from "../../assets/icons/Avatar";
import { NavLink } from "react-router-dom";
import ArrowLeftLg from "../../assets/icons/ArrowLeftLg";
import ArrowRightLg from "../../assets/icons/ArrowRightLg";
import Tick from "../../assets/icons/Tick";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import SwiperCore, {
    Navigation
} from 'swiper';
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import {FileUrl} from "../../components/utils/Urls";
import {fetchAvatarsData, updateUserAvatarData} from "../../store/image/imageActions";

// install Swiper modules
SwiperCore.use([Navigation]);

function AvatarBuilder(props) {
    const dispatch = useDispatch()
    const [isHidden, setIsHidden] = useState("")
    const avatarImages = useSelector(state =>state.image.avatarImages)
    const avatarImage = useSelector(state =>state.image.avatarImage)
    const language = useSelector(state =>state.userInfos.language)
    const userInfos = useSelector(state =>state.userInfos.userInfos)

    const prevRef = useRef(null);
    const nextRef = useRef(null);

    function saveAvatar(item) {
        dispatch(updateUserAvatarData(item.file,userInfos.id))
    }

    useEffect(()=>{
        dispatch(fetchAvatarsData())
    },[dispatch])

    return (
        <div className="container flex flex-col flex-1 px-3 mx-auto max-w-screen-lg items-center justify-between">
            <div className="mt-48">
                <div className="relative">
                    <img className="w-3/4" src={FileUrl+avatarImage} alt="" />
                    <div className="absolute -right-24 -top-32 bg-primary text-white rounded-lg">
                        <div className="p-5 relative avatar-text">{language.avatar_text}</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-full">
                <div className="flex">
                    <NavLink to="/user/settings/background-builder" className="flex items-center py-2 px-5 text-white bg-dark-primary">
                        <PaintBrush fill={"#fff"} />
                        <span className="mx-2">{language.background_builder}</span>
                    </NavLink>
                    <div onClick={() => setIsHidden("")} className="flex items-center py-2 px-5 text-white bg-primary cursor-pointer">
                        <Avatar fill={"#fff"} />
                        <span className="flex mx-2">{language.avatar_builder}</span>
                    </div>
                </div>
                <div className={`flex flex-col bg-white ${isHidden}`}>
                    <div className="flex justify-between mx-14 mt-2">
                        <div className="text-dark-primary text-sm">{language.select_avatar}</div>
                        <div className="cursor-pointer text-gray-300 text-sm" onClick={() => setIsHidden("hidden")}>{language.hide_bar}</div>
                    </div>
                    <div>
                        <div className="flex items-center p-2 gap-2">
                            <div className="cursor-pointer z-100" ref={prevRef} ><ArrowLeftLg fill={"#919e9f"} /></div>
                            <Swiper
                                loop={true} loopFillGroupWithBlank={true}
                                slidesPerView={10}
                                navigation={{
                                    prevEl: prevRef.current,
                                    nextEl: nextRef.current
                                }}
                                className="mySwiper"
                            >
                                {
                                    avatarImages.map((item) => {
                                        return (
                                            <SwiperSlide>
                                                <div onClick={() => {
                                                    saveAvatar(item)
                                                }} className="h-full max-h-16 w-16 relative flex items-center justify-center border border-gray-300">
                                                    <img className="w-full h-full px-2 py-3 object-cover" src={FileUrl + item.file} alt="" />
                                                    <div className={`h-full w-full absolute flex items-center justify-center bg-black opacity-70 ${item.file === avatarImage ? "" : "hidden"}`}>
                                                        <Tick fill={"#fff"} width={20} height={20} />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                            <div className="cursor-pointer" ref={nextRef}><ArrowRightLg fill={"#919e9f"} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AvatarBuilder;