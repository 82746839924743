import React  from 'react';

function Person ({fill}) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="17.909" height="22.1" viewBox="0 0 17.909 22.1">
                <g id="businessman" transform="translate(-48.547)">
                    <g id="Group_29" data-name="Group 29" transform="translate(52.106)">
                        <g id="Group_28" data-name="Group 28">
                            <path id="Path_12" data-name="Path 12" d="M136.4,0a5.4,5.4,0,1,0,5.4,5.4A5.4,5.4,0,0,0,136.4,0Zm0,9.5a4.1,4.1,0,1,1,4.1-4.1A4.105,4.105,0,0,1,136.4,9.5Z" transform="translate(-131)" fill={fill}/>
                        </g>
                    </g>
                    <g id="Group_31" data-name="Group 31" transform="translate(48.547 12.086)">
                        <g id="Group_30" data-name="Group 30">
                            <path id="Path_13" data-name="Path 13" d="M66.035,287.25A8.541,8.541,0,0,0,57.57,280H57.4a8.534,8.534,0,0,0-8.435,7.25l-.42,2.764H66.456Zm-9.182,1.469h-6.8l.194-1.275a7.236,7.236,0,0,1,4.263-5.546l2.343,3.623Zm-1.072-7.24a7.233,7.233,0,0,1,1.621-.184h.168a7.329,7.329,0,0,1,1.648.188L57.5,284.138Zm2.367,7.24h0v-3.2L60.49,281.9a7.226,7.226,0,0,1,4.266,5.542l.194,1.275Z" transform="translate(-48.547 -280)" fill={fill}/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default Person;