import React from 'react';

function ChangeOrder () {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="39" viewBox="0 0 21 39">
                <rect id="Rectangle_39" data-name="Rectangle 39" width="21" height="39" rx="5" fill="#f5f5f5"/>
            </svg>
        </div>
    );
}

export default ChangeOrder;