import React from 'react';
import {ArrowNarrowRightIcon} from "@heroicons/react/solid";
import {NavLink} from "react-router-dom";

function NavigateButton (props)  {
    return (
        <NavLink to={props.navigate}>
            <ArrowNarrowRightIcon className="bg-gray-200 opacity-70 hover:opacity-100 transition-all cursor-pointer h-6 text-yellow-700 rounded-md p-1"/>
        </NavLink>
    );
}

export default NavigateButton;