import React, {useEffect, useRef, useState} from 'react';
import SendMessage from "../../assets/icons/SendMessage";
import {FileUrl} from "../../components/utils/Urls";
import {useDispatch, useSelector} from "react-redux";
import {fetchParentStudentData} from "../../store/parentStudent/parentStudentActions";
import {
    fetchDashboardData,
    fetchDashboardStudentsData,
    fetchStudentGraphicData
} from "../../store/dashboard/dashboardActions";
import {dashboardActions} from "../../store/dashboard/dashboardSlice";

function Chat () {
    const dispatch = useDispatch()
    const chatRef = useRef(null)
    const [triggerChatScroll, setTriggerChatScroll] = useState(false)
    const [message,setMessage] = useState('')
    const [currentUser,setCurrentUser] = useState('')
    const [messages, setMessages] = useState([
        {
            id: 1,
            owner: 'sender',
            message: 'Hello dear student, did you complete your last homework?',
        },
        {
            id: 2,
            owner: 'receiver',
            message: 'Yes sir, i completed my homework.',
        },
        {
            id: 3,
            owner: 'sender',
            message: 'Hello dear student, did you complete your last homework?',
        },
        {
            id: 4,
            owner: 'receiver',
            message: 'Yes sir, i completed my homework.',
        },
        {
            id: 5,
            owner: 'sender',
            message: 'Hello dear student, did you complete your last homework?',
        },
        {
            id: 6,
            owner: 'receiver',
            message: 'Yes sir, i completed my homework.',
        },
        {
            id: 7,
            owner: 'sender',
            message: 'Hello dear student, did you complete your last homework?',
        },
        {
            id: 8,
            owner: 'receiver',
            message: 'lastMessage Yes sir, i completed my homework.',
        },
    ])
    const [searchParam] = useState(["firstName","middleName"]);
    const [query, setQuery] = useState("");

    const {students,studentId} = useSelector(state => state.dashboard)
    const {language,userInfos} = useSelector(state => state.userInfos)

    useEffect(()=>{
        if(triggerChatScroll){
           scrollToBottom()
        }
    },[triggerChatScroll])

    function scrollToBottom() {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
        setTriggerChatScroll(false)
    }

    function addMessage(e){
        e.preventDefault()
        if(message !== ''){
            setMessages([...messages,{id: (Math.random() + 1).toString(36).substring(7),owner:'sender',message}])
            setMessage('')
            setTriggerChatScroll(!triggerChatScroll)
        }
    }

    const getStudent = (item) =>{
        dispatch(dashboardActions.resetStatistic({
            book: 0, listen: 0, listenTime: 0, page: 0
        }))
        dispatch(fetchDashboardData(item))
    }

    console.log(students)

    function search(students) {
        return students.filter((item) => {
            return searchParam.some((newItem) => {
                if(item[newItem] != null){
                    return (
                        item[newItem]
                            .toString()
                            .toLowerCase()
                            .indexOf(query.toLowerCase()) > -1
                    );
                }
            });
        });
    }

    useEffect(()=>{
        if(currentUser !== ''){
            setTriggerChatScroll(true)
        }
    },[currentUser])

    useEffect(() => {
        dispatch(fetchDashboardStudentsData(userInfos.id))
    }, [dispatch])
    return (
        <div className="container relative flex px-3 gap-2 mx-auto overflow-hidden max-w-screen-lg" style={{height:'calc(100vh - 114px)'}}>
            <div className="flex flex-col w-full mt-3 md:w-3/12 bg-white border border-gray-300 rounded-md overflow-y-scroll">
                <input className="p-3 m-3 outline-none bg-gray-200 rounded-md" onChange={(e) => setQuery(e.target.value)} type="text" placeholder={language.search}/>
                {
                    search(students).map((item) => {
                        return (
                            <div key={item.id} onClick={()=> {
                                getStudent(item.id)
                                setCurrentUser(item)
                            }} className={`flex gap-2 cursor-pointer items-center border p-1 ${item.id === studentId ? "bg-gray-200" : ""}`}>
                                {
                                    item.avatar &&
                                    <img className="px-1 mt-2 h-8 w-8" src={FileUrl + item.avatar} alt="" />
                                }
                                <h3 className={`text-xs text-center`}>{(item.firstName + " " + (item.middleName != null ? item.middleName : '') + " " + (item.lastName != null ? item.lastName : '')).substr(0,18) + "..."}</h3>
                            </div>
                        )
                    })
                }
            </div>
            {
                currentUser !== '' ?
                    <div ref={chatRef} className="overflow-y-scroll md:w-9/12 relative flex-col bg-white mt-3">
                        <div className="sticky z-10 top-0 left-0 right-0 bg-gray-100 flex items-center mx-auto w-full">
                            <img className="px-1 h-8 w-8 rounded-full ml-3" src={FileUrl + currentUser.avatar} alt="" />
                            <div className="p-3 text-black font-bold">{(currentUser.firstName + " " + (currentUser.middleName != null ? currentUser.middleName : '') + " " + (currentUser.lastName != null ? currentUser.lastName : ''))}</div>
                        </div>
                        <div className="h-full flex flex-col justify-end mt-16">
                            {
                                messages.map((item)=>{
                                    return(
                                        <div className="flex flex-col chat-bubble">
                                            <div className={`flex relative p-3 text-sm max-w-3/4 mt-3 rounded-md ${item.owner === 'receiver' ? 'chat-teacher bg-gray-100 text-dark-primary mr-auto ml-3' : 'chat-student bg-primary text-white ml-auto mr-3'}`}>
                                                {item.message}
                                            </div>
                                            <div className={`w-8 rounded-lg h-2 mt-2 ${item.owner === 'receiver' ? 'bg-gray-100 ml-3 mr-auto' : 'bg-primary mr-3 ml-auto'}`}></div>
                                            <div className={`w-4 rounded-lg h-2 mt-2 ${item.owner === 'receiver' ? 'bg-gray-100 ml-3 mr-auto' : 'bg-primary mr-3 ml-auto'}`}></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="sticky bottom-0 left-0 right-0 mx-auto w-full">
                            <form onSubmit={addMessage} className="flex bg-gray-100 justify-between items-center pr-3">
                                <input value={message} onChange={(e)=>setMessage(e.target.value)} placeholder="Type your message" className="text-gray-400 flex-grow text-sm bg-transparent  outline-none p-2" type="text"/>
                                <button type="submit">
                                    <SendMessage className="text-primary-dark bg-primary-dark" />
                                </button>
                            </form>
                        </div>
                    </div> :
                    <div className="md:w-9/12 relative flex-col text-gray-400 bg-white mt-3 flex items-center justify-center">{language.start_conversation_text}</div>
            }
        </div>
    );
}

export default Chat;