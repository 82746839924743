export const amazonConfig = {
    bucketName: 'mylesson',
    dirName: '1', /* optional */
    // dirName: '/hafsa2022ftp/', /* optional */
    region: 'eu-central-1',
    // endpoint:'https://s3.eu-central-1.wasabisys.com',
    accessKeyId: 'C24VVPX76D7K0U7IU98W',
    secretAccessKey: 'MC2gQNIKeWaf1kZXkbk1UCHDTTqepsUdPdbmcRg3',
    s3Url: 'https://s3.eu-central-1.wasabisys.com/mylesson', /* optional */
    // s3Url: 'https://s3.eu-central-1.wasabisys.com/mylessonapp', /* optional */
}
