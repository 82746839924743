import { ApiUrl } from "../../components/utils/Urls";
import {cityActions} from "./citySlice";
import {callToaster} from "../../components/utils/ToastText";

export const fetchCityData = regionId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/city', {
                method: 'POST',
                body: "regionId="+regionId ,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch city data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const cityData = await fetchData()
            dispatch(cityActions.getCities(cityData))
        } catch (error) { }
    }
}

export const updateCityData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'system/cityEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch city data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(cityActions.getIsCity(2))
            callToaster("city_updated")
        } catch (error) { }
    }
}

export const deleteCityData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'system/cityDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch city data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(cityActions.getIsCity(2))
            callToaster("city_deleted")
        } catch (error) { }
    }
}

export const addCityData = (regionId,city) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/cityAdd', {
                method: 'POST', body: 'regionId=' + regionId + '&name=' + city,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch city data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(cityActions.getIsCity(2))
            callToaster("city_added")
            // dispatch(fetchCityData())
        } catch (error) { }
    }
}