import React from 'react';
import {NavLink} from "react-router-dom";
import Dash from "../../assets/icons/Dash";
import Manage from "../../assets/icons/Manage";
import Notification from "../../assets/icons/Notification";

function AdminSettingsNavigation () {
    return (
        <div className="bg-white pt-3">
            <div className="container px-3 mx-auto max-w-screen-lg">
                <div className="flex">
                    <div className="flex space-x-4">
                        <NavLink to="/admin-settings/manage-menus" activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                            <Dash/>
                            <div className="ml-2 text-lg">Manage Menus</div>
                        </NavLink>
                        <NavLink to="/admin-settings/manage-departments" activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                            <Manage/>
                            <div className="ml-2 text-lg">Manage Departments</div>
                        </NavLink>
                        <NavLink to="/admin-settings/notifications" activeClassName="border-b-4 border-primary text-active-navbar" className="pb-3 border-b-4 border-transparent flex items-center text-gray-navbar">
                            <Notification/>
                            <div className="ml-2 text-lg">Notifications</div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSettingsNavigation;