import React from 'react';
import './styles/main.css';
import Admin from './layouts/Admin';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import AdminSettings from "./layouts/AdminSettings";
import Login from "./pages/Login";
import { Provider } from 'react-redux'
import Layout from "./layouts/Layout";
import Quiz from "./pages/student/Quiz";
import StudentInteraction from "./layouts/StudentInteraction";
import store from './store'

function App(){
    const identity = JSON.parse(localStorage.getItem("userType"))
    if(identity === 'student' || identity === 'teacher' || identity === 'parent'){
        console.log("user girişi")
        return(
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/books" component={StudentInteraction} />
                        <Route path="/quiz" component={Quiz} />
                        <Route path="/user" component={Layout} />

                        <Redirect from="*" to="/login" />
                    </Switch>
                </BrowserRouter>
            </Provider>
        )

    }
    else if(identity === 'admin'){
        return(
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/admin" component={Admin} />
                        <Route path="/admin-settings" component={AdminSettings} />
                        <Route path="/login" component={Login} />

                        <Redirect from="*" to="/login" />
                    </Switch>

                </BrowserRouter>
            </Provider>

        );
    }
    else{
        return(
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Redirect from="*" to="/login" />
                    </Switch>
                </BrowserRouter>
            </Provider>
        )
    }
}

export default App
