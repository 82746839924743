import { createSlice } from "@reduxjs/toolkit";

const regionSlice = createSlice({
    name: 'regions',
    initialState: {
        regions: [],
        region: ''
    },
    reducers: {
        getRegions(state, action) {
            state.regions = action.payload
        },
        getRegion(state, action) {
            state.region = action.payload
        }
    }
})

export const regionActions = regionSlice.actions

export default regionSlice