import React, {useRef, useState} from 'react';

function SettingsInput(props) {
    const [isInputHidden,setIsInputHidden] = useState(true)
    const inputRef = useRef(null)

    return (
        <div className="flex justify-between items-center px-5 mt-2 py-1 border border-gray-200">
            <div className={`flex flex-col w-full ${isInputHidden ? 'opacity-40' : 'opacity-100'}`}>
                <span className="text-gray-300 text-sm">{props.label}</span>
                <input
                    onChange={props.onChange}
                    onBlur={() => setIsInputHidden(true)} ref={inputRef} className="text-bold bg-transparent text-sm outline-none" value={props.value} type={props.type} disabled={!!isInputHidden} />
            </div>
            <div onClick={() => {
                setIsInputHidden(false)
                setTimeout(() => {
                    inputRef.current.focus()
                }, 100)
            }} className="cursor-pointer">{props.editText}</div>
        </div>
    );
}

export default SettingsInput;