import React from 'react';
import CirclePlus from "../../assets/icons/CirclePlus";
import CrossCircle from "../../assets/icons/CrossCircle";

function DialogHeader(props) {
    return (
        <div className="flex justify-between items-center pb-2 border-b border-gray-200">
            <div className="flex space-x-2 items-center">
                <CirclePlus fillColor={"#0E9E57"} />
                <h3>{props.title}</h3>
            </div>
            <CrossCircle closeModal={props.closeModal} />
        </div>
    );
}

export default DialogHeader;