        import React from 'react';
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

function Library () {
    const language = useSelector(state => state.userInfos.language)
    const navigationItems = [
        {
            navigate: "/admin/library/publishing-houses",
            title: language.publishing_houses
        },
        {
            navigate: "/admin/library/authors",
            title: language.authors
        },
        {
            navigate: "/admin/library/book-levels",
            title: language.book_levels
        },
        {
            navigate: "/admin/library/categories",
            title: language.categories
        },
        {
            navigate: "/admin/library/subjects",
            title: language.subjects
        },
        {
            navigate: "/admin/library/education-types",
            title: language.education_types
        },
        {
            navigate: "/admin/library/education-levels",
            title: language.education_levels
        },
        {
            navigate: "/admin/library/classes",
            title: language.classes
        },
        {
            navigate: "/admin/library/languages",
            title: language.languages
        },
        {
            navigate: "/admin/library/faq",
            title: language.faq
        },
        {
            navigate: "/admin/library/listening",
            title: language.listening
        },
        {
            navigate: "/admin/library/avatars",
            title: language.avatars
        },
        {
            navigate: "/admin/library/backgrounds",
            title: language.backgrounds
        }
    ]
    return (
        <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
            <div className="flex flex-wrap items-center justify-center bg-white rounded-md mt-5 p-5">
                {
                    navigationItems.map((item)=>
                        <div className="flex items-center rounded-md w-full md:w-1/4 h-16 mx-4 my-2 font-bold font-xl justify-center text-dark-primary border-2 border-primary hover:bg-primary hover:text-white">
                            <NavLink className="w-full h-full px-5 py-2 flex items-center justify-center" to={item.navigate}>
                                {item.title}
                            </NavLink>
                        </div>)
                }
            </div>
        </div>
    );
}

export default Library;