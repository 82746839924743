import React from 'react';

function Question (props) {
    return (
        <svg width={props.width} height={props.height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.357 19.891">
            <path id="Icon_open-question-mark" data-name="Icon open-question-mark" d="M6.141,0A6.425,6.425,0,0,0,1.467,1.641,5.425,5.425,0,0,0,0,4.823l2.486.323a3.013,3.013,0,0,1,.771-1.716,3.692,3.692,0,0,1,2.884-.945,4.387,4.387,0,0,1,3.033.845,2.078,2.078,0,0,1,.7,1.641c0,2.064-.845,2.636-2.089,3.729A7.087,7.087,0,0,0,4.9,14.3v.622H7.384V14.3c0-2.064.771-2.636,2.014-3.729a7.13,7.13,0,0,0,2.959-5.594A4.777,4.777,0,0,0,10.89,1.467,6.872,6.872,0,0,0,6.141,0ZM4.9,17.4v2.486H7.384V17.4Z" fill={props.fill}/>
        </svg>

);
}

export default Question;