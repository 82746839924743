import React from 'react';

function GrayQuestionMark(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                <g id="Group_163" data-name="Group 163" transform="translate(-696 -371.75)">
                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="9" cy="9" r="9" transform="translate(696 373.25)" fill="#9da8b2"/>
                    <text id="_" data-name="?" transform="translate(701.5 387.75)" fill="#fff"><tspan x="0" y="0">?</tspan></text>
                </g>
            </svg>
        </div>
    );
}

export default GrayQuestionMark;