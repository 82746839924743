import React from 'react';

function CirclePlus ({fillColor}) {
    return (
        <div>
            <svg fill={`${fillColor}`} xmlns="http://www.w3.org/2000/svg" width="19.165" height="19.165" viewBox="0 0 19.165 19.165">
                <g id="add-tool" transform="translate(0 -0.001)">
                    <g id="Group_6" data-name="Group 6" transform="translate(0 0.001)">
                        <path id="Path_7" data-name="Path 7" d="M9.583,0a9.583,9.583,0,1,0,9.583,9.583A9.594,9.594,0,0,0,9.583,0Zm0,17.594a8.012,8.012,0,1,1,8.012-8.012A8.021,8.021,0,0,1,9.583,17.6Z" transform="translate(0 -0.001)" fill={`${fillColor}`}/>
                        <path id="Path_8" data-name="Path 8" d="M76.529,71.123H72.7V67.29a.785.785,0,0,0-1.571,0v3.833H67.292a.785.785,0,0,0,0,1.571h3.833v3.833a.785.785,0,1,0,1.571,0V72.694h3.833a.785.785,0,0,0,0-1.571Z" transform="translate(-62.328 -62.326)" fill={`${fillColor}`}/>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default CirclePlus;