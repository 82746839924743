import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import CirclePlus from "../../assets/icons/CirclePlus";
import CrossCircle from "../../assets/icons/CrossCircle";
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import Checkbox from "@material-ui/core/Checkbox";
import {PlusIcon} from "@heroicons/react/solid";
import {CloudUpload} from "@material-ui/icons";
import {useDispatch, useSelector } from 'react-redux'
import {bookActions} from "../../store/book/bookSlice";
import AddDialog from "../../components/dialogs/AddDialog";
import {addBookData, fetchBookData} from "../../store/book/bookActions";
import {fetchAuthorData} from "../../store/author/authorActions";
import {fetchCategoryData} from "../../store/category/categoryActions";
import {fetchBookLevelData} from "../../store/bookLevel/bookLevelActions";
import {fetchSubjectData} from "../../store/subject/subjectActions";
import {fetchEducationLevelData} from "../../store/educationLevel/educationLevelActions";
import AddFeature from "../../components/addBook/AddFeature";
import BookDialogBody from "../../components/addBook/BookDialogBody";
import TextInput from "../../components/textInput/TextInput";
import CircleArrowDownward from "../../assets/icons/CircleArrowDownward";
import {fetchLanguageData} from "../../store/language/languageActions";
import {addImageData} from "../../store/image/imageActions";
import S3 from 'react-aws-s3';
import {amazonConfig} from "../../components/utils/AmazonConfig";
import {useHistory} from "react-router-dom";

function AddBook () {
    let history = useHistory()
    const [openAddCategory,setOpenAddCategory] = useState(false)
    const [openAddLevel,setOpenAddLevel] = useState(false)
    const [openAddAuthor,setOpenAddAuthor] = useState(false)
    const [openAddSubject,setOpenAddSubject] = useState(false)
    const [openAdd,setOpenAdd] = useState(false)
    const [dialogBody,setDialogBody] = useState({})

    const cancelAddCategoryRef = useRef(null)
    const cancelAddLevelRef = useRef(null)
    const cancelAddAuthorRef = useRef(null)
    const cancelAddSubjectRef = useRef(null)
    const cancelAddRef = useRef(null)

    const [coverImage,setCoverImage] = useState('')
    // const [categories,setCategories] = useState([{name:"Category One",status:false}, {name:"Category Two",status:false},{name:"Category Three",status:false}, {name:"Category Four",status:false}])
    // const [levels,setLevels] = useState([{name:"Level One",status:false}, {name:"Level Two",status:false}])
    // const [authors,setAuthors] = useState([{name:"Author One",status:false}, {name:"Author Two",status:false}])
    // const [subjects,setSubjects] = useState([{name:"Subject One",status:false}, {name:"Subject Two",status:false}])
    // const [items,setItems] = useState([])
    // const [title, setTitle] = useState('')

    const dispatch = useDispatch()

    const ReactS3Client = new S3(amazonConfig);

    const [selectedAuthors,setSelectedAuthors] = useState([])
    const [selectedCategories,setSelectedCategories] = useState([])
    const [selectedSubjects,setSelectedSubjects] = useState([])

    const station = useSelector(state=>state.books.station)
    const categories = useSelector(state=>state.categories.categories)
    const levels = useSelector(state=>state.bookLevels.bookLevels)
    const authors = useSelector(state=>state.authors.authors)
    const subjects = useSelector(state=>state.subjects.subjects)
    const languages = useSelector(state=>state.bookLanguages.languages)
    const language = useSelector(state=>state.userInfos.language)
    const initList = [
        {list_item:authors,title:"Authors",setChange:setSelectedAuthors,change:selectedAuthors},
        {list_item:subjects,title:"Subjects",setChange:setSelectedSubjects,change:selectedSubjects},
        {list_item:categories,title:"Categories",setChange:setSelectedCategories,change:selectedCategories}]
    const items = useSelector(state=>state.books.items)
    const title = useSelector(state=>state.books.title)

    const [inputs,setInputs] = useState({})

    const handleChanges = (e,item) => {

        // console.log(dialogBody.change)

        console.log(dialogBody)

        // dialogBody.setChange([...dialogBody.change, item])

        if(title==='Authors'){
            if(e.target.checked){
                setSelectedAuthors([...selectedAuthors,item])
            }
            else{
                setSelectedAuthors(selectedAuthors.filter((single)=> single.id !== item.id))
            }

        }
        if(title==='Subjects'){
            if(e.target.checked) {
                setSelectedSubjects([...selectedSubjects, item])
            }
            else{
                setSelectedSubjects(selectedSubjects.filter((single)=> single.id !== item.id))
            }
        }
        if(title==='Categories'){
            if(e.target.checked) {
                setSelectedCategories([...selectedCategories, item])
            }
            else{
                setSelectedCategories(selectedCategories.filter((single)=> single.id !== item.id))
            }
        }

        // console.log(e,item,index)
        // dispatch(bookActions.changeCheckboxes({e, item, index}))
        // if(title === 'Levels'){
        //     if (e.target.checked) {
        //         // levels[index] = { name: item.name, status: true, }
        //         // const newItems = levels
        //         // setLevels(levels=>newItems)
        //         // setTitle('Levels')
        //         // setLevels([
        //         //     ...levels,
        //         //     {
        //         //         name: item.name,
        //         //         status: true,
        //         //     },
        //         // ]);
        //     } else {
        //         // setLevels([
        //         //     ...levels,
        //         //     {
        //         //         name: item.name,
        //         //         status: false,
        //         //     },
        //         // ]);
        //         levels[index] = { name: item.name, status: false, }
        //         const newLevels = levels
        //         setLevels(newLevels)
        //     }
        // }
        // else if(title === 'Authors'){
        //     if (e.target.checked) {
        //         authors[index] = { name: item.name, status: true, }
        //         const newItems = authors
        //         setAuthors(newItems)
        //         // setAuthors([
        //         //     ...authors,
        //         //     {
        //         //         name: item.name,
        //         //         status: true,
        //         //     },
        //         // ]);
        //     } else {
        //         // remove from list
        //         authors[index] = { name: item.name, status: false, }
        //         const newItems = authors
        //         setAuthors(newItems)
        //         // setAuthors(
        //         //     authors.filter((single) => single.id !== item.id),
        //         // );
        //     }
        // }
        // else if(title === 'Subjects'){
        //     if (e.target.checked) {
        //         subjects[index] = { name: item.name, status: true, }
        //         const newItems = subjects
        //         setSubjects(newItems)
        //         // setSubjects([
        //         //     ...subjects,
        //         //     {
        //         //         name: item.name,
        //         //         status: true,
        //         //     },
        //         // ]);
        //     } else {
        //         // remove from list
        //         subjects[index] = { name: item.name, status: false, }
        //         const newItems = subjects
        //         setSubjects(newItems)
        //         // setSubjects(
        //         //     subjects.filter((single) => single.id !== item.id),
        //         // );
        //     }
        // }
        // else{
        //     if (e.target.checked) {
        //         categories[index] = { name: item.name, status: true, }
        //         const newItems = categories
        //         setCategories(newItems)
        //         // setCategories([
        //         //     ...categories,
        //         //     {
        //         //         name: item.name,
        //         //         status: true,
        //         //     },
        //         // ]);
        //     } else {
        //         // remove from list
        //         categories[index] = { name: item.name, status: false, }
        //         const newItems = categories
        //         setCategories(newItems)
        //         // setCategories(
        //         //     categories.filter((single) => single.id !== item.id),
        //         // );
        //     }
        // }
    }

    const savePapers = () =>{
        ReactS3Client
            .uploadFile(inputs.file,inputs.file.name)
            .then(data => {
                dispatch(addBookData(inputs, selectedAuthors, selectedCategories, selectedSubjects,history))
                console.log(data)
            })
            // .then(data => console.log(data.key))
            .catch(err => console.error(err))
        console.log(coverImage)
    }

    useEffect(() => {
        dispatch(fetchCategoryData())
        dispatch(fetchAuthorData())
        dispatch(fetchBookLevelData())
        dispatch(fetchSubjectData())
        dispatch(fetchLanguageData())
    }, [dispatch])

    return (
        <div className="container px-3 mx-auto max-w-screen-lg mt-5">
            <div className="flex md:flex-row bg-white px-8 py-5 gap-8">
                <div className="flex flex-col w-full md:w-2/12">
                    <label htmlFor="cover_image" className="border w-full border-gray-300 h-52 flex items-center justify-center cursor-pointer">
                        {
                            coverImage.length > 0 &&
                            <img className="p-2 h-full object-contain" src={coverImage} alt=""/>
                        }
                        {
                            coverImage.length === 0 &&
                            <div className="flex flex-col items-center space-y-2 p-2">
                                <CloudUpload className="h-18 w-18 text-gray-300" />
                                <div className="text-gray-300 text-center">Upload Cover Image</div>
                            </div>
                        }
                        <input onChange={(e)=> {
                            setCoverImage(URL.createObjectURL(e.target.files[0]))
                            // setCoverImage({ ...coverImage, picture: URL.createObjectURL(e.target.files[0]) })
                            setInputs({...inputs,file:e.target.files[0]})
                            console.log(inputs)
                        }} id="cover_image" type="file" className="hidden"/>
                    </label>
                </div>
                <div className="flex flex-col space-y-2 add-book w-full md:w-10/12">
                    <TextInput
                        label={language.name}
                        placeholder={language.name}
                        onChange={(e)=>setInputs({...inputs,name:e.target.value})}
                    />
                    <TextInput
                        type="number"
                        label={language.number_of_pages}
                        placeholder={language.number_of_pages}
                        onChange={(e)=>setInputs({...inputs,number_of_pages:e.target.value})}
                    />
                    <div className="flex flex-col mt-3">
                        <div className="flex space-x-1 mb-1">
                            <span>{language.language}</span>
                            <span className="text-red-400">*</span>
                            <GrayQuestionMark />
                        </div>
                        <div className="flex relative items-center">
                            <select
                                onChange={(e)=>setInputs({...inputs,language:e.target.value})}
                                className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                <option value="">{language.choose}</option>
                                {
                                    languages.map((item)=>{
                                        return(
                                            <option value={item.code}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <CircleArrowDownward />
                        </div>
                    </div>
                    {
                        initList.map((item,index)=>{
                            return(
                                <AddFeature
                                    setChange={item.setChange}
                                    change={item.change}
                                    setDialogBody={setDialogBody}
                                    key={index}
                                    title={item.title}
                                    item={item.list_item}
                                    setOpenAdd={setOpenAdd}
                                />
                            )
                        })
                    }
                    <div className="flex flex-col mt-3">
                        <div className="flex space-x-1 mb-1">
                            <span>{language.level}</span>
                            <span className="text-red-400">*</span>
                            <GrayQuestionMark />
                        </div>
                        <div className="flex relative items-center">
                            <select
                                onChange={(e)=>setInputs({...inputs,level:e.target.value})}
                                className="text-gray-400 w-full appearance-none text-sm rounded-md bg-gray-100 outline-none p-2">
                                <option value="">{language.choose}</option>
                                {
                                    levels.map((item)=>{
                                        return(
                                            <option value={item.id}>{item.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <CircleArrowDownward />
                        </div>
                    </div>
                    <button type="button" onClick={savePapers}
                            className="mt-3 inline-flex w-1/3 justify-center ml-auto rounded-md shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none sm:mt-0 sm:text-sm">
                        {language.save}
                    </button>
                </div>
            </div>
            <Transition.Root show={openAdd} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelAddRef} onClose={setOpenAdd}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                              &#8203;
                            </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="flex flex-col mt-3">
                                        <div className="sm:flex flex-col">
                                            <div className="flex justify-between items-center pb-2 border-b border-gray-200">
                                                <div className="flex space-x-2 items-center">
                                                    <CirclePlus fillColor={"#0E9E57"}/>
                                                    <h3>Add {title}</h3>
                                                </div>
                                                <CrossCircle closeModal={()=>setOpenAdd(false)} />
                                            </div>
                                        </div>
                                        <div className="flex space-x-1 mb-1">
                                            <span>Choose {title}</span>
                                            <span className="text-red-400">*</span>
                                            <GrayQuestionMark/>
                                        </div>
                                        <div className="flex flex-wrap space-y-2 relative items-center">
                                            <BookDialogBody
                                                handleChanges={handleChanges}
                                                items={dialogBody.body}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 mb-2 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                            setOpenAdd(false)
                                            // dispatch(bookActions.changeCheckboxes(station))
                                        }}
                                        ref={cancelAddRef}
                                    >
                                        {language.continue}
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}

export default AddBook;