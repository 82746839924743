import { ApiUrl } from "../../components/utils/Urls";
import {ministryActions} from "./ministrySlice";
import {callToaster} from "../../components/utils/ToastText";

export const fetchMinistryData = cityId => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'system/ministry', {
                method: 'POST',
                body: 'cityId=' + cityId,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch ministry data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const ministryData = await fetchData()
            dispatch(ministryActions.getMinistries(ministryData))
        } catch (error) { }
    }
}

export const updateMinistryData = (id, name) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'system/ministryEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch ministry data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(ministryActions.getIsMinistry(2))
            callToaster("ministry_updated")
        } catch (error) { }
    }
}

export const deleteMinistryData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'system/ministryDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch ministry data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(ministryActions.getIsMinistry(2))
            callToaster("ministry_deleted")
        } catch (error) { }
    }
}

export const addMinistryData = (cityId,name) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'system/ministryAdd', {
                method: 'POST', body: 'cityId='+ cityId +'&name=' + name,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch ministry data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(ministryActions.getIsMinistry(2))
            callToaster("ministry_added")
        } catch (error) { }
    }
}