import { createSlice } from "@reduxjs/toolkit";

const readBookSlice = createSlice({
    name: 'readBook',
    initialState: {
        books: [],
        book: '',
        level: '',
        pages: [],
        bookInfo: '',
        isChanged: false,
        authors:[{name:""}],
        hasChanged: false,
        responseId: '',
    },
    reducers: {
        getBooks(state, action) {
            state.books = action.payload.list
            state.book = action.payload.list[0]
            state.hasChanged = true
        },
        getBook(state, action) {
            state.book = action.payload
        },
        getLevel(state, action) {
            state.level = action.payload.id
        },
        getPages(state, action) {
            state.pages = action.payload.page
            state.bookInfo = action.payload.book
            state.isChanged = true
        },
        getAuthors(state,action){
            state.authors = action.payload
        },
        getResponseId(state,action){
            state.responseId = action.payload
        }
    }
})

export const readBookActions = readBookSlice.actions

export default readBookSlice