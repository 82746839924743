import { createSlice } from "@reduxjs/toolkit";

const educationTypeSlice = createSlice({
    name: 'educationTypes',
    initialState: {
        educationTypes: [],
        educationType: '',
        isEducationType:false,
    },
    reducers: {
        getEducationTypes(state, action) {
            state.educationTypes = action.payload
        },
        getEducationType(state, action) {
            state.educationType = action.payload
        },
        getIsEducationType(state,action){
            state.isEducationType = !state.isEducationType
        }
    }
})

export const educationTypeActions = educationTypeSlice.actions

export default educationTypeSlice