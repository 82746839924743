import { ApiUrl } from "../../components/utils/Urls";
import { classActions } from "./classSlice";
import {callToaster} from "../../components/utils/ToastText";

export const fetchClassData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'library/schoolroom', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch class data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const classData = await fetchData()
            const singleClass = classData[0]
            dispatch(classActions.getClasses(classData))
            dispatch(classActions.getClass(singleClass))
        } catch (error) { }
    }
}

export const updateClassData = (id, classData) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'library/schoolroomEdit', {
                method: 'POST', body: 'id=' + id + '&name=' + classData.name + '&gradeId=' + classData.id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch class data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(classActions.getClass(''))
            callToaster("class_updated")
        } catch (error) { }
    }
}

export const deleteClassData = id => {
    return async (dispatch) => {
        const deleteData = async () => {
            const response = await fetch(ApiUrl + 'library/schoolroomDelete', {
                method: 'POST', body: 'id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch class data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await deleteData()
            dispatch(classActions.getClass(''))
            callToaster("class_deleted")
        } catch (error) { }
    }
}

export const addClassData = (addedClass) => {
    return async (dispatch) => {
        const addData = async () => {
            const response = await fetch(ApiUrl + 'library/schoolroomAdd', {
                method: 'POST', body: 'name=' + addedClass.name + '&gradeId=' + addedClass.id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch class data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await addData()
            dispatch(classActions.getClass(''))
            callToaster("class_added")
        } catch (error) { }
    }
}