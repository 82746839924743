import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import GrayQuestionMark from "../../assets/icons/GrayQuestionMark";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {tableTheme} from "../../components/customTable/TableTheme";
import ActionsOptions from "../../components/customTable/ActionsOptions";
import ActionsWrapper from "../../components/customTable/ActionsWrapper";
import EditButton from "../../components/customTable/EditButton";
import {faqActions} from "../../store/faq/faqSlice";
import DeleteButton from "../../components/customTable/DeleteButton";
import SearchButton from "../../components/customTable/SearchButton";
import FilterButton from "../../components/customTable/FilterButton";
import {StaticTableOptions} from "../../components/customTable/StaticTableOptions";
import AddButton from "../../components/customTable/AddButton";
import {addFaqData, deleteFaqData, fetchFaqData, updateFaqData} from "../../store/faq/faqActions";
import MUIDataTable from "mui-datatables";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import AddDialog from "../../components/dialogs/AddDialog";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogHeader from "../../components/dialogs/DialogHeader";
import TextInput from "../../components/textInput/TextInput";

function Faq({ getMuiTheme = () => createTheme(tableTheme) }) {

    const dispatch = useDispatch()
    const language = useSelector(state => state.userInfos.language)
    const faqs = useSelector(state => state.faqs.faqs)
    const isFaq = useSelector(state=>state.faqs.faq)
    const TableOptions = useSelector(state => state.tableOptions.options)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [faqId, setFaqId] = useState(false)
    const [isFirst,setIsFirst] = useState(false)
    const [faqType,setFaqType] = useState("teacher")

    const cancelAddRef = useRef(null)
    const cancelDeleteRef = useRef(null)

    const [faq, setFaq] = useState({type:"teacher"})

    const cols = [
        {
            name: "question",
            label: language.question,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>{value}</div>
                    );
                }
            }
        },
        {
            name: "answer",
            label: language.answer,
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div>{value}</div>
                    );
                }
            }
        },
        {
            name: "",
            options: {
                ...ActionsOptions,
                customBodyRender: (value, tableMeta, rowIndex) => {
                    return (
                        <ActionsWrapper>
                            <EditButton
                                onClick={() => {
                                    setFaqId(faqs[tableMeta.rowIndex].id)
                                    setOpenEdit(true)
                                    setOpenAdd(true)
                                    setFaq(faqs[tableMeta.rowIndex])
                                    dispatch(faqActions.getFaq(faqs[tableMeta.rowIndex]))
                                }}
                            />
                            <DeleteButton
                                onClick={() => {
                                    setFaq(faqs[tableMeta.rowIndex])
                                    dispatch(faqActions.getFaq(faqs[tableMeta.rowIndex]))
                                    setOpenDelete(true)
                                }
                                }
                            />
                        </ActionsWrapper>
                    );
                }
            }
        }];

    const components = {
        icons: {
            SearchIcon: SearchButton,
            ViewColumnIcon: FilterButton,
        }
    }

    const options = {
        ...TableOptions, ...StaticTableOptions,
        selectableRows: "multiple",
        customToolbar: () => {
            return (
                <div className="flex items-center">
                    <FormControl sx={{ m: 1, minWidth: 120, marginRight:0, marginLeft:0, '& .MuiOutlinedInput-notchedOutline': {
                            border:'none',
                        }}}>
                        <Select
                            sx={{ m: 1, minWidth: 120, maxHeight:40,backgroundColor:'white',padding:'9.5px 14px',border:'none' }}
                            value={faqType}
                            onChange={(e)=> {
                                setFaqType(e.target.value)
                                setFaq({ ...faq, type: e.target.value })
                                dispatch(fetchFaqData(e.target.value))
                            }}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="teacher">Teacher</MenuItem>
                            <MenuItem value="student">Student</MenuItem>
                            <MenuItem value="parent">Parent</MenuItem>
                            <MenuItem value="admin">Admin</MenuItem>
                        </Select>
                    </FormControl>
                    <AddButton onClick={() => {
                        setOpenAdd(true)
                        setOpenEdit(false)
                    }} />
                </div>
            )
        }
    }

    const saveFaq = () => {
        dispatch(addFaqData(faq))
        setOpenAdd(false)
    }

    const deleteFaq = () => {
        dispatch(deleteFaqData(faq))
        setOpenDelete(false)
    }

    const editFaq = () => {
        dispatch(updateFaqData(faqId, faq))
        setOpenEdit(false)
        setOpenAdd(false)
    }

    useEffect(() => {
        if(!isFirst){
            dispatch(fetchFaqData("teacher"))
            setIsFirst(true)
        }
    }, [dispatch,isFaq])

    return (
        <div>
            <div className="container mt-3 px-3 mx-auto max-w-screen-lg data-table">
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        className="w-full z-0"
                        title={language.faqs}
                        data={faqs}
                        columns={cols}
                        options={options}
                        components={components}
                    />
                </MuiThemeProvider>
            </div>
            <DeleteDialog
                openDelete={openDelete}
                cancelDeleteRef={cancelDeleteRef}
                setOpenDelete={setOpenDelete}
                deleteItem={deleteFaq}
                title={language.delete_faq}
                warning={language.faq_delete_warning}
            />
            <AddDialog
                isEdit={openEdit}
                openAdd={openAdd}
                setOpenEdit={setOpenEdit}
                setOpenAdd={setOpenAdd}
                cancelAddRef={cancelAddRef}
                saveItem={saveFaq}
                editItem={editFaq}
            >
                <DialogHeader
                    closeModal={() => setOpenAdd(false)}
                    title={openEdit ? language.edit_faq : language.add_faq}
                />
                <div className="flex gap-2 mt-3">
                    <div className="font-bold underline w-2/12">Deutsch :</div>
                    <div className="flex flex-col flex-1 w-10/12">
                        <TextInput
                            label={language.question}
                            value={openEdit ? faq.question : null}
                            onChange={(e) => setFaq({ ...faq, question: e.target.value })}
                            placeholder={language.question}
                        />
                        <div className="flex flex-col mt-3">
                            <div className="flex space-x-1 mb-1">
                                <span>{language.answer}</span>
                                <span className="text-red-400">*</span>
                                <GrayQuestionMark />
                            </div>
                            <textarea value={openEdit ? faq.answer : null} onChange={(e) => setFaq({ ...faq, answer: e.target.value })} placeholder={language.answer} className="text-gray-400 text-sm resize-none rounded-md bg-gray-100 outline-none p-2" type="text">
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className="flex gap-2 mt-3">
                    <div className="font-bold underline w-2/12">English :</div>
                    <div className="flex flex-col flex-1 w-10/12">
                        <TextInput
                            label={language.question}
                            value={openEdit ? faq.question : null}
                            onChange={(e) => setFaq({ ...faq, question: e.target.value })}
                            placeholder={language.question}
                        />
                        <div className="flex flex-col mt-3">
                            <div className="flex space-x-1 mb-1">
                                <span>{language.answer}</span>
                                <span className="text-red-400">*</span>
                                <GrayQuestionMark />
                            </div>
                            <textarea value={openEdit ? faq.answer : null} onChange={(e) => setFaq({ ...faq, answer: e.target.value })} placeholder={language.answer} className="text-gray-400 text-sm resize-none rounded-md bg-gray-100 outline-none p-2" type="text">
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className="flex gap-2 mt-3">
                    <div className="font-bold underline w-2/12">Turkish :</div>
                    <div className="flex flex-col flex-1 w-10/12">
                        <TextInput
                            label={language.question}
                            value={openEdit ? faq.question : null}
                            onChange={(e) => setFaq({ ...faq, question: e.target.value })}
                            placeholder={language.question}
                        />
                        <div className="flex flex-col mt-3">
                            <div className="flex space-x-1 mb-1">
                                <span>{language.answer}</span>
                                <span className="text-red-400">*</span>
                                <GrayQuestionMark />
                            </div>
                            <textarea value={openEdit ? faq.answer : null} onChange={(e) => setFaq({ ...faq, answer: e.target.value })} placeholder={language.answer} className="text-gray-400 text-sm resize-none rounded-md bg-gray-100 outline-none p-2" type="text">
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className="flex gap-2 mt-3">
                    <div className="font-bold underline w-2/12">عربي:</div>
                    <div className="flex flex-col flex-1 w-10/12">
                        <TextInput
                            label={language.question}
                            value={openEdit ? faq.question : null}
                            onChange={(e) => setFaq({ ...faq, question: e.target.value })}
                            placeholder={language.question}
                        />
                        <div className="flex flex-col mt-3">
                            <div className="flex space-x-1 mb-1">
                                <span>{language.answer}</span>
                                <span className="text-red-400">*</span>
                                <GrayQuestionMark />
                            </div>
                            <textarea value={openEdit ? faq.answer : null} onChange={(e) => setFaq({ ...faq, answer: e.target.value })} placeholder={language.answer} className="text-gray-400 text-sm resize-none rounded-md bg-gray-100 outline-none p-2" type="text">
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className="flex gap-2 mt-3">
                    <div className="font-bold underline w-2/12">سۆرانی:</div>
                    <div className="flex flex-col flex-1 w-10/12">
                        <TextInput
                            label={language.question}
                            value={openEdit ? faq.question : null}
                            onChange={(e) => setFaq({ ...faq, question: e.target.value })}
                            placeholder={language.question}
                        />
                        <div className="flex flex-col mt-3">
                            <div className="flex space-x-1 mb-1">
                                <span>{language.answer}</span>
                                <span className="text-red-400">*</span>
                                <GrayQuestionMark />
                            </div>
                            <textarea value={openEdit ? faq.answer : null} onChange={(e) => setFaq({ ...faq, answer: e.target.value })} placeholder={language.answer} className="text-gray-400 text-sm resize-none rounded-md bg-gray-100 outline-none p-2" type="text">
                            </textarea>
                        </div>
                    </div>
                </div>
            </AddDialog>
        </div>
    );
}

export default Faq;