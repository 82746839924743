import { tableOptionsActions } from "./tableOptionsSlice";
import TableFooter from "../components/customTable/TableFooter";
import AdminLanguage from "../components/language/AdminLanguage";


//This is neccessary for dynamic language on tableoptions. We can call options on every single page of course.
//That means if there are 10 pages, then we will call entire options 10 times. There could be a better way. i did it that way.
export const fetchTableOptionsData = () => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch('https://mylesson-62cc8-default-rtdb.firebaseio.com/userSettings/-MmUJuChLkvh54r44lbm.json')

            if (!response.ok) {
                throw new Error('Could not fetch cart data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            const langData = await fetchData()
            const state = AdminLanguage[langData.language]
            dispatch(tableOptionsActions.getTableOptions({
                textLabels: {
                    body: {
                        noMatch: state.no_result_found,
                        toolTip: state.order,
                        columnHeaderTooltip: () => state.order
                    },
                    viewColumns: {
                        title: state.show_columns,
                    },
                    toolbar: {
                        search: state.search,
                        viewColumns: state.view_columns,
                        filterTable: state.filter,
                    },
                    selectedRows: {
                        text: state.rows_selected,
                        delete: state.delete,
                        deleteAria: state.delete_selected_rows,
                    }
                }
            }))
        } catch (error) { }
    }
}