import { ApiUrl } from "../../components/utils/Urls";
import { profileActions } from "./profileSlice";
import {toast} from "react-toastify";
import {callToaster} from "../../components/utils/ToastText";

export const updateUserProfileData = (id,userInfos) => {
    return async (dispatch) => {
        const updateData = async () => {
            const response = await fetch(ApiUrl + 'user/profilUpdate', {
                // method: 'POST', body: 'id=1250&firstName=' + userInfos.firstName + '&middleName=' + userInfos.middleName + '&lastName=' + userInfos.lastName + '&phone=5555555555&birthday=1&timeZone=',
                method: 'POST', body: 'id=' + id + '&firstName=' + userInfos.firstName + '&mail=test&middleName=' + userInfos.middleName + '&lastName=' + userInfos.lastName + '&phone=' + userInfos.phone +'&birthdate=' +userInfos.birthdate+ '&timeZone=' + userInfos.timeZone,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Access-Control-Allow-Origin":"*",
                    "Access-Control-Allow-Headers":"X-Requested-With"
                }
            })

            if (!response.ok) {
                throw new Error('Could not fetch student profile data!')
            }
            const data = await response.json()
            return data;
        }

        try {
            await updateData()
            dispatch(profileActions.getIsChanged())
            localStorage.setItem("userInfos", JSON.stringify(userInfos))
            callToaster("profile_updated")
        } catch (error) { }
    }
}

export const updateUserPasswordData = (password,id) => {
    return async (dispatch) => {
        const fetchData = async () => {
            const response = await fetch(ApiUrl + 'user/password', {
                method: 'POST',
                body:'password=' + password +'&id=' + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })

            if (!response.ok) {
                throw new Error('Could not update parent password data!')
            }
            const data = await response.json()
            callToaster("password_updated")
            return data;
        }

        try {
            await fetchData()
        } catch (error) { }
    }
}