import useSound from 'use-sound';
import Microphone from "../../assets/icons/Microphone";
import { Pause, PlayArrow, Refresh, Stop } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from 'react';
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { bookListenedData, updateListeningData } from "../../store/readBook/readBookActions";
import { useDispatch, useSelector } from "react-redux";
function SoundPlayer(props) {
    // const sound = useSound('https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3');
    // const [play, { stop, pause }] = sound

    const dispatch = useDispatch()

    const identity = useSelector(state => state.userInfos.identity)
    const responseId = useSelector(state => state.readBook.responseId)

    const audioRef = useRef(null)
    const [recordIcon, setRecordIcon] = useState(false)
    const [playIcon, setPlayIcon] = useState(false)
    const [audioData, setAudioData] = useState(null)
    const [audioDuration, setAudioDuration] = useState(null)
    const [recordState, setRecordState] = useState(null)

    const stopRecord = (audioData) => {
        setRecordState(RecordState.STOP)
        setPlayIcon(!playIcon)
        // console.log('audioData', audioData)
        // console.log(audioRef.current.currentTime)
        setRecordIcon(false)
        console.log(audioData)
    }

    const pauseRecord = () => {
        setRecordState(RecordState.PAUSE)
        setPlayIcon(!playIcon)
    }

    const startRecord = () => {
        setRecordState(RecordState.START)
        setRecordIcon(!recordIcon)
        setPlayIcon(!playIcon)
        console.log("started")
        if (playIcon) {
            setRecordState(RecordState.PAUSE)
        }
        else {
            setRecordState(RecordState.START)
        }
    }

    const restartRecord = () => {
        setPlayIcon(false)
        setRecordIcon(false)
    }

    const onStop = (data) => {
        setAudioData(data)
        console.log(audioData)
    }

    const startListen = () => {
        setPlayIcon(!playIcon)
        if (playIcon) {
            audioRef.current.pause()
        }
        else {
            audioRef.current.play()
        }
    }

    // const pauseListen = () => {
    //     pause()
    // }

    // const stopListen = (second) => {
    //     stop()
    //     setPlayIcon(false)
    // }

    const onPlaybackPause = () => {
        setPlayIcon(false)
        // console.log(audioRef.current.currentTime.toFixed(2))
        const getDuration = Math.round(audioRef.current.duration)
        const getCurrent = Math.round(audioRef.current.currentTime)
        const getDurationsSeconds = Math.floor(getDuration / 60)
        const getCurrentsSeconds = Math.floor(getCurrent / 60)
        // console.log(getDurationsSeconds)
        // console.log(getDuration - 60*getDurationsSeconds)
        // console.log(getCurrentsSeconds)
        // console.log(getCurrent - 60*getCurrentsSeconds)

        console.log(getDuration - getCurrent)
        if (getDuration - getCurrent === 0) {
            dispatch(bookListenedData(responseId, identity))
        }
        dispatch(updateListeningData(responseId, getCurrent, identity))
    }

    const onPlaybackStart = () => {
        // console.log(audioRef.current.currentTime.toFixed(2))
        const getTime = (audioRef.current.duration)
        console.log(getTime)
    }

    return (
        <div className={`flex p-2 bg-white rounded-md flex-col z-10 items-center absolute left-0 bottom-8 mb-2 ${props.isRecordHidden ? 'hidden' : ''}`}>
            <audio
                onPause={onPlaybackPause}
                onPlay={onPlaybackStart}
                src="https://filesamples.com/samples/audio/mp3/sample3.mp3"
                controls
                className="hidden"
                ref={audioRef}
            />
            {
                props.record &&
                <div onClick={!recordIcon ? startRecord : stopRecord} className="bg-red-600 p-2 w-8 h-8 text-white flex items-center justify-center rounded-md cursor-pointer">
                    <div className="absolute bottom-0 right-16 text-white flex items-center gap-2">
                        <AudioReactRecorder
                            state={recordState}
                            canvasWidth={0}
                            canvasHeight={0}
                            onStop={onStop}
                            backgroundColor="rgb(255,255,255)"
                        />
                        <audio
                            ref={audioRef}
                            id="audio"
                            controls
                            src={audioData ? audioData.url : null}
                        >

                        </audio>
                    </div>
                    {
                        recordIcon ? <Stop /> : <div className="h-4 w-4 rounded-full bg-white"></div>
                    }
                </div>
            }
            {/*{*/}
            {/*    playIcon ?*/}
            {/*        <div onClick={props.record ? stopRecord : stopListen} className="bg-red-600 p-2 w-8 h-8 text-white flex items-center justify-center rounded-md cursor-pointer">*/}
            {/*            <Stop />*/}
            {/*        </div> :*/}
            {/*        <div onClick={props.record ? restartRecord : stopListen} className="bg-soft-blue p-2 w-8 h-8 text-white flex items-center justify-center rounded-md cursor-pointer">*/}
            {/*            <Refresh />*/}
            {/*        </div>*/}
            {/*}*/}
            {
                !props.record &&
                <div onClick={props.record ? startRecord : startListen} className="bg-primary p-2 w-8 h-8 text-white flex items-center justify-center rounded-md cursor-pointer">
                    {
                        playIcon ? <Pause /> : <PlayArrow />
                    }
                </div>
            }
        </div>
    )
}

export default SoundPlayer
