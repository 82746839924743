import React, { useEffect } from 'react';
import axios from "axios";
import { Route, Switch } from "react-router-dom";
import ReadBook from "../pages/ReadBook";
import Quiz from "../pages/student/Quiz";
import { useDispatch,useSelector } from "react-redux";

function StudentInteraction(props) {
    const language = useSelector(state => state.userInfos.language)

    const dispatch = useDispatch()

    useEffect(() => {
        if (language.id === "English" || language.id === "Deutsch" || language.id === "Türkçe") {
            document.body.style.direction = "ltr";
        }
        else {
            document.body.style.direction = "rtl";
        }
    }, [language, dispatch])
    return (
        <div>
            <Switch>
                <Route exact path="/books/:id" component={ReadBook} />
                <Route exact path="/my-library/quiz" component={Quiz} />
            </Switch>
        </div>
    );
}

export default StudentInteraction