import React, {Fragment} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import {useSelector} from "react-redux";

function AddDialog(props) {
    const language = useSelector(state => state.userInfos.language)
    return (
        <Transition.Root show={props.openAdd} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={props.cancelAddRef} onClose={props.setOpenAdd}>
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                            &#8203;
                        </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 max-h-96 overflow-auto">
                                <div className="sm:flex flex-col">
                                    {props.children}
                                </div>
                            </div>
                            <div className="mt-5 mb-2 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    disabled={props.isBtnDisabled}
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-primary text-base font-medium text-white focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={props.isEdit ? props.editItem : props.saveItem}
                                    ref={props.cancelAddRef}
                                >
                                    {language.continue}
                                </button>
                                <button
                                    type="button"
                                    className="w-full mt-3 sm:mt-0 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dark-primary text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => {
                                        props.setOpenAdd(false)
                                        props.setOpenEdit(false)
                                        props.setOpenAssign && props.setOpenAssign(false)
                                    }}
                                >
                                    {language.cancel}
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export default AddDialog;