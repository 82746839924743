import React from 'react';

function Book ({fill}) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.333" height="22" viewBox="0 0 18.333 22">
                <path id="fi-rr-book" d="M15.75,0H6.583A4.589,4.589,0,0,0,2,4.583v13.75A3.667,3.667,0,0,0,5.667,22H15.75a4.589,4.589,0,0,0,4.583-4.583V4.583A4.589,4.589,0,0,0,15.75,0ZM18.5,4.583V14.667H7.5V1.833h8.25A2.75,2.75,0,0,1,18.5,4.583ZM5.667,1.991V14.667a3.648,3.648,0,0,0-1.833.492V4.583A2.75,2.75,0,0,1,5.666,1.991ZM15.75,20.167H5.667a1.833,1.833,0,0,1,0-3.667H18.5v.917A2.75,2.75,0,0,1,15.75,20.167Z" transform="translate(-2)" fill={fill}/>
            </svg>
        </div>
    );
}

export default Book;