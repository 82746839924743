import React from 'react';

function Student(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20.625" height="22" viewBox="0 0 20.625 22">
                <g id="student" transform="translate(-10.844 0)">
                    <g id="Group_33" data-name="Group 33" transform="translate(10.844 0)">
                        <g id="Group_32" data-name="Group 32">
                            <path id="Path_14" data-name="Path 14" d="M35.336,10.424c0-7.254.02-6.792-.047-6.959a.644.644,0,0,0-.42-.861L25.845.025a.644.644,0,0,0-.354,0L16.468,2.6a.645.645,0,0,0,0,1.239l3.4.971V7.735a6.562,6.562,0,0,0,3.174,5.746l-.356.711A7,7,0,0,0,18.072,16.3,7.174,7.174,0,0,0,16,21.356a.645.645,0,0,0,.645.645H34.691a.645.645,0,0,0,.645-.645A7.173,7.173,0,0,0,33.264,16.3a7,7,0,0,0-4.613-2.112l-.356-.712a6.562,6.562,0,0,0,3.174-5.746V4.814l2.578-.737v6.346a1.937,1.937,0,0,0-1.289,1.823v1.289a.645.645,0,0,0,.645.645H35.98a.645.645,0,0,0,.645-.645V12.247A1.937,1.937,0,0,0,35.336,10.424ZM25.023,20.711h-7.7a5.848,5.848,0,0,1,5.428-5.232l2.272,3.408Zm-1.19-5.933.4-.8a5.255,5.255,0,0,0,2.871,0l.4.8L25.668,17.53Zm4.751.7a5.848,5.848,0,0,1,5.428,5.232h-7.7V18.887Zm-2.916-2.588a4.721,4.721,0,0,1-4.425-4.15,10.907,10.907,0,0,0,4.425.928,10.908,10.908,0,0,0,4.425-.927A4.721,4.721,0,0,1,25.668,12.891Zm4.5-5.884a.588.588,0,0,1-.3.419,9.558,9.558,0,0,1-4.2.953,9.557,9.557,0,0,1-4.2-.953.621.621,0,0,1-.316-.56V5.182c4.678,1.336,4.383,1.263,4.512,1.263s-.167.073,4.512-1.263A16.269,16.269,0,0,1,30.166,7.007Zm-4.5-1.876L18.991,3.223l6.677-1.908,6.677,1.908Zm9.668,7.76H34.047v-.645a.645.645,0,0,1,1.289,0Z" transform="translate(-16 0)" fill="currentColor"/>
                        </g>
                    </g>
                    <g id="Group_35" data-name="Group 35" transform="translate(28.891 18.047)">
                        <g id="Group_34" data-name="Group 34">
                            <path id="Path_15" data-name="Path 15" d="M317.934,420h-1.289a.645.645,0,1,0,0,1.289h1.289a.645.645,0,0,0,0-1.289Z" transform="translate(-316 -420.001)" fill="currentColor"/>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default Student;