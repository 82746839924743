import React from 'react';

function Filter(props) {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="17.563" height="19.165" viewBox="0 0 17.563 19.165">
                <path id="fi-rr-filter" d="M11.382,19.165a.8.8,0,0,1-.479-.16l-3.194-2.4a.8.8,0,0,1-.319-.639V11.483l-5.6-6.3A3.114,3.114,0,0,1,4.115,0H15.454A3.114,3.114,0,0,1,17.78,5.181l-5.6,6.3v6.883A.8.8,0,0,1,11.382,19.165Zm-2.4-3.593,1.6,1.2V11.18a.8.8,0,0,1,.2-.53l5.8-6.53A1.517,1.517,0,0,0,15.454,1.6H4.115A1.517,1.517,0,0,0,2.98,4.119l5.8,6.531a.8.8,0,0,1,.2.53Z" transform="translate(-1.002)" fill="#fff"/>
            </svg>
        </div>
    );
}

export default Filter;